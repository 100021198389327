import React from "react";

import { Button } from "@material-ui/core";

import SocialLogin from "react-social-login";

class SocialButton extends React.Component {
  render() {
    const { children, triggerLogin, ...props } = this.props;

    return (
      <Button
        variant="hovered-social"
        color="primary"
        type="submit"
        onClick={triggerLogin}
        {...props}
        style={{
          margin: `${
            children.toLocaleLowerCase().includes("sign up") ? "8px" : "16px"
          }  0px`,
        }}
      >
        {children}
      </Button>
    );
  }
}

export default SocialLogin(SocialButton);
