import React, { createContext, useContext, useEffect, useReducer } from "react";

import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../utils";

const defaultStateProject = {
  project: {}, // Project, if any project was selected through the menu
};

const reducer = (previousState, newState) => {
  if (newState === null) {
    removeLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_LOCAL_STORAGE_KEY,
    });

    return defaultStateProject;
  }

  return { ...previousState, ...newState };
};

const ProjectContext = createContext(defaultStateProject);

const ProjectProvider = ({ children }) => {
  const [project, setProject] = useReducer(
    reducer,
    getLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_LOCAL_STORAGE_KEY,
      defaultState: defaultStateProject,
    })
  );

  useEffect(() => {
    setLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_LOCAL_STORAGE_KEY,
      data: project,
    });
  }, [project]);

  return (
    <ProjectContext.Provider value={{ project, setProject }}>
      {children}
    </ProjectContext.Provider>
  );
};

const useProject = () => {
  const { project, setProject } = useContext(ProjectContext);

  if (!project)
    throw new Error("Hook useProject must be used over ProjectProvider");

  return { project, setProject };
};

export { ProjectContext, ProjectProvider, defaultStateProject, useProject };
