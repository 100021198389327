const countries = [
  {
    "Countries in the world": "Afghanistan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Albania",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Algeria",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Andorra",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "19 October 2010",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32010D0625",
  },
  {
    "Countries in the world": "Angola",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Antigua and Barbuda",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Argentina",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "30 June 2003",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32003D0490",
  },
  {
    "Countries in the world": "Armenia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Australia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Austria",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Azerbaijan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Bahamas",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Bahrain",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Bangladesh",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Barbados",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Belarus",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Belgium",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Belize",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Benin",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Bhutan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Bolivia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Bosnia and Herzegovina",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Botswana",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Brazil",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Brunei",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Bulgaria",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Burkina Faso",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Burundi",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Cabo Verde",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Cambodia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Cameroon",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Canada",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "20 December 2001",
    "Reason Adequacy":
      "applicable only for commercial organisations https://eur-lex.europa.eu/legal-content/en/TXT/?uri=CELEX%3A32002D0002",
  },
  {
    "Countries in the world": "Central African Republic (CAR)",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Chad",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Chile",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "China",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Colombia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Comoros",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Democratic Republic of the Congo",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Republic of the Congo",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Costa Rica",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Cote d'Ivoire",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Croatia",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Cuba",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Cyprus",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Czechia",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Denmark",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Djibouti",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Dominica",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Dominican Republic",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Ecuador",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Egypt",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "El Salvador",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Equatorial Guinea",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Eritrea",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Estonia",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Eswatini (formerly Swaziland)",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Ethiopia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Faroe Islands",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "5 March 2010",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/en/ALL/?uri=CELEX%3A32010D0146",
  },
  {
    "Countries in the world": "Fiji",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Finland",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "France",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Gabon",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Gambia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Georgia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Germany",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Ghana",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Greece",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Grenada",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Guatemala",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Guernsey",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "21 November 2003",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Guinea",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Guinea-Bissau",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Guyana",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Haiti",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Honduras",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Hungary",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Iceland",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "India",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Indonesia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Iran",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Iraq",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Ireland",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Isle of Man",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "28 April 2004",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32004D0411",
  },
  {
    "Countries in the world": "Israel",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "31 January 2011",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32011D0061",
  },
  {
    "Countries in the world": "Italy",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Jamaica",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Japan",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "23 January 2019",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2019.076.01.0001.01.ENG&toc=OJ:L:2019:076:TOC",
  },
  {
    "Countries in the world": "Jersey",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "8 May 2008",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32008D0393",
  },
  {
    "Countries in the world": "Jordan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Kazakhstan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Kenya",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Kiribati",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Kosovo",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Kuwait",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Kyrgyzstan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Laos",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Latvia",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Lebanon",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Lesotho",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Liberia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Libya",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Liechtenstein",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Lithuania",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Luxembourg",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Madagascar",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Malawi",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Malaysia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Maldives",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Mali",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Malta",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Marshall Islands",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Mauritania",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Mauritius",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Mexico",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Micronesia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Moldova",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Monaco",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Mongolia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Montenegro",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Morocco",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Mozambique",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Myanmar (formerly Burma)",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Namibia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Nauru",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Nepal",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Netherlands",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "New Zealand",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "19 December 2012",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=CELEX%3A32013D0065",
  },
  {
    "Countries in the world": "Nicaragua",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Niger",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Nigeria",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "North Korea",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "North Macedonia (formerly Macedonia)",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Norway",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Oman",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Pakistan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Palau",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Palestine",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Panama",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Papua New Guinea",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Paraguay",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Peru",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Philippines",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Poland",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Portugal",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Qatar",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Romania",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Russia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Rwanda",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Saint Kitts and Nevis",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Saint Lucia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Saint Vincent and the Grenadines",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Samoa",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "San Marino",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Sao Tome and Principe",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Saudi Arabia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Senegal",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Serbia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Seychelles",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Sierra Leone",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Singapore",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Slovakia",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Slovenia",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Solomon Islands",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Somalia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "South Africa",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "South Korea",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "South Sudan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Spain",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Sri Lanka",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Sudan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Suriname",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Sweden",
    "Non adequate": "",
    EU: "EU",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Switzerland",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "26 July 2000",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32000D0518",
  },
  {
    "Countries in the world": "Syria",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Taiwan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Tajikistan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Tanzania",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Thailand",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Timor-Leste",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Togo",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Tonga",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Trinidad and Tobago",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Tunisia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Turkey",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Turkmenistan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Tuvalu",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Uganda",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Ukraine",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "United Arab Emirates (UAE)",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "United Kingdom (UK)",
    "Non adequate": "",
    EU: "/",
    "Adequate non EU": "Adequate - to be followed",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "United States of America (USA)",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "12 July 2016",
    "Reason Adequacy":
      "limited to the Privacy Shield framework https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv%3AOJ.L_.2016.207.01.0001.01.ENG",
  },
  {
    "Countries in the world": "Uruguay",
    "Non adequate": "",
    EU: "",
    "Adequate non EU": "21 August 2012",
    "Reason Adequacy":
      "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX%3A32012D0484",
  },
  {
    "Countries in the world": "Uzbekistan",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Vanuatu",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Vatican City (Holy See)",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Venezuela",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Vietnam",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Yemen",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Zambia",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
  {
    "Countries in the world": "Zimbabwe",
    "Non adequate": "non adequate",
    EU: "",
    "Adequate non EU": "",
    "Reason Adequacy": "nan",
  },
];

export default countries;
