import React, { useEffect } from "react";

import { useDashboard } from "../contexts";

import { ProjectCategories } from "../components/dashboard";

const DashboardProjectCategories = () => {
  const { setDashboard } = useDashboard();

  useEffect(() => {
    setDashboard((previousState) => ({ ...previousState, menu: "dashboard" }));

    // eslint-disable-next-line
  }, []);

  return <ProjectCategories />;
};

export default DashboardProjectCategories;
