import React from "react";

import { CircularProgress, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  loadingButtonCircle: {
    color: theme.palette.purpleTwo,
    margin: ".5px 0",
  },
}));

const LoadingButton = () => {
  const classes = useStyles();

  return <CircularProgress className={classes.loadingButtonCircle} size={18} />;
};

export default LoadingButton;
