import React, { useState, useEffect } from "react";

import {
  Grid,
  Box,
  Typography,
  AppBar,
  // Tab,
  Link,
  makeStyles,
} from "@material-ui/core";

import {
  TabContext,
  // TabList,
  TabPanel,
} from "@material-ui/lab";

import {
  // Code as CodeIcon,
  Add as AddIcon,
  Public as PublicIcon,
  Update as UpdateIcon,
} from "@material-ui/icons";

import moment from "moment";

import { gql, useLazyQuery, useApolloClient } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { ErrorLoad, LoadingComponent } from "../general";

import {
  useProject,
  useProjectCategories,
  useProjectPrivacyPolicy,
} from "../../contexts";

import { ReactComponent as Privacy } from "../../assets/images/dashboard/categories/privacy.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "85vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  appBarRoot: {
    background: theme.palette.common.white,
    color: theme.palette.dashboardGrey,
    boxShadow: "none",
  },
  tabSelected: {
    color: theme.palette.secondary.main,
  },
  gridItemProjectCategoryTitle: {},
  projectCategoryTitle: {
    paddingRight: "14px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    textTransform: "uppercase",
    color: theme.palette.grayTwo,
  },
  gridItemCategoryItemParent: {
    display: "flex",
    alignItems: "center",
  },
  gridItemCategoryItemContainer: {},
  gridItemCategoryItem: {
    background: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    borderRadius: "5px",
    height: "140px",
    width: "225px",
    minWidth: "auto",
    marginRight: "10px",
    marginTop: "30px",
    [theme.breakpoints.up("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        marginRight: "0px",
      },
    },
  },
  gridItemCategoryItemAdd: {
    background: theme.palette.common.white,
    border: "1px solid rgba(237, 231, 246, .35)", // theme.palette.purpleFour but in RGBA
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "5px",
    height: "140px",
    width: "225px",
    minWidth: "auto",
    marginRight: "10px",
    marginTop: "30px",
    "&:hover": {
      "& > *:first-child": {
        color: theme.palette.grayFive,
        filter: "opacity(.5)",
      },
      "& > *:last-child": {
        color: theme.palette.grayFive,
        filter: "opacity(.5)",
      },
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "300px",
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        marginRight: "0px",
      },
    },
  },
  gridItemCategoryItemAddIcon: {
    color: theme.palette.dashboardGreyLight,
    fontSize: "4rem",
  },
  gridItemCategoryItemAddDescription: {
    color: theme.palette.dashboardBlueSoft,
    fontSize: "1rem",
  },
  boxCategoryItemContainer: {
    borderRadius: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    height: "100%",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    border: "1px solid rgba(237, 231, 246, .15)", // theme.palette.purpleFour but in RGBA
  },
  containerLink: {
    borderRadius: "2.5px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    position: "absolute",
    top: "-27px",
    right: "-16px",
    width: "60px",
    height: "60px",
    background: `linear-gradient(to top right, transparent 0%, transparent 50%, ${theme.palette.purpleFour} 51%, ${theme.palette.purpleFour} 100%)`,
  },
  link: {},
  linkIcon: {
    fontSize: "1.2rem",
    color: theme.palette.common.white,
    padding: "10px 9px 0 0",
    transform: "scale(1.5)",
  },
  boxCategoryItemInfo: {
    width: "100%",
    minHeight: "25px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  boxCategoryItemIcon: {
    display: "flex",
    flexDirection: "row",
    "& > *": {
      paddingRight: theme.spacing(1),
    },
    "& > *:last-child": {
      paddingRight: "0",
    },
  },
  boxCategoryItemIconTitle: {
    cursor: "pointer",
    color: theme.palette.secondary.main,
    fontSize: "23px",
  },
  boxCategoryItemButtonIconDelete: {
    fontSize: "1.7rem",
  },
  boxCategoryItemButtonDelete: {
    "&:hover": {
      background: "transparent",
      filter: "opacity(.8)",
    },
  },
  boxCategoryItemIconDelete: {
    cursor: "pointer",
    fontSize: "23px",
    color: theme.palette.grayFive,
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
  boxCategoryItemIconPublic: {
    color: theme.palette.secondary.main,
    fontSize: "1.7rem",
    paddingRight: "6px",
  },
  boxCategoryItemIconDate: {
    color: theme.palette.secondary.main,
    fontSize: "1.7rem",
    paddingRight: "6px",
  },
  boxCategoryItemTitle: {
    position: "relative",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    display: "flex",
    minHeight: "35px",
  },
  boxCategoryItemTitleText: {
    cursor: "pointer",
    fontStyle: "normal",
    fontSize: "22px",
    fontWeight: "900",
    borderRadius: "5px",
    color: theme.palette.blackOne,
    letterSpacing: ".3px",
    wordSpacing: "5px",
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
  boxCategoryItemContent: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: "25px",
    alignItems: "center",
    flexDirection: "row",
    "& > *:nth-child(2)": {
      color: theme.palette.grayFive,
      paddingRight: theme.spacing(1),
    },
    "& > *:last-child": {
      color: theme.palette.grayFive,
    },
  },
  boxCategoryItemText: {
    fontSize: "15px",
  },
}));

// eslint-disable-next-line
const ProjectCategoryItemTitle = ({ categoryItem }) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      className={classes.gridItemProjectCategoryTitle}
    >
      <Typography variant="body3" className={classes.projectCategoryTitle}>
        {categoryItem?.title ? categoryItem?.title : "Project category"}
      </Typography>
    </Grid>
  );
};

const SEARCH_PRIVACY_POLICY = gql`
  query SearchPrivacyPolicy($input: PrivacyPolicySearchInput!) {
    searchPrivacyPolicy(input: $input) {
      _id
      _idProject
      _idProjectCategory
      _idProjectCategoryItem
      views
      publicCode
      updatedAt
    }
  }
`;

// Search queries
const selectProjectCategoryItemQuery = ({
  project,
  projectCategory,
  categoryItem,
}) => {
  switch (categoryItem?.title?.toLowerCase()) {
    case "privacy policy":
      return {
        variables: {
          input: {
            _idProject: project?.project?._id,
            _idProjectCategory: projectCategory?._id,
            _idProjectCategoryItem: categoryItem?._id,
          },
        },
        query: SEARCH_PRIVACY_POLICY,
        fetchPolicy: "no-cache",
        notifyOnNetworkStatusChange: true,
      };

    default:
      return null;
  }
};

const selectProjectCategoryItemAction = ({ hooks, categoryItem }) => {
  switch (categoryItem?.title?.toLowerCase()) {
    case "privacy policy":
      return {
        hook: hooks["privacy policy"],
        path: "/dashboard/privacy-policy",
        publicUrl: "https://audaxly.com/privacy-policy?code=",
        categoryIcon: Privacy,
      };

    default:
      return {
        hook: (anything) => {
          console.log(
            `Invalid project category item selected. Arguments received: ${anything}`
          );
        },
        path: "dashboard/categories",
      };
  }
};

const ProjectCategoryItemCards = ({
  index,
  project,
  projectCategory,
  categoryItem,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const { setProjectPrivacyPolicy } = useProjectPrivacyPolicy();

  const [categoryItemsRequest, setCategoryItemsRequest] = useState({
    projectId: project?.project?._id,
  });

  const client = useApolloClient();

  const projectCategoryItemQuery = selectProjectCategoryItemQuery({
    project,
    projectCategory,
    categoryItem,
  });

  // The paths are set inside the function, once it is not
  // required to be "instantiate" inside a component, as the
  // hooks do
  const {
    hook: projectCategoryItemHook,
    path: projectCategoryItemPath,
    publicUrl,
    categoryIcon: CategoryIcon,
  } = selectProjectCategoryItemAction({
    hooks: {
      "privacy policy": setProjectPrivacyPolicy,
    },
    categoryItem,
  });

  useEffect(() => {
    (async () => {
      setLoading(true);

      if (categoryItemsRequest?.projectId !== project?.project?._id) {
        setCategoryItemsRequest({ projectId: project?.project?._id });
      }

      try {
        let data = (await client.query({ ...projectCategoryItemQuery })).data;

        data = Object.keys(data).length ? data[Object.keys(data)[0]][0] : data;

        setCategoryItemsRequest((previousCategoryItems) => ({
          ...previousCategoryItems,
          [categoryItem._id]: data,
        }));
      } catch (error) {
        setCategoryItemsRequest((previousCategoryItems) => ({
          ...previousCategoryItems,
          [categoryItem._id]: null,
        }));
      }

      setLoading(false);
    })();

    // eslint-disable-next-line
  }, [project]);

  useEffect(() => {
    // Redirect directly to Privacy Policy
    //
    // If this is disabled the line
    //
    // <ProjectCategoryItemTitle categoryItem={categoryItem} />
    //
    // needs to be uncommented
    if (
      categoryItemsRequest[categoryItem._id] &&
      categoryItemsRequest[categoryItem._id].hasOwnProperty(
        "_idProjectCategoryItem"
      )
    ) {
      projectCategoryItemHook({
        ids: categoryItemsRequest[categoryItem._id],
        status: "update",
      });

      navigate(projectCategoryItemPath);
    }
    // eslint-disable-next-line
  }, [categoryItemsRequest, setCategoryItemsRequest]);

  if (loading)
    return (
      <Box className={classes.container}>
        <LoadingComponent />
      </Box>
    );

  return (
    <React.Fragment key={`${index} ${categoryItem.title}`}>
      <ProjectCategoryItemTitle categoryItem={categoryItem} />

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemCategoryItemParent}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          wrap="wrap"
          className={classes.gridItemCategoryItemContainer}
        >
          {!!categoryItemsRequest[categoryItem._id] && (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              className={classes.gridItemCategoryItem}
            >
              <Box className={classes.boxCategoryItemContainer}>
                <Box className={classes.boxCategoryItemTitle}>
                  <Typography
                    variant="h3"
                    className={classes.boxCategoryItemTitleText}
                    onClick={() => {
                      projectCategoryItemHook({
                        ids: categoryItemsRequest[categoryItem._id],
                        status: "update",
                      });

                      navigate(projectCategoryItemPath);
                    }}
                  >
                    {categoryItem?.title}
                  </Typography>

                  {categoryItemsRequest[categoryItem._id]?.publicCode ? (
                    <Box className={classes.containerLink}>
                      <Link
                        underline="none"
                        href={`${publicUrl}${
                          categoryItemsRequest[categoryItem._id]?.publicCode
                        }`}
                        target="_blank"
                        className={classes.link}
                      >
                        <CategoryIcon className={classes.linkIcon} />
                      </Link>
                    </Box>
                  ) : null}
                </Box>

                <Box className={classes.boxCategoryItemInfo}>
                  <Box className={classes.boxCategoryItemContent}>
                    <UpdateIcon className={classes.boxCategoryItemIconDate} />

                    <Typography
                      variant="body2"
                      className={classes.boxCategoryItemText}
                    >
                      {moment(
                        categoryItemsRequest[categoryItem._id]?.updatedAt,
                        "x"
                      ).format("MMMM Do YYYY")}
                    </Typography>
                  </Box>

                  <Box className={classes.boxCategoryItemContent}>
                    <PublicIcon className={classes.boxCategoryItemIconPublic} />

                    <Typography
                      variant="body2"
                      className={classes.boxCategoryItemText}
                    >
                      {categoryItemsRequest[categoryItem._id]?.views} Views
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          )}

          {loading ? (
            <LoadingComponent />
          ) : !categoryItemsRequest[categoryItem._id] ? (
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={4}
              className={classes.gridItemCategoryItemAdd}
              onClick={() => {
                projectCategoryItemHook({
                  status: "create",
                });

                navigate(projectCategoryItemPath);
              }}
            >
              <AddIcon className={classes.gridItemCategoryItemAddIcon} />

              <Typography
                variant="body2"
                className={classes.gridItemCategoryItemAddDescription}
              >
                Create a {categoryItem?.title}
              </Typography>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

const ProjectCategoryItems = ({ projectCategory }) => {
  const { project } = useProject();

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      {projectCategory?.categoryItems?.map((categoryItem, index) => (
        <ProjectCategoryItemCards
          index={index}
          project={project}
          projectCategory={projectCategory}
          categoryItem={categoryItem}
        />
      ))}
    </Grid>
  );
};

const SEARCH_PROJECT_CATEGORY = gql`
  query SearchProjectCategory($input: ProjectCategorySearchInput!) {
    searchProjectCategory(input: $input) {
      _id
      title
      updatedAt
      categoryItems {
        _id
        title
      }
    }
  }
`;

const convertIndex = (index) => (index + 1).toString();

const ProjectCategoriesTabs = ({ projectCategories, hideItems = false }) => {
  const classes = useStyles();

  // const navigate = useNavigate();

  // eslint-disable-next-line
  const [activeTab, setActiveTab] = useState("1");

  // const handleChange = (event, newValue) => {
  //   setActiveTab(newValue);
  // };

  return (
    <TabContext value={activeTab}>
      <AppBar position="static" classes={{ root: classes.appBarRoot }}>
        {/* Tab */}

        {/* <TabList onChange={handleChange} aria-label="project categories tabs">
          {projectCategories.map((projectCategory, index) => {
            return (
              <Tab
                label={projectCategory.title}
                value={convertIndex(index)}
                classes={{ selected: classes.tabSelected }}
                key={projectCategory._id}
                onClick={() => navigate("/dashboard/categories")}
              />
            );
          })}
        </TabList> */}
      </AppBar>

      {!hideItems &&
        projectCategories.map((projectCategory, index) => {
          return (
            <TabPanel
              value={convertIndex(index)}
              key={projectCategory._id + projectCategory.title}
            >
              <ProjectCategoryItems projectCategory={projectCategory} />
            </TabPanel>
          );
        })}
    </TabContext>
  );
};

const ProjectCategories = () => {
  const { projectCategories, setProjectCategories } = useProjectCategories();

  const [searchProjectCategories, { data, loading, error }] = useLazyQuery(
    SEARCH_PROJECT_CATEGORY,
    {
      variables: {
        input: {},
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
    }
  );

  useEffect(() => {
    if (!projectCategories.length) searchProjectCategories();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!projectCategories.length)
      if (data) setProjectCategories(data.searchProjectCategory);

    // eslint-disable-next-line
  }, [data]);

  if (loading) return <LoadingComponent />;

  if (error)
    return <ErrorLoad message={"Error while getting project categories"} />;

  return <ProjectCategoriesTabs projectCategories={projectCategories} />;
};

export { ProjectCategories, ProjectCategoriesTabs };
