import React, { useRef, useEffect } from "react";

import {
  Grid,
  Button,
  Container,
  Typography,
  Box,
  makeStyles,
  Link,
  TextField,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { ErrorMessage } from "@hookform/error-message";

import { yupResolver } from "@hookform/resolvers/yup";

import { gql, useApolloClient } from "@apollo/client";

import { toast } from "react-toastify";

import { useNavigate } from "react-router-dom";

import * as Yup from "yup";

import { useTheme } from "@material-ui/styles";

import {
  useAuthentication,
  useDashboard,
  useUser,
  useProject,
} from "../../contexts";

import { removeAllLocalStorage } from "../../utils";

import { Header } from "./index";

import { LoadingButton } from "../general";

import { SocialLogin } from "./";

import { ReactComponent as Google } from "../../assets/images/authentication/google.svg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {},
  container: {
    height: "auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(3),
  },
  pageDescription: {
    textAlign: "justify",
    color: theme.palette.grayOne,
  },
  form: {
    width: "100%",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "350px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "400px",
      },
    },
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  containerInput: {
    width: "100%",
    marginBottom: theme.spacing(4),
    "&:last-child": {
      marginBottom: "0",
    },
    "& > *": {
      width: "100%",
    },
  },
  boxContainerActions: {
    display: "inline-flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  forgotPassword: {
    color: theme.palette.grayOne,
    "&:hover": {
      color: theme.palette.secondary.light,
    },
  },
  signUp: {
    color: theme.palette.grayOne,
  },
  highlight: {
    color: theme.palette.secondary.light,
    "&:hover": {
      color: theme.palette.secondary.soft,
    },
  },
  error: {
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
  },
}));

const SIGN_IN = gql`
  query SignIn($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      issuedAt
      expiresAt
    }
  }
`;

const SIGN_IN_SOCIAL = gql`
  query SignInSocial($email: String!, $profileId: String!, $provider: String!) {
    signInSocial(email: $email, profileId: $profileId, provider: $provider) {
      token
      issuedAt
      expiresAt
    }
  }
`;

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required("You must fill the email")
    .trim()
    .email("Invalid email"),
  password: Yup.string()
    .required("You must fill the password")
    .min(8, "Minimum 8 characters"),
});

const AlreadyLoggedIn = ({
  closeToast,
  navigate,
  theme,
  state,
  setDashboard,
  setProject,
}) => {
  return (
    <>
      <Typography>You're already logged in</Typography>

      <Button
        onClick={() => {
          closeToast();

          setProject({ project: {} });

          if (state && state.origin) {
            navigate(state.origin);
          } else {
            setDashboard({
              menu: "dashboard",
              optionsProfileMenuItemSelected: 0,
              optionsSupportMenuItemSelected: 0,
              hideSidebar: false,
            });

            navigate("/dashboard/home");
          }
        }}
        style={{
          color: theme.palette.common.white,
          background: theme.palette.secondary.main,
        }}
      >
        Go to dashboard
      </Button>
    </>
  );
};

const LoginForm = ({ state }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(LoginSchema),
  });

  const classes = useStyles();

  const client = useApolloClient();

  const loginButtonContent = useRef("Login");

  const { setDashboard } = useDashboard();

  const navigate = useNavigate();

  const theme = useTheme();

  const { authentication, setAuthentication } = useAuthentication();

  const { setProject } = useProject();

  const { user } = useUser();

  if (user?.verifyRegistration?.status === false) removeAllLocalStorage();

  useEffect((props) => {
    if (!!authentication.token && window.location.pathname === "/login")
      toast(
        <AlreadyLoggedIn
          navigate={navigate}
          theme={theme}
          state={state}
          setDashboard={setDashboard}
          setProject={setProject}
        />,
        {
          hideProgressBar: true,
          closeButton: <></>,
          autoClose: false,
        }
      );

    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    loginButtonContent.current = <LoadingButton />;

    try {
      const authentication = (
        await client.query({
          query: SIGN_IN,
          variables: data,
        })
      ).data.signIn;

      setAuthentication(authentication);

      event.target.reset();

      setProject({ project: {} });

      if (state && state.origin) {
        navigate(state.origin);
      } else {
        setDashboard({
          menu: "dashboard",
          optionsProfileMenuItemSelected: 0,
          optionsSupportMenuItemSelected: 0,
          hideSidebar: false,
        });

        navigate("/dashboard/home");
      }
    } catch (error) {
      toast.error(error.message, { toastId: "login" });
    }

    loginButtonContent.current = "Login";
  };

  const onSubmitSocial = async (data) => {
    try {
      const authentication = (
        await client.query({
          query: SIGN_IN_SOCIAL,
          variables: {
            email: data._profile.email,
            profileId: data._profile.id,
            provider: data._provider,
          },
        })
      ).data.signInSocial;

      setAuthentication(authentication);

      setProject({ project: {} });

      if (state && state.origin) {
        navigate(state.origin);
      } else {
        setDashboard({
          menu: "dashboard",
          optionsProfileMenuItemSelected: 0,
          optionsSupportMenuItemSelected: 0,
          hideSidebar: false,
        });

        navigate("/dashboard/home");
      }
    } catch (error) {
      toast.error(error.message, { toastId: "loginSocial" });
    }
  };

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Header title="Login" />

      <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          className={classes.grid}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridItem}
          >
            <Box className={classes.containerInput}>
              <TextField
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors.email)}
                inputRef={register}
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="email" />
              </Typography>
            </Box>

            <Box className={classes.containerInput}>
              <TextField
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors.password)}
                inputRef={register}
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="password" />
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridItem}
          >
            <Button variant="hovered" color="primary" type="submit">
              {loginButtonContent.current}
            </Button>

            <SocialLogin
              provider="google"
              appId={process.env.REACT_APP_GOOGLE_KEY}
              onLoginSuccess={async (user) => await onSubmitSocial(user)}
              onLoginFailure={(error) => console.log(error)}
              startIcon={<Google style={{ width: 15 }} />}
            >
              Login with Google
            </SocialLogin>

            <Box className={classes.boxContainerActions}>
              <Typography className={classes.signUp}>
                Don’t have an account?{" "}
                <Link
                  underline="none"
                  href="/sign-up"
                  className={classes.highlight}
                >
                  Sign up
                </Link>
                !
              </Typography>

              <Link
                underline="none"
                href="/forgot-password"
                className={classes.forgotPassword}
              >
                Forgot password
              </Link>
            </Box>
          </Grid>
        </Grid>
      </form>

      <Typography className={classes.pageDescription}>
        By clicking “Login”, you agree to Audaxly’s{" "}
        <Link
          underline="none"
          href="/terms-and-conditions"
          className={classes.highlight}
        >
          Terms and Conditions
        </Link>{" "}
        and{" "}
        <Link
          underline="none"
          href={process.env.REACT_APP_PRIVACY_POLICY}
          className={classes.highlight}
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </Container>
  );
};

export default LoginForm;
