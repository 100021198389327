import React from "react";

import { Grid, Link, makeStyles } from "@material-ui/core";

import character from "../../assets/images/authentication/character.svg";

import logoDark from "../../assets/images/home/logoDark.svg";

const useStyles = makeStyles((theme) => ({
  grid: {
    background: theme.palette.gradient,
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      "&": {
        display: "none",
      },
    },
  },
  gridItemLogo: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  gridItemCharacter: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  imgLogo: {
    width: "195px",
  },
  imgCharacter: {
    width: "100%",
    maxWidth: "100%",
  },
}));

const Intro = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.grid}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemLogo}
      >
        <Link href="/">
          <img src={logoDark} alt="Audaxly" className={classes.imgLogo} />
        </Link>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemCharacter}
      >
        <img src={character} alt="Character" className={classes.imgCharacter} />
      </Grid>
    </Grid>
  );
};

export default Intro;
