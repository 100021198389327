import React from "react";

import { HeaderSticky, Footer } from "../components/general";

import { Grid } from "@material-ui/core";

import { Helmet } from "react-helmet";

import { Information } from "../components/notFound";

const NotFound = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="stretch"
    >
      <Helmet>
        <title>Page not found</title>
      </Helmet>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <HeaderSticky />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Information />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Footer disclaimer={false} />
      </Grid>
    </Grid>
  );
};

export default NotFound;
