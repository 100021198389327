import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { ProjectCategoriesTabs } from "../components/dashboard";

import { Sections } from "../components/projectPrivacyPolicy";

import { useProjectCategories } from "../contexts";

const useStyles = makeStyles((theme) => ({
  gridItemSections: {
    padding: theme.spacing(3),
    overflowY: "scroll",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: theme.spacing(2),
      },
    },
  },
}));

const ProjectPrivacyPolicy = () => {
  const { projectCategories } = useProjectCategories();

  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <ProjectCategoriesTabs
          projectCategories={projectCategories}
          hideItems={true}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemSections}
      >
        <Sections />
      </Grid>
    </Grid>
  );
};

export default ProjectPrivacyPolicy;
