import React, { useRef, useState, useEffect } from "react";

import {
  Grid,
  Box,
  Button,
  Typography,
  makeStyles,
  Checkbox,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { gql, useQuery, useMutation } from "@apollo/client";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import { toast } from "react-toastify";

import { LoadingComponent, LoadingButton, ErrorLoad } from "../general";

import { useDashboard } from "../../contexts";

import { objectsDifference } from "../../utils";

const useStyles = makeStyles((theme) => ({
  gridNotifications: {
    boxSizing: "border-box",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(5),
    "& > *": {
      // marginTop: theme.spacing(4),
    },
    "& > *:first-child": {
      // marginTop: "0",
    },
    "& > *:last-child": {},
  },
  gridItemTitle: {},
  boxMarket: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  marketingAcceptCheckBox: {
    padding: "0px",
    marginRight: theme.spacing(1),
  },
  title: {
    textAlign: "start",
    color: theme.palette.blackOne,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "42px",
  },
  gridItemForm: {},
  grid: {},
  gridItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& > *:first-child": {
      marginTop: "0",
    },
    "& > *:last-child": {
      marginBottom: "0",
    },
  },
  gridItemSave: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    marginTop: "0",
    marginBottom: "0",
  },
  buttonSubmit: {
    fontWeight: "normal",
  },
}));

const NotificationsSchema = Yup.object().shape({});

const USER = gql`
  query User {
    user {
      marketingAccept
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      marketingAccept
    }
  }
`;

const NotificationsForm = () => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(USER);

  // eslint-disable-next-line
  const [marketingAccept, setMarketingAccept] = useState(false);

  const [
    updateUser,
    // eslint-disable-next-line
    { dataUpdateUser, loadingUpdateUser, errorUpdateUser, calledUpdateUser },
  ] = useMutation(UPDATE_USER, { refetchQueries: [{ query: USER }] });

  // eslint-disable-next-line
  const { register, handleSubmit, errors, control } = useForm({
    resolver: yupResolver(NotificationsSchema),
  });

  const { setDashboard } = useDashboard();

  const notificationsButtonContent = useRef("Save");

  useEffect(() => {
    setDashboard({ menu: "profile", optionsProfileMenuItemSelected: 1 });

    // eslint-disable-next-line
  }, []);

  const onSubmit = async (_dataForm, event) => {
    const dataForm = objectsDifference(_dataForm, data);

    if (Object.keys(dataForm).length === 0) {
      toast.warn(`No changes found`);

      return;
    }

    notificationsButtonContent.current = <LoadingButton />;

    try {
      // eslint-disable-next-line
      const userUpdated = (
        await updateUser({
          variables: {
            input: dataForm,
          },
        })
      ).data.updateUser;

      toast.success(`Notification preferences updated`);
    } catch (error) {
      toast.error(error.message);
    }

    notificationsButtonContent.current = "Save";
  };

  if (loading) return <LoadingComponent />;

  if (error) return <ErrorLoad message={error.message} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.gridNotifications}
    >
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={5}
        className={classes.gridItemTitle}
      >
        <Typography variant="h1" className={classes.title}>
          Notifications
        </Typography>
      </Grid>

      <Grid item xs={12} sm={10} md={8} lg={5} className={classes.gridItemForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.grid}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItem}
            >
              <Box className={classes.boxMarket}>
                <Checkbox
                  defaultChecked={data.user.marketingAccept}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  inputRef={register}
                  name="marketingAccept"
                  className={classes.marketingAcceptCheckBox}
                />

                <Typography variant="body1">
                  Features, Offers, Promotions & Marketing
                </Typography>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={`${classes.gridItem} ${classes.gridItemSave}`}
            >
              <Button
                variant="hovered"
                color="primary"
                type="submit"
                className={classes.buttonSubmit}
              >
                {notificationsButtonContent.current}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default NotificationsForm;
