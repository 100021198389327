import React, { createContext, useContext, useEffect, useReducer } from "react";

import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../utils";

// Don't confuse yourself, this context its used internally
// to manage the category items, more precisely the Privacy
// Policy item, it is not used to store data from the public
// view of any Privacy Policy

const defaultStateProjectPrivacyPolicy = {
  ids: {
    _id: "",
    _idProject: "",
    _idProjectCategory: "",
    _idProjectCategoryItem: "",
  },
  status: "create", // Options: update, create or empty
};

const reducer = (previousState, newState) => {
  if (newState === null) {
    removeLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_PRIVACY_POLICY_LOCAL_STORAGE_KEY,
    });

    return defaultStateProjectPrivacyPolicy;
  }

  return { ...previousState, ...newState };
};

const ProjectPrivacyPolicyContext = createContext(
  defaultStateProjectPrivacyPolicy
);

const ProjectPrivacyPolicyProvider = ({ children }) => {
  const [projectPrivacyPolicy, setProjectPrivacyPolicy] = useReducer(
    reducer,
    getLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_PRIVACY_POLICY_LOCAL_STORAGE_KEY,
      defaultState: defaultStateProjectPrivacyPolicy,
    })
  );

  useEffect(() => {
    setLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_PRIVACY_POLICY_LOCAL_STORAGE_KEY,
      data: projectPrivacyPolicy,
    });
  }, [projectPrivacyPolicy]);

  return (
    <ProjectPrivacyPolicyContext.Provider
      value={{ projectPrivacyPolicy, setProjectPrivacyPolicy }}
    >
      {children}
    </ProjectPrivacyPolicyContext.Provider>
  );
};

const useProjectPrivacyPolicy = () => {
  const { projectPrivacyPolicy, setProjectPrivacyPolicy } = useContext(
    ProjectPrivacyPolicyContext
  );

  if (!projectPrivacyPolicy)
    throw new Error(
      "Hook useProjectPrivacyPolicy must be used over ProjectPrivacyPolicyProvider"
    );

  return { projectPrivacyPolicy, setProjectPrivacyPolicy };
};

export {
  ProjectPrivacyPolicyContext,
  ProjectPrivacyPolicyProvider,
  defaultStateProjectPrivacyPolicy,
  useProjectPrivacyPolicy,
};
