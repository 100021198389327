import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Grid,
  Button,
  Box,
  Container,
  Typography,
  makeStyles,
  Link,
  Checkbox,
  TextField,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { ErrorMessage } from "@hookform/error-message";

import * as Yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import { Header } from "./index";

import { omit } from "lodash";

import { toast } from "react-toastify";

import { gql, useMutation } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { LoadingButton } from "../general";

import { useAuthentication, useDashboard, useProject } from "../../contexts";

import { SocialLogin } from "./";

import { ReactComponent as Google } from "../../assets/images/authentication/google.svg";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(3),
  },
  containerConfirmation: {
    height: "auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(3),
  },
  pageDescription: {
    textAlign: "justify",
    color: theme.palette.grayOne,
  },
  form: {
    width: "100%",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "525px",
    width: "100%",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "auto",
  },
  containerInput: {
    width: "100%",
    marginBottom: theme.spacing(3),
    "&:last-child": {
      marginBottom: "0",
    },
    "& > *": {
      width: "100%",
    },
  },
  signUp: {
    fontSize: "1.1rem",
    color: theme.palette.grayOne,
    marginTop: theme.spacing(2),
  },
  boxContainerCheckbox: {
    display: "inline-flex",
    "& > *": {
      padding: "0",
    },
  },
  checkboxIcon: {
    paddingRight: theme.spacing(1),
  },
  checkboxText: {
    fontSize: "1.1rem",
    color: theme.palette.grayOne,
  },
  boxContentConfirmation: {
    height: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  contentConfirmationText: {
    color: theme.palette.grayOne,
  },
  contentConfirmationLink: {
    color: theme.palette.secondary.main,
  },
  accountConfirmedTitle: {
    color: theme.palette.grayOne,
  },
  redirectedText: {
    color: theme.palette.grayOne,
  },
  confirmingAccountText: {
    color: theme.palette.grayOne,
    textAlign: "left",
  },
  errorConfirmingAccountText: {
    color: theme.palette.grayOne,
  },
  error: {
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
  },
  highlight: {
    color: theme.palette.secondary.light,
    "&:hover": {
      color: theme.palette.secondary.soft,
    },
  },
}));

const SIGN_UP = gql`
  mutation SignUp($input: UserSignUpInput!) {
    signUp(input: $input) {
      name
      #   email
      #   password
      #   marketingAccept
      #   customerId
      #   paymentMethods {
      #     id
      #   }
    }
  }
`;

const SIGN_UP_SOCIAL = gql`
  mutation SignUpSocial($input: UserSignUpSocialInput!) {
    signUpSocial(input: $input) {
      name
      #   email
      #   password
      #   marketingAccept
      #   customerId
      #   paymentMethods {
      #     id
      #   }
    }
  }
`;

const VERIFY_REGISTRATION = gql`
  mutation verifyRegistration($email: String!) {
    verifyRegistration(email: $email)
  }
`;

const VERIFY_REGISTRATION_UPDATE = gql`
  mutation VerifyRegistrationUpdate($code: String!) {
    verifyRegistrationUpdate(code: $code) {
      status
      token
      issuedAt
      expiresAt
    }
  }
`;

const SingUpSchema = Yup.object().shape({
  name: Yup.string().required("You must fill the name"),
  email: Yup.string()
    .required("You must fill the email")
    .trim()
    .email("Invalid email"),
  password: Yup.string()
    .required("You must fill the password")
    .min(8, "Minimum 8 characters")
    .matches(/[A-Z]/, "Must contain one uppercase character")
    .matches(/[0-9]/, "Must contain one number"),
  confirmPassword: Yup.string()
    .required("You must fill the same password again")
    .min(8, "Minimum 8 characters")
    .matches(/[A-Z]/, "Must contain one uppercase character")
    .matches(/[0-9]/, "Must contain one number")
    .oneOf(
      [Yup.ref("password")],
      "Confirm password must be equal to the password."
    ),
});

const SingUp = ({ classes, confirmation, setConfirmation, setEmail }) => {
  // eslint-disable-next-line
  const [marketingAccept, setMarketingAccept] = useState(false);

  const signUpButtonContent = useRef("Sign up");

  const [
    signUp,
    // eslint-disable-next-line
    { data, loading, error, called },
  ] = useMutation(SIGN_UP);

  // prettier-ignore
  const [
    signUpSocial,
    // eslint-disable-next-line
    { data: dataSocial, loading: loadingSocial, error: errorSocial, called: calledSocial },
  ] = useMutation(SIGN_UP_SOCIAL);

  // prettier-ignore
  const [
    verifyRegistration,
    // eslint-disable-next-line
    { dataVerifyRegistration, loadingVerifyRegistration, errorVerifyRegistration, calledVerifyRegistration },
  ] = useMutation(VERIFY_REGISTRATION);

  const { register, handleSubmit, errors, watch } = useForm({
    resolver: yupResolver(SingUpSchema),
  });

  // eslint-disable-next-line
  const watchFields = watch();

  const onSubmit = async (data, event) => {
    signUpButtonContent.current = <LoadingButton />;

    try {
      await signUp({
        variables: {
          input: {
            ...omit(data, "confirmPassword"),
            email: data.email?.toLowerCase(),
            marketingAccept: data.marketingAccept,
          },
        },
      });

      const verifyRegistrationEmailSent = (
        await verifyRegistration({
          variables: {
            email: data.email?.toLowerCase(),
          },
        })
      ).data.verifyRegistration;

      if (!verifyRegistrationEmailSent)
        throw new Error(
          "Account registered, but an error happened when sending the email to verify your account email"
        );

      toast.success(
        `An email to verify your account email was sent to "${data.email?.toLowerCase()}", check your inbox`
      );

      event.target.reset();

      setEmail(data.email);

      setConfirmation(!confirmation);
    } catch (error) {
      toast.success(error.message);
    }

    signUpButtonContent.current = "Sign up";
  };

  const onSubmitSocial = async (data) => {
    toast.info("Processing the sign up, wait a few seconds");

    try {
      await signUpSocial({
        variables: {
          input: {
            name: data._profile.name,
            email: data._profile.email,
            marketingAccept: watchFields?.marketingAccept
              ? watchFields?.marketingAccept
              : false,
            profileId: data._profile.id,
            provider: data._provider,
          },
        },
      });

      const verifyRegistrationEmailSent = (
        await verifyRegistration({
          variables: {
            email: data._profile.email,
          },
        })
      ).data.verifyRegistration;

      if (!verifyRegistrationEmailSent)
        throw new Error(
          "Account registered, but an error happened when sending the email to verify your account email"
        );

      toast.success(
        `An email to verify your account email was sent to "${data._profile.email?.toLowerCase()}", check your inbox`
      );

      setEmail(data._profile.email);

      setConfirmation(!confirmation);
    } catch (error) {
      toast.success(error.message);
    }
  };

  return (
    <Container maxWidth="xs" className={classes.container}>
      <Header title="Sign Up" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          className={classes.grid}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridItem}
          >
            <Box className={classes.containerInput}>
              <TextField
                name="name"
                type="text"
                label="Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors.name)}
                inputRef={register}
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="name" />
              </Typography>
            </Box>

            <Box className={classes.containerInput}>
              <TextField
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  autoComplete: "email",
                  form: {
                    autoComplete: "off",
                  },
                }}
                error={Boolean(errors.email)}
                inputRef={register}
              />
              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="email" />
              </Typography>
            </Box>

            <Box className={classes.containerInput}>
              <TextField
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                helperText="Your password is encrypted and only you have access to it"
                inputProps={{
                  autoComplete: "password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                error={Boolean(errors.password)}
                inputRef={register}
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="password" />
              </Typography>
            </Box>

            <Box className={classes.containerInput}>
              <TextField
                name="confirmPassword"
                type="password"
                label="Confirm password"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  autoComplete: "confirmPassword",
                  form: {
                    autoComplete: "off",
                  },
                }}
                error={Boolean(errors.confirmPassword)}
                inputRef={register}
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="confirmPassword" />
              </Typography>
            </Box>

            <Box className={classes.boxContainerCheckbox}>
              <Checkbox
                defaultChecked={marketingAccept}
                inputProps={{ "aria-label": "primary checkbox" }}
                inputRef={register}
                name="marketingAccept"
                className={classes.checkboxIcon}
              />

              <Typography variant="body2" className={classes.checkboxText}>
                Send me emails with relevant content & offers
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            className={classes.gridItem}
          >
            <Button variant="hovered" color="primary" type="submit">
              {signUpButtonContent.current}
            </Button>

            <SocialLogin
              provider="google"
              appId={process.env.REACT_APP_GOOGLE_KEY}
              onLoginSuccess={async (user) => await onSubmitSocial(user)}
              onLoginFailure={(error) => console.log(error)}
              startIcon={<Google style={{ width: 15 }} />}
            >
              Sign up with Google
            </SocialLogin>

            <Typography className={classes.signUp}>
              Already have an account?{" "}
              <Link
                underline="none"
                href="/login"
                className={classes.highlight}
              >
                Login
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </form>

      <Typography className={classes.pageDescription}>
        By clicking "Create”, you agree to Audaxly’s{" "}
        <Link
          underline="none"
          href="/terms-and-conditions"
          className={classes.highlight}
        >
          Terms and Conditions
        </Link>{" "}
        and{" "}
        <Link
          underline="none"
          href={process.env.REACT_APP_PRIVACY_POLICY}
          className={classes.highlight}
        >
          Privacy Policy
        </Link>
        .
      </Typography>
    </Container>
  );
};

const Confirmation = ({ classes, queryString, email }) => {
  const navigate = useNavigate();

  const { setAuthentication } = useAuthentication();

  const { setProject } = useProject();

  const { setDashboard } = useDashboard();

  // prettier-ignore
  const [
    verifyRegistration,
    // eslint-disable-next-line
    { dataVerifyRegistration, loadingVerifyRegistration, errorVerifyRegistration, calledVerifyRegistration },
  ] = useMutation(VERIFY_REGISTRATION);

  // prettier-ignore
  const [
    verifyRegistrationUpdate,
    // eslint-disable-next-line
    { dataVerifyRegistrationUpdate, loadingVerifyRegistrationUpdate, errorVerifyRegistrationUpdate, calledVerifyRegistrationUpdate },
  ] = useMutation(VERIFY_REGISTRATION_UPDATE);

  const [confirmationStatus, setConfirmationStatus] = useState({
    confirming: true,
    confirmed: false,
    error: false,
    errorMessage: "",
  });

  const confirmed = Boolean(queryString.code);

  const sendItAgain = async (event) => {
    event.preventDefault();

    try {
      const verifyRegistrationEmailSent = (
        await verifyRegistration({
          variables: {
            email,
          },
        })
      ).data.verifyRegistration;

      if (!verifyRegistrationEmailSent)
        throw new Error(
          "An error happened when sending the email to verify your account email"
        );
      else
        toast.success(
          `An email to verify your account email was sent to "${email}", check your inbox`
        );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const confirmVerification = useCallback(async (code) => {
    try {
      const { status, ...authenticationToken } = (
        await verifyRegistrationUpdate({
          variables: {
            code,
          },
        })
      ).data.verifyRegistrationUpdate;

      setConfirmationStatus({
        confirming: false,
        confirmed: status,
        error: false,
        errorMessage: "",
      });

      setAuthentication(authenticationToken);

      setProject({ project: {} });

      setDashboard({
        menu: "dashboard",
        optionsProfileMenuItemSelected: 0,
        optionsSupportMenuItemSelected: 0,
        hideSidebar: false,
      });

      toast.success("Account confirmed");

      navigate("/dashboard/home");
    } catch (error) {
      setConfirmationStatus({
        confirming: false,
        confirmed: false,
        error: true,
        errorMessage: error.message?.toLowerCase(),
      });

      toast.error(error.message);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (confirmed) {
      confirmVerification(queryString.code);
    }

    // eslint-disable-next-line
  }, []);

  return (
    <Container maxWidth="xs" className={classes.containerConfirmation}>
      <Header title="One more step…" />

      {confirmed && (
        <Box className={classes.boxContentConfirmation}>
          {confirmationStatus.confirming && (
            <Typography
              variant="body2"
              className={classes.confirmingAccountText}
            >
              Confirming account...
            </Typography>
          )}

          {confirmationStatus.confirmed && (
            <Typography
              variant="body2"
              className={classes.accountConfirmedTitle}
            >
              Account confirmed!
            </Typography>
          )}

          {confirmationStatus.error && (
            <Typography
              variant="body2"
              className={classes.errorConfirmingAccountText}
            >
              If you can't verify your account email clean the cache or try a different email.
            </Typography>
          )}
          {confirmationStatus.confirmed && (
            <Typography variant="body2" className={classes.redirectedText}>
              Account confirmed, please,
              <Link
                underline="none"
                href="/login"
                className={classes.highlight}
              >
                login
              </Link>
            </Typography>
          )}
        </Box>
      )}

      {!confirmed && (
        <Box className={classes.boxContentConfirmation}>
          <Typography
            variant="body2"
            className={classes.contentConfirmationText}
          >
            Check your inbox and click the link in the email to confirm your
            account. Didn’t receive an email?{" "}
            <Link
              href=""
              className={classes.contentConfirmationLink}
              onClick={sendItAgain}
            >
              Send it again.
            </Link>
          </Typography>
        </Box>
      )}
    </Container>
  );
};

const SignUpForm = ({ queryString }) => {
  const classes = useStyles();

  const [confirmation, setConfirmation] = useState(false);

  const [email, setEmail] = useState("");

  return confirmation || Boolean(queryString.code) ? (
    <Confirmation classes={classes} queryString={queryString} email={email} />
  ) : (
    <SingUp
      classes={classes}
      confirmation={confirmation}
      setConfirmation={setConfirmation}
      setEmail={setEmail}
    />
  );
};

export default SignUpForm;
