const marketing = [
  {
    providerName: "ActiveCampaign, LLC",
    providerService: "ActiveCampaign widget",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\npassword\nphone number\nprofession\nUsage Data\nusername\nvarious types of Data",
    providerPrivacyLink: "https://www.activecampaign.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Mailing list signup form",
    "Unnamed: 12": null,
  },
  {
    providerName: "Acumbamail S.L",
    providerService: "Acumbamail email",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://acumbamail.com/politica-de-privacidad/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Amazon Web Services EMEA SARL (EEA users) | Amazon Web Services, Inc. (USA & other countries users)",
    providerService: "Amazon Simple Email Service",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://aws.amazon.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "AWeber Systems, Inc.",
    providerService: "AWeber",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nusername\nvarious type of data",
    providerPrivacyLink: "https://www.aweber.com/privacy.htm",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Campaign Monitor Pty Ltd.",
    providerService: "Campaign Monitor",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nusername",
    providerPrivacyLink:
      "https://www.campaignmonitor.com/policies/#privacy-policy",
    optOut: null,
    placeProcessing: "Australia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "CleverReach GmbH & Co. KG",
    providerService: "CleverReach",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.cleverreach.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Constant Contact Inc.",
    providerService: "Constant Contact",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername",
    providerPrivacyLink: "https://www.endurance.com/privacy/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "ContactLab S.p.A.",
    providerService: "ContactLab Outbound",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: "App notifications\nemail address\nphone number ",
    providerPrivacyLink: "https://contactlab.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "ConvertKit LLC",
    providerService: "ConvertKit",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://convertkit.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Customerly Ltd.",
    providerService: "Customerly E-mail Marketing",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nusername\nVAT number",
    providerPrivacyLink: "https://www.customerly.io/en/privacy",
    optOut: null,
    placeProcessing: "Ireland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "FeedBlitz, LLC",
    providerService: "FeedBlitz",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nusername",
    providerPrivacyLink: "https://www.feedblitz.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "HubSpot, Inc.",
    providerService: "HubSpot Email",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://legal.hubspot.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "iContact LLC",
    providerService: "iContact",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.icontact.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Hoplo S.r.l.",
    providerService: "Infomail",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "email \nfirst name\nlast name \nphone number \nUsage Data\nvarious types of data as specified in the privacy policy of the service ",
    providerPrivacyLink: "https://www.infomail.it/informativa-privacy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Iterable, Inc.",
    providerService: "Iterable email marketing",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncity\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nusername\nZIP/Postal code",
    providerPrivacyLink: "https://iterable.com/trust/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Infusion Software, Inc. dba Keap",
    providerService: "Keap ",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://keap.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "GoDaddy.com LLC",
    providerService: "Mad Mimi",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername\nvarious types of data",
    providerPrivacyLink:
      "https://es.godaddy.com/legal/agreements/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Diennea S.r.l.",
    providerService: "MagNews",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.magnews.it/privacy-policy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "The Rocket Science Group LLC",
    providerService: "Mailchimp",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername\nvarious types of data",
    providerPrivacyLink: "https://mailchimp.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "UAB MailerLite Lithuania Ltd.",
    providerService: "MailerLite widget",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername\nvarious types of Data",
    providerPrivacyLink: "https://www.mailerlite.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "Lithuania",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Mailing list signup form",
    "Unnamed: 12": "Move to MKT",
  },
  {
    providerName: "Mailgun Technologies, Inc.",
    providerService: "Mailgun",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nvarious types of data",
    providerPrivacyLink: "https://www.mailgun.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Mailjet SAS",
    providerService: "Mailjet",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername",
    providerPrivacyLink: "https://www.mailjet.com/privacy-policy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "The Rocket Science Group LLC",
    providerService: "Mandrill",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nusername\nvarious types of data",
    providerPrivacyLink: "https://mailchimp.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Marketo EMEA Ireland Ltd (EEA users) | Marketo, Inc. (other countries users) ",
    providerService: "Marketo Email Marketing",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername\nvarious types of data",
    providerPrivacyLink: "https://documents.marketo.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Paperless Inc. - VeraSafe Ireland Ltd. (EU repr. for EEA users)",
    providerService: "Paperless Post ",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.paperlesspost.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Our Services are not designed to collect sensitive data (such as personal data revealing political opinions, religious beliefs or health). However, you might make certain sensitive data public if you send invitations relating to certain events, such as those associated with a political issue or campaign or religious activities or organizations. For EU Users, we process this Personal Data on the basis that it has been manifestly made public by you.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Rapidmail GmbH",
    providerService: "Rapidmail",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.rapidmail.com/data-protection",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SendinBlue SAS",
    providerService: "SendinBlue Email",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.sendinblue.com/legal/privacypolicy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Techdrop Labs Inc.",
    providerService: "Sendwithus",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.dyspatch.io/legal/privacy-policy/#sendwithus",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Smarter Click Technology Ltd.",
    providerService: "Smarter Click Email Engagement",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nstate \nUsage Data",
    providerPrivacyLink: "https://www.smarterclick.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Message Systems, Inc.",
    providerService: "SparkPost",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.sparkpost.com/policies/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Deluxe Small Business Sales, Inc.",
    providerService: "VerticalResponse",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.verticalresponse.com/about/privacy_tos.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Webbdone S.r.l.",
    providerService: "Webbdone Email Marketing",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webbdone.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zoho Corporation BV (EEA users) | Zoho Corporation Pvt. Ltd. (AU users) | Zoho Corporation (USA&other countries users)",
    providerService: "ZOHO Campaigns",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.zoho.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "lempire SAS",
    providerService: "Lemlist ",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.notion.so/lemlist-Privacy-Policy-d878ef235ff44c2c8338af13c5a02b71",
    optOut: null,
    placeProcessing: "France ",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SixMix LLC",
    providerService: "MailMunch",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://legal.mailmunch.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "E-business4us Inc dba Builderall LLC",
    providerService: "MailingBoss",
    questionGenerator: "Q2.2. - Email marketing ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mailingboss.marketing/gdpr",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Vertex9 LLC",
    providerService: "Carrd",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://carrd.co/docs/general/terms#privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Peaberry Software, Inc.",
    providerService: "Customer.io",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://customer.io/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Dotdigital Group Plc.",
    providerService: "dotdigital",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername",
    providerPrivacyLink: "https://dotdigital.com/terms/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Avenue 81, Inc.",
    providerService: "Drip",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername",
    providerPrivacyLink: "https://www.drip.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "EngageBay Inc. ",
    providerService: "EngageBay Marketing ",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.engagebay.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Freshworks Inc.",
    providerService: "Freshworks 360",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.freshworks.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Freshworks\u2019 unified sales, support, marketing, and success platform makes every interaction a moment of WOW!",
    "Unnamed: 12": null,
  },
  {
    providerName: "GetResponse Sp. z o.o.",
    providerService: "GetResponse (includes widgets)",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink:
      "https://eu.getresponse.com/email-marketing/eu-legal/privacy.html",
    optOut: null,
    placeProcessing: "Poland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Features in the product pack:\nEmail MKT\nAnalytics\nCRM\nSocial ads management\nLanding page creator\nWebinars",
    "Unnamed: 12": "Integrated marketing tool",
  },
  {
    providerName: "Instapage, Inc.",
    providerService: "Instapage",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://instapage.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Iterable, Inc.",
    providerService: "Iterable",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://iterable.com/trust/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Products: Growth Marketing / Multi-Channel Marketing / Customer Engagement / Personalized Marketing / Mobile Marketing / Email Marketing / Direct Mail Marketing\nTO BE ADDED TO MRKTNG?",
    "Unnamed: 12": null,
  },
  {
    providerName: "KickoffLabs LLC ",
    providerService: "KickoffLabs",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://kickofflabs.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Lander, Inc ",
    providerService: "Lander",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://landerapp.com/terms-and-conditions",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Launchaco, Inc.",
    providerService: "Launchaco",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.launchaco.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Startups.com LLC",
    providerService: "Launchrock",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.launchrock.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Vodaware, Inc. ",
    providerService: "LeadOutcome",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.leadoutcome.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "marketing automation platform\nTO BE ADDED TO MRKTNG?",
    "Unnamed: 12": null,
  },
  {
    providerName: "Avenue 81, Inc.",
    providerService: "Leadpages",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.leadpages.net/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "The Rocket Science Group LLC",
    providerService: "Mailchimp Landing Page",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nusername",
    providerPrivacyLink: "https://mailchimp.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "UAB MailerLite Lithuania Ltd.",
    providerService: "MailerLite",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername\nvarious types of data",
    providerPrivacyLink: "https://www.mailerlite.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "Lithuania",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Sarbacane Software SAS",
    providerService: "Mailify",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mailify.com/privacy",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "MailUp S.p.A.",
    providerService: "MailUp",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nUsage Data\nusername\nvarious types of data",
    providerPrivacyLink: "https://www.mailup.es/privacidad/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Marketo EMEA Ireland Ltd (EEA users) | Marketo, Inc. (other countries users) ",
    providerService: "Marketo Lead Generation",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://documents.marketo.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Mozeo, LLC ",
    providerService: "Mozeo",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mozeo.com/mozeo-policies.php",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Salesforce.com, Inc.",
    providerService: "Pardot",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "TO BE ADDED TO MRKTNG?",
    "Unnamed: 12": "B2B marketing automation",
  },
  {
    providerName: "Wildbit, LLC",
    providerService: "Postmark",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername\nvarious types of data",
    providerPrivacyLink: "https://wildbit.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Robly Inc.",
    providerService: "Robly",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\nCookies\nemail address\n\ufb01rst name\ngender\nphone number\nprofession\nUsage Data\nvarious types of data",
    providerPrivacyLink: "http://support.robly.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Salesforce.com, Inc.",
    providerService: "Salesforce Marketing Cloud",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "TO BE ADDED TO MRKTNG?",
    "Unnamed: 12": "marketing solution",
  },
  {
    providerName: "Salesforce.com, Inc.",
    providerService: "Salesforce Sales Cloud",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": "sales solution but could be included in mrktng",
  },
  {
    providerName: "Sendinblue SAS",
    providerService: "SendinBlue Marketing Automation",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://hello.sendinblue.com/legal/privacypolicy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      'Also CRM & Marketing Automation & Contact Management\n"..personal data may be transferred to an EU or non-EU country. Sendinblue implements guarantees ensuring the protection and security of this data, in compliance with applicable rules and regulations.\nSendinblue does not transfer or rent personal data to third parties for marketing purposes without the express consent of the Users of Sendinblue.\nIn addition, personal data may only be disclosed to third parties for purposes other than marketing in the following cases:\n-with their authorisation;\n-at the request of the competent legal authorities, upon judicial request, or in the context of a legal dispute."',
    "Unnamed: 12": "marketing automation",
  },
  {
    providerName: "Shareaholic, Inc.",
    providerService: "Shareaholic",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.shareaholic.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SharpSpring, Inc.",
    providerService: "SharpSpring",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData:
      "address\ncity\ncompany name\nCookies\ncountry\ndevice information\nemail address\n\ufb01rst name\ngeographic position\nlanguage\nlast name\nphone number\nsocial media accounts\nstate\nUsage Data\nvarious types of Data as speci\ufb01ed in the privacy policy of the service\nwebsite",
    providerPrivacyLink: "https://sharpspring.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Marketing Automation\nEmail Marketing\nSocial Management\nLanding Page Designer\nCampaign Tracking\nAnd More...",
    "Unnamed: 12": "marketing automation",
  },
  {
    providerName:
      "Product Hunt, Inc. - AngelList Ltd (EU repr. for EEA users) ",
    providerService: "Ship by Product Hunt",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.producthunt.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Swrve New Media, Inc.",
    providerService: "Swrve Core & Push",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.swrve.com/company/gdpr",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Within North America, the Swrve Service is made available by Swrve New Media Inc., a Delaware corporation with offices at 55 Stockton Street, Suite 400, San Francisco, CA 94108, USA.\nOutside of North America, the Swrve Service is provided by New Game Technologies Limited t/a Swrve New Media UK, a company organized under the laws of the United Kingdom with it registered offices at Suite 2.03, The White Collar Factory, 1 Old Street Yard London EC1Y 8AF. \nServices: Push & SMS Messaging, In-App Messaging, Email and Web Push, Notifications, OTT Messaging , Triggering and Delivery, Artificial Intelligence, Cross-Channel Experience , Real-time Delivery\nCustomer Data & Segmentation, A/B Test, Iterate, and Optimize] Dynamic Campaigns, BI-Level Precision Targeting and Triggering, Customer Churn Management, Integrating with Swrve, Content and Personalization Tools, Templates, Analytics and Reporting, Ad to App Experiences",
    "Unnamed: 12": "marketing automation",
  },
  {
    providerName:
      "Twilio Ireland Limited (EEA users) | Twilio Inc. (other countries users)",
    providerService: "Twilio",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.twilio.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Unbounce Marketing Solutions Inc.",
    providerService: "Unbounce",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://unbounce.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Vero Holdings Australia Pty. Ltd.",
    providerService: "Vero",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nphone number\nUsage Data\nusername",
    providerPrivacyLink: "https://www.getvero.com/privacy/",
    optOut: null,
    placeProcessing: "Australia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Webtrekk GmbH, company of Mapp Digital Germany GmbH (EEA users) | Mapp Digital US, LLC (USA&other countries users) ",
    providerService: "Webtrekk Marketing Automation",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webtrekk.com/privacy-statement/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Analytics\nLive Analytics\nApp Analytics\nDashboards\nCross-Device Bridge\nSmart Alerts\nTV Analytics\nRaw Data Export\nUser Predictions\nMarketing\nOnsite Personalization\nAudience Stream\nProduct Recommendations\nAudience API\nData Streams\nMapp Cloud\nMapp + Webtrekk\n",
    "Unnamed: 12": "marketing automation",
  },
  {
    providerName: "Wishpond Technologies Ltd.",
    providerService: "Wishpond",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.wishpond.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "AutopilotHQ, Inc.",
    providerService: "Autopilot Marketing Automation",
    questionGenerator: "Q2.1. - Integrated marketing tools ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.autopilothq.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Checkbox Survey Inc.",
    providerService: "Checkbox Survey",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.checkbox.com/privacy_policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "Crowdsignal",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nnumber of employees\npassword\nphone number\nprofession\nstate\nTax ID\nUsage Data\nusername\nVAT Number\nwebsite",
    providerPrivacyLink: "https://automattic.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": "Sending Survey / Feedback forms",
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "Crowdsignal Widget ",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://automattic.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "You cannot buy just the widget. Is the entire service. ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Customerly Ltd.",
    providerService: "Customerly Survey",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nnumber of employees\npassword\nphone number\nprofession\nstate\nTax ID\nUsage Data\nusername\nVAT Number\nwebsite",
    providerPrivacyLink: "https://www.customerly.io/en/privacy",
    optOut: null,
    placeProcessing: "Ireland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Live Chat Software\nKnowledgebase platform\nSales Marketing Funnel\nIn-app Surveys & NPS",
    "Unnamed: 12": "Sending Survey / Feedback forms",
  },
  {
    providerName: "Outside Software Inc.",
    providerService: "eSuveysPro",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.esurveyspro.com/Privacy.aspx",
    optOut: null,
    placeProcessing: "Romania",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "GetWebCraft Ltd.",
    providerService: "GetSiteControl Survey Widget",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://getsitecontrol.com/privacy/",
    optOut: null,
    placeProcessing: "Cyprus",
    EuEea: "yes",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Hotjar Ltd.",
    providerService: "Hotjar Survey / Poll / User Tester",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nnumber of employees\npassword\nphone number\nprofession\nstate\nTax ID\nUsage Data\nusername\nVAT Number\nwebsite",
    providerPrivacyLink: "https://www.hotjar.com/legal/policies/privacy",
    optOut: "https://www.hotjar.com/legal/compliance/opt-out",
    placeProcessing: "Malta",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Features: but! bought as 1 product \nHeatmaps\nVisitor Recordings\nConversion Funnels\nForm Analysis\nFeedback Polls\nIncoming Feedback\nSurveys\nDPA: specifically states that data is going to be processed in the EU / EEA ",
    "Unnamed: 12": "Sending Survey / Feedback forms",
  },
  {
    providerName: "JotForm Inc.",
    providerService: "JotForm",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nnumber of employees\npassword\nphone number\nprofession\nstate\nTax ID\nUsage Data\nusername\nVAT Number\nwebsite",
    providerPrivacyLink: "https://www.jotform.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": "Sending Survey / Feedback forms",
  },
  {
    providerName: "Qualaroo LLC",
    providerService: "Qualaroo",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nnumber of employees\npassword\nphone number\nprofession\nstate\nUsage Data\nusername\nVAT Number\nwebsite",
    providerPrivacyLink: "https://qualaroo.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Questionable ",
    "Unnamed: 12": "Sending Survey / Feedback forms",
  },
  {
    providerName: "Qualtrics, LLC",
    providerService: "Qualtrics",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.qualtrics.com/privacy-statement/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SoGoSurvey LLC",
    providerService: "SoGoSurvey",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.sogosurvey.com/survey-privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Stamped.io PTE Singapore Ltd. ",
    providerService: "Stamped.io",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://stamped.io/privacy",
    optOut: null,
    placeProcessing: "Sigapore",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Widgix, LLC",
    providerService: "SurveyGizmo",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.surveygizmo.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "SurveyMonkey Europe Ireland UC (EEA users) | SurveyMonkey Inc. (other countries users)",
    providerService: "SurveyMonkey",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nnumber of employees\npassword\nphone number\nprofession\nstate\nTax ID\nUsage Data\nusername\nVAT Number\nwebsite",
    providerPrivacyLink:
      "https://www.surveymonkey.com/mp/legal/privacy-policy/?ut_source=footer",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Unless otherwise noted, our services are provided by SurveyMonkey Inc. inside of the United States, by SurveyMonkey Brasil Internet Eireli inside of Brazil, and by SurveyMonkey Europe UC everywhere else.\nWe\u2019re aware that many of our customers with EU users and EU affiliates would prefer that their data be hosted in the EU. To address this, we are actively engaged in building a data centre in the EU. Updates on when this data storage option will be available for customers will be provided through our website.",
    "Unnamed: 12": "Sending Survey / Feedback forms",
  },
  {
    providerName: "Survey Planet, LLC.",
    providerService: "SurveyPlanet",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://surveyplanet.com/legal",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Toluna SAS",
    providerService: "Toluna",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://uk.toluna.com/Privacy",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Typeform SL",
    providerService: "Typeform",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nnumber of employees\npassword\nphone number\nprofession\nstate\nTax ID\nUsage Data\nusername\nvarious types of data as specified in the privacy policy of the service \nVAT Number\nwebsite",
    providerPrivacyLink: "https://admin.typeform.com/to/dwk6gt/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Your information (not third parties\u2019 information collected through typeforms, which is subject to section 4.10 below) may be transferred, processed, and stored in countries that do not have data protection laws as protective as those in your jurisdiction. Your agreement to the terms of this Privacy Policy, followed by your submission of information in connection with the Service, represents your agreement to this international transfer of personal data.\n4.10. For the provision of the Services or because you want to process data from a given location or hand it to another company, data may be transferred outside the European Economic Area to an entity not part of the Privacy Shield scheme or to a country which has not been declared to offer a level of protection equal to the one provided by European data protection regulations.\n",
    "Unnamed: 12": "Sending Survey / Feedback forms",
  },
  {
    providerName: "UserVoice, Inc.",
    providerService: "Uservoice",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.uservoice.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Feedback - software that you integrate just for getting product feeback from your customers ",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "SurveyMonkey Europe Ireland UC (EEA users) | SurveyMonkey Inc. (other countries users)",
    providerService: "Wufoo",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\ngeographic position\nlanguage \nlast name\nnumber of employees\npassword\nphone number\nprofession\nstate\nTax ID\nUsage Data\nusername\nvarious types of data as specified in the privacy policy of the service \nVAT Number\nwebsite\nworkplace",
    providerPrivacyLink:
      "https://www.surveymonkey.com/mp/legal/privacy-policy/?ut_source=footer",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": "Sending Survey / Feedback forms",
  },
  {
    providerName: "Formstack LLC",
    providerService: "Formstack Online Forms ",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.formstack.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zoho Corporation BV (EEA users) | Zoho Corporation Pvt. Ltd. (AU users) | Zoho Corporation (USA&other countries users)",
    providerService: "Zoho Survey",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.zoho.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "The Quiz Collective, Inc.",
    providerService: "Tryinteract",
    questionGenerator: "Q2.5. -  Marketing surveys ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.tryinteract.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "ActiveCampaign, LLC",
    providerService: "ActiveCampaign Text Message and SMS",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData:
      "address\ncompany name\ndate of birth \nemail address\n\ufb01rst name\ngender\nlast name\npassword\nphone number\nprofession\nUsage Data\nusername\nvarious type of data",
    providerPrivacyLink: "https://www.activecampaign.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Acumbamail S.L",
    providerService: "Acumbamail SMS",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://acumbamail.com/politica-de-privacidad/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Celerity Systems Pty Ltd. ",
    providerService: "BulkSMS",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink:
      "https://www.bulksms.com/company/data-protection-and-privacy-policy.htm",
    optOut: null,
    placeProcessing: "South Africa",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason:
      "SCC \nhttps://www.bulksms.com/company/data-protection-addendum.htm\n",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Iterable, Inc.",
    providerService: "Iterable SMS marketing",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData:
      "address\ncity\ncompany name\nCookies\ncountry\nData communicated while using the service\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nprofession\nstate \nTax ID\nUsage Data\nusername\nZIP/Postal code",
    providerPrivacyLink: "https://iterable.com/trust/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Vonage UK Ltd. (EEA users) | Vonage Holdings Corp. (other countries users)",
    providerService: "Nexmo",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://www.vonage.com/privacy-policy?pca_ref",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SendinBlue SAS",
    providerService: "SendinBlue SMS",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://www.sendinblue.com/legal/privacypolicy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Commify Italia S.p.A. ",
    providerService: "Skebby",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink:
      "https://www.skebby.it/wp-content/uploads/2018/05/Informativa-Privacy-Estesa-SKEBBY.pdf",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Infomotiv SASU",
    providerService: "SMSFactor",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://www.smsfactor.com/mentions-legales",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "No privacy policy on website. Linking to general legal terms.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Calade Technologies srl.",
    providerService: "sMsmode",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink:
      "https://www.smsmode.com/en/politique-de-confidentialite/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Webklipper Technologies Pvt. Ltd.",
    providerService: "WebEngage Widget",
    questionGenerator: "Q2.3. - Messaging ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://webengage.com/privacy-policy/",
    optOut: null,
    placeProcessing: "India",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Twilio Ireland Limited (EEA users) | Twilio Inc. (other countries users)",
    providerService: "Sendgrid",
    questionGenerator: "Q2.6. - Newsletters ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\ndate of birth\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername",
    providerPrivacyLink: "https://www.twilio.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "The Rocket Science Group LLC",
    providerService: "Tiny Letter",
    questionGenerator: "Q2.6. - Newsletters ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://mailchimp.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "ActiveCampaign, LLC.",
    providerService: "ActiveCampaign site messages",
    questionGenerator: "Q2.4. - Notifications & Popups ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.activecampaign.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Different services that can be purchased separately : Email Marketing / Marketing Automation / Sales & CRM / Messaging/ Machine Learning",
    "Unnamed: 12": null,
  },
  {
    providerName: "Oracle Corp.",
    providerService: "AddThis",
    questionGenerator: "Q2.4. - Notifications & Popups ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.oracle.com/legal/privacy/addthis-privacy-policy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "- Link Promotion [ banner / popup/ slider allows client to show CTA to user ]",
  },
  {
    providerName: "Apptentive, Inc.",
    providerService: "Apptentive Widget",
    questionGenerator: "Q2.4. - Notifications & Popups ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.apptentive.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Elevio Pty. Ltd.",
    providerService: "Elevio Widget",
    questionGenerator: "Q2.4. - Notifications & Popups ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData:
      "cookies \ndata communicated while using the service \nemail address\nfirst name\nlast name\nusage data ",
    providerPrivacyLink: "https://elev.io/legal/privacy-policy",
    optOut: null,
    placeProcessing: "Australia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "OneSignal, Inc.",
    providerService: "OneSignal",
    questionGenerator: "Q2.4. - Notifications & Popups ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://onesignal.com/privacy_policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Next Net Media LLC",
    providerService: "PopUp Domination",
    questionGenerator: "Q2.4. - Notifications & Popups ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://popupdomination.com/privacy-policy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Marketing popup",
    "Unnamed: 12": "Move to MKT",
  },
  {
    providerName: "Robly Inc.",
    providerService: "Robly Widget",
    questionGenerator: "Q2.4. - Notifications & Popups ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData:
      "address\ncookies\nemail address\n\ufb01rst name\ngender\npayment data \nphone number\nprofession\nusage Data\nvarious types of Data as specified in the privacy policy of the service",
    providerPrivacyLink: "http://support.robly.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Exit-Intent popup",
    "Unnamed: 12": "Move to MKT",
  },
  {
    providerName: "UserVoice, Inc.",
    providerService: "Uservoice Widget",
    questionGenerator: "Q2.4. - Notifications & Popups ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.uservoice.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Oracle Corp.",
    providerService: "AddThis Related Posts",
    questionGenerator: "Q2.7. - Other Marketing ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.oracle.com/legal/privacy/addthis-privacy-policy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "- Related Posts [ allows client to recommend additional content from his website ]",
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Tag Manager",
    questionGenerator: "Q2.7. - Other Marketing ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy?hl=en",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "centralizes page tag management into a single dashboard. The page will only need one JS script in order to have all tags working (e.g.: Google Analytics, Facebook Pixel, Hotjar heatmaps, etc)",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Segment.io, Inc. - Segment Technologies Ireland, Ltd. (EU repr. for EEA users)",
    providerService: "Segment",
    questionGenerator: "Q2.7. - Other Marketing ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://segment.com/docs/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Fjord Technologies SAS. ",
    providerService: "TagCommander",
    questionGenerator: "Q2.7. - Other Marketing ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.commandersact.com/en/privacy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Tealium Inc.",
    providerService: "Tealium IQ tag Management",
    questionGenerator: "Q2.7. - Other Marketing ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://tealium.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Webtrekk GmbH, company of Mapp Digital Germany GmbH (EEA users) | Mapp Digital US, LLC (USA&other countries users) ",
    providerService: "Webtrekk Tag Integration",
    questionGenerator: "Q2.7. - Other Marketing ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webtrekk.com/privacy-statement/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "GrowSurf, Inc",
    providerService: "GrowSurf Referral Marketing",
    questionGenerator: "Q2.7. - Other Marketing ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service,, Cookies",
    specificData: null,
    providerPrivacyLink: "https://growsurf.com/legal",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
];

export default marketing;
