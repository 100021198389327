import React from "react";

import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";

import { ReactComponent as Eyes } from "../../assets/images/dashboard/newProject/eyes.svg";

const useStyles = makeStyles((theme) => ({
  infoBox: {
    display: "flex",
    position: "relative",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  infoCentralizationBox: {
    width: "125px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexWrap: "nowrap",
  },
  infoButton: {
    cursor: "default",
    color: theme.palette.grayTwo,
    "&:hover": {
      background: theme.palette.common.white,
    },
  },
  infoButtonIcon: {},
  infoMessage: {
    fontSize: "1.1rem",
    color: theme.palette.grayTwo,
    marginTop: theme.spacing(1),
  },
}));

const InfoMessage = ({ message, description, action }) => {
  const classes = useStyles();

  return (
    <Box className={classes.infoBox}>
      <Box className={classes.infoCentralizationBox}>
        <IconButton
          aria-label="info"
          className={classes.infoButton}
          disableRipple
          disableFocusRipple
          onClick={action ? action : () => {}}
        >
          <Eyes className={classes.infoButtonIcon} />
        </IconButton>

        <Typography variant="body3" className={classes.infoMessage}>
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

export default InfoMessage;
