import React from "react";

import { makeStyles, Box, Link, Typography } from "@material-ui/core";

import { GetStarted } from "../general";

import gradient from "../../assets/images/home/gradient.png";

import illustration from "../../assets/images/home/illustration.svg";

import illustrationCharacter from "../../assets/images/home/illustrationCharacter.svg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    height: "100vh",
    width: "100%",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      "&": {
        height: "110vh",
      },
    },
  },
  boxBackground: {
    zIndex: "-2",
    height: "100%",
    width: "100%",
    position: "absolute",
    left: "0",
    backgroundImage: `url(${gradient})`,
    backgroundAttachment: "scroll",
    backgroundPosition: "top left",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
  },
  boxIllustration: {
    zIndex: "-1",
    height: "100%",
    width: "100%",
    position: "absolute",
    left: "-10px",
    backgroundImage: `url(${illustration})`,
    backgroundAttachment: "scroll",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    [theme.breakpoints.down("md")]: {
      "&": {
        left: "-20px",
        bottom: "-115px",
        backgroundSize: "100%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        backgroundImage: `url(${illustrationCharacter})`,
        backgroundSize: "90%",
        bottom: "-200px",
        left: "0",
      },
    },
  },
  boxContainerActions: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  boxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "530px",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    position: "absolute",
    top: "15%",
    left: "15%",
    transition: "all .25s ease-in-out 0s",
    [theme.breakpoints.down("lg")]: {
      "&": {
        height: "500px",
        width: "550px",
        left: "12%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        alignItems: "center",
        height: "fit-content",
        minHeight: "450px",
        width: "100%",
        left: "0%",
        top: "0",
        padding: theme.spacing(0, 6),
      },
    },
    // prettier-ignore
    ["@media (max-width:375px)"]: { // eslint-disable-line no-useless-computed-key
		"&": {
			padding: theme.spacing(0, 3),
		}
	},
  },
  boxContentText: {
    width: "100%",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      "&": {
        marginBottom: theme.spacing(1),
      },
    },
  },
  contentTitle: {
    fontSize: "4rem",
    [theme.breakpoints.down("sm")]: {
      fontSize: "3rem",
    },
    // prettier-ignore
    ["@media (max-width:435px)"]: { // eslint-disable-line no-useless-computed-key
      "&": {
        fontSize: "2.75rem",
      },
    },
  },
  textStyle: {
    "&::after": {
      content: '""',
      display: "block",
      width: "300px",
      height: "30px",
      marginTop: "-62px",
      marginLeft: "175px",
      borderBottom: `16px solid ${theme.palette.yellowOne}`,
    },
    [theme.breakpoints.down("sm")]: {
      "&::after": {
        marginLeft: "130px",
        width: "225px",
      },
    },
    // prettier-ignore
    ["@media (max-width:465px)"]: { // eslint-disable-line no-useless-computed-key
      	"&::after": {
			display: 'none'
      	},
    },
  },
  contentDescription: {
    fontSize: "1.7rem",
    color: theme.palette.grayOne,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.3rem",
    },
  },
  boxContentActions: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  contentCreditCard: {
    fontSize: "1.1rem",
    color: theme.palette.grayFive,
    marginTop: theme.spacing(2),
    marginLeft: ".75px",
  },
  link: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontSize: "1.3rem",
    fontWeight: "600",
    textDecoration: "none",
    lineHeight: "2",
  },
}));

const Intro = () => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxBackground}></Box>

      <Box className={classes.boxIllustration}></Box>

      <Box className={classes.boxContent}>
        <Box className={classes.boxContentText}>
          <Typography variant="h1" className={classes.contentTitle}>
            Create your user
            <br />
            friendly <span className={classes.textStyle}>privacy policy</span>
          </Typography>

          <Typography variant="body2" className={classes.contentDescription}>
            Audaxly helps you create and manage <br />
            compliant, well designed privacy policies
          </Typography>
        </Box>

        <Box className={classes.boxContainerActions}>
          <Box className={classes.boxContentActions}>
            <GetStarted
              text="Get started - Free"
              styles={{ width: "150px", height: "45px" }}
            />

            <Typography className={classes.contentCreditCard}>
              No credit card required!
            </Typography>
          </Box>

          <Link
            underline="none"
            href={process.env.REACT_APP_PRIVACY_POLICY}
            target="_blank"
            className={classes.link}
          >
            Demo
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Intro;
