import React, { useState, useEffect, useMemo } from "react";

import {
  Box,
  Select,
  InputLabel,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Alert,
  Menu,
  MenuItem,
  ListItemIcon,
  Link,
  makeStyles,
} from "@material-ui/core";

import {
  Add as AddIcon,
  Close as CloseIcon,
  ArrowBack as ArrowBackIcon,
  Settings as SettingsIcon,
  MergeType as MergeTypeIcon,
  ExpandMore as ExpandMoreIcon,
  DeleteOutline as DeleteOutlineIcon,
  MoreVert as MoreVertIcon,
  Person as PersonIcon,
  Business as BusinessIcon,
} from "@material-ui/icons";

import clsx from "clsx";

import { useNavigate } from "react-router-dom";

import { useForm, Controller } from "react-hook-form";

import * as Yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import { ErrorMessage } from "@hookform/error-message";

import { useMutation, useQuery, gql, useApolloClient } from "@apollo/client";

import * as stringSimilarity from "string-similarity";

import * as omitDeep from "omit-deep-lodash";

import { lowerCase, startCase } from "lodash";

import { toast } from "react-toastify";

import {
  ErrorLoad,
  SuccessLoad,
  LoadingComponent,
  InfoMessage,
  DeleteDialog,
} from "../general";

import { URL_PATTERN, limitText } from "../../utils";

import { useProject, useDashboard } from "../../contexts";

import { countries } from "../../data";

import { ReactComponent as Business } from "../../assets/images/dashboard/newProject/business.svg";

import { ReactComponent as Personal } from "../../assets/images/dashboard/newProject/personal.svg";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".radioIcon": {},
    ".radioIcon path": {
      fill: theme.palette.secondary.main,
      transform: "scale(.9)",
    },
    ".radioIconDisabled": {},
    ".radioIconDisabled path": {
      fill: theme.palette.disabledColor,
      transform: "scale(.9)",
    },
  },
  boxContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  boxTitle: {
    width: "100%",
    flex: ".8",
    maxHeight: "50px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    boxSizing: "border-box",
    padding: theme.spacing(1, 1, 0, 3),
    marginTop: "10px",
  },
  boxListItems: {
    boxSizing: "border-box",
    width: "100%",
    maxHeight: "75vh",
    flex: "7",
    padding: "12px",
    // prettier-ignore
    ["@media (max-height:350px)"]: { // eslint-disable-line no-useless-computed-key
      flex: "5",
	},
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "6px 12px",
        maxHeight: "85vh",
      },
    },
  },
  boxNewProject: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    maxHeight: "80px",
    flex: "1",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderTop: "1px solid rgba(231, 238, 236, .75)",
  },
  newProjectButton: {
    width: "100%",
    height: "100%",
    color: theme.palette.primary.main,
    fontSize: "1.10rem",
    fontWeight: "700",
    textDecoration: "none",
    background: "transparent",
    fontFamily: "Lato, Montserrat",
    "&:hover": {
      background: "transparent",
      "& .MuiSvgIcon-root": {
        filter: "opacity(.8)",
      },
    },
  },
  newProjectButtonIcon: {
    color: theme.palette.secondary.main,
    marginBottom: "3px",
  },
  dialog: {},
  dialogTitle: {
    maxHeight: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0",
  },
  dialogContent: {
    padding: "0",
    height: "100%",
  },
  stepperContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  paperBox: {
    margin: theme.spacing(1),
    height: "725px",
    width: "585px",
    minWidth: "auto",
    maxHeight: "fit-content",
    background: theme.palette.common.white,
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        height: "800px",
        maxHeight: "99%",
      },
    },
  },
  closeButton: {
    color: theme.palette.blackOne,
    "&:hover": {
      background: "transparent",
    },
  },
  closeButtonIcon: {
    width: "24px",
    height: "24px",
  },
  stepperTitleContainer: {
    width: "100%",
    height: "auto",
    maxHeight: "180px",
    textAlign: "center",
  },
  stepperTitle: {
    color: theme.palette.blackOne,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "30px",
    lineHeight: "35px",
  },
  stepperSubTitle: {
    padding: theme.spacing(0, 1),
    marginTop: theme.spacing(1),
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    color: theme.palette.grayOne,
  },
  stepperFinishedResetButton: {},
  stepperContentContainer: {
    padding: theme.spacing(2),
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  stepControlsContainer: {
    flex: ".5",
    width: "100%",
    minHeight: "50px",
    maxHeight: "50px",
    boxSizing: "border-box",
    padding: "0 15px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  labelRoot: {
    // color: theme.palette.secondary.main,
  },
  notchedOutline: {
    // borderWidth: "1px",
    // borderColor: `${theme.palette.secondary.main} !important`,
  },
  formSteps: {
    width: "100%",
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  formStepsContainerFieldsProjectType: {
    flexDirection: "row",
  },
  formControlProjectType: {
    width: "100%",
  },
  formRadioGroupProjectType: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      "&": {
        flexDirection: "column",
      },
    },
  },
  formLabelProjectType: {
    cursor: (props) => (props.update ? "default" : "pointer"),
    flex: "1",
    minHeight: "100px",
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    borderRadius: "5px",
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.disabledColor}`,
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
    "&:first-child": {
      marginLeft: "0",
    },
    "&:last-child": {
      marginRight: "0",
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "auto",
        margin: "0",
        marginTop: theme.spacing(1),
      },
    },
  },
  formLabelProjectTypeActive: {
    borderColor: theme.palette.secondary.main,
  },
  formLabelTextProjectType: {
    color: theme.palette.disabledColor,
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
  },
  formLabelTextProjectTypeActive: {
    color: theme.palette.dashboardBlackLight,
  },
  formRadioProjectType: {
    display: "none",
  },
  formStepsContainerFields: {
    width: "100%",
    flex: "1",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0 16px",
    "& > *": {
      width: "100%",
      paddingBottom: theme.spacing(1),
      padding: "0 16px",
    },
    "& > *:last-child": {
      paddingBottom: "0",
    },
  },
  formStepsContainerControls: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    boxSizing: "border-box",
    padding: "0 16px",
    paddingTop: "8px",
  },
  note: {
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "16px",
    color: theme.palette.grayOne,
    marginBottom: theme.spacing(3),
    marginLeft: "4px",
  },
  formStepsContainerFieldsPersonalDetails: {
    flex: "8",
    padding: "0 16px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    "& > *": {
      width: "100%",
    },
  },
  personalDetailsAlert: {
    fontSize: "14px",
    maxHeight: "35px",
    marginBottom: "5px",
    color: theme.palette.grayOne,
  },
  backButton: {
    color: theme.palette.dashboardBlue,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    "&:hover": {
      background: "transparent",
      filter: "opacity(.8)",
    },
  },
  backButtonIcon: {
    marginRight: "10px",
  },
  nextButton: {
    fontWeight: "normal",
  },
  completeButton: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    background: "transparent",
    "&:hover": {
      background: "transparent",
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  error: {
    alignSelf: "self-end",
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      "&": {
        paddingBottom: "2px",
      },
    },
  },
  projectCardSelected: {
    minHeight: "85px",
    borderRadius: "5px",
    // Keep the background in sync with the hover from the
    // project not selected (see the next class)
    border: `1px solid ${theme.palette.purpleThree}`,
    backgroundColor: `${theme.palette.purpleOne} !important`,
    boxSizing: "border-box",
    padding: theme.spacing(1, 2, 1, 2),
    "& > *": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
  },
  projectCardNotSelected: {
    minHeight: "85px",
    borderRadius: "5px",
    background: theme.palette.common.white,
    boxSizing: "border-box",
    padding: theme.spacing(1, 2, 1, 2),
    border: `1px solid transparent`,
    "& > *": {
      paddingTop: "10px",
      paddingBottom: "10px",
    },
    "&:hover": {
      border: `1px solid ${theme.palette.purpleThree}`,
      backgroundColor: `${theme.palette.purpleOne} !important`,
    },
  },
  projectCardRow: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "space-between",
    boxSizing: "border-box",
  },
  projectCardTitleContainer: {},
  projectCardTitle: {
    cursor: "pointer",
    color: theme.palette.blackOne,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: ".4px",
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
  projectCardTitleActionsContainer: {
    boxSizing: "border-box",
    flex: ".5",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  projectMoreOptions: {
    cursor: "pointer",
    fontSize: "1.7rem",
    color: theme.palette.secondary.main,
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
  menuProject: {
    "& > *": {
      boxShadow: theme.shadows[9],
      // Box
      left: "543px",
      "& > *:first-child": {
        // Ul
        "& > *": {
          //Li
          paddingTop: "10px",
          paddingBottom: "10px",
        },
      },
    },
  },
  projectCardSettingsButton: {
    margin: "0",
    padding: "0",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  projectCardSettingsButtonIcon: {
    fontSize: "1.7rem",
    color: theme.palette.primary.main,
  },
  projectCardContentContainer: {},
  projectCardPlanTypeButton: {
    padding: "0 0 0 6px",
  },
  projectCardPlanTypeButtonIconRoot: {
    minWidth: "auto",
  },
  projectCardPlanTypeButtonIcon: {
    color: theme.palette.secondary.main,
  },
  projectCardProjectDeleteButtonIcon: {
    fontSize: "1.7rem",
    color: theme.palette.primary.main,
  },
  projectCardProjectDeleteButton: {
    padding: "0",
    color: theme.palette.grayFive,
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  projectsList: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "100%",
    overflow: "overlay",
    boxSizing: "border-box",
    "& > *": {
      minHeight: "100px",
      marginBottom: "15px",
      marginTop: "15px",
    },
    "& > *:first-child": {
      marginTop: "0px",
    },
    "& > *:last-child": {
      minHeight: "unset",
    },
  },
  projectListFetchMore: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center",
    padding: "5px",
  },
  projectListFetchMoreButton: {
    width: "35px",
    height: "35px",
    color: theme.palette.secondary.main,
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grayThree}`,
    borderRadius: "50%",
  },
  projectListFetchMoreButtonIcon: {},
  optionSectionTitle: {
    paddingRight: "15px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    textTransform: "uppercase",
    color: theme.palette.grayTwo,
  },
  formControl: {
    width: "100%",
  },
  buttonClean: {
    padding: "0",
    color: theme.palette.grayFive,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "18px",
    background: "transparent",
    "&:hover": {
      background: "transparent",
    },
  },
  activeStepIndicator: {
    color: theme.palette.grayFive,
  },
  link: {
    color: theme.palette.secondary.light,
    "&:hover": {
      color: theme.palette.secondary.soft,
    },
  },
}));

const COMPANY = "company";

const INDIVIDUAL = "individual";

const ProjectDetailsSchema = Yup.object().shape({
  title: Yup.string()
    .required("You must fill the title")
    .max(80, "Must be at most 80 characters"),
  url: Yup.string()
    .required("You must fill the website' URL")
    .matches(URL_PATTERN, "Invalid URL"),
  email: Yup.string()
    .required("You must fill the email")
    .trim()
    .email("Invalid email"),
  scope: Yup.string().oneOf(
    [COMPANY, INDIVIDUAL],
    "Project scope must be one of the above options."
  ),
});

const ProjectDetailsForm = ({
  activeStep,
  projectData,
  setProjectData,
  handleNext,
  update = false,
}) => {
  const classes = useStyles();

  const [scope, setScope] = useState(projectData.scope || COMPANY);

  const methods = useForm({
    resolver: yupResolver(ProjectDetailsSchema),
    defaultValues: {
      scope,
      ...projectData,
    },
    shouldUnregister: false,
  });

  const handleChangeScope = (event) => {
    event.preventDefault();

    setScope(event.target.value);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    const url = (
      data.url.startsWith("http://") || data.url.startsWith("https://")
        ? data.url
        : `http://${data.url}`
    ).toLowerCase();

    setProjectData((previousStepData) => ({
      ...previousStepData,
      ...data,
      scope,
      url,
    }));

    handleNext();
  };

  return (
    <form
      onSubmit={methods.handleSubmit(onSubmit)}
      className={classes.formSteps}
    >
      <Box className={classes.formStepsContainerFields}>
        {/* Title */}
        <Box>
          <TextField
            name="title"
            type="text"
            label="Project Name"
            variant="outlined"
            InputProps={{
              classes: { notchedOutline: classes.notchedOutline },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
              },
            }}
            error={Boolean(methods.errors.title)}
            inputRef={methods.register}
            fullWidth
          />

          <Typography className={classes.error}>
            <ErrorMessage errors={methods.errors} name="title" />
          </Typography>
        </Box>

        {/* Site */}
        <Box>
          <TextField
            name="url"
            type="text"
            label="Website"
            variant="outlined"
            helperText="The url for the website / app for which you create the legal documents"
            InputProps={{
              classes: { notchedOutline: classes.notchedOutline },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
              },
            }}
            error={Boolean(methods.errors.url)}
            inputRef={methods.register}
            fullWidth
          />

          <Typography className={classes.error}>
            <ErrorMessage errors={methods.errors} name="url" />
          </Typography>
        </Box>

        {/* Email */}
        <Box>
          <TextField
            name="email"
            type="text"
            label="Email"
            variant="outlined"
            helperText="The email where the user can contact you. This info will be
            displayed in the generated documents"
            InputProps={{
              classes: { notchedOutline: classes.notchedOutline },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
              },
            }}
            error={Boolean(methods.errors.email)}
            inputRef={methods.register}
            fullWidth
          />

          <Typography className={classes.error}>
            <ErrorMessage errors={methods.errors} name="email" />
          </Typography>
        </Box>

        {/* Type */}
        <Box>
          {update ? (
            <Typography className={classes.note}>
              For now its not possible to change the project type
            </Typography>
          ) : (
            <Typography className={classes.note}>
              Who is the legal owner of the project? Please consider that the
              legal owner can’t be changed
            </Typography>
          )}

          <Controller
            as={
              <FormControl
                component="fieldset"
                className={classes.formControlProjectType}
              >
                <RadioGroup
                  aria-label="scope"
                  name="scope"
                  value={scope}
                  onChange={handleChangeScope}
                  className={classes.formRadioGroupProjectType}
                >
                  <FormLabel
                    className={`${classes.formLabelProjectType} ${
                      scope === COMPANY
                        ? classes.formLabelProjectTypeActive
                        : ""
                    }`}
                    disabled={update}
                  >
                    <Business
                      className={
                        scope === COMPANY ? "radioIcon" : "radioIconDisabled"
                      }
                    />

                    <Radio
                      className={classes.formRadioProjectType}
                      value={COMPANY}
                      disabled={update}
                    />

                    <Typography
                      variant="body1"
                      className={`${classes.formLabelTextProjectType} ${
                        scope === COMPANY
                          ? classes.formLabelTextProjectTypeActive
                          : ""
                      }`}
                    >
                      Company
                    </Typography>
                  </FormLabel>

                  <FormLabel
                    className={`${classes.formLabelProjectType} ${
                      scope === INDIVIDUAL
                        ? classes.formLabelProjectTypeActive
                        : ""
                    }`}
                    disabled={update}
                  >
                    <Personal
                      className={
                        scope === INDIVIDUAL ? "radioIcon" : "radioIconDisabled"
                      }
                    />

                    <Radio
                      className={classes.formRadioProjectType}
                      value={INDIVIDUAL}
                      disabled={update}
                    />

                    <Typography
                      variant="body1"
                      className={`${classes.formLabelTextProjectType} ${
                        scope === INDIVIDUAL
                          ? classes.formLabelTextProjectTypeActive
                          : ""
                      }`}
                    >
                      Individual
                    </Typography>
                  </FormLabel>
                </RadioGroup>
              </FormControl>
            }
            name="scope"
            control={methods.control}
          />

          <Typography className={classes.error}>
            <ErrorMessage errors={methods.errors} name="scope" />
          </Typography>
        </Box>
      </Box>

      <Box className={classes.formStepsContainerControls}>
        <span className={classes.activeStepIndicator}>
          {activeStep + 1} / {totalSteps}
        </span>

        <Button
          variant="hovered"
          color="primary"
          type="submit"
          disableRipple
          disableFocusRipple
          disableElevation
        >
          Next
        </Button>
      </Box>
    </form>
  );
};

const SEARCH_PROJECT_CATEGORY = gql`
  query SearchProjectCategory($input: ProjectCategorySearchInput!) {
    searchProjectCategory(input: $input) {
      _id
      categoryItems {
        _id
        title
      }
    }
  }
`;

const CREATE_PRIVACY_POLICY = gql`
  mutation CreatePrivacyPolicy($input: PrivacyPolicyCreateInput!) {
    createPrivacyPolicy(input: $input) {
      publicCode
    }
  }
`;

const ProjectDetailsCompanySchema = Yup.object().shape({
  legalName: Yup.string()
    .required("You must fill the company legal name")
    .max(180, "Must be at most 180 characters"),
  country: Yup.mixed().test(
    "country",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!value) return false;

      return countries
        .map((country) => country["Countries in the world"])
        .includes(value);
    }
  ),
  state: Yup.string()
    .required("You must fill the state / city / territory / province")
    .max(140, "Must be at most 140 characters"),
  address: Yup.string()
    .required("You must fill the address")
    .max(140, "Must be at most 140 characters"),
  postalCode: Yup.string()
    .required("You must fill the postal code / ZIP")
    .max(40, "Must be at most 40 characters"),
});

const ProjectDetailsIndividualSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("You must fill the first name")
    .max(70, "Must be at most 70 characters"),
  lastName: Yup.string()
    .required("You must fill the last name")
    .max(70, "Must be at most 70 characters"),
  country: Yup.mixed().test(
    "country",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!value) return false;

      return countries
        .map((country) => country["Countries in the world"])
        .includes(value);
    }
  ),
  state: Yup.string()
    .required("You must fill the state / city / territory / province")
    .max(140, "Must be at most 140 characters"),
  address: Yup.string()
    .required("You must fill the address")
    .max(140, "Must be at most 140 characters"),
  postalCode: Yup.string()
    .required("You must fill the postal code / ZIP")
    .max(40, "Must be at most 40 characters"),
});

const PersonalDetailsForm = ({
  setOpenModal,
  setLoadingProject,
  activeStep,
  setActiveStep,
  projectData,
  setProjectData,
  handleBackClojure,
  setProjectError,
  setProjectSuccess,
  projectOnSubmit,
  setProjects,
  page,
  setPage,
  limit,
  projectOnSubmitVariables = {},
  update = false,
}) => {
  const classes = useStyles();

  const client = useApolloClient();

  const navigate = useNavigate();

  const { setProject } = useProject();

  const { dashboard } = useDashboard();

  const status = dashboard.newProjectModal;

  const methods = useForm({
    resolver: yupResolver(
      projectData.scope === COMPANY
        ? ProjectDetailsCompanySchema
        : ProjectDetailsIndividualSchema
    ),
    defaultValues: projectData,
    shouldUnregister: false,
  });

  const onSubmit = async (data, event) => {
    event.preventDefault();

    setLoadingProject(true);

    setActiveStep(0);

    setProjectData((previousStepData) => ({
      ...previousStepData,
      ...data,
    }));

    // The project type (on the GraphQl API) related to the update operation
    // doesn't receive the attributes public code and scope, cause these
    // attributes are immutable

    // Data from the current step
    const {
      publicCode: publicCode1,
      scope: scope1,
      firstName,
      lastName,
      legalName,
      ...dataClean
    } = data;

    // Data from all steps, used to create or update the project
    const {
      publicCode: publicCode2,
      scope: scope2,
      firstName: firstName2,
      lastName: lastName2,
      legalName: legalName2,
      ...projectDataClean
    } = projectData;

    let project = {};

    try {
      // eslint-disable-next-line
      project = await projectOnSubmit({
        variables: {
          input: {
            ...projectDataClean,
            ...dataClean,
            ...(update ? { scope: scope1 } : {}),
            // Make sure that a project type is never changed
            ...(projectData.scope === COMPANY
              ? { legalName }
              : { firstName, lastName }),
            // Change plan information when payment is implemented
            plan: {
              deal: "basic",
              price: 0.0,
              periodical: "monthly",
              startAt: new Date().toISOString(),
            },
          },
          ...projectOnSubmitVariables,
        },
      });

      setProjectError(false);

      setProjectSuccess(true);

      setTimeout(() => {
        setOpenModal(false);
      }, 2500);
    } catch (error) {
      setProjectError(true);

      setProjectSuccess(false);
    }

    if (status && project?.data?.createProject) {
      try {
        const [projectCategoryPrivacyPolicy] = (
          await client.query({
            variables: {
              input: {
                title: "Web",
                categoryItems: {
                  title: "Privacy Policy",
                },
              },
            },
            query: SEARCH_PROJECT_CATEGORY,
          })
        ).data.searchProjectCategory;

        // eslint-disable-next-line
        const privacyPolicy = (
          await client.mutate({
            variables: {
              input: {
                _idProject: project?.data?.createProject?._id,
                _idProjectCategory: projectCategoryPrivacyPolicy?._id,
                _idProjectCategoryItem:
                  projectCategoryPrivacyPolicy?.categoryItems?.find(
                    (item) => item?.title === "Privacy Policy"
                  )?._id,
                colors: {
                  main: "#000000",
                  link: "#424242",
                  icon: "#000000",
                  scroll: "transparent",
                },
                overrides: {
                  disablePadding: false,
                  disableError: false,
                },
              },
            },
            mutation: CREATE_PRIVACY_POLICY,
          })
        ).data.createPrivacyPolicy;
      } catch (error) {
        // console.log(error);
      }
    }

    if (project?.data?.createProject) {
      try {
        // eslint-disable-next-line
        const projects = (
          await client.query({
            query: SEARCH_PROJECTS,
            variables: { page: 1, limit: page * limit },
            notifyOnNetworkStatusChange: true,
            fetchPolicy: "no-cache",
          })
        ).data.searchProjects;

        setPage(projects?.page ? projects.page : 1);

        setProjects(projects?.projects);
      } catch (error) {
        // console.log(error);

        setProjects([]);
      }
    }

    if (status && project?.data?.createProject) {
      setProject({ project: project?.data?.createProject });

      navigate("/dashboard/categories");

      if (!dashboard.newProjectTutorial) {
      }
    }
  };

  return (
    <form
      onSubmit={methods.handleSubmit(onSubmit)}
      className={classes.formSteps}
    >
      <Box className={classes.formStepsContainerFields}>
        <Box>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.personalDetailsAlert}
          >
            This info wil be displayed in the generated documents
          </Alert>
        </Box>

        {projectData.scope === INDIVIDUAL && (
          <>
            <Box>
              <TextField
                name="firstName"
                type="text"
                label="First name"
                variant="outlined"
                InputProps={{
                  classes: { notchedOutline: classes.notchedOutline },
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                error={Boolean(methods.errors.firstName)}
                inputRef={methods.register}
                fullWidth
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={methods.errors} name="firstName" />
              </Typography>
            </Box>

            <Box>
              <TextField
                name="lastName"
                type="text"
                label="Last name"
                variant="outlined"
                InputProps={{
                  classes: { notchedOutline: classes.notchedOutline },
                }}
                InputLabelProps={{
                  shrink: true,
                  classes: {
                    root: classes.labelRoot,
                  },
                }}
                error={Boolean(methods.errors.lastName)}
                inputRef={methods.register}
                fullWidth
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={methods.errors} name="lastName" />
              </Typography>
            </Box>
          </>
        )}

        {projectData.scope === COMPANY && (
          <Box>
            <TextField
              name="legalName"
              type="text"
              label="Company legal name"
              variant="outlined"
              InputProps={{
                classes: { notchedOutline: classes.notchedOutline },
              }}
              InputLabelProps={{
                shrink: true,
                classes: {
                  root: classes.labelRoot,
                },
              }}
              error={Boolean(methods.errors.legalName)}
              inputRef={methods.register}
              fullWidth
            />

            <Typography className={classes.error}>
              <ErrorMessage errors={methods.errors} name="legalName" />
            </Typography>
          </Box>
        )}

        <Box>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel shrink htmlFor="country">
              Country
            </InputLabel>

            <Select
              native
              autoWidth
              label="Country"
              inputProps={{
                name: "country",
                id: "country",
              }}
              inputRef={methods.register}
            >
              <option value="">None</option>

              {countries.map((country) => (
                <option value={country["Countries in the world"]}>
                  {country["Countries in the world"]}
                </option>
              ))}
            </Select>
          </FormControl>

          <Typography className={classes.error}>
            <ErrorMessage errors={methods.errors} name="country" />
          </Typography>
        </Box>

        <Box>
          <TextField
            name="state"
            type="text"
            label="State / City / Territory / Province"
            variant="outlined"
            InputProps={{
              classes: { notchedOutline: classes.notchedOutline },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
              },
            }}
            error={Boolean(methods.errors.state)}
            inputRef={methods.register}
            fullWidth
          />

          <Typography className={classes.error}>
            <ErrorMessage errors={methods.errors} name="state" />
          </Typography>
        </Box>

        <Box>
          <TextField
            name="address"
            type="text"
            label="Address"
            variant="outlined"
            InputProps={{
              classes: { notchedOutline: classes.notchedOutline },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
              },
            }}
            error={Boolean(methods.errors.address)}
            inputRef={methods.register}
            fullWidth
          />

          <Typography className={classes.error}>
            <ErrorMessage errors={methods.errors} name="address" />
          </Typography>
        </Box>

        <Box>
          <Typography className={classes.note}>
            Don't know the postal code / ZIP? Search it{" "}
            <Link
              underline="none"
              href="https://worldpostalcode.com/"
              target="_blank"
              className={classes.link}
            >
              here
            </Link>
          </Typography>

          <TextField
            name="postalCode"
            type="text"
            label="Postal code / ZIP"
            variant="outlined"
            InputProps={{
              classes: { notchedOutline: classes.notchedOutline },
            }}
            InputLabelProps={{
              shrink: true,
              classes: {
                root: classes.labelRoot,
              },
            }}
            error={Boolean(methods.errors.postalCode)}
            inputRef={methods.register}
            fullWidth
          />

          <Typography className={classes.error}>
            <ErrorMessage errors={methods.errors} name="postalCode" />
          </Typography>
        </Box>
      </Box>

      <Box className={classes.formStepsContainerControls}>
        <span className={classes.activeStepIndicator}>
          {activeStep + 1} / {totalSteps}
        </span>

        <Box>
          <Button
            onClick={() => handleBackClojure(setProjectData, methods)}
            className={classes.backButton}
            disableRipple
            disableFocusRipple
            disableElevation
          >
            <ArrowBackIcon className={classes.backButtonIcon} /> Back
          </Button>

          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.completeButton}
            disableRipple
            disableFocusRipple
            disableElevation
          >
            {status ? "Create" : "Update"}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

const totalSteps = 2;

const stepsText = {
  projectDetails: {
    title: "Project details",
  },
  typeDetails: {
    company: {
      title: "Company details",
      description: "The legal owner of the project",
    },
    individual: {
      title: "Personal details",
      description: "The legal owner of the project",
    },
  },
};

const CREATE_PROJECT = gql`
  mutation CreateProject($input: ProjectCreateInput!) {
    createProject(input: $input) {
      ... on CompanyProject {
        _id
        title
        scope
        url
        email
        legalName
        country
        state
        address
        postalCode
        plan {
          deal
        }
        publicCode
      }
      ... on IndividualProject {
        _id
        title
        scope
        url
        email
        firstName
        lastName
        country
        state
        address
        postalCode
        plan {
          deal
        }
        publicCode
      }
    }
  }
`;

const NewProjectStepper = ({
  setOpenModal,
  activeStep,
  setActiveStep,
  setProjects,
  page,
  setPage,
  limit,
  setLimit,
}) => {
  const classes = useStyles();

  const [createProjectError, setCreateProjectError] = useState(false);

  const [createProjectSuccess, setCreateProjectSuccess] = useState(false);

  const [creatingProject, setCreatingProject] = useState(false);

  const newProjectDataDefault = { scope: COMPANY };

  const [projectData, setProjectData] = useState(newProjectDataDefault);

  // prettier-ignore
  const [
		createProject,
		// eslint-disable-next-line
		{ dataCreateProject, loadingCreateProject, errorCreateProject, calledCreateProject },
	  ] = useMutation(CREATE_PROJECT);

  useEffect(() => {
    return () => {
      setProjectData(newProjectDataDefault);

      setActiveStep(0);
    };

    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setActiveStep((previousStep) => previousStep + 1);
  };

  const handleBack = () => {
    setActiveStep((previousStep) => previousStep - 1);
  };

  const handleBackClojure = (setProjectData, methods) => {
    setProjectData((previousStepData) => ({
      ...previousStepData,
      ...methods.getValues(),
    }));

    handleBack();
  };

  return (
    <Box className={classes.stepperContainer}>
      {creatingProject && !createProjectError && !createProjectSuccess && (
        <LoadingComponent message={"Creating project"} />
      )}

      {!createProjectError && !createProjectSuccess && !creatingProject && (
        <>
          <Box className={classes.stepperTitleContainer}>
            <Typography variant="h2" className={classes.stepperTitle}>
              {activeStep === 0
                ? stepsText.projectDetails.title
                : projectData.scope === COMPANY
                ? stepsText.typeDetails.company.title
                : stepsText.typeDetails.individual.title}
            </Typography>

            <Typography variant="h3" className={classes.stepperSubTitle}>
              {activeStep === 0
                ? stepsText.projectDetails.description
                : projectData.scope === COMPANY
                ? stepsText.typeDetails.company.description
                : stepsText.typeDetails.individual.description}
            </Typography>
          </Box>

          <Box className={classes.stepperContentContainer}>
            {activeStep === 0 && (
              <ProjectDetailsForm
                activeStep={activeStep}
                projectData={projectData}
                setProjectData={setProjectData}
                handleNext={handleNext}
              />
            )}

            {activeStep === 1 && (
              <PersonalDetailsForm
                setOpenModal={setOpenModal}
                setLoadingProject={setCreatingProject}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                projectData={projectData}
                setProjectData={setProjectData}
                handleNext={handleNext}
                handleBackClojure={handleBackClojure}
                setProjectError={setCreateProjectError}
                setProjectSuccess={setCreateProjectSuccess}
                projectOnSubmit={createProject}
                setProjects={setProjects}
                page={page}
                setPage={setPage}
                limit={limit}
                update={true}
              />
            )}
          </Box>
        </>
      )}

      {!!createProjectError && (
        <ErrorLoad message={"Error while creating the project"} />
      )}

      {!!createProjectSuccess && (
        <SuccessLoad
          message={
            "You successfully created a project and we generated the basic version of the Privacy Policy for you!"
          }
        />
      )}
    </Box>
  );
};

const NewProject = ({ setProjects, page, setPage, limit, setLimit }) => {
  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);

  const { dashboard, setDashboard } = useDashboard();

  const setModalStatus = (status = true) => {
    setDashboard({
      newProjectModal: status,
    });
  };

  const closeModal = (event, reason) => {
    setDashboard({
      newProjectModal: false,
    });
  };

  return (
    <>
      <Button
        variant="contained"
        className={classes.newProjectButton}
        startIcon={<AddIcon className={classes.newProjectButtonIcon} />}
        disableElevation
        disableRipple
        disableFocusRipple
        onClick={() => setModalStatus(true)}
      >
        New project
      </Button>

      <Dialog
        onClose={closeModal}
        aria-labelledby="new-project-dialog"
        open={dashboard.newProjectModal}
        className={classes.dialog}
        classes={{ paper: classes.paperBox }}
        PaperComponent={Box}
      >
        <DialogTitle onClose={closeModal} className={classes.dialogTitle}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={closeModal}
            disableRipple
            disableFocusRipple
          >
            <CloseIcon className={classes.closeButtonIcon} />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <NewProjectStepper
            setOpenModal={setModalStatus}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            setProjects={setProjects}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const UPDATE_PROJECT = gql`
  mutation UpdateProject($input: ProjectUpdateInput!, $_id: ID!) {
    updateProject(input: $input, _id: $_id) {
      ... on CompanyProject {
        _id
        title
        scope
        url
        email
        legalName
        country
        state
        address
        postalCode
        plan {
          deal
        }
        publicCode
      }
      ... on IndividualProject {
        _id
        title
        scope
        url
        email
        firstName
        lastName
        country
        state
        address
        postalCode
        plan {
          deal
        }
        publicCode
      }
    }
  }
`;

const UpdateProjectStepper = ({
  setOpenModal,
  activeStep,
  setActiveStep,
  project,
  setProjects,
  page,
  setPage,
  limit,
}) => {
  const classes = useStyles();

  const [updateProjectError, setUpdateProjectError] = useState(false);

  const [updateProjectSuccess, setUpdateProjectSuccess] = useState(false);

  const [updatingProject, setUpdatingProject] = useState(false);

  const updateProjectDataDefault = useMemo(
    () => ({
      ...omitDeep(omitDeep(project, "__typename"), "_id"),
    }),
    // eslint-disable-next-line
    []
  );

  const [projectData, setProjectData] = useState(updateProjectDataDefault);

  // prettier-ignore
  const [
    updateProject,
    // eslint-disable-next-line
    { dataUpdateProject, loadingUpdateProject, errorUpdateProject, calledUpdateProject },
  ] = useMutation(UPDATE_PROJECT);

  useEffect(() => {
    return () => {
      setProjectData({ scope: COMPANY });

      setActiveStep(0);
    };

    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    setActiveStep((previousStep) => previousStep + 1);
  };

  const handleBack = () => {
    setActiveStep((previousStep) => previousStep - 1);
  };

  const handleBackClojure = (setProjectData, methods) => {
    setProjectData((previousStepData) => ({
      ...previousStepData,
      ...methods.getValues(),
    }));

    handleBack();
  };

  return (
    <Box className={classes.stepperContainer}>
      {updatingProject && !updateProjectError && !updateProjectSuccess && (
        <LoadingComponent message={"Updating project"} />
      )}

      {!updateProjectError && !updateProjectSuccess && !updatingProject && (
        <>
          <Box className={classes.stepperTitleContainer}>
            <Typography variant="h2" className={classes.stepperTitle}>
              {activeStep === 0
                ? stepsText.projectDetails.title
                : projectData.scope === COMPANY
                ? stepsText.typeDetails.company.title
                : stepsText.typeDetails.individual.title}
            </Typography>

            <Typography variant="h3" className={classes.stepperSubTitle}>
              {activeStep === 0
                ? stepsText.projectDetails.description
                : projectData.scope === COMPANY
                ? stepsText.typeDetails.company.description
                : stepsText.typeDetails.individual.description}
            </Typography>
          </Box>

          <Box className={classes.stepperContentContainer}>
            {activeStep === 0 && (
              <ProjectDetailsForm
                activeStep={activeStep}
                projectData={projectData}
                setProjectData={setProjectData}
                handleNext={handleNext}
                update={true}
              />
            )}

            {activeStep === 1 && (
              <PersonalDetailsForm
                setOpenModal={setOpenModal}
                setLoadingProject={setUpdatingProject}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                projectData={projectData}
                setProjectData={setProjectData}
                handleNext={handleNext}
                handleBackClojure={handleBackClojure}
                setProjectError={setUpdateProjectError}
                setProjectSuccess={setUpdateProjectSuccess}
                projectOnSubmit={updateProject}
                projectOnSubmitVariables={{ _id: project._id }}
                setProjects={setProjects}
                page={page}
                setPage={setPage}
                limit={limit}
              />
            )}
          </Box>
        </>
      )}

      {!!updateProjectError && (
        <ErrorLoad message={"Error while updating the project"} />
      )}

      {!!updateProjectSuccess && <SuccessLoad message={"Project updated"} />}
    </Box>
  );
};

const UpdateProject = ({
  project,
  setProjects,
  page,
  setPage,
  limit,
  handleClose,
}) => {
  const classes = useStyles();

  const [openModal, setOpenModal] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = (event, reason) => {
    setOpenModal(false);
  };

  return (
    <>
      <MenuItem
        onClick={(event) => {
          handleClose();

          handleOpenModal(event);
        }}
      >
        <ListItemIcon>
          <IconButton
            aria-label="close"
            disableRipple
            disableFocusRipple
            className={classes.projectCardSettingsButton}
          >
            <SettingsIcon className={classes.projectCardSettingsButtonIcon} />
          </IconButton>
        </ListItemIcon>

        <Typography variant="inherit">Update</Typography>
      </MenuItem>

      <Dialog
        onClose={handleCloseModal}
        open={openModal}
        className={classes.dialog}
        classes={{ paper: classes.paperBox }}
        PaperComponent={Box}
      >
        <DialogTitle onClose={handleCloseModal} className={classes.dialogTitle}>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={handleCloseModal}
            disableRipple
            disableFocusRipple
          >
            <CloseIcon className={classes.closeButtonIcon} />
          </IconButton>
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <UpdateProjectStepper
            setOpenModal={setOpenModal}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            project={project}
            setProjects={setProjects}
            page={page}
            setPage={setPage}
            limit={limit}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const DELETE_PROJECT = gql`
  mutation DeleteProject($_id: ID!) {
    deleteProject(_id: $_id)
  }
`;

const DeleteProject = ({
  project,
  setProjects,
  page,
  setPage,
  limit,
  handleClose,
}) => {
  const classes = useStyles();

  const [openDeleteProject, setOpenDeleteProject] = useState(false);

  const navigate = useNavigate();

  const client = useApolloClient();

  const [
    _deleteProject,
    // prettier-ignore
    // eslint-disable-next-line
    { dataDeleteProject, loadingDeleteProject, errorDeleteProject, calledDeleteProject,
    },
  ] = useMutation(DELETE_PROJECT);

  const handleOpenDeleteProject = () => {
    setOpenDeleteProject(true);
  };

  const deleteProject = async () => {
    try {
      // eslint-disable-next-line
      const projectDeleted = (
        await _deleteProject({
          variables: {
            _id: project._id,
          },
        })
      ).data.deleteProject;

      toast.success(`Project deleted`);

      setOpenDeleteProject(false);

      navigate("/dashboard/home");
    } catch (error) {
      toast.error(error.message);
    }

    try {
      // eslint-disable-next-line
      const projects = (
        await client.query({
          query: SEARCH_PROJECTS,
          variables: { page: 1, limit: page * limit },
          notifyOnNetworkStatusChange: true,
          fetchPolicy: "no-cache",
        })
      ).data.searchProjects;

      setPage(projects?.page ? projects.page : 1);

      setProjects(projects?.projects);
    } catch (error) {
      // console.log(error);

      setProjects([]);
    }
  };

  return (
    <>
      <MenuItem
        onClick={() => {
          handleClose();

          handleOpenDeleteProject();
        }}
      >
        <ListItemIcon>
          <IconButton
            aria-label="delete"
            disableRipple
            disableFocusRipple
            className={classes.projectCardProjectDeleteButton}
          >
            <DeleteOutlineIcon
              className={classes.projectCardProjectDeleteButtonIcon}
            />
          </IconButton>
        </ListItemIcon>

        <Typography variant="inherit">Delete</Typography>
      </MenuItem>

      <DeleteDialog
        resource="project"
        openStatus={openDeleteProject}
        closeAction={() => {
          setOpenDeleteProject(false);
        }}
        deleteAction={deleteProject}
      />
    </>
  );
};

const selectPlanIcon = (project) => {
  if (project.firstName || project.lastName) {
    return <PersonIcon />;
  } else if (project.name || project.legalName) {
    return <BusinessIcon />;
  } else {
    return <MergeTypeIcon />;
  }
};

const Project = ({
  project,
  setProjects,
  page,
  setPage,
  pagination,
  limit,
}) => {
  const classes = useStyles();

  const { project: _project, setProject } = useProject();

  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box
      className={clsx({
        [classes.projectCardSelected]: _project?.project?._id === project?._id,
        [classes.projectCardNotSelected]:
          _project?.project?._id !== project?._id,
      })}
    >
      <Box className={classes.projectCardRow}>
        <Box className={classes.projectCardTitleContainer}>
          <Typography
            variant="h2"
            className={classes.projectCardTitle}
            onClick={() => {
              setProject({ project });

              navigate("/dashboard/categories");
            }}
          >
            {project?.title ? limitText(project.title) : "Project"}
          </Typography>
        </Box>

        <Box className={classes.projectCardTitleActionsContainer}>
          <MoreVertIcon
            className={classes.projectMoreOptions}
            onClick={handleMenu}
          />

          <Menu
            id="menu-project"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={open}
            onClose={handleClose}
            className={classes.menuProject}
          >
            <DeleteProject
              project={project}
              setProjects={setProjects}
              page={page}
              setPage={setPage}
              limit={limit}
              handleClose={handleClose}
            />

            <UpdateProject
              project={project}
              setProjects={setProjects}
              page={page}
              setPage={setPage}
              limit={limit}
              handleClose={handleClose}
            />
          </Menu>
        </Box>
      </Box>

      <Box className={classes.projectCardRow}>
        <Box className={classes.projectCardContentContainer}>
          <Button
            disableRipple
            disableFocusRipple
            startIcon={selectPlanIcon(project)}
            classes={{
              root: classes.projectCardPlanTypeButtonIconRoot,
              startIcon: classes.projectCardPlanTypeButtonIcon,
            }}
            className={`${classes.projectCardPlanTypeButton} ${classes.buttonClean}`}
            disabled={true}
          >
            {project?.plan?.deal
              ? startCase(lowerCase(limitText(project.plan.deal)))
              : "Unknown"}{" "}
            plan
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const SEARCH_PROJECTS = gql`
  query SearchProjects($page: Int!, $limit: Int!) {
    searchProjects(page: $page, limit: $limit)
      @connection(key: "searchProjects", filter: ["page"]) {
      projects {
        ... on CompanyProject {
          _id
          title
          scope
          url
          email
          legalName
          country
          state
          address
          postalCode
          plan {
            deal
          }
          publicCode
        }
        ... on IndividualProject {
          _id
          title
          scope
          url
          email
          firstName
          lastName
          country
          state
          address
          postalCode
          plan {
            deal
          }
          publicCode
        }
      }
      total
      limit
      page
      pages
    }
  }
`;

const ProjectsList = ({
  projects,
  setProjects,
  page,
  setPage,
  limit,
  setLimit,
}) => {
  const classes = useStyles();

  // eslint-disable-next-line

  // eslint-disable-next-line

  const [internalError, setInternalError] = useState(false);

  const { loading, error, data, fetchMore } = useQuery(SEARCH_PROJECTS, {
    variables: { page, limit },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (data) {
      setProjects(
        data?.searchProjects?.projects?.length
          ? data.searchProjects.projects
          : []
      );

      setPage(data?.searchProjects?.page ? data.searchProjects.page : 1);
    }

    // eslint-disable-next-line
  }, [data]);

  if (loading) return <LoadingComponent />;

  if (
    (error &&
      !(
        stringSimilarity.compareTwoStrings(
          "project (s) not found",
          error?.message?.toLocaleLowerCase()
        ) > 0.8
      )) ||
    internalError
  )
    return <ErrorLoad message={"Error while getting project (s)"} />;

  if (!projects.length) return <InfoMessage message={"No projects"} />;

  const pagination = {
    total: data?.searchProjects?.total ? data.searchProjects.total : 0,
    limit: data?.searchProjects?.limit ? data.searchProjects.limit : 0,
    page: data?.searchProjects?.page ? data.searchProjects.page : 0,
    pages: data?.searchProjects?.pages ? data.searchProjects.pages : 0,
  };

  const handleFetchMore = (page) => {
    try {
      fetchMore({
        variables: {
          page,
          limit,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          if (!fetchMoreResult) return previousResult;

          try {
            // A new reference is needed, otherwise the cache is not
            // updated
            //
            // https://github.com/apollographql/react-apollo/issues/3487#issuecomment-530859465
            const projects = {
              searchProjects: {
                ...previousResult.searchProjects,
                ...fetchMoreResult.searchProjects,
                projects: [
                  ...previousResult.searchProjects.projects,
                  ...fetchMoreResult.searchProjects.projects,
                ],
              },
            };

            setProjects(projects.searchProjects.projects);

            setPage(projects.searchProjects.page);

            return projects;
          } catch (error) {
            // console.log(error)

            return {};
          }
        },
      });

      setInternalError(false);
    } catch (error) {
      setInternalError(true);
    }
  };

  return (
    <Box className={classes.projectsList}>
      {projects.map((project, index) => (
        <Project
          key={`${project.publicCode} ${index}`}
          project={project}
          setProjects={setProjects}
          page={page}
          setPage={setPage}
          pagination={pagination}
          limit={limit}
        />
      ))}

      {projects.length && pagination.total > projects.length ? (
        <Box className={classes.projectListFetchMore}>
          <IconButton
            aria-label="fetch-more"
            onClick={() => handleFetchMore(page + 1)}
            disableRipple
            disableFocusRipple
            className={classes.projectListFetchMoreButton}
          >
            <ExpandMoreIcon
              className={classes.projectListFetchMoreButtonIcon}
            />
          </IconButton>
        </Box>
      ) : null}
    </Box>
  );
};

const OptionsDashboard = () => {
  const classes = useStyles();

  const [projects, setProjects] = useState([]);

  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(6);

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxTitle}>
        <Typography variant="body2" className={classes.optionSectionTitle}>
          Projects
        </Typography>
      </Box>

      <Box className={classes.boxListItems}>
        <ProjectsList
          projects={projects}
          setProjects={setProjects}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      </Box>

      <Box className={classes.boxNewProject}>
        <NewProject
          setProjects={setProjects}
          page={page}
          setPage={setPage}
          limit={limit}
          setLimit={setLimit}
        />
      </Box>
    </Box>
  );
};

export default OptionsDashboard;
