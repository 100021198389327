import { createMuiTheme } from "@material-ui/core";

// Default theme: https://material-ui.com/customization/default-theme/

const theme = {
  palette: {
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    primary: {
      soft: "#66696f",
      light: "#4a4c51",
      main: "#37393c",
      dark: "#252528",
    },
    secondary: {
      soft: "#B7B7FB",
      light: "#8085EE",
      main: "#613DC1",
      dark: "#5a38b2",
    },
    error: {
      main: "#ea6450",
      light: "#ee8373",
      dark: "#e6452d",
    },
    warning: {
      main: "#f1d45c",
      light: "#f3d96e",
      dark: "#efcf4a",
    },
    info: {
      main: "#009fea",
      light: "#00acfe",
      dark: "#0092d6",
    },
    success: {
      light: "#8085EE",
      main: "#613DC1",
      dark: "#5a38b2",
    },
    // Black
    blackOne: "#2e2f32",
    blackTwo: "#4F4F4F",
    // Purple
    purpleOne: "#f9f9fe",
    purpleTwo: "#b69bff",
    purpleThree: "#f8f8ff",
    purpleFour: "#ede7f6",
    purpleFive: "#c3bef7",
    // Blue
    blueOne: "#2683F0",
    blueTwo: "#263238",
    // Green
    greenOne: "#60DDD6",
    greenTwo: "#f7fffd",
    greenThree: "#f7fafc",
    // Gray
    grayOne: "#718096",
    grayTwo: "#CCCCCC",
    grayThree: "#e2e5f459",
    grayFour: "#f1f2fa",
    grayFive: "#aeb8c0",
    graySix: "#f4f4f8",
    // Yellow
    yellowOne: "#fbd466",
    yellowTwo: "#fccc00",
    // Gradient
    // prettier-ignore
    gradient: "linear-gradient(358.32deg, rgba(239, 255, 250, 0.5) 1.69%, #FFFFFF 98.65%)",

    privacyPolicyBlue: "#718096",
    privacyPolicyBlueSoft: "rgba(0, 0, 0, 0.25)",
    privacyPolicyBlueDark: "#37474f",
    dashboardBlue: "#718096",
    dashboardBlueSoft: "#CBD5E0",
    dashboardGrey: "#BDC7D2",
    dashboardGreyLight: "#E2E8F0",
    dashboardBlackLight: "#424242",
    disabledColor: "rgba(0, 0, 0, 0.12)",
  },
  typography: {
    fontFamily: ["Lato", "Montserrat", "Roboto", "Arial", "sans-serif"].join(
      ","
    ),
    fontWeight: "400",
    h1: {
      color: "#2e2f32",
      fontSize: "3.5rem",
      fontWeight: "700",
      lineHeight: "70px",
      textDecoration: "none",
    },
    h2: {
      color: "#2e2f32",
      fontSize: "2rem",
      fontWeight: "700",
      textDecoration: "none",
    },
    h3: {
      color: "#2e2f32",
      fontSize: "1.5rem",
      fontWeight: "600",
      textDecoration: "none",
    },
    h4: {
      color: "#2e2f32",
      fontSize: "1.2rem",
      fontWeight: "500",
      textDecoration: "none",
    },
    body1: {
      color: "#2e2f32",
      fontSize: "1.1rem",
      fontWeight: "400",
      textDecoration: "none",
    },
    body2: {
      color: "#2e2f32",
      fontSize: "1.3rem",
      fontWeight: "400",
      textDecoration: "none",
      lineHeight: "2",
    },
    button: {
      textTransform: "none",
    },
  },
  shadows: [
    "none",
    "0px 0px 1px 0px #f1f2fa, 0px -2px 1px -3px #f1f2fa",
    "0px 0px 3px 0px #f1f2fa, 0px -2px 1px -3px #f1f2fa",
    "0px 0px 3px 0px #f1f2fa, 0px -2px 3px -3px #f1f2fa",
    "0px 0px 3px 0px #f1f2fa, 0px -2px 4px -3px #f1f2fa",
    "0px 0px 3px 0px #f1f2fa, 0px -2px 5px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 5px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 5px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 5px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 6px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 6px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 7px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 8px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 8px -3px #f1f2fa",
    "0px 0px 4px 0px #f1f2fa, 0px -2px 9px -3px #f1f2fa",
    "0px 0px 5px 0px #f1f2fa, 0px -2px 9px -3px #f1f2fa",
    "0px 0px 5px 0px #f1f2fa, 0px -2px 10px -3px #f1f2fa",
    "0px 0px 5px 0px #f1f2fa, 0px -2px 11px -3px #f1f2fa",
    "0px 0px 5px 0px #f1f2fa, 0px -2px 11px -3px #f1f2fa",
    "0px 0px 5px 0px #f1f2fa, 0px -2px 12px -3px #f1f2fa",
    "0px 0px 5px 0px #f1f2fa, 0px -2px 13px -3px #f1f2fa",
    "0px 0px 5px 0px #f1f2fa, 0px -2px 13px -3px #f1f2fa",
    "0px 0px 5px 0px #f1f2fa, 0px -2px 14px -3px #f1f2fa",
    "0px 0px 6px 0px #f1f2fa, 0px -2px 14px -3px #f1f2fa",
    "0px 0px 6px 0px #f1f2fa, 0px -2px 14px -3px #f1f2fa",
  ],
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: "body3" },
          style: {
            color: "#383b3f",
            fontSize: "1.4rem",
            fontWeight: "400",
            textDecoration: "none",
          },
        },
      ],
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        contained: {
          boxShadow: "none",
        },
      },
      variants: [
        {
          props: { variant: "hovered" },
          style: {
            color: "#fff",
            backgroundColor: "#613DC1",
            fontWeight: "700",
            fontSize: "1.1rem",
            marginRight: "0",
            border: "1px solid #613DC1",
            transition: "all .2s linear 0s",
            "&:hover": {
              color: "#613DC1",
              backgroundColor: "#fff",
              "& > *": {
                color: "#613DC1",
              },
            },
          },
        },
        {
          props: { variant: "hovered-social" },
          style: {
            color: "#37393c",
            backgroundColor: "#fff",
            fontWeight: "700",
            fontSize: "1.1rem",
            marginRight: "0",
            border: "1px solid #aeb8c0",
            transition: "all .2s linear 0s",
            "&:hover": {
              color: "#37393c",
              backgroundColor: "#fff",
              filter: "opacity(.8)",
              "& > *": {
                color: "#37393c",
              },
            },
          },
        },
        {
          props: { variant: "hovered-reversed" },
          style: {
            color: "#613DC1",
            backgroundColor: "#fff",
            fontWeight: "700",
            fontSize: "1.1rem",
            marginRight: "0",
            border: "1px solid #613DC1",
            transition: "all .2s linear 0s",
            "&:hover": {
              color: "#fff",
              backgroundColor: "#613DC1",
              "& > *": {
                color: "#fff",
              },
            },
          },
        },
        {
          props: { variant: "hovered-reversed-gray" },
          style: {
            color: "#613DC1",
            backgroundColor: "#fff",
            fontWeight: "700",
            fontSize: "1.1rem",
            marginRight: "0",
            border: "1px solid #e2e5f4",
            transition: "all .2s linear 0s",
            "&:hover": {
              backgroundColor: "#fff",
              borderColor: "#e2e5f4",
              filter: "opacity(.8)",
            },
          },
        },
        {
          props: { variant: "hovered-reversed-no-border" },
          style: {
            color: "#613DC1",
            backgroundColor: "#fff",
            fontWeight: "700",
            fontSize: "1.1rem",
            margin: "0px",
            padding: "0px",
            width: "fit-content",
            "&:hover": {
              filter: "opacity(.8)",
              backgroundColor: "#fff",
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: "#718096",
          "&$focused": {
            color: "#613DC1",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: "#66696f",
          position: "relative",
          "& $notchedOutline": {
            borderColor: "#e2e5f4",
          },
          "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderColor: "#613DC1",
          },
          "&$focused $notchedOutline": {
            borderColor: "#613DC1",
            borderWidth: "1px",
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&:last-child td, &:last-child th": {
            borderBottom: "0",
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          background: "transparent",
        },
      },
    },
  },
};

export default createMuiTheme(theme);
