const advertising = [
  {
    providerName:
      "Alibaba.com Singapore E-Commerce Private Ltd (EEA&USA & other countries users) | Hangzhou Alibaba Advertising Co., Ltd. (mainland China users) | Alibaba.com Hong Kong Ltd (Hong Kong & Macau users) ",
    providerService: "AliExpress Affiliate",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://helppage.aliexpress.com/buyercenter/questionAnswer.htm?spm=a2g0o.home.0.0.7d2877deaA6Hld&isRouter=0&viewKey=1&id=1000099018&categoryIds=9205401",
    optOut: null,
    placeProcessing: "Singapore",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Amazon.com, Inc.",
    providerService: "Amazon Affiliation",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.amazon.com/gp/help/customer/display.html?ie=UTF8&nodeId=468496",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Anastore Bio S.L.",
    providerService: "Anastore affiliation program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://en.anastore.com/information/legal_notice.php",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Awin Ltd. (EEA users) | Awin Inc. (USA & other countries users)",
    providerService: "Awin",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.awin.com/gb/privacy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Banggood Technology Co., Ltd. ",
    providerService: "Banggood Affiliates Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.banggood.com/Privacy-Policy_hl34",
    optOut: null,
    placeProcessing: "China",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "BigMarker.com, LLC",
    providerService: "BigMarker Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.bigmarker.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Booking.com B.V.",
    providerService: "Booking.com Affiliate Partner Programme",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://admin.booking.com/hotel/hoteladmin/privacy.html",
    optOut: null,
    placeProcessing: "Netherlands",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Civitatis Tours SL",
    providerService: "Civitatis ",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.civitatis.com/en/privacy/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Conversant Europe Ltd.",
    providerService: "CJ Affiliate by Conversant",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.conversantmedia.eu/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Click Sales Inc. ",
    providerService: "ClickBank",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://support.clickbank.com/hc/en-us/articles/360004023572-Customer-Privacy-Policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Etison, LLC",
    providerService: "ClickFunnels",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://signup.clickfunnels.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Commission Factory Pty Ltd",
    providerService: "Commission Factory (Asia Pacific)",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.commissionfactory.com/privacy-policy",
    optOut: null,
    placeProcessing: "Australia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Endurance International Group, Inc",
    providerService: "Constant Contact Email Marketing Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.endurance.com/privacy/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "eBay Partner Network, Inc. | eBay GmbH (EU users) |  eBay Inc. (USA users) | eBay Canada Ltd. (CA users) | eBay UK Ltd. (UK users) | eBay Marketplaces GmbH (other countries users) ",
    providerService: "eBay Partner Network",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://partnernetwork.ebay.com/page/network-agreement#privacy-notice",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "BCR",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Effiliation SAS",
    providerService: "Effiliation",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.effiliation.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Get Ideal Ltd.",
    providerService: "FATJOE",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://fatjoe.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Fiverr Germany GmbH (EEA users) | Fiverr International Ltd. (USA & other countries users)",
    providerService: "Fiverr Affiliates Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.fiverr.com/privacy-policy",
    optOut: null,
    placeProcessing: "Israel",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "FlexOffers.com, LLC ",
    providerService: "Flex Offers",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.flexoffers.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "iDevAffiliate Inc.",
    providerService: "iDevAffiliate",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.idevdirect.com/policies.php#privacypolicy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Apple Distribution International Ltd. (EEA users) | Apple Inc. (USA & other countries users)",
    providerService: "iTunes affiliation",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.apple.com/uk/legal/privacy/en-ww/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "BBC Systems, Inc.",
    providerService: "JVZoo",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.jvzoo.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Kinsta Inc.",
    providerService: "Kinsta Wordpress Hosting",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://kinsta.com/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "LeadDyno, LLC",
    providerService: "Lead Dyno",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.leaddyno.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Avenue 81, Inc.",
    providerService: "Leadpages Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.leadpages.net/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "WebCom Consulting OG.",
    providerService: "links2revenue",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.links2revenue.com/privacy_policy/",
    optOut: null,
    placeProcessing: "Austria",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Rakuten Marketing Europe Ltd. & Rakuten Marketing LLC dba Rakuten Advertising (EEA users) | Rakuten Marketing LLC (USA users) | Rakuten Europe S.a.r.l. (other countries users)",
    providerService: "LinkShare",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://rakutenmarketing.com/legal-notices/website-privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "LiveChat, Inc.",
    providerService: "LiveChat Partner Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.livechatinc.com/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Logitravel Ltd.",
    providerService: "Logitravel Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.logitravel.co.uk/legal/privacy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Media.Net Advertising FZ-LLC",
    providerService: "Media.net Ambassador Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.media.net/privacy-policy",
    optOut: null,
    placeProcessing: "United Arab Emirates",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "GrowSumo Inc",
    providerService: "PartnerStack",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.partnerstack.com/policies",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "PeerFly, Inc.",
    providerService: "Peerfly Affiliate Network",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://peerfly.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Pepperjam LLC ",
    providerService: "Pepperjam",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.pepperjam.com/legal#privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Printful Latvia (EEA users) | Printful, Inc. (USA&other countries users) ",
    providerService: "Printful",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.printful.com/policies/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Rakuten Marketing Europe Ltd. & Rakuten Marketing LLC dba Rakuten Advertising (EEA users) | Rakuten Marketing LLC (USA users) | Rakuten Europe S.a.r.l. (other countries users)",
    providerService: "Rakuten Marketing Affiliate",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: " ",
    providerPrivacyLink:
      "https://rakutenmarketing.com/legal-notices/website-privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Anafore Pte. Ltd.",
    providerService: "ReferralCandy",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.referralcandy.com/privacy/",
    optOut: null,
    placeProcessing: "Singapore",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Savings United GMBH",
    providerService: "Savings United",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "Cookies \nusage data \nvarious types of Data as specified in the privacy policy of the service ",
    providerPrivacyLink: "https://savings-united.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Semrush Inc.",
    providerService: "SEMrush Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.berush.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Awin Ltd. (EEA users) | Sharesale.com, Inc. (USA & other countries users) ",
    providerService: "ShareAsale",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.shareasale.com/PrivacyPolicy.pdf",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Shopify International Ltd. (EEA & Middle East & Suth America & Africa users) | Shopify Inc. (other countries users)",
    providerService: "Shopify Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.shopify.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SiteGround Spain S.L.",
    providerService: "SiteGround: Hosting Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.siteground.com/privacy.htm",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Skimbit Ltd",
    providerService: "Skimlinks",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://skimlinks.com/privacy-policies/",
    optOut: "https://optout.skimlinks.com/?tested=1",
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Smart Digital Advertising, S.L.",
    providerService: "Smart4ads",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "http://smart4ads.com/s4a/en/policy/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Sovrn Holdings, Inc.",
    providerService: "Sovrn //Commerce (formerly VigLink)",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.sovrn.com/privacy-policy/",
    optOut: "https://www.viglink.com/opt-out",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Spocket, Inc.",
    providerService: "Spocket Partner Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://d3eyb8shadqthh.cloudfront.net/PrivacyPolicy.pdf",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Caseproof LLC",
    providerService: "ThirstyAffiliates",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://thirstyaffiliates.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Whitesquare GmbH",
    providerService: "Thrive Themes",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://thrivethemes.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Switzerland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "TradeTracker International B.V.",
    providerService: "TradeTracker",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://tradetracker.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Netherlands",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Niche Marketing Inc.",
    providerService: "Wealthy Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.wealthyaffiliate.com/privacy/",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "WOW Media UK Ltd",
    providerService: "WOW TRK",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.wowtrk.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "WPEngine, Inc.",
    providerService: "WP Engine's Managed WordPress Hosting Affiliate Program",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://wpengine.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Zooplus AG",
    providerService: "Zooplus Affiliate Programme",
    questionGenerator: "Q3 - Commercial affiliation ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.zooplus.co.uk/content/privacy",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "[m]PLATFORM Ltd (EEA users) | [m]PLATFORM LLC. (USA & other countries users) ",
    providerService: "[m]PLATFORM",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "Cookies \nUnique device identifiers for advertising (Google Advertiser ID or IDFA, for example)\nVarious types of Data as specified in the privacy policy of the service ",
    providerPrivacyLink: "https://www.groupm.com/mplatform-privacy-notice",
    optOut: "https://www.groupm.com/mplatform-privacy-notice",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "When we transfer personal data from the EEA or Switzerland to an affiliate outside the EEA or Switzerland, we enter into standard (\u201cmodel\u201d) contractual clauses with the recipient in order to ensure an appropriate level of data protection. When we transfer personal data from the EEA or Switzerland to an unaffiliated entity (such as a service provider performing services on our behalf) outside the EEA or Switzerland, we either enter into standard (\u201cmodel\u201d) contractual clauses, or substantially equivalent terms and conditions, or ensure that the transfer is pursuant to another valid mechanism, such as the service provider being certified under the EU-U.S. Privacy Shield Framework.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Ad4Game Ireland Ltd. ",
    providerService: "Ad4Game",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://a4g.com/privacy",
    optOut: null,
    placeProcessing: "Ireland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Congoo LLC",
    providerService: "Adblade",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.adblade.com/doc/privacy",
    optOut: "https://www.adblade.com/doc/opt-out-in",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Sas Purpule Brain, FR",
    providerService: "AdBuddiz",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://adbuddiz-resources.commondatastorage.googleapis.com/doc/Terms_and_Conditions_for_Developers.pdf",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SparkLIT Networks Inc",
    providerService: "AdButler",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.adbutler.com/agreements.spark?agreement=privacy",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Adcash O\u00dc",
    providerService: "Adcash",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://adcash.com/legal/#h.r05qsnxjz0g8",
    optOut: null,
    placeProcessing: "Estonia",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "AdColony, Inc.",
    providerService: "AdColony",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.adcolony.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Adform",
    providerService: "Adform",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://site.adform.com/privacy-center/",
    optOut: "https://site.adform.com/privacy-center/platform-privacy/opt-out/",
    placeProcessing: "Denmark",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "AdMob",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: "https://adssettings.google.com/authenticated",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Users are able to disable theAdvertising  Cookies by going to: https://adssettings.google.com/ ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Amazon.com, Inc.",
    providerService: "Amazon Mobile Ads",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.amazon.com/gp/help/customer/display.html/?ie=UTF8&nodeId=468496&ref=a20m_us_fnav_prvcy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "App Samurai",
    providerService: "App Samurai",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://appsamurai.com/frequently-asked-questions/#privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "NO Privacy Policy Provided - when following the link you need to sign in to the system in order to maybe? see policy",
    "Unnamed: 12": null,
  },
  {
    providerName: "AppLovin Corp.",
    providerService: "AppLovin",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.applovin.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Appnext Ltd. ",
    providerService: "Appnext",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.appnext.com/privacy-policy-oem-operators/",
    optOut: null,
    placeProcessing: "British Virgin Islands",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Appnext allows you to create an opt-out possibility. If you make use of it, add it to your privacy policy via a custom service.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Xandr Inc.",
    providerService: "AppNexus",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.appnexus.com/platform-privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "appTV Ltd.",
    providerService: "AppTV",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.apptv.com/privacy-policy",
    optOut: null,
    placeProcessing: "Israel",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "belboon GmbH",
    providerService: "Belboon",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.belboon.com/en/about-us/privacy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Broadstreet Ads, Inc",
    providerService: "Broadstreet Ads",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://broadstreetads.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "BuySellAds.com Inc.",
    providerService: "BuySellAds",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.buysellads.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Chartboost Inc.",
    providerService: "Chartboost",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://answers.chartboost.com/en-us/articles/200780269",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Clickpoint Marketing SLU",
    providerService: "Clickpoint",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.clickpoint.com/privacy/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "BCN - horrible privacy policy. To contact when MVP ready.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Tactics Network, LLC",
    providerService: "CPMStar",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Underage data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.cpmstar.com/privacyPolicy.html",
    optOut: "http://server.cpmstar.com/optout.aspx",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      '"The Oldest and Largest Ad Network in the Games and Youth Oriented Entertainment Space." \nPP: "Personally Identifiable Information:\nWe do not currently collect personally identifiable information in the process of serving ads to end users....Expansion of Services:\nWe may expand our services in the future to collect and use additional informaton."',
    "Unnamed: 12": null,
  },
  {
    providerName: "Criteo S.A.",
    providerService: "Criteo",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.criteo.com/privacy/",
    optOut: "https://www.criteo.com/privacy/",
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Cuebiq Inc. ",
    providerService: "Cuebiq AudienceQ",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.cuebiq.com/privacypolicy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Cxense ASA",
    providerService: "Cxense",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.cxense.com/es/node/5821",
    optOut: "https://www.cxense.com/preferences?optOutStatus=false",
    placeProcessing: "Norway",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Cyberclick Agent S.L",
    providerService: "Cyberclick ",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.cyberclick.es/politica-de-privacidad#responsable",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Epom Ltd ",
    providerService: "Epom Ad Server",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://epom.com/privacy-policy",
    optOut: null,
    placeProcessing: "Commonwealth of Dominica",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Facebook Ireland Ltd (EEA users) | Facebook Inc. (USA&other countries users)",
    providerService: "Facebook Lookalike Audience & Network",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.facebook.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      'If you\'re managing your ads through Facebook Lookalike Audience, then add this clause to your privacy policy.\nConsider to add also "Facebook Custom Audience".',
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Verizon Media EMEA Ltd Ireland (EEA users) | Verizon Media (USA & other countries users)",
    providerService: "Flurry RTB",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.verizonmedia.com/policies/ie/en/verizonmedia/privacy/index.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Standard contractual clauses. The European Commission has adopted standard contractual clauses that provide safeguards for personal data that is transferred outside the EEA. We often use these clauses when transferring personal data outside the EEA including to our affiliates.",
    "Unnamed: 12": null,
  },
  {
    providerName: "FreeWheel Media, Inc.",
    providerService: "FreeWheel",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.freewheel.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Our primary data centres are located in Europe, Singapore, China and the USA. These data centres are used to store data that we receive from end-users. We comply with all applicable laws to protect the security of data during international transfer. FreeWheel Media, Inc. participates in and has certified its compliance with the EU-U.S. Privacy Shield Framework and the Swiss-U.S. Privacy Shield Framework.",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Funding Choices",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "If you are using a Funding Choices for messaging ad block Users embedded on your site, it may track user behavior on your website. Add this clause to your privacy policy.\n\nUsers are able to disable theAdvertising  Cookies by going to: https://adssettings.google.com/ ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Fyber GmbH - The Fyber Group ",
    providerService: "Fyber",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.fyber.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Fyber GmbH - The Fyber Group (Fyber N.V., Fyber Monetization Ltd., Fyber GmbH, Fyber Media GmbH, Fyber RTB GmbH, Advertile Mobile GmbH, and Heyzap Ltd.)",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Ad Manager / AdSense / Similar Audiences",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: "https://adssettings.google.com/authenticated?hl=en",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "If you use Google Ad Manager, then add this clause to your privacy policy. If you have a direct relationship with advertising networks used in conjunction with Google Ad Manager, add those to your privacy policy as well.\n\nIf you or your users are based in the European Economic Area (EEA) or Switzerland consider to add Google Ireland Limited as service provider in the next step.\nIf you or your users are based outside of the EEA, then simply add Google LLC as the the service provider.\nIf both of the above apply, then simply add both service providers. More information can be found here. https://www.iubenda.com/en/help/14936-place-of-processing-customisation-for-google-services \n\nFormerly DoubleClick for Publishers.\n\nUsers are able to disable theAdvertising  Cookies by going to: https://adssettings.google.com/ \n",
    "Unnamed: 12": null,
  },
  {
    providerName: "Oracle Corp.",
    providerService: "Grapeshot",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.oracle.com/legal/privacy/marketing-cloud-data-cloud-privacy-policy.html#12",
    optOut: "https://datacloudoptout.oracle.com/#optout",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "IDG Communications, Inc.,",
    providerService: "IDG TechNetwork",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.idg.com/idg-privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "BCR",
    "Comments about the service":
      "IDG Communications, Inc., \nDPO Mr. John McGill, c/o IDG Direct, Millennium House, Great Strand Street, Dublin, Ireland\n& IDG Communications Media AG, Lyonel-Feininger-Strasse 26, Munich 80807, Germany",
    "Unnamed: 12": null,
  },
  {
    providerName: "Vibrant Media, Inc",
    providerService: "IntelliTXT",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.vibrantmedia.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "IronSource Mobile Ltd.",
    providerService: "ironSource",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.ironsource.mobi/privacypolicy.html  ",
    optOut: null,
    placeProcessing: "Israel",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list + SCC",
    "Comments about the service":
      'No PP on the website. Found only by searching on Google. Different URL for PP.\nIronSource Ltd. https://www.ironsrc.com/wp-content/uploads/2019/03/ironSource-Privacy-Policy.pdf - but the privacy policy specifically referss to the website - "privacy of the users of its website at the address www.ironsrc.com/ "\nIronSource Mobile Ltd. https://www.ironsource.mobi/privacypolicy.html - "This privacy policy describes ironSource Mobile\u2019s privacy practices with respect to information it processes through its ad network, mediation platform, and marketing platform. This privacy policy does not govern the use of our website, dashboard for publishers, and dashboard for advertisers."',
    "Unnamed: 12":
      "IronSource Mobile Ltd. \ndpo@ironsrc.com\n121 Menachem Begin Rd., Tel Aviv, Israel\n\nironSource Ltd.",
  },
  {
    providerName: "Kiip, Inc.",
    providerService: "Kiip",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://app.kiip.me/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "HINT: Kiip is a mobile advertising service that displays banners for the purpose of user acquisition. It works via the user's email.\n\nhttps://en.wikipedia.org/wiki/Kiip \nOn August 26, 2019, Kiip went into foreclosure terminating the majority of its employees with Diablo management group taking over the closure, sale of assets, and liquidation on September 15, 2019. [34]NinthDecimal acquired the assets in bankruptcy court with subsequent details revealed in lawsuit filings in The Meet Group vs Kiip case filed in San Francisco court. [35][36]",
    "Unnamed: 12": null,
  },
  {
    providerName: "Comunicaci\u00f3n La Magnetica SL",
    providerService: "La magnetica ",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.lamagnetica.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Leadbolt Pty Ltd. ",
    providerService: "LeadBolt",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.leadbolt.com/privacy/",
    optOut: null,
    placeProcessing: "Australia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "We are headquartered in Sydney, Australia with offices in Los Angeles, San Francisco, and Bangalore.",
    "Unnamed: 12": null,
  },
  {
    providerName: "LifeStreet Corp. | LifeStreet International WebAMG",
    providerService: "LifeStreet Media",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://lifestreet.com/privacy/",
    optOut: "https://lifestreet.com/optout/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Outbrain Inc. / Ligatus GmbH",
    providerService: "Ligatus",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.ligatus.com/en/privacy-policy",
    optOut: "https://www.ligatus.com/en/privacy-policy#cookie_status",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      'Outbrain Acquires Ligatus, the Leading Native Advertising Platform in Europe ( https://www.outbrain.com/blog/outbrain-acquires-ligatus-the-leading-native-advertising-platform-in-europe/) \n\nOutbrain PP https://www.outbrain.com/legal/privacy#privacy-policy\n\nLigatus PP: "When we collect information, we solely store your data on highly protected servers in Germany and the EU."\nOpt Out https://www.ligatus.com/en/privacy-policy#cookie_status\n\n',
    "Unnamed: 12": null,
  },
  {
    providerName: "LiveIntent, Inc. ",
    providerService: "LiveIntent",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.liveintent.com/services-privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "mainADV S.R.L",
    providerService: "MainAd",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "http://www.mainad.com/website-privacy-policy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": '"mainADV S.R.L\n\ndpo@mainad.com"',
    "Unnamed: 12": null,
  },
  {
    providerName: "WPP plc.",
    providerService: "Medialets",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.wpp.com/privacy-policy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Microsoft Ireland Operations Ltd (EEA users) | Microsoft Corp. (USA & other countries users)",
    providerService: "Microsoft Advertising",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://privacy.microsoft.com/en-us/privacystatement",
    optOut:
      "https://account.microsoft.com/privacy/ad-settings/signedout?ru=https:%2F%2Faccount.microsoft.com%2Fprivacy%2Fad-settings",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Mobfox US LLC",
    providerService: "MobFox",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mobfox.com/privacy-policy/",
    optOut: "N/A ",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Twitter International Company (EEA users) | Twitter, Inc. (USA & other countries users) ",
    providerService: "MoPub",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mopub.com/legal/privacy/",
    optOut: "N/A ",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Oniad Advertising S.L. ",
    providerService: "OniAd",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://oniad.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "There are no international transfers of personal data outside of authorized cases.",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "OpenX Software Ltd. (EEA users) | OpenX Technologies, Inc. (USA & other countries users)  ",
    providerService: "OpenX Ad Exchange",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.openx.com/legal/privacy-policy/",
    optOut: "N/A ",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Outbrain Inc.",
    providerService: "Outbrain",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.outbrain.com/legal/privacy#privacy-policy",
    optOut: "https://my.outbrain.com/recommendations-settings/profile",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Passendo ApS",
    providerService: "Passendo ApS",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://passendo.com/users-privacy-policy/",
    optOut: "N/A ",
    placeProcessing: "Denmark",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "PowerLinks Media Ltd (EEA users) | PowerLinks Media Inc. (USA&other countries users) ",
    providerService: "Powerlinks",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.powerlinks.com/privacy-policy/",
    optOut: "http://www.powerlinks.com/ad-choices/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "PressComm Tech S.r.l.",
    providerService: "Presscommtech",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.presscommtech.com/privacy-policy/",
    optOut: "N/A ",
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Propeller Ads Ltd",
    providerService: "Propeller Ads",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://propellerads.com/privacy/",
    optOut: "N/A ",
    placeProcessing: "EU - Cyprus\nIsle of Man - EU Adequacy decision ",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "PubMatic Ltd. (EEA users) | PubMatic Inc. (other countries users) ",
    providerService: "PubMatic",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://pubmatic.com/legal/privacy-policy/",
    optOut: "https://pubmatic.com/legal/opt-out/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Quantum Corp.",
    providerService: "Quantum",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.quantum.com/en/privacypolicy/",
    optOut: "http://privacy.hi-media.com/html/en/adchoice.html",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Quora, Inc. - VeraSafe Ireland Ltd. (EU repr. for EEA users) ",
    providerService: "Quora List Match / Lookalike Audience",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.quora.com/about/privacy",
    optOut: "https://www.quora.com/optout",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service":
      'If you\'re managing your ads through Quora List Match Audience, then add this clause to your privacy policy.\n\nConsider to add also "Quora Conversion Pixel", "Quora Lookalike Audience" and "Quora Website Traffic Audience".',
    "Unnamed: 12":
      "Quora, Inc.\n\nEU - VeraSafe Ireland Ltd.\nUnit 3D North Point House\nNorth Point Business Park\nNew Mallow Road\nCork T23AT2P\nIreland",
  },
  {
    providerName: "Rai Pubblicit\u00e0 S.p.a",
    providerService: "Rai Pubblicit\u00e0",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "http://www.raipubblicita.it/privacypolicy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Rakuten Marketing Europe Ltd. & Rakuten Marketing LLC dba Rakuten Advertising (EEA users) | Rakuten Marketing LLC (USA users) | Rakuten Europe S.a.r.l. (other countries users)",
    providerService: "Rakuten Marketing",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://rakutenmarketing.com/en-uk/legal-notices/website-privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service":
      "Consider to add also \u201cRakuten Marketing Affiliate\u201c and \u201dRakuten Remarketing\u201c from the list of services offered by iubenda.",
    "Unnamed: 12": null,
  },
  {
    providerName: "RCS MediaGroup SpA, ",
    providerService: "RCS MediaGroup Advertising",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "http://www.rcsmediagroup.it/en/pages/privacy-and-cookies-policies/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Mobile Representation International Corp",
    providerService: "RevMob",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "http://mobilerepresentationinternational.com/privacy-policy.html",
    optOut: null,
    placeProcessing: "British Virgin Islands",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      '"MRI\u201d means the British Virgin Islands Company that is a exclusive agent of Revmob, authorized to operate the Revmob program with worldwide users.',
    "Unnamed: 12":
      "exclusive agent for RevMob \nWbsite not working https://www.revmobmobileadnetwork.com/",
  },
  {
    providerName: "RhythmOne, LLC.",
    providerService: "RhythmOne",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.rhythmone.com/privacy-policy#oH4yOGLK4oC5VbkA.97",
    optOut: "https://www.rhythmone.com/opt-out#6fU1KGsoA3gH1M2u.97",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "The Rubicon Project, Inc.",
    providerService: "Rubicon Project",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://rubiconproject.com/privacy/",
    optOut:
      "https://rubiconproject.com/privacy/consumer-online-profile-and-opt-out/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Seedtag Advertising, S.L.",
    providerService: "Seedtag",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.seedtag.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Simplifi Holdings, Inc.",
    providerService: "Simpli.fi",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://simpli.fi/simpli-fi-services-privacy-policy/",
    optOut: "https://app.simpli.fi/opt-out/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Smart AdServer SAS",
    providerService: "Smart AdServer",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://smartadserver.com/end-user-privacy-policy/",
    optOut:
      "http://www.youronlinechoices.com/wp-content/plugins/optout/callback/?status=optedout&token=56fc749a46bc4e28b4fde37351dcc097",
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Sociomatic Dunnhumby Germany GmbH",
    providerService: "Sociomantic",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.sociomantic.com/privacy/en/",
    optOut: "https://www.sociomantic.com/privacy/en/#opt-out-header",
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "StartApp Inc. ",
    providerService: "StartApp",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.startapp.com/policy/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SuperAwesome Trading Ltd. ",
    providerService: "SuperAwesome",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Underage data, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.superawesome.com/privacy-hub/awesomeads-privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "SuperAwesome delivers compliant, kid-safe ad campaigns globally through AwesomeAds, our digital advertising platform. Our business was created specifically to develop kidtech \u2013 technology solutions for the compliance requirements of the digital kids\u2019 market.  We create software and set standards to enable advertisers, publishers and intermediaries to be compliant with GDPR-K (the provisions of General Data Protection Regulation protecting under-16s) and COPPA, the Children\u2019s Online Privacy and Protection Act.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Taboola, Inc.",
    providerService: "Taboola Monetize Content",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.taboola.com/es/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Target Performance GmbH",
    providerService: "Target Performance",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "http://www.targetperformance.net/privacy-policy/",
    optOut: "http://www.targetperformance.net/privacy-policy/",
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Teads SA.",
    providerService: "Teads",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.teads.com/privacy-policy/",
    optOut: "https://www.teads.com/privacy-policy/",
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "By email: dpo@teads.tv.\nBy mail: Legal Department (DPO) -Teads \u2013 97 rue du Cherche midi \u2013 75006 Paris \u2013 France",
    "Unnamed: 12": null,
  },
  {
    providerName: "Tradedoubler AB",
    providerService: "Tradedoubler",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.tradedoubler.com/en/privacy-policy/",
    optOut: "https://publisher.tradedoubler.com/include/functions/optout.html",
    placeProcessing: "Sweden",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Triboo S.P.A.",
    providerService: "Triboo Media",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://triboo.com/informativa-privacy-contacts-2",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Unity Technologies Finland OY (EEA users) | Unity Technologies USA (USA&other countries users)  ",
    providerService: "Unity Ads",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://unity3d.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service":
      "EU contact OY, Kaivokatu 8 B, 00100 Helsinki, Finland ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Viralize s.r.l.",
    providerService: "Viralize",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.iubenda.com/privacy-policy/41798584",
    optOut: "https://viralize.com/opt-out/",
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Vungle, Inc.",
    providerService: "Vungle",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://vungle.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Webgains Ltd UK",
    providerService: "Webgains",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webgains.com/public/en/privacy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Webgains Ltd UK part of ad pepper media GmbH Germany",
    "Unnamed: 12": null,
  },
  {
    providerName: "Wiget Group AB ",
    providerService: "Wiget Media",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://wigetmedia.com/assets/pdf/privacy_policy.pdf",
    optOut: null,
    placeProcessing: "Sweden ",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "6. International Transfers of Your Personal Data\n6.1 We do not envisage that any of your personal data will be transferred to countries outside the\nEuropean Economic Area (EEA) as our offices, facilities, subcontractors and other third parties\nwith which we work are all located within the EEA.\n6.2 In the event that this changes in the future and one or more recipients of your personal data\nare located outside the EEA, where data protection laws may be of a lower standard than in the\nEEA, we will impose the same data protection safeguards that we deploy inside the EEA to\nensure that your personal data are always protected.",
    "Unnamed: 12": null,
  },
  {
    providerName: "GroupM UK Digital Ltd",
    providerService: "Xaxis",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "Cookies \nUnique device identifiers for advertising (Google Advertiser ID or IDFA, for example)\nVarious types of Data as specified in the privacy policy of the service ",
    providerPrivacyLink: "https://www.xaxis.com/privacy-notice/",
    optOut: "https://www.xaxis.com/opt-out/",
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      'Final PP:\n\n"Xaxis is an advertising service provided by Xaxis, LLC or by GroupM UK Digital Ltd., depending on the location www.tremobooks.com is accessed from."',
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Verizon Media EMEA Ltd Ireland (EEA users) | Verizon Media (USA & other countries users)",
    providerService: "Yahoo App Publishing Advertising",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.verizonmedia.com/policies/us/en/verizonmedia/privacy/index.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Appodeal, Inc.",
    providerService: "Appodeal ",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.appodeal.com/home/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Infolinks Media, LLC",
    providerService: "Infolinks Display Ads",
    questionGenerator: "Q2 - Direct advertising ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.infolinks.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SparkLIT Networks Inc",
    providerService: "AdButler",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.adbutler.com/agreements.spark?agreement=privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Privacy Policy of SparkLIT \nhttps://www.sparklit.com/agreements.spark?agreement=privacy",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Adobe Systems Software Ireland Ltd. (EEA&other countries users) | Adobe Inc. (USA users)",
    providerService: "Adobe Audience Manager",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.adobe.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "NextRoll, Inc.",
    providerService: "AdRoll",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://help.adroll.com/hc/en-us/articles/216104687-Privacy-Policy-Requirements",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      'not in Privacy Shield list. "May" include SCC',
    "Unnamed: 12": null,
  },
  {
    providerName: "Xandr Inc.",
    providerService: "AppNexus",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.appnexus.com/platform-privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Criteo S.A.",
    providerService: "Criteo Dynamic Retargeting",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.criteo.com/privacy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Facebook Ireland Ltd (EEA users) | Facebook Inc. (USA&other countries users)",
    providerService: "Facebook Remarketing & Custom Audience",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.facebook.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Ad Manager / Remarketing / Signals",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Google Ad Manager",
    "Unnamed: 12": null,
  },
  {
    providerName: "InMobi Pte Ltd.",
    providerService: "InMobi",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.inmobi.com/privacy-policy/\n",
    optOut: "inmobi.com/page/opt-out/",
    placeProcessing: "Singapore",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service":
      "\nhttps://www.inmobi.com/privacy-policy-for-eea/",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "LinkedIn Ireland Unlimited Company (EEA users) | LinkedIn Corporation (USA & other countries users)",
    providerService: "LinkedIn Website Retargeting",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.linkedin.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Mapp Digital Germany GmbH (EEA users) | Mapp Digital US, LLC (USA&other countries users) ",
    providerService: "Mapp Aquire",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://mapp.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Neodata Group s.r.l. ",
    providerService: "Neodata Ad Server",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.neodatagroup.com/en/security-policy/neodata-services-privacy-policy",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "OpenX Software Ltd. (EEA users) | OpenX Technologies, Inc. (USA & other countries users)  ",
    providerService: "OpenX Ad Server",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.openx.com/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "OpenX adheres to the Digital Advertising Alliance (DAA) Self-Regulatory Principles in the US and to the European Digital Advertising Alliance (EDAA) Principles in the European Union (\u201cEU\u201d) and the IAB Europe OBA Framework.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Oracle Corp.",
    providerService: "Oracle BlueKai",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.oracle.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Marin Software Inc",
    providerService: "Perfect Audience",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.perfectaudience.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Quora, Inc. - VeraSafe Ireland Ltd. (EU repr. for EEA users) ",
    providerService: "Quora Website Traffic Audience",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.quora.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Rakuten Marketing Europe Ltd. & Rakuten Marketing LLC dba Rakuten Advertising (EEA users) | Rakuten Marketing LLC (USA users) | Rakuten Europe S.a.r.l. (other countries users)",
    providerService: "Rakuten Remarketing",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://rakutenmarketing.com/legal-notices/website-privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Salesforce.com, Inc.",
    providerService: "Salesforce Audience Studio",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Twitter International Company (EEA users) | Twitter, Inc. (USA & other countries users) ",
    providerService: "Twitter Remarketing",
    questionGenerator: "Q4 - Remarketing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://twitter.com/en/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
];

export default advertising;
