import React, { useEffect } from "react";

import { useApolloClient, gql } from "@apollo/client";

const INCREASE_PRIVACY_POLICY_VIEW = gql`
  mutation IncreasePrivacyPolicyView($publicCode: ID!) {
    increasePrivacyPolicyView(publicCode: $publicCode)
  }
`;

const Views = ({ queryString }) => {
  const client = useApolloClient();

  useEffect(() => {
    (async () => {
      try {
        await client.mutate({
          mutation: INCREASE_PRIVACY_POLICY_VIEW,
          variables: {
            publicCode: queryString?.code,
          },
        });
      } catch (error) {}
    })();

    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default Views;
