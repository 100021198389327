const analytics = [
  {
    providerName: "AB Tasty SAS",
    providerService: "AB Tasty",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.abtasty.com/terms-of-use/",
    optOut: null,
    placeProcessing: "France ",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Adobe Systems Software Ireland Ltd. (EEA&other countries users) | Adobe Inc. (USA users)",
    providerService: "Adobe Test&Target",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.adobe.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Urban Airship, Inc.",
    providerService: "Apptimize",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://apptimize.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Convert Insights Inc.",
    providerService: "Convert",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.convert.com/privacy-notice/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Crazy Egg, Inc.",
    providerService: "Crazy Egg",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.crazyegg.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Evergage, Inc.",
    providerService: "Evergage",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.evergage.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Firebase Remote Config",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Freshworks, Inc.",
    providerService: "Freshmarketer",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.freshworks.com/freshmarketer-privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Optimize",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Leadformly Ltd.",
    providerService: "Leadformly",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://leadformly.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Nelio Software SL",
    providerService: "Nelio A/B Testing",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://neliosoftware.com/privacy-policy-cookies/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Omniconvert SRL",
    providerService: "Omniconvert",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.omniconvert.com/privacy-security/",
    optOut: null,
    placeProcessing: "Romania",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Optimizely, Inc.",
    providerService: "Optimizely",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.optimizely.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Unbounce Marketing Solutions Inc.",
    providerService: "Unbounce",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://unbounce.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Wingify Software Pvt Ltd",
    providerService: "Visual Website Optimizer",
    questionGenerator: "Q 3.1. - A/B testing ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://vwo.com/privacy-policy/",
    optOut: null,
    placeProcessing: "India",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "FullStory, Inc.",
    providerService: "FullStory Record & Replay  ",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.fullstory.com/legal/privacy/",
    optOut: "https://www.fullstory.com/optout",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "ContentSquare SAS (EEA users) | Clicktale, Inc. (USA&other countries users)",
    providerService: "ClickTale",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.clicktale.com/company/data-privacy/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Crazy Egg, Inc.",
    providerService: "Crazy Egg",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.crazyegg.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Decibel Insight Limited (EEA users) | Decibel Insight, Inc. (USA users)",
    providerService: "Decibel Insight",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.decibelinsight.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Feng-GUI Co.",
    providerService: "Feng-gui",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://feng-gui.com/privacy",
    optOut: null,
    placeProcessing: "Israel",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Freshworks Inc. ",
    providerService: "Freshworks",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.freshworks.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "HeatMap, Inc.",
    providerService: "Heatmap",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://heatmap.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Hotjar Ltd.",
    providerService: "Hotjar Heat Maps & Recordings",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.hotjar.com/legal/policies/privacy",
    optOut: "https://www.hotjar.com/legal/compliance/opt-out",
    placeProcessing: "Malta",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Inspectlet, Inc",
    providerService: "Inspectlet",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.inspectlet.com/terms-of-service#privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Lucky Orange LLC",
    providerService: "LuckyOrange",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.luckyorange.com/privacy.php",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "FG Forrest, a.s.",
    providerService: "Monkeytracker",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.monkeytracker.cz/en/information-about-website",
    optOut: null,
    placeProcessing: "Czech Republic",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Mouseflow, ApS",
    providerService: "Mouseflow",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://mouseflow.com/privacy/",
    optOut: null,
    placeProcessing: "Denmark",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "ManHack LLC",
    providerService: "Screensquid",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://screensquid.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SessionCam Ltd",
    providerService: "SessionCam",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://sessioncam.com/privacy-policy-cookies/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Smartsupp.com, s.r.o.",
    providerService: "Smartlook",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://help.smartlook.com/en/articles/3244453-terms-of-service",
    optOut: null,
    placeProcessing: "Czech Republic",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "UXCam Inc.",
    providerService: "UXCam",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://help.uxcam.com/hc/en-us/articles/360004158171",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Yandex LLC",
    providerService: "Yandex Metrica",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://yandex.com/legal/confidential/?lang=en",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "LogRocket, Inc",
    providerService: "LogRocket ",
    questionGenerator: "Q3.2. - Heat mapping and session recording ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://logrocket.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Airbrake Technologies, Inc.",
    providerService: "Airbrake",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://airbrake.io/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Apteligent, Inc",
    providerService: "Apteligent",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.vmware.com/help/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Buglife, Inc.",
    providerService: "Buglife",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData:
      "app information\nCookies\ncountry\ndevice information\ndevice logs\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nscreenshots\nunique device identi\ufb01ers for advertising (Google Advertiser ID or IDFA, for example)\nUsage Data",
    providerPrivacyLink: "https://www.buglife.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Bugsnag Inc.",
    providerService: "Bugsnag",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData:
      "address\napp information\nCookies\ncountry\ndate of birth\ndevice information\ndevice logs\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nphone number\nscreenshots\nUsage Data\nusername\nvarious types of Data as speci\ufb01ed in the privacy policy of the service",
    providerPrivacyLink: "https://docs.bugsnag.com/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Crashlytics",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Firebase Performance Monitoring",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Datadog, Inc.",
    providerService: "Datadog",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.datadoghq.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Icinga GmbH",
    providerService: "Icinga",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://icinga.com/about/privacy-policy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Instabug, Inc.",
    providerService: "Instabug",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData:
      "address\napp information\nCookies\ncountry\ndate of birth\ndevice information\ndevice logs\nemail address\n\ufb01rst name\ngender\ngeographic position\nlast name\nphone number\nscreenshots\nUsage Data\nusername\nvarious types of Data as speci\ufb01ed in the privacy policy of the service",
    providerPrivacyLink: null,
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Progress Software Corp.",
    providerService: "Ipswitch WhatsUp Gold",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.progress.com/legal/privacy-center",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "LibreNMS",
    providerService: "LibreNMS",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://community.librenms.org/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Rapid7 International UK Ltd. (EEA users) | Rapid7, Inc. (USA&other countries users)",
    providerService: "Logentries",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.rapid7.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "TeamViewer US, Inc.",
    providerService: "Monitis",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.monitis.com/privacy-cookies-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Nagios Enterprises, LLC",
    providerService: "Nagios",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.nagios.com/legal/privacypolicy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "New Relic, Inc.",
    providerService: "New Relic",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink:
      "https://newrelic.com/termsandconditions/services-notices",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zoho Corporation BV (EEA users) | Zoho Corporation Pvt. Ltd. (AU users) | Zoho Corporation (USA&other countries users)",
    providerService: "ManageEngine OpManager",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.manageengine.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "ITRS Group Ltd",
    providerService: "Op5 (ITRS OP5 Monitor)",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.itrsgroup.com/company/privacy-notice",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "op5 AB (Sweden) got aquired by ITRS Group Ltd - new PP",
    "Unnamed: 12": null,
  },
  {
    providerName: "SolarWinds Worldwide, LLC",
    providerService: "Pingdom",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.solarwinds.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Raygun Ltd. ",
    providerService: "Raygun",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://raygun.com/privacy#gdpr",
    optOut: null,
    placeProcessing: "New Zealand",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Rollbar, Inc.",
    providerService: "Rollbar",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://docs.rollbar.com/docs/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Scalyr, Inc.",
    providerService: "Scalyr",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.scalyr.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Functional Software Inc. dba Sentry ",
    providerService: "Sentry",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://sentry.io/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Tilde, Inc.",
    providerService: "Skylight",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.skylight.io/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Splunk Services UK Ltd. (EEA USERS) | Splunk Inc. (USA&other countries uders)",
    providerService: "Splunk MINT Express",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink:
      "https://www.splunk.com/en_us/legal/privacy/privacy-policy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "TrafficCake Ltd. ",
    providerService: "StatusCake",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.statuscake.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Uptime Robot Service Provider Ltd.",
    providerService: "Uptime Robot",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://uptimerobot.com/privacyPolicy",
    optOut: null,
    placeProcessing: "Malta",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Zabbix SIA Latvia ",
    providerService: "Zabbix",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.zabbix.com/privacy_policy",
    optOut: null,
    placeProcessing: "Latvia",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zoho Corporation BV (EEA users) | Zoho Corporation Pvt. Ltd. (AU users) | Zoho Corporation (USA&other countries users)",
    providerService: "Site 24X7 ",
    questionGenerator: "Q3.3. - Infrastructure monitoring",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.zoho.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "AdEspresso LLC",
    providerService: "AdEspresso conversion tracking",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://adespresso.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service.\nConversion tracking services are useful for understanding the impact of your advertising spend on a given network.",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Adobe Systems Software Ireland Ltd. (EEA&other countries users) | Adobe Inc. (USA users)",
    providerService: "Adobe Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.adobe.com/privacy/policy.html",
    optOut: "https://www.adobe.com/privacy/opt-out.html",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      'GENERAL HINT:\nAnalytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service.\nIf you also use self hosted analytics, add "Analytics collected directly".',
    "Unnamed: 12": null,
  },
  {
    providerName: "Alexa Internet, Inc.",
    providerService: "Alexa Metrics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.alexa.com/help/privacy",
    optOut:
      "https://support.alexa.com/hc/en-us/articles/200685410-Opting-Out-of-Alexa-Measurement-Pixel",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Amplitude, Inc.",
    providerService: "Amplitude",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://amplitude.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      'The only PP found states that: \n"This privacy policy does not apply to the mobile applications, websites or services offered by our Customers. This privacy policy also does not apply to the personal data or information of the users of our Customers\u2019 mobile applications, websites or services." \nHowever Privacy Shield certified.',
    "Unnamed: 12": null,
  },
  {
    providerName:
      "AppsFlyer Germany GmbH (EEA users) | AppsFlyer Inc. (USA&CA users) | AppsFlyer UK Ltd. (UK users) | AppsFlyer Ltd. (other countries users)",
    providerService: "Appsflyer",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.appsflyer.com/services-privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Singular Labs, Inc.",
    providerService: "Apsalar | Singurar",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.singular.net/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Branch Metrics, Inc.",
    providerService: "Branch Attribution",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://branch.io/policies/#privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Chartbeat, Inc.",
    providerService: "Chartbeat",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://chartbeat.com/privacy/#what-information-we-collect-and-how-we-use-it",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Roxr Software Ltd",
    providerService: "Clicky",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://clicky.com/terms/privacy",
    optOut: "https://clicky.com/optout",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Comscore NL B.V. (EEA users) | Comscore, Inc. (USA&other countries users)",
    providerService: "ComScore Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.comscore.com/About/Privacy-Policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Conversific Ltd.",
    providerService: "Conversific ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.conversific.com/terms-and-conditions/",
    optOut: null,
    placeProcessing: "Hungary",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Countly Ltd ",
    providerService: "Countly",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://count.ly/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      '"Data from device" is invluded as PP states "We may also collect data about the device you are using to access our Services. This data may include the device type, operating system, unique device identifiers, device settings, and geo-location data. What we collect can depend on the individual settings of your device and software. " ',
    "Unnamed: 12": null,
  },
  {
    providerName: "Ptmind Inc.",
    providerService: "Datadeck ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.datadeck.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "Japan",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Webtrekk GmbH, company of Mapp Digital Germany GmbH (EEA users) | Mapp Digital US, LLC (USA&other countries users) ",
    providerService: "DMP extension for Webtrekk",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webtrekk.com/privacy-statement/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Facebook Ireland Ltd (EEA users) | Facebook Inc. (USA&other countries users)",
    providerService: "Facebook Analytics / Ads conversion (pixel)",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://pixel.facebook.com/about/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Verizon Media EMEA Ltd Ireland (EEA users) | Verizon Media (USA & other countries users)",
    providerService: "Flurry Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.verizonmedia.com/policies/ie/en/verizonmedia/privacy/index.html",
    optOut:
      "https://developer.yahoo.com/flurry/end-user-opt-out/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuaXViZW5kYS5jb20vcHJpdmF0ZS9wcml2YWN5LXBvbGljeS8xNTk3NTcyL2xlZ2FsP3ByZXZpZXc9dHJ1ZSZpZnI9dHJ1ZSZoZWlnaHQ9ODAwJm5ld21hcmt1cD1ubyZhbj1ubw&guce_referrer_sig=AQAAAHUSLeOEqK-92fL249jSTqqLTAq-cvBG--RfjsXjRwJaISY3KUhq4z-XHQdiy3Ne_m8mKIlSyflCfLT1uVh4FiNM65iDSmKihj1yxjqiTZYMcDtFUcsSMPqw5Mlf697jiWt8oUQKSF5KNINF2PvqXM0i0z9EpYUEvLIfRU7NE-Qu",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Standard contractual clauses. The European Commission has adopted standard contractual clauses that provide safeguards for personal data that is transferred outside the EEA. We often use these clauses when transferring personal data outside the EEA including to our affiliates.",
    "Unnamed: 12": null,
  },
  {
    providerName: "GameAnalytics Ltd",
    providerService: "GameAnalytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://gameanalytics.com/privacy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      '"Our Service is hosted by Amazon Web Services and Google both of which comply with the EU-U.S. Privacy Shield Framework (the \u201cPrivacy Shield\u201d) as set forth by the U.S. Department of Commerce regarding the collection, use, and retention of Personal Data transferred from the EEA to the U.S."',
    "Unnamed: 12": null,
  },
  {
    providerName: "Report Garden Inc.",
    providerService: "Gauges",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://get.gaug.es/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Go Squared Ltd",
    providerService: "Go Squared",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.gosquared.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Fabric Answers",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: "https://tools.google.com/dlpage/gaoptout?hl=en",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      'Applicable to all Google services \nAs the PP does not specifically state what PII they collect in relation to each service and it just generally states that it collects location, "Data from your device" has been added to the categories of data.\n"We collect information about your location when you use our services, which helps us offer features like driving directions for your weekend getaway or showtimes for movies playing near you.\nYour location can be determined with varying degrees of accuracy by:\n-GPS\n-IP address\n-Sensor data from your device\n-Information about things near your device, such as Wi-Fi access points, cell towers, and Bluetooth-enabled devices"',
    "Unnamed: 12":
      'Add this clause if you use Fabric Answers. Also keep in mind that there are various extensions for Fabric Answers. If you\'ve activated any additional features, check out the list of available services by typing "Fabric" into the search bar above.\n\nThis section is one of those you should read and see whether it matches your actual use of Fabric/Answers, or whether there are any changes needed.\n\nIf you or your users are based in the European Economic Area (EEA) or Switzerland consider to add Google Ireland Limited as service provider in the next step.\nIf you or your users are based outside of the EEA, then simply add Google LLC as the the service provider.\nIf both of the above apply, then simply add both service providers. More information can be found here.',
  },
  {
    providerName: "Conva Ventures Inc.",
    providerService: "Fathom Analytics ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://usefathom.com/privacy",
    optOut: "https://tools.google.com/dlpage/gaoptout?hl=en",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Ads conversion tracking",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: "https://tools.google.com/dlpage/gaoptout?hl=en",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service.\nConversion tracking services are useful for understanding the impact of your advertising spend on a given network.\n\nIf you or your users are based in the European Economic Area (EEA) or Switzerland consider to add Google Ireland Limited as service provider in the next step.\nIf you or your users are based outside of the EEA, then simply add Google LLC as the the service provider.\nIf both of the above apply, then simply add both service providers. More information can be found here.\n\nFormerly: AdWords",
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: "https://tools.google.com/dlpage/gaoptout?hl=en",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      'Add this clause if you use Google Analytics. Also keep in mind that there are various extensions for Google Analytics. If you\'ve activated any additional features, check out the list of available services by typing "Google Analytics" in the search bar above.\nCheck out Display Advertising for Google Analytics, for example.\nIf you\'re based in Germany, use "Google Analytics with anonymized IP" instead of this clause. For more information, read our guide on the topic.\n\nIf you or your users are based in the European Economic Area (EEA) or Switzerland consider to add Google Ireland Limited as service provider in the next step.\nIf you or your users are based outside of the EEA, then simply add Google LLC as the the service provider.\nIf both of the above apply, then simply add both service providers. More information can be found here.',
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Analytics with anonymized IP",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: "https://tools.google.com/dlpage/gaoptout?hl=en",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      'Use this clause instead of our standard "Google Analytics" if you\'re based in Germany and potentially elsewhere in Europe. Make sure you follow these instructions.\nhttps://www.iubenda.com/blog/privacy-policy-google-analytics-germany/\n\nIf you or your users are based in the European Economic Area (EEA) or Switzerland consider to add Google Ireland Limited as service provider in the next step.\nIf you or your users are based outside of the EEA, then simply add Google LLC as the the service provider.\nIf both of the above apply, then simply add both service providers. More information can be found here.\nhttps://www.iubenda.com/en/help/14936-place-of-processing-customisation-for-google-services',
    "Unnamed: 12":
      'Use this clause instead of our standard "Google Analytics" if you\'re based in Germany and potentially elsewhere in Europe. Make sure you follow these instructions.\n\nIf you or your users are based in the European Economic Area (EEA) or Switzerland consider to add Google Ireland Limited as service provider in the next step.\nIf you or your users are based outside of the EEA, then simply add Google LLC as the the service provider.\nIf both of the above apply, then simply add both service providers. More information can be found here.',
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "User ID extension for Google Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: "https://tools.google.com/dlpage/gaoptout?hl=en",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "If you are making use of the user ID feature in Google Analytics, then add this clause.\n\nIf you or your users are based in the European Economic Area (EEA) or Switzerland consider to add Google Ireland Limited as service provider in the next step.\nIf you or your users are based outside of the EEA, then simply add Google LLC as the the service provider.\nIf both of the above apply, then simply add both service providers. More information can be found here.",
  },
  {
    providerName: "Heap, Inc.",
    providerService: "Heap Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://heap.io/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "If you receive services from us and advertently disclose personal health information to us, we may collect and store such personal health information also. This is limited only to customers who work with personal health information and collect personal health information. It is your responsibility to not disclose personal health information to Heap. ]\n",
    "Unnamed: 12": null,
  },
  {
    providerName: "Hotjar Ltd.",
    providerService: "Hotjar Form Analysis & Conversion Funnels",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.hotjar.com/legal/policies/privacy#enduserenglish",
    optOut: "https://www.hotjar.com/legal/compliance/opt-out",
    placeProcessing: "Malta",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "All data Hotjar collects is stored electronically in Ireland, Europe on the Amazon Web Services infrastructure, eu-west-1 datacenter. Our application servers and database servers run inside an Amazon VPC, Virtual Private Cloud. The database containing visitor and usage data is only accessible from the application servers and no outside sources are allowed to connect to the database. Our data retention times are no longer than 365 days.\nMore at: https://help.hotjar.com/hc/en-us/articles/115011639887-Data-Safety-Privacy-Security",
    "Unnamed: 12": null,
  },
  {
    providerName: "HubSpot, Inc.",
    providerService: "HubSpot Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://legal.hubspot.com/product-privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      'HubSpot Analytics is an analytics service. Analytics services use various techniques to track user behavior. Add this clause if you use this analytics service.\nIf you also use self hosted analytics, add "Analytics collected directly".\nIf you use other Hubspot features, additionally add those clauses by searching for "Hubspot". If something is missing, add that missing data collection practice as a custom clause via "add custom clause".',
  },
  {
    providerName: "Explora Consulting Hong Kong",
    providerService: "Hummingbird",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://hummingbirdanalytics.com/privacy-policy-2/",
    optOut: null,
    placeProcessing: "China",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Awio Web Services LLC ",
    providerService: "Improvely",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.awio.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "IO Technologies Inc. ",
    providerService: "IO Technologies",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://iotechnologies.com/pp",
    optOut: null,
    placeProcessing: "Cyprus",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      '"All your personal data collected by us is stored on our secure servers located in Germany and the Netherlands.\nWe may also transfer your personal data from the European Economic Area (the \u201cEEA\u201d) outside the EEA (e.g., from our servers located in Germany and the Netherlands to our offices in Ukraine). If we do so, we reasonably ensure that your rights and freedoms as a data subject are protected in compliance with the GDPR."\n\n!!! Official data of comp. could not be found. On website, "Contact Us" these addresses are given:\n- Wilmington; 301 N. Market Street, Wilmington, Delaware, 19801\n- Nicosia; Esterson Limited. Prodromou, 75, Oneworld Parkview House, 4th Floor, 2063, Nicosia, Cyprus',
    "Unnamed: 12": null,
  },
  {
    providerName: "Jetscale UG",
    providerService: "Jetscale",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.jetscale.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Only privacy polycy for website available. \nThe PP does not mention privacy practce for final users (usters of Jetscale's customers). Location of processing unknown. ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Keen.io LLC",
    providerService: "Keen IO",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://keen.io/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      'Privacy policy only applies to Keen.io website. \n"This Privacy Policy does not apply to personal data that you transmit as a Keen customer to establish and maintain your account, or to any personal data that may be part of the content that you submit or manage using the Keen service."\nFrom Privacy Shield webbsite: "Keen Privacy Policy This Privacy Policy describes how Keen.io LLC (\u201cKeen,\u201d \u201cKEEN IO,\u201d \u201cwe,\u201d or \u201cour\u201d) collects information from and about you as a visitor to our website (the \u201cSite\u201d), how we use that information, and the circumstances under which we share information with others." ',
    "Unnamed: 12": null,
  },
  {
    providerName: "Space Pencil, Inc. dba Kissmetrics ",
    providerService: "KISSmetrics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://signin.kissmetrics.com/privacy#optout",
    optOut: "https://signin.kissmetrics.com/privacy/#controls",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no ",
    adequacyReason: "Privacy Shield (inactive 12/04/2020)",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Upland Software, Inc. ",
    providerService: "LeadLander",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://uplandsoftware.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "LinkedIn Ireland Unlimited Company (EEA users) | LinkedIn Corporation (USA & other countries users)",
    providerService: "LinkedIn conversion tracking",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.linkedin.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      '" If you use our Services from a mobile device, that device will send us data about your location based on your phone settings. We will ask you to opt-in before we use GPS or other tools to identify your precise location."',
    "Unnamed: 12":
      "Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service.\nConversion tracking services are useful for understanding the impact of your advertising spend on a given network.",
  },
  {
    providerName: "Linkpulse AS",
    providerService: "Linkpulse",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected: " Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.linkpulse.com/privacy.html",
    optOut: null,
    placeProcessing: "Norway",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      '"Linkpulse collect all traffic metrics in realtime such as pageviews, clicks, time spent on pages, unique users."\nWhat data is not collected by Linkpulse\nIP address - Linkpulse could technically log the client remote IP address. However, this information is discarded on the log servers. The Linkpulse log servers are configured in such a way that no IP address is stored permanently.\nPersonal information - Personal information, that can identify a unique user, is never collected. When cookie tracking is enabled, Linkpulse is able to recognize a user uniquely. However, Linkpulse still cannot identify the user personally. Nor is any information stored together with the unique id, allowing any identification of the user at a later stage.\nBrowser type - Linkpulse does not collect the client\u2019s browser type. However, the tracking script does use some logic to identify the browser type to maintain cross browser functionality. The Linkpulse log servers do not keep this information in the log files.\n',
    "Unnamed: 12": null,
  },
  {
    providerName: "Aruba S.p.a.",
    providerService: "Livestats Aruba",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://hosting.aruba.it/documents/tc-files/en/7_privacypolicyhostingcart.pdf",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Upland Software, Inc.",
    providerService: "Localytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.localytics.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Microsoft Ireland Operations Ltd (EEA users) | Microsoft Corp. (USA & other countries users)",
    providerService: "Microsoft Advertising Universal Event. Tracking ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://privacy.microsoft.com/en-us/privacystatement",
    optOut:
      "https://account.microsoft.com/privacy/ad-settings/signedout?ru=https:%2F%2Faccount.microsoft.com%2Fprivacy%2Fad-settings",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service. Conversion tracking services are useful for understanding the impact of your advertising spend on a given network.",
  },
  {
    providerName: "Fjord Technologies SAS. ",
    providerService: "MixCommander",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.commandersact.com/en/privacy/",
    optOut: null,
    placeProcessing: "France ",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      'PP aplyes only to website not to services. \n"Our personal data protection policy provides information on:\n-The personal data that is collected and processed when you use Commanders Act\u2019s website https://www.commandersact.com (\u201cSite\u201d);"',
    "Unnamed: 12":
      'Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service. If you also use self hosted analytics, add "Analytics collected directly".\nConsider to add also \u201cTagCommander\u201d',
  },
  {
    providerName: "Mixpanel, Inc.",
    providerService: "MixPanel",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://mixpanel.com/legal/privacy-policy/",
    optOut:
      "https://help.mixpanel.com/hc/en-us/articles/360000679006-Managing-Personal-Information#optout-users",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "PP does not apply to privacy practices in relation to the end users of Mixpanel's Customers. T&S apply. (https://mixpanel.com/legal/terms-of-use/) \nThey have DPA available here https://mixpanel.com/legal/dpa/ . \n",
    "Unnamed: 12": null,
  },
  {
    providerName: "Mouseflow Inc. ",
    providerService: "Mouseflow",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://mouseflow.com/privacy/",
    optOut: "https://mouseflow.com/opt-out/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "The Nielsen Company, LLC.",
    providerService: "Nielsen Audience Measurement",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.nielsen.com/us/en/legal/privacy-statement/",
    optOut:
      "https://priv-policy.imrworldwide.com/priv/browser/us/en/optout.html#choices",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "NinjaReports.com",
    providerService: "Ninja Reports ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.ninjareports.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Quantcast International Ltd. (EU&other country users) | Quantcast Corp. (USA users)",
    providerService: "Quantcast Measure",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.quantcast.com/privacy/",
    optOut: "https://www.quantcast.com/opt-out/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Quora, Inc. - VeraSafe Ireland Ltd. (EU repr. for EEA users) ",
    providerService: "Quora Conversion Pixel",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.quantcast.com/privacy/\n\nhttps://www.quora.com/about/pixel_privacy",
    optOut: "https://www.quora.com/optout",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12":
      "Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service. Conversion tracking services are useful for understanding the impact of your advertising spend on a given network.",
  },
  {
    providerName:
      "ResponseTap UK Ltd. (EU&other countries users) | ResponseTap Inc. (USA users)",
    providerService: "ResponseTap",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.responsetap.com/gb/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      '"ResponseTap Limited stores and processes personal data on servers based in the European Union. \nIn respect of Customers who have contracted with ResponseTap Inc in the USA, the data will be processed by ResponseTap Inc in the USA, and we may therefore store information on servers in the United States."\n',
    "Unnamed: 12": null,
  },
  {
    providerName: "Salesforce.com, inc.",
    providerService: "Salesforce Analytics Cloud",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "Salesforce.com, Inc.",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Full Circle Studies, Inc.",
    providerService: "ScorecardResearch",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.scorecardresearch.com/privacy.aspx?newlanguage=1",
    optOut: "https://www.scorecardresearch.com/preferences.aspx?newlanguage=1",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Found in Privacy Shield under comScore.inc https://www.privacyshield.gov/participant?id=a2zt00000008WCuAAM&status=Active ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Signals Analytics, Inc.",
    providerService: "Signal",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://signals-analytics.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "StatCounter Ltd. ",
    providerService: "StatCounter",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://statcounter.com/about/legal/#privacy",
    optOut: null,
    placeProcessing: "Ireland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "PP seems to be appicable only to website. Policy incomplete.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Numerotron, Inc. ",
    providerService: "Stathat",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.stathat.com/docs/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "NO PP for services. Only 4 paragrapgh applicable to stathat.com'c customers.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Sumo Group Inc.",
    providerService: "SumoMe Content Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://help.sumo.com/hc/en-us/articles/218958727-Privacy-Policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Tapstream Network Inc.",
    providerService: "Tapstream",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://tapstream.com/legal/privacy-policy/",
    optOut: "https://tapstream.com/opt_out_confirmation/",
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      '"(i) We may use service providers located outside of British Columbia, and, if applicable, your personal information may be processed and stored in other countries and therefore may be subject to disclosure under the laws of those jurisdictions; "\n- No mentioning of EU / GDPR / SCC ',
    "Unnamed: 12": null,
  },
  {
    providerName: "TradeTracker International B.V.",
    providerService: "TradeTracker conversion tracking",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://tradetracker.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Ireland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": '"Our servers are located in Ireland."',
    "Unnamed: 12":
      "Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service.\nConversion tracking services are useful for understanding the impact of your advertising spend on a given network.",
  },
  {
    providerName:
      "Twitter International Company (EEA users) | Twitter, Inc. (USA & other countries users) ",
    providerService: "Twitter Ads conversion tracking",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://twitter.com/en/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service.\nConversion tracking services are useful for understanding the impact of your advertising spend on a given network.\n\nAlways be aware of Twitter's rules for this kind of service.",
  },
  {
    providerName:
      "Unity Technologies Finland OY (EEA users) | Unity Technologies USA (USA&other countries users)  ",
    providerService: "Unity Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://unity3d.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "Denmark",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Visitor Analytics GmbH",
    providerService: "Visitors",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.visitor-analytics.io/en/support/legal-data-privacy-certificates/visitor-analytics-homepage/privacy-policy",
    optOut:
      "https://www.visitor-analytics.io/en/support/legal-data-privacy-certificates/standard-integration/opt-out-do-not-track-dnt",
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Walinns Innovation India Private Ltd. ",
    providerService: "Walinns Mobile App Analytics ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "http://walinns.com/privacy-policy/",
    optOut: null,
    placeProcessing: "India",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Web Tracking Services, LLC.",
    providerService: "Web-Stat live",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.web-stat.com/help_privacy_policy.htm",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Webtrekk GmbH, company of Mapp Digital Germany GmbH (EEA users) | Mapp Digital US, LLC (USA&other countries users) ",
    providerService: "Webtrekk Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webtrekk.com/privacy-statement/",
    optOut: "https://www.webtrekk.com/opt-out-webtrekk/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Webtrekk GmbH, company of Mapp Digital Germany GmbH (EEA users) | Mapp Digital US, LLC (USA&other countries users) ",
    providerService: "Webtrekk Analytics with anonymized IP",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webtrekk.com/privacy-statement/",
    optOut: "https://www.webtrekk.com/opt-out-webtrekk/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": "This is the version for setups with an anonymized IP.",
  },
  {
    providerName: "Webtrends Inc.",
    providerService: "Webtrends Analytics",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webtrends.com/legal/privacy-statement/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Woopra Inc.",
    providerService: "Woopra",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.woopra.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Verizon Media EMEA Ltd Ireland (EEA users) | Verizon Media (USA & other countries users)",
    providerService: "Yahoo Advertising conversion tracking",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.verizonmedia.com/policies/us/en/verizonmedia/privacy/index.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12":
      "Analytics services use various techniques to track user behavior and must be mentioned in the privacy policy. Add this clause if you use this analytics service.\nConversion tracking services are useful for understanding the impact of your advertising spend on a given network.",
  },
  {
    providerName: "Yandex LLC",
    providerService: "Yandex Metrica",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://yandex.com/legal/confidential/",
    optOut: null,
    placeProcessing: "Russia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "FullStory, Inc.",
    providerService: "FullStory Analyze & Collaborate ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.fullstory.com/legal/privacy/",
    optOut: "https://www.fullstory.com/optout",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Short.cm Inc",
    providerService: "Short.cm Inc",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://short.cm/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Bitly, Inc.",
    providerService: "Bitly (bit.ly links & branded domains)",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://bitly.com/pages/privacy",
    optOut: "https://bit.ly/a/optout\n\n&\n\nhttps://bitly.com/a/optout",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "InMobi Pte Ltd",
    providerService: "InMobi",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.inmobi.com/privacy-policy/\n",
    optOut: "inmobi.com/page/opt-out/",
    placeProcessing: "Singapore",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service":
      "\nhttps://www.inmobi.com/privacy-policy-for-eea/",
    "Unnamed: 12": null,
  },
  {
    providerName: "GetSocial S.A. ",
    providerService: "GetSocial Analytics ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://getsocial.io/privacy-policy",
    optOut: "https://getsocial.io/opt-out",
    placeProcessing: "Portugal",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Gainsight, Inc.",
    providerService: "GainInsight Analytics Platform",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.gainsight.com/policy/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Epsagon LTD",
    providerService: "Epsagon SDK",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://epsagon.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Liidio Oy",
    providerService: "Leadfeder ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.leadfeeder.com/privacy/",
    optOut: null,
    placeProcessing: "Finland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Matomo.org ",
    providerService: "Matomo Analytics ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://matomo.org/privacy-policy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Indicative, Inc.",
    providerService: "Indicative ",
    questionGenerator: "Q3.4. - Other analytics ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.indicative.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
];

export default analytics;
