import React from "react";

import { Grid } from "@material-ui/core";

import { Intro, ForgotPasswordForm } from "../components/authentication";

import { Helmet } from "react-helmet";

import * as queryString from "query-string";

const ForgotPassword = (props) => {
  // Parse parameters received at the forgot password
  // route: forgot-password?key=value
  const parsed = queryString.parse(window.location.search);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      <Helmet>
        <title>Forgot password</title>
      </Helmet>

      <Grid item xs={12} sm={5} md={6} lg={6}>
        <Intro />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <ForgotPasswordForm queryString={{ code: parsed.code }} />
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
