import React, { useState, useEffect } from "react";

import {
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  makeStyles,
} from "@material-ui/core";

import { ErrorMessage } from "@hookform/error-message";

import { Controller } from "react-hook-form";

import { InformationNote } from "./";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    marginLeft: (props) => {
      if (props.subQuestion) return theme.spacing(2);

      if (props.subSubQuestion) return theme.spacing(4);

      return "0";
    },
  },
  labelQuestion: {
    wordBreak: "break-all",
    display: "flex",
    alignItems: "baseline",
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    marginBottom: theme.spacing(1),
  },
  labelQuestionNumber: {
    color: theme.palette.primary.main,
    fontWeight: "600",
    fontSize: "1.2rem",
    marginRight: theme.spacing(1),
    wordBreak: "keep-all",
  },
  radioGroup: {
    display: "flex",
    flexDirection: "row",
  },
  nullRadio: {
    position: "fixed",
    opacity: "0",
  },
  error: {
    alignSelf: "start",
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      "&": {
        paddingBottom: "2px",
      },
    },
  },
}));

const RadioInput = ({
  subQuestion,
  subSubQuestion,
  labelNumber,
  label,
  name,
  defaultValue,
  methods,
  informationNote,
  styles = {},
}) => {
  const classes = useStyles({ ...styles, subQuestion, subSubQuestion });

  const [status, setStatus] = useState(String(defaultValue));

  useEffect(() => {
    methods.setValue(name, status === "null" ? null : status === "true");

    // eslint-disable-next-line
  }, [status, setStatus]);

  return (
    <section className={classes.section}>
      <label className={classes.labelQuestion}>
        <span className={classes.labelQuestionNumber}>{labelNumber}</span>{" "}
        
		<span>
          {label}
        
		  {informationNote ? <InformationNote text={informationNote} /> : null}
        </span>
      </label>

      <Controller
        name={name}
        control={methods.control}
        defaultValue={status}
        as={
          <FormControl component="fieldset">
            <RadioGroup
              aria-label={name}
              value={status}
              className={classes.radioGroup}
              onChange={(event) => {
                event.preventDefault();

                setStatus(event.target.value);
              }}
              onClick={(event) => {
                if (event.target.value === status) {
                  setStatus("null");
                }
              }}
            >
              <FormControlLabel
                value={"true"}
                control={<Radio />}
                label="Yes"
              />

              <FormControlLabel
                value={"false"}
                control={<Radio />}
                label="No"
              />

              <FormControlLabel
                value={"null"}
                control={<Radio className={classes.nullRadio} />}
              />
            </RadioGroup>
          </FormControl>
        }
      />

      <Typography className={classes.error}>
        <ErrorMessage errors={methods.errors} name={name} />
      </Typography>
    </section>
  );
};

export default RadioInput;
