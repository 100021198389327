import React from "react";

import { Grid, Box, Button, Typography, makeStyles } from "@material-ui/core";

import floating from "../../assets/images/notFound/floating.svg";

const useStyles = makeStyles((theme) => ({
  grid: {
    width: "50%",
    height: "calc(100vh - 64px)",
    margin: "0 auto",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.down("lg")]: {
      "&": {
        width: "60%",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "80%",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "100%",
      },
    },
  },
  gridItemIllustration: {
    justifyContent: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(2),
  },
  img: {
    width: "85%",
    minWidth: "250px",
    maxWidth: "500px",
  },
  gridItemContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&> *": {
      margin: "10px 0",
    },
    "& > *:first-child": {
      margin: "0 0 10px 0",
    },
  },
  title: {
    margin: "0 0 10px 0",
    textAlign: "center",
    color: theme.palette.blackOne,
    fontStyle: "normal",
    fontWeight: "900",
    fontSize: "2.5em",
    lineHeight: "42px",
    "& > *:first-child": {
      marginBottom: "10px",
    },
  },
  description: {
    textAlign: "center",
    fontWeight: "500",
    fontSize: "1.5rem",
    lineHeight: "42px",
    color: theme.palette.grayOne,
  },
  back: {
    fontSize: "1.25em",
  },
}));

const Information = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.grid}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemIllustration}
      >
        <img src={floating} alt="Page not found" className={classes.img} />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemContent}
      >
        <Box>
          <Typography variant="h1" className={classes.title}>
            Nothing to see here...
          </Typography>

          <Typography variant="h2" className={classes.description}>
            The page you were looking for could not be found
          </Typography>
        </Box>

        <Button
          variant="hovered-reversed-no-border"
          color="primary"
          href="/"
          className={classes.back}
        >
          Take me back home
        </Button>
      </Grid>
    </Grid>
  );
};

export default Information;
