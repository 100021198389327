import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { GetStarted } from "../general";

import safeguardCharacter from "../../assets/images/home/safeguardCharacter.svg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    minHeight: "700px",
    background: theme.palette.gradient,
    [theme.breakpoints.down("md")]: {
      "&": {
        flexDirection: "column",
        marginTop: "0",
        marginBottom: theme.spacing(8),
      },
    },
  },
  boxIllustration: {
    height: "400px",
    width: "600px",
    maxWidth: "100%",
    backgroundImage: `url(${safeguardCharacter})`,
    backgroundAttachment: "scroll",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "70%",
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "500px",
        backgroundSize: "75%",
      },
    },
  },
  boxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "65%",
    maxWidth: "35%",
    marginLeft: theme.spacing(12),
    [theme.breakpoints.down("md")]: {
      "&": {
        maxWidth: "65%",
        marginLeft: "0",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        maxWidth: "85%",
      },
    },
  },
  boxText: {
    marginBottom: theme.spacing(6),
  },
  title: {
    fontSize: "3rem",
    fontWeight: "700",
    color: theme.palette.blackOne,
    marginBottom: theme.spacing(3),
  },
  description: {
    fontSize: "1.5rem",
    color: theme.palette.grayOne,
  },
  textStyle: {
    display: "inline-block",
    "&::after": {
      content: '""',
      display: "block",
      width: "156px",
      height: "34px",
      marginTop: "-58px",
      marginLeft: "2px",
      borderBottom: `12px solid ${theme.palette.secondary.soft}`,
    },
  },
}));

const Safeguard = () => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxIllustration}></Box>

      <Box className={classes.boxContent}>
        <Box className={classes.boxText}>
          <Typography className={classes.title}>
            A <span className={classes.textStyle}>safeguard</span> for both
            <br />
            you and your users
          </Typography>

          <Typography className={classes.description}>
            We help you comply with the law so you
            <br /> can have peace of mind and focus on
            <br /> your business
          </Typography>
        </Box>

        <GetStarted text="Get started - Free" styles={{ width: "150px" }} />
      </Box>
    </Box>
  );
};

export default Safeguard;
