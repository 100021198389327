import React from "react";

import {
  Box,
  Typography,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxLoadingComponent: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  loadingComponentCircle: {
    color: theme.palette.secondary.main,
  },
  loadMessage: {
    marginTop: "20px",
	fontSize: "1.3rem",
    color: theme.palette.grayOne,
  },
}));

const LoadingComponent = ({ message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxLoadingComponent}>
      <CircularProgress size={35} className={classes.loadingComponentCircle} />

      {message && (
        <Typography variant="h2" className={classes.loadMessage}>
          {message}
        </Typography>
      )}
    </Box>
  );
};

export default LoadingComponent;
