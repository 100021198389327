import React from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    minHeight: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "290px",
      },
    },
  },
  title: {
    color: theme.palette.blackOne,
  },
  description: {
    fontSize: "1.5rem",
    color: theme.palette.grayOne,
  },
  highlight: {
    fontWeight: "bold",
  },
}));

const Intro = () => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box>
        <Typography variant="h1" className={classes.title}>
          We’ll guide you, step by step
        </Typography>

        <Typography variant="body2" className={classes.description}>
          With Audaxly you can stay compliant in{" "}
          <span className={classes.highlight}>every phase of your project</span>
          .
        </Typography>

        <Typography variant="body2" className={classes.description}>
          We make the process easy and allow you to build compliance{" "}
          <span className={classes.highlight}>step by step.</span>.
        </Typography>
      </Box>
    </Box>
  );
};

export default Intro;
