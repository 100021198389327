import React from "react";

import {
  Box,
  IconButton,
  Typography,
  Button,
  makeStyles,
} from "@material-ui/core";

import { Error as ErrorIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  errorRequestBox: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  errorInfoBox: {
    flex: "2",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
  },
  errorRetryBox: {
    flex: ".5",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "center",
    padding: "0 15px",
    boxSizing: "border-box",
  },
  errorButton: {
    color: theme.palette.secondary.main,
  },
  errorButtonIcon: {
    width: "45px",
    height: "45px",
  },
  retryButton: {
    color: theme.palette.secondary.main,
    border: `1px solid ${theme.palette.secondary.main}`,
    background: "transparent",
    "&:hover": {
      background: "transparent",
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
  errorMessage: {
	fontSize: "1.3rem",
    color: theme.palette.grayOne,
  },
}));

const ErrorLoadActions = ({ message, onSubmit, data }) => {
  const classes = useStyles();

  return (
    <Box className={classes.errorRequestBox}>
      <Box className={classes.errorInfoBox}>
        <IconButton
          aria-label="delete"
          className={classes.errorButton}
          disableRipple
          disableFocusRipple
        >
          <ErrorIcon className={classes.errorButtonIcon} />
        </IconButton>

        <Typography variant="h2" className={classes.errorMessage}>
          {message}
        </Typography>
      </Box>

      {onSubmit && data && (
        <Box className={classes.errorRetryBox}>
          <Button
            variant="contained"
            color="primary"
            className={classes.retryButton}
            onClick={() => {
              onSubmit(data);
            }}
            disableRipple
            disableFocusRipple
            disableElevation
          >
            Retry
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ErrorLoadActions;
