import React from "react";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

const ToastContainerConfigured = () => {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={true}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={true}
      draggable={true}
      pauseOnHover={true}
      limit={4}
    />
  );
};

export default ToastContainerConfigured;
