import React from "react";

import { HeaderSticky, Footer } from "../components/general";

import { Sections, Intro } from "../components/aboutUs";

import { Grid } from "@material-ui/core";

import { Helmet } from "react-helmet";

const AboutUs = () => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Helmet>
        <title>About us</title>
      </Helmet>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <HeaderSticky />
      </Grid>

      <Grid item xs={12} sm={12} md={11} lg={9}>
        <Intro />
      </Grid>

      <Grid item xs={12} sm={12} md={11} lg={9}>
        <Sections />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Footer disclaimer={false} />
      </Grid>
    </Grid>
  );
};

export default AboutUs;
