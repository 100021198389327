import React, { Fragment } from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyle = makeStyles((theme) => ({
  boxContainer: {
    display: "inline-flex",
    flexDirection: "row",
    marginBottom: "25px",
    alignItems: "center",
  },
  img: {
    color: theme.palette.common.black,
    width: "35px",
    marginRight: "20px",
  },
  anchor: {
    display: "block",
    position: "relative",
    top: "-86px",
    visibility: "hidden",
  },
}));

const Section = ({ image, title, anchor }) => {
  const classes = useStyle();

  return (
    <Fragment>
      <span className={classes.anchor} id={anchor} />

      <Box className={classes.boxContainer}>
        <img alt={title} src={image} className={classes.img} />

        <Typography variant="h3">{title}</Typography>
      </Box>
    </Fragment>
  );
};

export default Section;
