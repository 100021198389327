import "react-app-polyfill/stable"; // Must be the first line

import React, { Suspense } from "react";

import ReactDOM from "react-dom";

import { MuiThemeProvider } from "@material-ui/core";

import theme from "./theme";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import Application from "./application";

import "./index.css";

import dotenv from "dotenv";

import { ApolloProvider } from "@apollo/client";

import { LoadingPage } from "./components/general";

import {
  AuthenticationProvider,
  UserProvider,
  SpamProvider,
  DashboardProvider,
  ProjectProvider,
  ProjectCategoriesProvider,
  ProjectPrivacyPolicyProvider,
} from "./contexts";

import ToastContainerConfigured from "./notifications";

import client from "./apolloClient";

import reportWebVitals from "./reportWebVitals";

// Environment variables
dotenv.config();

// All the providers must also be provided on the application
// test
ReactDOM.render(
  <>
    <MuiThemeProvider theme={theme}>
      <ApolloProvider client={client}>
        <AuthenticationProvider>
          <SpamProvider>
            <UserProvider>
              <DashboardProvider>
                <ProjectProvider>
                  <ProjectCategoriesProvider>
                    <ProjectPrivacyPolicyProvider>
                      <Suspense fallback={<LoadingPage />}>
                        <Application />
                      </Suspense>
                    </ProjectPrivacyPolicyProvider>
                  </ProjectCategoriesProvider>
                </ProjectProvider>
              </DashboardProvider>
            </UserProvider>
          </SpamProvider>
        </AuthenticationProvider>
      </ApolloProvider>
    </MuiThemeProvider>

    <ToastContainerConfigured />
  </>,
  document.getElementById("root")
);

// Use unregister() or register() to register a service work to make
// the application work offline and load faster. Note this comes with
// some pitfalls.
//
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// Pass a function to log the results, e.g reportWebVitals(console.log),
// to  measuring the application performance.
//
// Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
