import React from "react";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import { useApolloClient } from "@apollo/client";

import {
  useAuthentication,
  useDashboard,
  useProject,
  useProjectCategories,
  useProjectPrivacyPolicy,
  useUser,
  useSpam,
  checkAuthenticationStatus,
} from "./contexts";

import { logout } from "./utils";

import {
  Home,
  HowItWorks,
  Pricing,
  AboutUs,
  Login,
  SignUp,
  ForgotPassword,
  NotFound,
  PrivacyPolicy,
  TermsAndConditions,
  ChangeEmail,
  Dashboard,
  DashboardHome,
  Account,
  Notifications,
  Contact,
  ProjectPrivacyPolicy,
  DashboardProjectCategories,
} from "./pages";

const PrivateRoute = ({ authenticationStatus, state }) => {
  return authenticationStatus ? (
    <Dashboard>
      <Outlet />
    </Dashboard>
  ) : (
    <Navigate
      to="/login"
      replace
      state={
        state
          ? state
          : {
              message: "Access expired or not logged in",
              origin: window.location.pathname,
            }
      }
    />
  );
};

// If new pages are added or existing ones have it's path
// changed, update the site map on the public folder
const Application = () => {
  const client = useApolloClient();

  const { authentication, setAuthentication } = useAuthentication();

  const { setDashboard } = useDashboard();

  const { setProject } = useProject();

  const { setProjectCategories } = useProjectCategories();

  const { setProjectPrivacyPolicy } = useProjectPrivacyPolicy();

  const { setUser } = useUser();

  const { setSpam } = useSpam();

  const authenticationStatus = checkAuthenticationStatus(authentication);

  // Logged in, i.e. token is stored, but expired
  if (!!authentication?.token && !authenticationStatus) {
    logout({
      client,
      setters: [
        setAuthentication,
        setDashboard,
        setProject,
        setProjectCategories,
        setProjectPrivacyPolicy,
        setUser,
        setSpam,
      ],
    });
  }

  // If the path for the routes that has hash links change, it is necessary
  // to update the internal base URL for those links, e.g on how it works
  // and Privacy Policy embed
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />

        <Route path="how-it-works" element={<HowItWorks />} />

        <Route path="pricing" element={<Pricing />} />

        <Route path="about-us" element={<AboutUs />} />

        <Route path="login" element={<Login />} />

        <Route path="sign-up" element={<SignUp />} />

        <Route path="forgot-password" element={<ForgotPassword />} />

        <Route path="change-email" element={<ChangeEmail />} />

        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute authenticationStatus={authenticationStatus} />}
        >
          <Route path="home" element={<DashboardHome />} />

          <Route path="account" element={<Account />} />

          <Route path="notifications" element={<Notifications />} />

          <Route path="contact" element={<Contact />} />

          <Route path="categories" element={<DashboardProjectCategories />} />

          <Route path="privacy-policy" element={<ProjectPrivacyPolicy />} />
        </Route>

        <Route path="privacy-policy" element={<PrivacyPolicy />} />

        <Route path="terms-and-conditions" element={<TermsAndConditions />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default Application;
