import React, { useState } from "react";

import { Tooltip, makeStyles } from "@material-ui/core";

import { InfoOutlined as InfoOutlinedIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  tooltip: {
    padding: theme.spacing(1),
    fontSize: "1.1rem",
    color: theme.palette.common.white,
  },
  popper: {
    color: theme.palette.purpleTwo,
  },
  icon: {
    fontSize: "1.6rem",
    color: theme.palette.secondary.main,
    verticalAlign: "bottom",
    marginLeft: "4px",
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
}));

const InformationNote = ({ text }) => {
  const classes = useStyles();

  const [tooltipClick, setTooltipClick] = useState(false);

  return (
    <Tooltip
      title={text}
      arrow
      classes={{ tooltip: classes.tooltip, popper: classes.popper }}
      open={tooltipClick}
      onClick={() => setTooltipClick((status) => !status)}
      onMouseEnter={() => setTooltipClick(true)}
      onMouseLeave={() => setTooltipClick(false)}
    >
      <InfoOutlinedIcon className={classes.icon}>Arrow</InfoOutlinedIcon>
    </Tooltip>
  );
};

export default InformationNote;
