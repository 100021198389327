import { ApolloClient, from, HttpLink, InMemoryCache } from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

import { RetryLink } from "@apollo/client/link/retry";

import { onError } from "@apollo/client/link/error";

import { defaultStateAuthentication } from "./contexts";

import { getLocalStorageItem } from "./utils";

const link = from([
  onError(({ operation, response, graphQLErrors, networkError, forward }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }, index) => {
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        );

        return null;
      });

    if (networkError) console.log(`[Network error]: ${networkError}`);
  }),
  // Authentication
  setContext((_, { headers }) => {
    // Get the authentication token from local storage if it exists
    const token = getLocalStorageItem({
      key: process.env.REACT_APP_AUTHENTICATION_LOCAL_STORAGE_KEY,
      defaultState: defaultStateAuthentication,
    }).token;

    // Return the headers to the context so HTTP Link can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
  }),
  new RetryLink({
    delay: {
      initial: 500,
      // max: Infinity,
      // jitter: true
    },
    attempts: {
      max: 2,
      // retryIf: (error, _operation) => !!error
    },
  }),
  new HttpLink({
    uri: process.env.REACT_APP_API_ENDPOINT,
  }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  fetchOptions: {
    mode: "no-cors",
  },
});

export default client;
