import React from "react";

import { Typography, makeStyles } from "@material-ui/core";

import Select from "react-select";

import { ErrorMessage } from "@hookform/error-message";

import { Controller } from "react-hook-form";

import { InformationNote } from "./";

const useStyles = makeStyles((theme) => ({
  section: {
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(3),
    marginLeft: (props) => {
      if (props.subQuestion) return theme.spacing(2);

      if (props.subSubQuestion) return theme.spacing(4);

      return "0";
    },
  },
  labelQuestion: {
    wordBreak: "break-all",
    display: "flex",
    alignItems: "baseline",
    color: theme.palette.primary.main,
    fontSize: "1.2rem",
    marginBottom: theme.spacing(1),
  },
  labelQuestionNumber: {
    color: theme.palette.primary.main,
    fontWeight: "600",
    fontSize: "1.2rem",
    marginRight: theme.spacing(1),
    wordBreak: "keep-all",
  },
  select: {
    outline: "none",
    minWidth: "100%",
    marginTop: theme.spacing(1),
  },
  error: {
    alignSelf: "self-end",
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      "&": {
        paddingBottom: "2px",
      },
    },
  },
}));

const SelectInput = ({
  subQuestion,
  subSubQuestion,
  labelNumber,
  label,
  name,
  options = [],
  sort = true,
  lastOptions = [],
  closeMenuOnSelect = false,
  isLoading = false,
  isDisabled = false,
  isSearchable = true,
  defaultValue = [],
  methods,
  informationNote,
  styles = {},
}) => {
  const classes = useStyles({ ...styles, subQuestion, subSubQuestion });

  return (
    <section className={classes.section}>
      <label className={classes.labelQuestion}>
        {labelNumber ? (
          <span className={classes.labelQuestionNumber}>{labelNumber}</span>
        ) : null}

        <span>
          {label}

          {informationNote ? <InformationNote text={informationNote} /> : null}
        </span>
      </label>

      <Controller
        isMulti
        name={name}
        options={(sort
          ? options.sort((a, b) =>
              a.label > b.label ? 1 : b.label > a.label ? -1 : 0
            )
          : options
        ).concat(lastOptions)}
        classNamePrefix="select"
        closeMenuOnSelect={closeMenuOnSelect}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isSearchable={isSearchable}
        defaultValue={defaultValue}
        menuPlacement="auto"
        placeholder="Select..."
        captureMenuScroll
        hideSelectedOptions
        maxMenuHeight={200}
        as={Select}
        control={methods.control}
        className={classes.select}
      />

      <Typography className={classes.error}>
        <ErrorMessage errors={methods.errors} name={name} />
      </Typography>
    </section>
  );
};

export default SelectInput;
