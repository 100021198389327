import React from "react";

import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";

import { Error as ErrorIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  errorRequestBox: {
    textAlign: "center",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  errorButton: {
    color: theme.palette.secondary.main,
  },
  errorButtonIcon: {
    width: "45px",
    height: "45px",
  },
  errorMessage: {
    fontSize: "1.3rem",
    color: theme.palette.primary.soft,
  },
}));

const ErrorLoad = ({ message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.errorRequestBox}>
      <IconButton
        aria-label="delete"
        className={classes.errorButton}
        disableRipple
        disableFocusRipple
      >
        <ErrorIcon className={classes.errorButtonIcon} />
      </IconButton>

      <Typography variant="h2" className={classes.errorMessage}>
        {message}
      </Typography>
    </Box>
  );
};

export default ErrorLoad;
