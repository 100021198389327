import React from "react";

import { Grid } from "@material-ui/core";

import { Helmet } from "react-helmet";

import * as queryString from "query-string";

import { Sections, Views } from "../components/privacyPolicy";

const PrivacyPolicy = () => {
  // Parse parameters received at the sign up
  // route: privacy-policy?key=value
  const parsed = queryString.parse(window.location.search);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Helmet>
        <title>Privacy Policy</title>
      </Helmet>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Sections queryString={{ code: parsed.code }} />

        <Views queryString={{ code: parsed.code }} />
      </Grid>
    </Grid>
  );
};

export default PrivacyPolicy;
