import React from "react";

import { Box, Button, Typography, makeStyles } from "@material-ui/core";

import { useDashboard } from "../../contexts";

import welcome from "../../assets/images/dashboard/welcome/welcome.svg";

const useStyles = makeStyles((theme) => ({
  boxWelcomeContainer: {
    display: "flex",
    flexDirection: "row-reverse",
    alignItems: "stretch",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("lg")]: {
      "&": {
        flexDirection: "column",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        height: "125%",
        // The following recreates the background set in dashboard,
        // once the content of this component gets out of the height
        // limit defined in the dashboard... search for future alternatives
        // that works with the way the outlet component from navigation works
        background: theme.palette.gradient,
      },
    },
  },
  boxImageContainer: {
    flex: ".75",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  boxImageEffect: {
    zIndex: "1000",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "350px",
    height: "100%",
    margin: theme.spacing(0, 1),
    position: "relative",
    backgroundColor: theme.palette.purpleFive,
    [theme.breakpoints.down("lg")]: {
      "&": {
        height: "95%",
        width: "100%",
        margin: "0",
      },
    },
  },
  img: {
    width: "450px",
    [theme.breakpoints.down("lg")]: {
      "&": {
        width: "400px",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "95%",
      },
    },
  },
  boxContentContainer: {
    flex: "1",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    height: "100%",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      "&": {
        alignItems: "center",
      },
    },
  },
  boxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "85%",
    width: "85%",
    boxSizing: "border-box",
    [theme.breakpoints.down("lg")]: {
      "&": {
        height: "100%",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "95%",
        padding: theme.spacing(0, 2),
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: theme.spacing(0, 3),
      },
    },
  },
  title: {
    fontSize: "4rem",
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      "&": {
        fontSize: "2rem",
        lineHeight: "50px",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        fontSize: "1.8rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        fontSize: "1.6rem",
      },
    },
  },
  subTitle: {
    fontSize: "1.6rem",
    color: theme.palette.grayOne,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("lg")]: {
      "&": {
        fontSize: "1.4rem",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        fontSize: "1.3rem",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        fontSize: "1.2rem",
      },
    },
  },
  button: {
    alignSelf: "start",
    backgroundColor: "transparent",
    marginTop: theme.spacing(4),
    color: theme.palette.secondary.main,
    fontSize: "1.25rem",
    fontWeight: "700",
    textDecoration: "none",
    "&:hover": {
      backgroundColor: "transparent",
      filter: "opacity(.8)",
    },
    "& > *": {
      zIndex: 1,
    },
    "&::before": {
      zIndex: 0,
      position: "absolute",
      content: '""',
      display: "block",
      width: "60px",
      height: "60px",
      marginLeft: "-55px",
      borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
      backgroundColor: theme.palette.common.white,
      boxShadow: `0px 0px 15px ${theme.palette.graySix}`,
    },
    [theme.breakpoints.down("lg")]: {
      "&::before": {
        width: "45px",
        height: "45px",
        marginLeft: "-65px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    },
  },
}));

const Welcome = () => {
  const classes = useStyles();

  const { setDashboard } = useDashboard();

  return (
    <Box className={classes.boxWelcomeContainer}>
      <Box className={classes.boxImageContainer}>
        <Box className={classes.boxImageEffect}>
          <img src={welcome} alt="Welcome" className={classes.img} />
        </Box>
      </Box>

      <Box className={classes.boxContentContainer}>
        <Box className={classes.boxContent}>
          <Box>
            <Typography variant="h1" className={classes.title}>
              Create
            </Typography>

            <Typography variant="h1" className={classes.title}>
              Publish
            </Typography>
            <Typography variant="h1" className={classes.title}>
              Manage
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2" className={classes.subTitle}>
              When you create a project we generate a basic version of your
              privacy policy, so you can use it right away.
            </Typography>

            <Typography variant="body2" className={classes.subTitle}>
              Then, you can build compliance step by step by updating the
              document with your information, at any time.
            </Typography>
          </Box>

          <Button
            variant="contained"
            underline="none"
            color="inherit"
            onClick={() => {
              setDashboard({ newProjectModal: true });
            }}
            disableRipple
            disableFocusRipple
            disableElevation
            className={classes.button}
          >
            New project
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Welcome;
