import React, { useState, useEffect, useRef, useCallback } from "react";

import {
  Box,
  Grid,
  Container,
  Typography,
  makeStyles,
  Link,
} from "@material-ui/core";

import { useApolloClient } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import { gql, useMutation } from "@apollo/client";

import { logout } from "../../utils";

import { Header } from "./index";

import {
  useAuthentication,
  useDashboard,
  useProject,
  useProjectCategories,
  useProjectPrivacyPolicy,
  useUser,
  useSpam,
} from "../../contexts";

const stopCounter = 0;

const startCounter = 10;

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    height: "auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(3),
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "auto",
    width: "100%",
    boxSizing: "border-box",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  gridErrorEmailChangeConfirmation: {
    padding: "0px",
  },
  gridItemErrorEmailChangeConfirmation: {},
  gridNoCodeFound: {},
  gridItemNoCodeFound: {},
  gridConfirming: {},
  gridItemConfirming: {},
  colorInfoMain: {
    color: theme.palette.secondary.main,
  },
  colorLink: {
    color: theme.palette.secondary.main,
  },
}));

const CHANGE_EMAIL_UPDATE = gql`
  mutation ChangeEmailUpdate($code: String!) {
    changeEmailUpdate(code: $code)
  }
`;

const ChangeEmailConfirmation = ({ queryString }) => {
  const classes = useStyles();

  const { setAuthentication } = useAuthentication();

  const { setDashboard } = useDashboard();

  const { setProject } = useProject();

  const { setProjectPrivacyPolicy } = useProjectPrivacyPolicy();

  const { setProjectCategories } = useProjectCategories();

  const { setUser } = useUser();

  const { setSpam } = useSpam();

  const client = useApolloClient();

  // eslint-disable-next-line
  const [emailConfirmed, setEmailConfirmed] = useState(false);

  const [
    errorChangeEmailConfirmation,
    // eslint-disable-next-line
    setErrorChangeEmailConfirmation,
  ] = useState(false);

  const navigate = useNavigate();

  const [counter, setCounter] = useState(startCounter);

  const intervalId = useRef();

  const [
    changeEmailUpdate,
    // eslint-disable-next-line
    { data, loading, error, called },
  ] = useMutation(CHANGE_EMAIL_UPDATE);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setCounter((previous) => previous - 1);
    }, 1000);

    return () => clearInterval(intervalId.current);
  }, []);

  useEffect(() => {
    if (emailConfirmed && counter === stopCounter) {
      clearInterval(intervalId.current);

      navigate(`/login`);
    }

    // eslint-disable-next-line
  }, [counter]);

  const changeEmailConfirmation = useCallback(async (code) => {
    try {
      // eslint-disable-next-line
      const changeEmailConfirmationStatus = (
        await changeEmailUpdate({
          variables: {
            code,
          },
        })
      ).data.changeEmailUpdate;

      setEmailConfirmed(true);

      setErrorChangeEmailConfirmation(false);

      logout({
        client,
        setters: [
          setAuthentication,
          setDashboard,
          setProject,
          setProjectCategories,
          setUser,
          setSpam,
          setProjectPrivacyPolicy,
        ],
      });

      setCounter(startCounter);

      toast.success("Email confirmed");
    } catch (error) {
      setEmailConfirmed(false);

      setErrorChangeEmailConfirmation(true);

      clearInterval(intervalId.current);

      toast.error(error.message);
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (queryString.code) changeEmailConfirmation(queryString.code);
    // eslint-disable-next-line
  }, []);

  return (
    <Box className={classes.boxContainer}>
      <Container maxWidth="xs" className={classes.container}>
        <Header title="Email confirmation" />

        {!queryString.code && errorChangeEmailConfirmation === false && (
          <Grid
            container
            direction="column"
            alignItems="center"
            className={`${classes.grid} ${classes.gridNoCodeFound}`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItemNoCodeFound}
            >
              <Typography variant="body2">
                No code provided on the URL, try to open the email that was sent
                to you and click on the "confirm" button
              </Typography>
            </Grid>
          </Grid>
        )}

        {queryString.code && !errorChangeEmailConfirmation && !emailConfirmed && (
          <Grid
            container
            direction="column"
            alignItems="center"
            className={`${classes.grid} ${classes.gridConfirming}`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItemConfirming}
            >
              <Typography variant="body2">Confirming the email...</Typography>
            </Grid>
          </Grid>
        )}

        {emailConfirmed && !errorChangeEmailConfirmation && (
          <Grid
            container
            direction="column"
            alignItems="center"
            className={`${classes.grid} ${classes.gridConfirmRedirect}`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItemConfirmRedirect}
            >
              <Typography variant="body2">
                You're gonna be redirected to make login in {counter} seconds,
                or click{" "}
                <Link
                  underline="none"
                  href="/login"
                  className={classes.colorLink}
                >
                  here
                </Link>{" "}
                to be redirected now.
              </Typography>
            </Grid>
          </Grid>
        )}

        {errorChangeEmailConfirmation && (
          <Grid
            container
            direction="column"
            alignItems="center"
            className={`${classes.grid} ${classes.gridErrorEmailChangeConfirmation}`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItemErrorEmailChangeConfirmation}
            >
              <Typography variant="body2">
                It was not possible to confirm your email. Try to update the
                page, change your email again or contact us
              </Typography>
            </Grid>
          </Grid>
        )}

        {errorChangeEmailConfirmation && (
          <Typography variant="body2">
            <br />
            If you can't confirm your email try to clean your cache or a different email.
          </Typography>
        )}
      </Container>
    </Box>
  );
};

export default ChangeEmailConfirmation;
