import React from "react";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  deleteDialogPaper: {
    padding: theme.spacing(3),
    boxShadow: "none",
  },
  dialogDeleteTitle: {
    padding: theme.spacing(1, 0, 0, 0),
    color: theme.palette.common.black,
    fontWeight: "600",
    fontSize: "30px",
  },
  dialogDeleteContent: {
    padding: theme.spacing(1, 0),
    color: theme.palette.grayOne,
  },
  dialogDeleteActions: {
    marginTop: theme.spacing(1),
    padding: "0",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  dialogDeleteActionCancel: {
    fontWeight: "normal",
    padding: "5px",
  },
  dialogDeleteActionDelete: {
    fontWeight: "normal",
    padding: "5px",
    color: theme.palette.primary.main,
  },
}));

const DeleteDialog = ({
  resource,
  openStatus,
  closeAction,
  deleteAction,
  deleteContent = null,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={openStatus}
      onClose={closeAction}
      aria-labelledby={`form-dialog-${resource}`}
      classes={{ paper: classes.deleteDialogPaper }}
    >
      <DialogTitle className={classes.dialogDeleteTitle}>
        Delete {resource}
      </DialogTitle>

      <DialogContent className={classes.dialogDeleteContent}>
        <DialogContentText>
          Are you sure you want to delete your {resource}?
        </DialogContentText>
      </DialogContent>

      <DialogActions className={classes.dialogDeleteActions}>
        <Button
          variant="hovered-reversed-no-border"
          onClick={deleteAction}
          className={classes.dialogDeleteActionDelete}
        >
          {deleteContent ? deleteContent : "Delete"}
        </Button>

        <Button
          variant="hovered"
          onClick={closeAction}
          className={classes.dialogDeleteActionCancel}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
