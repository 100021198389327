import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { GetStarted } from "../general";

import paperwork from "../../assets/images/home/paperwork.svg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(8),
    position: "relative",
    minHeight: "700px",
    background: theme.palette.gradient,
    [theme.breakpoints.down("md")]: {
      "&": {
        flexDirection: "column",
        marginBottom: theme.spacing(8),
        marginTop: theme.spacing(5),
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "750px",
        justifyContent: "space-evenly",
      },
    },
  },
  boxIllustration: {
    height: "625px",
    width: "625px",
    maxWidth: "100%",
    backgroundImage: `url(${paperwork})`,
    backgroundAttachment: "scroll",
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "90%",
        height: "300px",
        backgroundSize: "75%",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        backgroundSize: "90%",
      },
    },
  },
  boxContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "65%",
    maxWidth: "35%",
    marginLeft: theme.spacing(18),
    [theme.breakpoints.down("md")]: {
      "&": {
        maxWidth: "65%",
        marginLeft: "0",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        maxWidth: "85%",
      },
    },
  },
  boxText: {
    marginBottom: theme.spacing(6),
  },
  title: {
    fontSize: "3rem",
    fontWeight: "700",
    color: theme.palette.blackOne,
    marginBottom: theme.spacing(3),
  },
  description: {
    fontSize: "1.5rem",
    color: theme.palette.grayOne,
  },
  textStyle: {
    "&::after": {
      content: '""',
      display: "block",
      width: "360px",
      height: "34px",
      marginTop: "-58px",
      borderBottom: `12px solid ${theme.palette.secondary.soft}`,
    },
    [theme.breakpoints.down("sm")]: {
      "&::after": {
        width: "220px",
      },
    },
  },
}));

const Paperwork = () => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxIllustration}></Box>

      <Box className={classes.boxContent}>
        <Box className={classes.boxText}>
          <Typography className={classes.title}>
            Tailored to your
            <br />
            <span className={classes.textStyle}>business requirements</span>
          </Typography>

          <Typography className={classes.description}>
            You can customize it based on your business’
            <br /> operations and update it anytime
          </Typography>
        </Box>

        <GetStarted text="Get started - Free" styles={{ width: "150px" }} />
      </Box>
    </Box>
  );
};

export default Paperwork;
