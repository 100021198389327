import React, { useState, useEffect } from "react";

import { Helmet } from "react-helmet";

import moment from "moment";

import {
  Grid,
  Box,
  makeStyles,
  Typography,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  Chip,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Button,
} from "@material-ui/core";

import { useNavigate } from "react-router-dom";

import { HashLink } from "react-router-hash-link";

import gql from "graphql-tag";

import { useQuery } from "@apollo/client";

import {
  ExpandMore as ExpandMoreIcon,
  ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";

import { thirdParties } from "../../data";

import { LoadingPage } from "../general";

import logoDark from "../../assets/images/home/logoDark.svg";

// 2.
import { ReactComponent as RightAccess } from "../../assets/images/privacyPolicy/rightAccess.svg";

import { ReactComponent as RightRequest } from "../../assets/images/privacyPolicy/rightRequest.svg";

import { ReactComponent as RightForgotten } from "../../assets/images/privacyPolicy/rightForgotten.svg";

import { ReactComponent as RightAsk } from "../../assets/images/privacyPolicy/rightAsk.svg";

import { ReactComponent as RightTransfer } from "../../assets/images/privacyPolicy/rightTransfer.svg";

import { ReactComponent as RightObject } from "../../assets/images/privacyPolicy/rightObject.svg";

import { ReactComponent as RightComplaint } from "../../assets/images/privacyPolicy/rightComplaint.svg";

import { ReactComponent as RightSubjected } from "../../assets/images/privacyPolicy/rightSubjected.svg";

// 3
import { ReactComponent as WhatDataWeCollectThirdParty } from "../../assets/images/privacyPolicy/whatDataWeCollectThirdParty.svg";

import { ReactComponent as WhatDataWeCollectLocal } from "../../assets/images/privacyPolicy/whatDataWeCollectLocal.svg";

// 4 & 6
import { ReactComponent as AccessConnectivity } from "../../assets/images/privacyPolicy/accessConnectivity.svg";

import { ReactComponent as Advertising } from "../../assets/images/privacyPolicy/advertising.svg";

import { ReactComponent as Analytics } from "../../assets/images/privacyPolicy/analytics.svg";

import { ReactComponent as CustomerSupport } from "../../assets/images/privacyPolicy/customerSupport.svg";

// import { ReactComponent as DevicePermission } from "../../assets/images/privacyPolicy/devicePermission.svg";

import { ReactComponent as Infrastructure } from "../../assets/images/privacyPolicy/infrastructure.svg";

import { ReactComponent as InteractionExternal } from "../../assets/images/privacyPolicy/interactionExternal.svg";

import { ReactComponent as Marketing } from "../../assets/images/privacyPolicy/marketing.svg";

import { ReactComponent as Payments } from "../../assets/images/privacyPolicy/payments.svg";

// 5
import { ReactComponent as PersonalData } from "../../assets/images/privacyPolicy/personalData.svg";

import { ReactComponent as LevelProtection } from "../../assets/images/privacyPolicy/levelProtection.svg";

// 7
import { ReactComponent as HowLongKeepData } from "../../assets/images/privacyPolicy/howLongKeepData.svg";

import { ReactComponent as HowLongKeepDataSafe } from "../../assets/images/privacyPolicy/howLongKeepDataSafe.svg";

// Error
import errorOops from "../../assets/images/privacyPolicy/oops.svg";

const defaultMargin = "20px";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "::-webkit-scrollbar": {
      width: (props) => {
        return props.colors?.scroll !== "transparent" ? "5px" : "0";
      },
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      transition: "all 1s ease-in-out 0s",
      background: (props) => props.colors?.scroll,
    },
    "::-webkit-scrollbar-track": {},
    "::-webkit-scrollbar-thumb:hover": {},
    html: {
      // position: "static"
    },
    body: {
      height: "100vh",
      width: "100vw",
      margin: "0",
      padding: "0",
      boxSizing: "border-box",
      overflow: "scroll",
    },
    '[class*="MuiTypography-body"]': {
      fontFamily: "Muli, Arial, sans-serif",
      color: theme.palette.common.black,
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "16px",
      lineHeight: "175%",
      letterSpacing: ".01em",
    },
    ".MuiAccordionDetails-root": {
      display: "flex",
    },
    ".MuiAccordionSummary-content": {
      margin: "6px 0",
    },
    ".MuiListItem-root": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    ".ppIconSvg": {
      width: "32px",
      minWidth: "32px",
      height: "32px",
      minHeight: "32px",
    },
    ".ppIconSvg path": {
      fill: (props) => props.colors?.icon,
      width: "32px",
      height: "32px",
    },
  },
  boxIntroTitleHeader: {
    textAlign: "left",
    color: theme.palette.common.black,
    fontWeight: "900",
    fontSize: "48px",
    lineHeight: "155%",
  },
  boxWoWeAreContentTitle: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "155%",
  },
  noteText: {
    fontWeight: "300",
    fontSize: "14px",
  },
  noteTitle: {
    fontWeight: "700",
    textTransform: "uppercase",
    color: (props) => props.colors?.main,
    letterSpacing: "0.05em",
  },
  explanationTitle: {
    textTransform: "uppercase",
    color: (props) => props.colors?.main,
  },
  listItemText: {
    paddingRight: "8px",
  },
  tableTextColor: {
    color: theme.palette.privacyPolicyBlue,
  },
  gridGlobal: {
    margin: "0 auto",
    width: (props) => (props?.overrides?.disablePadding ? "100%" : "85%"),
    padding: (props) => (props?.overrides?.disablePadding ? "0" : "0 100px"),
    boxSizing: "border-box",
    "& > *": {
      width: "100%",
      height: "100%",
    },
    "& > *:not(:first-child)": {
      margin: `${defaultMargin} 0`,
    },
    // prettier-ignore
    ["@media (max-width:1700px)"]: { // eslint-disable-line no-useless-computed-key
	  width: (props) => props?.overrides?.disablePadding ? "100%" : "95%",
	  padding: (props) => props?.overrides?.disablePadding ? "0" : "0 95px",
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "100%",
        // prettier-ignore
        padding: (props) => props?.overrides?.disablePadding ? "0" : "0 80px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: (props) => (props?.overrides?.disablePadding ? "0" : "0 20px"),
      },
    },
  },
  subSectionNumber: {
    padding: "0 20px",
  },
  subSectionNumberList: {
    display: "inline-block",
    width: "10px",
    marginRight: "20px",
  },
  indentationLevel1: {
    boxSizing: "border-box",
    paddingLeft: "27px",
    paddingRight: "48px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 0px 5px",
      },
    },
  },
  indentationLevel1List: {
    boxSizing: "border-box",
    paddingLeft: "32px",
    paddingRight: "48px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 0px 30px",
      },
    },
  },
  indentationLevel2: {
    boxSizing: "border-box",
    paddingLeft: "126px",
    paddingRight: "48px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 0px 56px",
      },
    },
  },
  indentationLevel2Inline: {
    boxSizing: "border-box",
    paddingLeft: "128px",
    paddingRight: "48px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 0px 55px",
      },
    },
  },
  indentationLevel2List: {
    boxSizing: "border-box",
    paddingLeft: "62px",
    paddingRight: "88px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 0px 30px",
      },
    },
  },
  indentationLevel3: {
    boxSizing: "border-box",
    paddingLeft: "146px",
    paddingRight: "48px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 0px 77px",
      },
    },
  },
  containerTitle: {
    height: "auto",
    display: "flex",
    alignItems: "start",
    boxSizing: "border-box",
    paddingLeft: "25px",
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 10px 0px",
      },
    },
  },
  containerTitleInlineSimple: {
    display: "inline-flex",
  },
  containerTitleInline: {
    height: "auto",
    alignItems: "start",
    boxSizing: "border-box",
    paddingLeft: "27px",
    paddingBottom: "10px",
    display: "inline-flex",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 10px 0px",
      },
    },
  },
  gutters: {
    boxSizing: "border-box",
    paddingLeft: "0",
    paddingRight: "20px",
    // paddingTop: "0px",
  },
  gridItemIntro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    minHeight: "425px",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        minHeight: "500px",
      },
    },
  },
  gridItemDrawer: {
    background: "transparent",
  },
  boxIntroTitle: {
    flex: ".5",
  },
  boxIntroTitleBack: {
    marginTop: "18px",
  },
  boxIntroDescription: {
    flex: ".35",
  },
  sectionHeaderTitle: {
    color: theme.palette.common.black,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "24px",
    lineHeight: "155%",
    letterSpacing: "0.01em",
    marginBottom: "20px",
  },
  gridWhoWeAre: {
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    minHeight: "550px",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "stretch",
    },
  },
  gridWhoWeAreContent: {
    height: (props) => (props?.overrides?.disablePadding ? "360px" : "500px"),
    justifyContent: "space-evenly",
    "& > *": {
      flex: "1",
    },
    [theme.breakpoints.down("lg")]: {
      "& > *:nth-child(2)": {
        paddingTop: "25px",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        // prettier-ignore
        height: (props) => props?.overrides?.disablePadding ? "360px" : "600px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        // prettier-ignore
        height: (props) => props?.overrides?.disablePadding ? "360px" : "700px",
      },
    },
  },
  boxWoWeAreContent: {
    flex: "2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    background: theme.palette.graySix,
    boxSizing: "border-box",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    borderTop: (props) => `4px solid ${props.colors?.main}`,
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "260px",
      },
    },
  },
  boxWoWeAreOptout: {
    boxSizing: "border-box",
    paddingTop: "60px",
    paddingBottom: "25px",
  },
  gridWhoWeAreNote: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    [theme.breakpoints.down("lg")]: {
      "&": {
        display: "none",
      },
    },
  },
  boxWoWeAreNote: {
    borderRadius: "4px",
    width: "280px",
    height: "454px",
    display: "flex",
    padding: theme.spacing(1),
    flexDirection: "column",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.graySix}`,
  },
  gridYourRights: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
    },
  },
  gridItemYourRightsContent: {},
  gridItemYourRightsContentContainer: {
    boxSizing: "border-box",
  },
  gridItemYourRightsContentContainerExtraInfo: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "30px",
    background: theme.palette.graySix,
    borderLeft: (props) => `2px solid ${props.colors?.main}`,
    marginTop: "25px",
  },
  gridItemYourRightsNote: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    [theme.breakpoints.down("lg")]: {
      "&": {
        display: "none",
      },
    },
  },
  boxYourRightsNote: {
    borderRadius: "4px",
    minHeight: "250px",
    width: "280px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "8px",
    border: `1px solid ${theme.palette.graySix}`,
  },
  gridWhatDataWeCollect: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
    },
  },
  gridItemWhatDataWeCollectContent: {
    "& > *:first-child": {
      width: "100%",
      paddingTop: "0",
      "& > *:first-child": {
        paddingTop: "0",
      },
    },
  },
  gridItemWhatDataWeCollectNote: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    [theme.breakpoints.down("lg")]: {
      "&": {
        display: "none",
      },
    },
  },
  boxItemWhatDataWeCollectNote: {
    borderRadius: "4px",
    minHeight: "225px",
    width: "280px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.graySix}`,
  },
  gridFinalWords: {
    minHeight: "300px",
    [theme.breakpoints.down("md")]: {
      "&": {
        minHeight: "450px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "600px",
      },
    },
  },
  gridWhyHowUseData: {
    height: "auto",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "start",
      alignItems: "center",
    },
  },
  gridItemWhyHowUseDataContent: {
    "& > *:first-child": {
      paddingTop: "0",
    },
  },
  gridItemWhyHowUseDataContentContainer: {},
  gridItemWhyHowUseDataContentContainerItemList: { marginTop: "20px" },
  gridItemWhyHowUseDataNote: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    [theme.breakpoints.down("lg")]: {
      "&": {
        display: "none",
      },
    },
  },
  boxWhyHowUseDataNote: {
    borderRadius: "4px",
    minHeight: "195px",
    width: "280px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.graySix}`,
  },
  gridWhereProcessData: {},
  gridItemWhereProcessDataContent: {},
  gridItemWhereProcessDataContentContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gridItemWhereProcessDataNote: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    [theme.breakpoints.down("lg")]: {
      "&": {
        display: "none",
      },
    },
  },
  boxWhereProcessDataNote: {
    borderRadius: "4px",
    minHeight: "230px",
    width: "280px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.graySix}`,
  },
  gridHowLongKeepData: {},
  gridItemHowLongKeepDataContent: {},
  gridItemHowLongKeepDataContentContainer: {},
  gridItemHowLongKeepDataNote: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    [theme.breakpoints.down("lg")]: {
      "&": {
        display: "none",
      },
    },
  },
  boxHowLongKeepDataNote: {
    borderRadius: "4px",
    minHeight: "200px",
    width: "280px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.graySix}`,
  },
  gridThirdParties: {},
  gridItemThirdPartiesContent: {},
  gridItemThirdPartiesContentContainer: {
    "& > *:first-child": {
      marginBottom: "20px",
    },
  },
  gridItemThirdPartiesNote: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "flex-end",
    [theme.breakpoints.down("lg")]: {
      "&": {
        display: "none",
      },
    },
  },
  boxThirdPartiesNote: {
    borderRadius: "4px",
    minHeight: "250px",
    width: "280px",
    padding: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.graySix}`,
  },
  gridItemExplanation: {
    padding: "25px 0px 0px 0px",
  },
  expandPainelExplanationActive: {
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.graySix}`,
    borderRadius: "4px",
  },
  expandPainelExplanation: {
    padding: theme.spacing(1),
    border: `1px solid transparent`,
    borderRadius: "4px",
  },
  gridExplanation: {
    display: "flex",
    justifyContent: "space-around",
  },
  AccordionSummary: {
    padding: "0px",
  },
  AccordionSummaryClosed: {
    padding: "0px",
    cursor: "default !important",
  },
  AccordionSummarySubsection: {
    padding: "0px",
  },
  expandIconDefaultRotation: {
    transform: "rotate(180deg)",
  },
  expandIconActiveRotation: {
    transform: "rotate(360deg)",
  },
  expandContent: {
    borderBottom: `1px solid ${theme.palette.graySix}`,
  },
  chips: {
    display: "inline-flex",
    boxSizing: "border-box",
    flexWrap: "wrap",
    "& > *": {
      textTransform: "capitalize",
      border: `1px solid ${theme.palette.graySix}`,
      background: theme.palette.common.white,
      margin: "5px 4px",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "700",
      "&:hover": {
        color: theme.palette.common.white,
        background: (props) => props.colors?.main,
      },
    },
  },
  atomicChip: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.graySix}`,
    marginBottom: "15px",
    marginTop: "40px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "700",
    "&:hover": {
      background: theme.palette.common.white,
    },
  },
  boxChip: {
    display: "flex",
    justifyContent: "start",
    alignItems: "flex-start",
    marginTop: "15px",
    // flexWrap: "wrap",
    "& > *:first-child": {
      display: "ruby",
      marginTop: "5px",
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        flexWrap: "wrap",
      },
    },
  },
  colorLink: {
    color: (props) => props.colors?.link,
  },
  img: {
    width: "35px",
  },
  bold: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "200%",
    letterSpacing: "0.01em",
    boxSizing: "border-box",
  },
  listSubtitle: {
    "& > *": {
      display: "block",
    },
  },
  AccordionDetails: {
    padding: "0",
  },
  AccordionDetailsSubsection: {
    padding: "0",
    display: "flex",
    flexDirection: "column",
    marginBottom: "24px",
    "& > *:last-child": {
      marginBottom: "0px",
    },
  },
  anchor: {
    top: "86px",
    display: "block",
    position: "relative",
    visibility: "hidden",
  },
  listRoot: {
    "& > *": {
      listStyleType: "disc",
      listStylePosition: "inside",
      textIndent: "-1.5em",
      paddingLeft: "2.7em !important",
    },
  },
  listRootNote: {
    "& > *": {
      listStyleType: "disc",
      listStylePosition: "inside",
      textIndent: "-.9em",
      paddingLeft: "2.5em !important",
    },
  },
  listRootLetter: {
    "& > *": {
      listStyleType: "disc",
      listStylePosition: "inside",
      textIndent: "-2em",
      paddingLeft: "2.5em !important",
    },
  },
  tableContainer: {
    borderRadius: "4px",
    background: theme.palette.graySix,
  },
  table: {},
  tableHead: {
    border: `3px solid ${theme.palette.graySix}`,
    background: theme.palette.common.white,
    height: "160px",
  },
  rowContentHead: {},
  tableHeadCell: {
    color: (props) => props.colors?.main,
    fontFamily: "Muli, Arial, sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "155%",
    textAlign: "center",
    borderRight: `3px solid ${theme.palette.graySix}`,
    "&:last-child": {
      borderRight: `0px solid ${theme.palette.graySix}`,
    },
  },
  rowContentBody: {
    borderBottom: `3px solid ${theme.palette.common.white}`,
  },
  tableBodyCellIcon: {
    textAlign: "center",
    borderRight: `3px solid ${theme.palette.common.white}`,
  },
  tableBodyCellProvider: {
    textAlign: "center",
    borderRight: `3px solid ${theme.palette.common.white}`,
    maxWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        minWidth: "150px",
      },
    },
  },
  tableBodyCellList: {
    borderRight: `3px solid ${theme.palette.common.white}`,
    [theme.breakpoints.down("sm")]: {
      "&": {
        minWidth: "300px",
      },
    },
  },
  tableBodyCellPlace: {
    minWidth: "30px",
    textAlign: "center",
  },
  containerError: {
    height: "100vh",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "stretch",
    "& > *": {
      flex: "1",
    },
  },
  containerErrorLogo: {
    padding: theme.spacing(1, 2, 2),
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      "&": {
        justifyContent: "center",
        flex: ".6",
      },
    },
  },
  errorBanner: {
    width: "100%",
    maxWidth: "740px",
  },
  containerErrorText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
  },
  subContainerErrorText: {
    boxSizing: "border-box",
    padding: theme.spacing(1, 2, 2),
    background: theme.palette.common.white,
    position: "absolute",
    maxWidth: "650px",
    width: "100%",
    margin: "-80px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "Roboto",
    fontStyle: "normal",
    "& > *": {
      marginTop: "35px",
      marginBottom: "10px",
    },
    "& > *:first-child": {
      marginTop: "0",
    },
    "& > *:last-child": {
      marginTop: "0",
      marginBottom: "0",
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        maxWidth: "550px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        maxWidth: "450px",
        marginBottom: "35px",
      },
    },
  },
  errorTextTitle: {
    textAlign: "left",
    color: theme.palette.primary.light,
    fontWeight: "bold",
    fontSize: "36px",
  },
  errorText: {
    color: theme.palette.dashboardBlackLight,
    fontWeight: "300",
    fontSize: "16px",
  },
  errorLink: {
    fontWeight: "500",
  },
  errorTextThankYou: {
    color: theme.palette.privacyPolicyBlueSoft,
  },
  loadingPage: {
    color: theme.palette.privacyPolicyBlueDark,
  },
  boxContainerPoweredBy: {
    minHeight: "75px",
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
    display: "flex",
    alignItems: "center",
    justifyItems: "center",
    [theme.breakpoints.down("sm")]: {
      "&": {
        paddingLeft: "0",
      },
    },
  },
  textPoweredBy: {
    color: theme.palette.blackOne,
    marginRight: theme.spacing(1),
  },
  linkPoweredBy: {},
  imagePoweredBy: {
    width: "110px",
    height: "25px",
    marginBottom: "-5px",
  },
}));

// Utils
const toggleAllSubSections = (setter, values) => {
  setter(
    Object.assign(
      {},
      ...Object.entries(values).map(([key, value]) => {
        return { [key]: values.clicked ? false : true };
      })
    )
  );
};

const openSpecificSubSection = (setter, values, changes) => {
  setter({ ...values, ...changes });
};

const getAttributeProject = (objectsList, attribute) => {
  return Array.isArray(objectsList) && objectsList.length
    ? objectsList[0][attribute]
    : `not found or invalid ${attribute}`;
};

const getSectionQuestion = (section, identify) => {
  const result = section.find((item) => item.identify === identify);

  return result ? result : {};
};

const getSectionQuestionListText = (
  section,
  identify,
  attribute = "conditionText"
) => {
  const question = getSectionQuestion(section, identify);

  // Check if the question was found, if the question has condition text
  // instead of condition, if the condition has at least one option
  // and if the option is "none of the above", which implies that
  // the list of condition text will not be rendered
  if (
    !Object.keys(question).length ||
    !question[attribute] ||
    !question[attribute].length ||
    question[attribute].includes("none of the above")
  )
    return [];

  return question[attribute];
};

const unionThirdParties = (section) => {
  let thirdPartiesMatched = [];

  for (let question of section) {
    if (question.thirdParties.length) {
      for (let thirdParty of question.thirdParties) {
        // Third party matched from the list of third parties,
        // if a match happens using the name
        //
        // The third parties object bellow is imported
        const thirdPartyMatch = thirdParties.find(
          (item) =>
            thirdParty.thirdPartyName.toLowerCase().trim() ===
            item.providerName.toLowerCase().trim()
        );

        if (thirdPartyMatch) {
          // Check for duplications
          if (
            thirdPartiesMatched.some((item) => {
              return item.providerName === thirdPartyMatch.providerName;
            })
          )
            continue;

          thirdPartiesMatched.push(thirdPartyMatch);
        }
      }
    }
  }

  return thirdPartiesMatched;
};

const mergeListOfObjects = (list) => {
  return list.reduce((accumulation, current) => {
    for (let key in current) {
      if (key in accumulation) {
        accumulation[key] = [
          ...new Set([...accumulation[key], ...current[key]]),
        ];
      } else {
        accumulation[key] = current[key];
      }
    }

    return accumulation;
  }, {});
};

const unionPersonalData = (section) => {
  const accumulation = {};

  for (let personalData of section.map((question) => question.personalData)) {
    for (let personalDataObject of personalData) {
      if (!(personalDataObject.tag.toLowerCase() in accumulation)) {
        accumulation[personalDataObject.tag.toLowerCase()] = [
          personalDataObject.dataType.toLowerCase(),
        ];
      } else {
        if (
          !(
            personalDataObject.dataType.toLowerCase() in
            accumulation[personalDataObject.tag.toLowerCase()]
          )
        )
          accumulation[personalDataObject.tag.toLowerCase()].push(
            personalDataObject.dataType.toLowerCase()
          );
      }
    }
  }
  return accumulation;
};

const reduceSectionCondition = (section) => {
  return section.reduce((sum, current) => sum || current.condition, false);
};

const unionLegalBasis = (section) => {
  return Array.from(
    new Set(
      section.flatMap((question) =>
        question.legalBasis.map((legalBase) => legalBase.toLowerCase())
      )
    )
  );
};

const anchorTips = (section, code, setExplanationStatus) => {
  // The static anchor is a unique anchor that is present at the drawer summary
  // and the daynamic anchor (s) are set inside the drawer body, which can cause
  // the scroll to lost its reference, once when the click happens the drawer body
  // is expanded

  return section.map((legalBase) => (
    <Chip
      label={legalBase}
      onClick={() => setExplanationStatus(true)}
      component={HashLink}
      smooth
      key={legalBase.split(" ").join("-")}
      // Static anchor
      to={`/privacy-policy?code=${code}#legalBasis`}
      // Daynamic anchor (s)
      // to={`/privacy-policy?code=${code}#${legalBase.split(" ").join("-")}`}
    />
  ));
};

// PP sections
const IntroSection = ({ data }) => {
  const classes = useStyles({ colors: data.colors });

  const navigate = useNavigate();

  const projectUrl = getAttributeProject(data.project, "url");

  return (
    <>
      <Box className={classes.boxIntroTitle}>
        {window.location.href.includes(
          process.env.REACT_APP_PRIVACY_POLICY_CODE
        ) ? (
          <Button
            variant="hovered-reversed-no-border"
            startIcon={<ArrowBackIcon />}
            onClick={() => navigate("/")}
            disableElevation
            disableFocusRipple
            disableRipple
            className={classes.boxIntroTitleBack}
          >
            Back
          </Button>
        ) : null}

        <Typography variant="body2" className={classes.boxIntroTitleHeader}>
          Privacy Policy
        </Typography>

        <Typography variant="body2" className={classes.indentationLevel1}>
          Last updated:{" "}
          <span className={classes.bold}>
            {moment(data.updatedAt, "x").format("MMMM Do YYYY")}
          </span>
        </Typography>
      </Box>

      <Box
        className={`${classes.boxIntroDescription} ${classes.indentationLevel1}`}
      >
        <Typography variant="body2">
          Welcome to{" "}
          <Link
            href={projectUrl}
            target="_blank"
            rel="noreferrer"
            className={classes.colorLink}
          >
            {projectUrl}
          </Link>
          's Privacy Policy. Thank you for being interested in privacy!
        </Typography>

        <Typography variant="body2">
          If you have any questions related to this Privacy Policy or generally
          to the way in which we protect your data, please contact us at the
          contact details provided below.
        </Typography>
      </Box>
    </>
  );
};

const WhoWeAreSection = ({ data }) => {
  const classes = useStyles({ colors: data.colors });

  const dataStorageTransfersTwo = getSectionQuestion(
    data.dataStorageTransfers,
    "2"
  );

  const projectLegalName = getAttributeProject(data.project, "legalName");

  const projectFirstName = getAttributeProject(data.project, "firstName");

  const projectLastName = getAttributeProject(data.project, "lastName");

  const projectAddress = getAttributeProject(data.project, "address");

  const projectState = getAttributeProject(data.project, "state");

  const projectCountry = getAttributeProject(data.project, "country");

  const projectPostalCode = getAttributeProject(data.project, "postalCode");

  const projectEmail = getAttributeProject(data.project, "email");

  const projectUrl = getAttributeProject(data.project, "url");

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      square={true}
      elevation={0}
    >
      <AccordionSummary
        classes={{
          root: classes.AccordionSummaryClosed,
          content: classes.expandContent,
        }}
        aria-controls="panel1-control"
        id="panel1-id"
      >
        <Typography variant="h2" className={classes.sectionHeaderTitle}>
          1. Who we are
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.AccordionDetails }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.gridWhoWeAre}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={8}
            className={classes.gridWhoWeAreContent}
          >
            <Typography variant="body2" className={classes.indentationLevel1}>
              We are responsible for the processing of your personal data. By
              accessing and using our services, you give your acceptance to the
              terms of this Privacy Policy. If you do not agree with or you are
              not comfortable with any aspect of this policy, please discontinue
              the access and the use of our services.
            </Typography>

            <Typography variant="body2" className={classes.indentationLevel1}>
              For any kind of query feel free to contact us. Below you find our
              contact details:
            </Typography>

            <Box className={classes.boxWoWeAreContent}>
              <Typography
                variant="body2"
                className={`${classes.boxWoWeAreContentTitle} ${classes.indentationLevel1}`}
              >
                {projectLegalName && projectLegalName}
                {projectFirstName && projectFirstName}{" "}
                {projectLastName && projectLastName}
              </Typography>

              {projectAddress ||
              projectState ||
              projectCountry ||
              projectPostalCode ? (
                <Box>
                  <Typography
                    variant="body2"
                    className={classes.indentationLevel1}
                  >
                    {projectAddress}
                  </Typography>

                  <Typography
                    variant="body2"
                    className={classes.indentationLevel1}
                  >
                    {`${projectState} ${
                      projectState ? "," : ""
                    } ${projectCountry}`}
                  </Typography>

                  <Typography
                    variant="body2"
                    className={classes.indentationLevel1}
                  >
                    {projectPostalCode}
                  </Typography>
                </Box>
              ) : null}

              <Box>
                <Typography
                  variant="body2"
                  className={classes.indentationLevel1}
                >
                  For privacy inquiries please contact us at:
                </Typography>

                <Typography
                  variant="body2"
                  className={classes.indentationLevel1}
                >
                  <Link
                    href={"mailto:" + projectEmail}
                    target="_top"
                    className={classes.colorLink}
                  >
                    {projectEmail}
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            className={classes.gridWhoWeAreNote}
          >
            <Box
              display="flex"
              flexDirection="column"
              p={2}
              alignItems="stretch"
              className={classes.boxWoWeAreNote}
            >
              <Typography
                variant="body2"
                className={`${classes.noteTitle} ${classes.noteText}`}
              >
                Welcome!
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                Here you will find our Privacy Policy resumed in plain language.
                This is not legally binding and it's purpose is to help you
                understand.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                Protecting your privacy is really important to us.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                <span className={classes.bold}>Our responsibility:</span>
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                To respect this Privacy Policy and the applicable legislation.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                <span className={classes.bold}>Your responsibility:</span>
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                To read this Privacy Policy.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                As is standard, your continued use of{" "}
                <Link
                  href={projectUrl}
                  target="_blank"
                  rel="noreferrer"
                  className={classes.colorLink}
                >
                  {projectUrl}
                </Link>{" "}
                means that you agree with this policy.
              </Typography>
            </Box>
          </Grid>

          {!!Object.keys(dataStorageTransfersTwo).length &&
            dataStorageTransfersTwo.url && (
              <Grid item xs={12} sm={12} md={11} lg={8}>
                <Box className={classes.boxWoWeAreOptout}>
                  <Typography
                    variant="body2"
                    className={classes.indentationLevel1}
                  >
                    Please follow the link below if you'd like to opt out from
                    our advertising and marketing activities:{" "}
                    <Link
                      href={dataStorageTransfersTwo.url}
                      target="_blank"
                      rel="noreferrer"
                      className={classes.colorLink}
                    >
                      {dataStorageTransfersTwo.url}
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const WhatAreYouRightsSection = ({ data }) => {
  const classes = useStyles({ colors: data.colors });

  const [subSectionsStatus, setSubSectionsStatus] = useState({
    clicked: false,
    2.1: false,
    2.2: false, // Only used to show the order on the interface
    2.3: false,
    2.4: false,
    2.5: false,
    2.6: false,
    2.7: false,
    2.8: false,
  });

  // eslint-disable-next-line
  let [clicked, ...sectionIdentifies] = Object.keys(subSectionsStatus);

  const generalQuestionsFour = getSectionQuestion(data.generalQuestions, "4");

  const generalQuestionsFourOneListText = getSectionQuestionListText(
    data.generalQuestions,
    "4.1"
  );

  const generalQuestionsFourOneDecisionsOptions = [
    "specific consent",
    "necessity of entering or performing a contract",
  ];

  const generalQuestionsFourOneDecisions =
    generalQuestionsFourOneListText.length &&
    generalQuestionsFourOneListText.some((item) =>
      generalQuestionsFourOneDecisionsOptions.includes(item)
    );

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      square={true}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            className={
              subSectionsStatus.clicked === true
                ? classes.expandIconActiveRotation
                : classes.expandIconDefaultRotation
            }
          />
        }
        classes={{
          root: classes.AccordionSummary,
          content: classes.expandContent,
        }}
        onClick={() =>
          toggleAllSubSections(setSubSectionsStatus, subSectionsStatus)
        }
        aria-controls="panel1-control"
        id="panel1-id"
      >
        <Typography variant="h2" className={classes.sectionHeaderTitle}>
          2. What are your rights
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.AccordionDetails }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.gridYourRights}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={8}
            className={classes.gridItemYourRightsContent}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={classes.gridItemYourRightsContentContainer}
            >
              {/* 2.1 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  expanded={subSectionsStatus["2.1"]}
                  square={true}
                  elevation={0}
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 2.1: !subSectionsStatus["2.1"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box className={classes.containerTitle}>
                      <RightAccess className="ppIconSvg" />

                      <Typography variant="body2">
                        <span
                          className={`${classes.bold} ${classes.subSectionNumber}`}
                        >
                          {sectionIdentifies.shift()}
                        </span>{" "}
                      </Typography>

                      <Typography variant="body2">
                        You have the{" "}
                        <span className={classes.bold}>
                          right to access your data
                        </span>
                        .
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2}
                    >
                      This allows you to obtain information about:
                    </Typography>

                    <List
                      className={classes.indentationLevel3}
                      classes={{ root: classes.listRoot }}
                    >
                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          the purpose of data processing;
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          the categories of data we are processing;
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          the third parties with whom the data may be disclosed;
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          how long the data will be stored or the criteria used
                          to determine that period;
                        </Typography>
                      </ListItem>
                    </List>

                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2}
                    >
                      We will provide you with the information within one month
                      of your request, unless doing so would adversely affect
                      the rights and freedoms of others (e.g. another person’s
                      confidentiality or intellectual property rights). We will
                      tell you if we can’t meet your request for that reason.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 2.2 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  expanded={false}
                  square={true}
                  elevation={0}
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummaryClosed,
                    }}
                    aria-controls="panel7-control"
                    id="panel7-id"
                  >
                    <Box className={classes.containerTitle}>
                      <RightRequest className="ppIconSvg" />

                      <Typography variant="body2">
                        <span
                          className={`${classes.bold} ${classes.subSectionNumber}`}
                        >
                          {sectionIdentifies.shift()}
                        </span>{" "}
                      </Typography>

                      <Typography variant="body2">
                        You have the{" "}
                        <span className={classes.bold}>
                          right to request us to modify your data
                        </span>
                        .
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    .
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 2.3 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  expanded={subSectionsStatus["2.3"]}
                  square={true}
                  elevation={0}
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 2.3: !subSectionsStatus["2.3"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box className={classes.containerTitle}>
                      <RightForgotten className="ppIconSvg" />

                      <Typography variant="body2">
                        <span
                          className={`${classes.bold} ${classes.subSectionNumber}`}
                        >
                          {sectionIdentifies.shift()}
                        </span>{" "}
                      </Typography>

                      <Typography variant="body2">
                        You have the{" "}
                        <span className={classes.bold}>
                          right to request data deletion / the right to be
                          forgotten
                        </span>
                        .
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2}
                    >
                      This means that you can request us to delete your data,
                      under certain circumstances. For example, if you have a
                      contract with us we are legally obliged to keep your
                      contact details and your financial details for a number of
                      years.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 2.4 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  expanded={subSectionsStatus["2.4"]}
                  square={true}
                  elevation={0}
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 2.4: !subSectionsStatus["2.4"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box className={classes.containerTitle}>
                      <RightAsk className="ppIconSvg" />

                      <Typography variant="body2">
                        <span
                          className={`${classes.bold} ${classes.subSectionNumber}`}
                        >
                          {sectionIdentifies.shift()}
                        </span>{" "}
                      </Typography>

                      <Typography variant="body2">
                        You have the{" "}
                        <span className={classes.bold}>
                          right to ask for the restriction of the processing of
                          your personal data
                        </span>
                        .
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2}
                    >
                      This is applicable if:
                    </Typography>

                    <List
                      className={classes.indentationLevel3}
                      classes={{ root: classes.listRoot }}
                    >
                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          you think that your data is not accurate and you ask
                          us to review it;
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          the processing is considered unlawful and you ask us
                          not to erase the data;
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          if we no longer need your data but you ask us to keep
                          it so that you can establish, exercise or defend your
                          legal claims;
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          if you oppose to the processing of your data based on
                          our legitimate interest or if you disagree with such
                          interest on which we are basing our processing.
                        </Typography>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 2.5 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  expanded={subSectionsStatus["2.5"]}
                  square={true}
                  elevation={0}
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 2.5: !subSectionsStatus["2.5"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box className={classes.containerTitle}>
                      <RightTransfer className="ppIconSvg" />

                      <Typography variant="body2">
                        <span
                          className={`${classes.bold} ${classes.subSectionNumber}`}
                        >
                          {sectionIdentifies.shift()}
                        </span>{" "}
                      </Typography>

                      <Typography variant="body2">
                        You have the{" "}
                        <span className={classes.bold}>
                          right to ask us to transfer your data
                        </span>
                        .
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <List
                      className={classes.indentationLevel2}
                      classes={{ root: classes.listRoot }}
                    >
                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          We can transfer the data directly to you or, if
                          possible, to another service provider.
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          This is applicable only for the data that you have
                          provided to us and that we process automatically based
                          on your consent or on a contract that we have with you
                          or on the pre-contractual steps taken for the
                          conclusion of the contract.
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          We will transfer such data, to the possible extent, in
                          a commonly used machine - readable format.
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          We will not able to do this if so would have a
                          negative effect on other individuals. For example, in
                          the case you ask us to port your data, which also
                          contains data about other people, to another service
                          provider that does not respect the GDPR principles.
                        </Typography>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 2.6 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  expanded={subSectionsStatus["2.6"]}
                  square={true}
                  elevation={0}
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 2.6: !subSectionsStatus["2.6"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box className={classes.containerTitle}>
                      <RightObject className="ppIconSvg" />

                      <Typography variant="body2">
                        <span
                          className={`${classes.bold} ${classes.subSectionNumber}`}
                        >
                          {sectionIdentifies.shift()}
                        </span>{" "}
                      </Typography>

                      <Typography variant="body2">
                        You have the{" "}
                        <span className={classes.bold}>
                          right to object to the processing of your personal
                          data
                        </span>
                        .
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <List
                      className={classes.indentationLevel2}
                      classes={{ root: classes.listRoot }}
                    >
                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          You have an absolute right to object to the processing
                          of your personal data for direct marketing purposes,
                          at any time and without any justification
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          You have the right to object to the processing of your
                          personal data based on our legitimate interest by
                          giving a specific reason for your objection.
                        </Typography>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 2.7 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  expanded={subSectionsStatus["2.7"]}
                  square={true}
                  elevation={0}
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 2.7: !subSectionsStatus["2.7"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box className={classes.containerTitle}>
                      <RightComplaint className="ppIconSvg" />

                      <Typography variant="body2">
                        <span
                          className={`${classes.bold} ${classes.subSectionNumber}`}
                        >
                          {sectionIdentifies.shift()}
                        </span>{" "}
                      </Typography>

                      <Typography variant="body2">
                        You have the{" "}
                        <span className={classes.bold}>
                          right to make a complaint with the Data Protection
                          Supervisory Authority
                        </span>
                        .
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2}
                    >
                      In particular, the complaint can be made in the Member
                      State of your habitual residence, place of work, or where
                      you think that the supposed infringement took place. We
                      would appreciate hearing and trying to solve your problem
                      first, but if we let you down, you can make a complaint to
                      the competent Authority.
                    </Typography>{" "}
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 2.8 */}
              {!!Object.keys(generalQuestionsFour).length &&
                generalQuestionsFour.condition && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion
                      defaultExpanded={false}
                      expanded={subSectionsStatus["2.8"]}
                      square={true}
                      elevation={0}
                      onClick={() =>
                        openSpecificSubSection(
                          setSubSectionsStatus,
                          subSectionsStatus,
                          { 2.8: !subSectionsStatus["2.8"] }
                        )
                      }
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                      >
                        <Box className={classes.containerTitle}>
                          <RightSubjected className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            You have the{" "}
                            <span className={classes.bold}>
                              right not to be subjected to a decision taken only
                              by automated processing which can have legal or
                              similar effects on you
                            </span>
                            .
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{
                          root: classes.AccordionDetailsSubsection,
                        }}
                      >
                        {generalQuestionsFourOneListText.length ||
                        generalQuestionsFourOneListText.includes(
                          "None of the above"
                        ) ? (
                          <>
                            <Typography
                              variant="body2"
                              className={classes.indentationLevel2}
                            >
                              We take automated decision based on:
                            </Typography>

                            <List
                              className={classes.indentationLevel3}
                              classes={{ root: classes.listRoot }}
                            >
                              {generalQuestionsFourOneListText.map((item) => (
                                <ListItem
                                  classes={{ gutters: classes.gutters }}
                                >
                                  <Typography variant="body2">
                                    <span
                                      className={`${classes.listItemText} ${classes.bold}`}
                                    >
                                      •
                                    </span>{" "}
                                    {item}
                                  </Typography>
                                </ListItem>
                              ))}
                            </List>
                          </>
                        ) : null}

                        {generalQuestionsFourOneDecisions ? (
                          <>
                            <Typography
                              variant="body2"
                              className={classes.indentationLevel2}
                            >
                              In relation to such decisions you have the right
                              to:
                            </Typography>

                            <List
                              className={classes.indentationLevel3}
                              classes={{ root: classes.listRootLetter }}
                            >
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    a.
                                  </span>{" "}
                                  obtain human intervention;
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    b.
                                  </span>{" "}
                                  express your point of view and;
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    c.
                                  </span>{" "}
                                  contest the decision.
                                </Typography>
                              </ListItem>
                            </List>
                          </>
                        ) : null}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}

              <Box
                className={classes.gridItemYourRightsContentContainerExtraInfo}
              >
                <Typography variant="body2">
                  These rights are not without limitations so please exercise
                  them in good faith. You can exercise these rights, free of
                  charge, by contacting us at the contact details provided
                  above. We will make our best to reply as quickly as possible
                  and always within one month.
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            className={classes.gridItemYourRightsNote}
          >
            <Box
              display="flex"
              flexDirection="column"
              p={2}
              alignItems="stretch"
              className={classes.boxYourRightsNote}
            >
              <Typography
                variant="body2"
                className={`${classes.noteTitle} ${classes.noteText}`}
              >
                Basically
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                When it comes to your personal data you have multiple rights.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                This section tells you what rights you have and how you can
                exercise them.Also, as with every right, there are limitations
                and conditions for exercising them.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                We will respect the law, your privacy and other’s privacy when
                enforcing your rights.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const WhatDataWeCollectSection = ({ data }) => {
  const classes = useStyles({ colors: data.colors });

  const [subSectionsStatus, setSubSectionsStatus] = useState({
    clicked: false,
    3.1: false,
    3.2: false,
  });

  // eslint-disable-next-line
  let [clicked, ...sectionIdentifies] = Object.keys(subSectionsStatus);

  let sectionIdentifiesThreeOne = [
    "a.",
    "b.",
    "c.",
    "d.",
    "e.",
    "f.",
    "g.",
    "h.",
    "i.",
  ];

  let sectionIdentifiesThreeTwo = [
    "a.",
    "b.",
    "c.",
    "d.",
    "e.",
    "f.",
    "g.",
    "h.",
  ];

  const personalData = [
    unionPersonalData(data.accessConnectivity),
    unionPersonalData(data.advertising),
    unionPersonalData(data.analytics),
    unionPersonalData(data.customerSupport),
    unionPersonalData(data.devicePermission),
    unionPersonalData(data.infrastructure),
    unionPersonalData(data.interactionExternalPlatforms),
    unionPersonalData(data.marketing),
    unionPersonalData(data.payments),
    unionPersonalData(data.dataStorageTransfers),
    unionPersonalData(data.generalQuestions),
  ];

  const accumulations = mergeListOfObjects(personalData);

  // General questions
  const generalQuestionsOne = getSectionQuestion(data.generalQuestions, "1");

  const generalQuestionsTwoListText = getSectionQuestionListText(
    data.generalQuestions,
    "2"
  );

  const generalQuestionsTwoOneListText = getSectionQuestionListText(
    data.generalQuestions,
    "2.1"
  );

  // "Data collected" are the categories of personal data
  //
  // Here the third parties from the Mongo are match with
  // the ones locally (Google Sheet -> thirdParties.js) and
  // the "Data collected" is used to show the items in 3.2,
  // which are gonna have static content
  const thirdPartiesDataCollectedData = [
    ...unionThirdParties(data.accessConnectivity),
    ...unionThirdParties(data.advertising),
    ...unionThirdParties(data.analytics),
    ...unionThirdParties(data.customerSupport),
    ...unionThirdParties(data.infrastructure),
    ...unionThirdParties(data.interactionExternalPlatforms),
    ...unionThirdParties(data.marketing),
    ...unionThirdParties(data.payments),
  ].reduce(
    (accumulation, current) =>
      accumulation.concat(
        current.dataCollected
          .split(",")
          .map((category) => category.toLowerCase().trim())
      ),
    []
  );

  const showDataThatWeCollectAndProcessDirectlyQuestions = [
    accumulations["3. what data do we collect a. personal & contact details"],

    accumulations["3. what data do we collect b. financial information"],

    accumulations[
      "3. what data do we collect c. data introduced by you within the platform"
    ],

    accumulations[
      "3. what data do we collect d. data that identifies you & your device"
    ],

    accumulations[
      "3. what data do we collect e. data on how you use the service"
    ],

    accumulations[
      "3. what data do we collect f. data related to your third party accounts"
    ],

    accumulations["3. What data do we collect g. Data from your device"],
  ].some((item) => item !== undefined);

  const showDataThatWeCollectAndProcessDirectly =
    showDataThatWeCollectAndProcessDirectlyQuestions ||
    generalQuestionsOne.condition ||
    !generalQuestionsTwoListText.includes("None of the above");

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      square={true}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            className={
              subSectionsStatus.clicked === true
                ? classes.expandIconActiveRotation
                : classes.expandIconDefaultRotation
            }
          />
        }
        classes={{
          root: classes.AccordionSummary,
          content: classes.expandContent,
        }}
        onClick={() =>
          toggleAllSubSections(setSubSectionsStatus, subSectionsStatus)
        }
        aria-controls="panel3-control"
        id="panel3-id"
      >
        <Typography variant="h2" className={classes.sectionHeaderTitle}>
          3. What data we collect
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.AccordionDetails }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.gridWhatDataWeCollect}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={8}
            className={classes.gridItemWhatDataWeCollectContent}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={classes.gridItemWhatDataWeCollectContent}
            >
              {/* 3.1 */}
              {showDataThatWeCollectAndProcessDirectly ? (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Accordion
                    defaultExpanded={false}
                    square={true}
                    elevation={0}
                    expanded={subSectionsStatus["3.1"]}
                  >
                    <AccordionSummary
                      classes={{
                        root: classes.AccordionSummarySubsection,
                      }}
                      aria-controls="panel1-control"
                      id="panel1-id"
                      onClick={() =>
                        openSpecificSubSection(
                          setSubSectionsStatus,
                          subSectionsStatus,
                          {
                            3.1: !subSectionsStatus["3.1"],
                          }
                        )
                      }
                    >
                      <Box>
                        <Box className={classes.containerTitleInline}>
                          <WhatDataWeCollectLocal className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>
                              Data that we collect and process directly
                            </span>
                          </Typography>
                        </Box>

                        <Typography
                          variant="body2"
                          className={classes.indentationLevel2}
                        >
                          We process different categories of personal data that
                          can identify you or your device. Some of the data is
                          actively provided by you and some is passively
                          collected by us.
                        </Typography>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      classes={{ root: classes.AccordionDetailsSubsection }}
                    >
                      {showDataThatWeCollectAndProcessDirectlyQuestions ? (
                        <Typography
                          variant="body2"
                          className={classes.indentationLevel2}
                        >
                          More precisely we collect and process:
                        </Typography>
                      ) : null}
                      <List
                        className={`${classes.indentationLevel3} ${classes.listSubtitle}`}
                      >
                        {accumulations[
                          "3. what data do we collect a. personal & contact details"
                        ] && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            <Box className={classes.containerTitleInlineSimple}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.bold} ${classes.subSectionNumberList}`}
                                >
                                  {sectionIdentifiesThreeOne.shift()}
                                </span>{" "}
                              </Typography>

                              <Typography variant="body2">
                                <span className={classes.bold}>
                                  Personal and contact details
                                </span>
                              </Typography>
                            </Box>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel1List}
                            >
                              Data such as:{" "}
                              {accumulations[
                                "3. what data do we collect a. personal & contact details"
                              ].join(", ")}
                              , etc.
                            </Typography>
                          </ListItem>
                        )}

                        {accumulations[
                          "3. what data do we collect b. financial information"
                        ] && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            <Box className={classes.containerTitleInlineSimple}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.bold} ${classes.subSectionNumberList}`}
                                >
                                  {sectionIdentifiesThreeOne.shift()}
                                </span>{" "}
                              </Typography>

                              <Typography variant="body2">
                                <span className={classes.bold}>
                                  Financial information
                                </span>
                              </Typography>
                            </Box>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel1List}
                            >
                              Data such as:
                              {accumulations[
                                "3. what data do we collect b. financial information"
                              ].join(", ")}
                              , etc.
                            </Typography>
                          </ListItem>
                        )}

                        {accumulations[
                          "3. what data do we collect c. data introduced by you within the platform"
                        ] && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            <Box className={classes.containerTitleInlineSimple}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.bold} ${classes.subSectionNumberList}`}
                                >
                                  {sectionIdentifiesThreeOne.shift()}
                                </span>{" "}
                              </Typography>

                              <Typography variant="body2">
                                <span className={classes.bold}>
                                  Data introduced by you
                                </span>
                              </Typography>
                            </Box>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel1List}
                            >
                              Data such as:{" "}
                              {accumulations[
                                "3. what data do we collect c. data introduced by you within the platform"
                              ].join(", ")}
                              , etc.
                            </Typography>
                          </ListItem>
                        )}

                        {accumulations[
                          "3. what data do we collect d. data that identifies you & your device"
                        ] && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            <Box className={classes.containerTitleInlineSimple}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.bold} ${classes.subSectionNumberList}`}
                                >
                                  {sectionIdentifiesThreeOne.shift()}
                                </span>{" "}
                              </Typography>

                              <Typography variant="body2">
                                <span className={classes.bold}>
                                  Data that identifies you & your device
                                </span>
                              </Typography>
                            </Box>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel1List}
                            >
                              Data such as:{" "}
                              {accumulations[
                                "3. what data do we collect d. data that identifies you & your device"
                              ].join(", ")}
                              , etc.
                            </Typography>
                          </ListItem>
                        )}

                        {accumulations[
                          "3. what data do we collect e. data on how you use the service"
                        ] && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            <Box className={classes.containerTitleInlineSimple}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.bold} ${classes.subSectionNumberList}`}
                                >
                                  {sectionIdentifiesThreeOne.shift()}
                                </span>{" "}
                              </Typography>

                              <Typography variant="body2">
                                <span className={classes.bold}>
                                  Data on how you use the service
                                </span>
                              </Typography>
                            </Box>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel1List}
                            >
                              Data such as:{" "}
                              {accumulations[
                                "3. what data do we collect e. data on how you use the service"
                              ].join(", ")}
                              , etc.
                            </Typography>
                          </ListItem>
                        )}

                        {accumulations[
                          "3. what data do we collect f. data related to your third party accounts"
                        ] && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            <Box className={classes.containerTitleInlineSimple}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.bold} ${classes.subSectionNumberList}`}
                                >
                                  {sectionIdentifiesThreeOne.shift()}
                                </span>{" "}
                              </Typography>

                              <Typography variant="body2">
                                <span className={classes.bold}>
                                  Data related to your third party accounts
                                </span>
                              </Typography>
                            </Box>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel1List}
                            >
                              Data such as:{" "}
                              {accumulations[
                                "3. what data do we collect f. data related to your third party accounts"
                              ].join(", ")}
                              , etc.
                            </Typography>
                          </ListItem>
                        )}

                        {accumulations[
                          "3. What data do we collect g. Data from your device"
                        ] && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            <Box className={classes.containerTitleInlineSimple}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.bold} ${classes.subSectionNumberList}`}
                                >
                                  {sectionIdentifiesThreeOne.shift()}
                                </span>{" "}
                              </Typography>

                              <Typography variant="body2">
                                <span className={classes.bold}>
                                  Data from your device
                                </span>
                              </Typography>
                            </Box>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel1List}
                            >
                              Data such as:{" "}
                              {accumulations[
                                "3. What data do we collect g. Data from your device"
                              ].join(", ")}
                              , etc.
                            </Typography>
                          </ListItem>
                        )}

                        {generalQuestionsOne.condition && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            <Box className={classes.containerTitleInlineSimple}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.bold} ${classes.subSectionNumberList}`}
                                >
                                  {sectionIdentifiesThreeOne.shift()}
                                </span>{" "}
                              </Typography>

                              <Typography variant="body2">
                                <span className={classes.bold}>
                                  Underage data
                                </span>
                              </Typography>
                            </Box>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel1List}
                            >
                              Data related to: users which are under the age of
                              consent, as defined by the different states: 13 -
                              16 years, depending on the jurisdiction.
                            </Typography>
                          </ListItem>
                        )}

                        {!generalQuestionsTwoListText.includes(
                          "None of the above"
                        ) && (
                          <ListItem classes={{ gutters: classes.gutters }}>
                            {generalQuestionsTwoListText.length ? (
                              <>
                                <Box
                                  className={classes.containerTitleInlineSimple}
                                >
                                  <Typography variant="body2">
                                    <span
                                      className={`${classes.bold} ${classes.subSectionNumberList}`}
                                    >
                                      {sectionIdentifiesThreeOne.shift()}
                                    </span>{" "}
                                  </Typography>

                                  <Typography variant="body2">
                                    <span className={classes.bold}>
                                      Sensitive data
                                    </span>
                                  </Typography>
                                </Box>

                                <Typography
                                  variant="body2"
                                  className={classes.indentationLevel1List}
                                >
                                  Data related to:{" "}
                                  {generalQuestionsTwoListText.join(", ")}.
                                </Typography>
                              </>
                            ) : null}

                            {!!generalQuestionsTwoOneListText.length && (
                              <Box pt={2}>
                                <Typography variant="body2">
                                  We process such data only in limited cases and
                                  only because:
                                </Typography>

                                <List
                                  className={`${classes.indentationLevel1List} ${classes.listSubtitle}`}
                                >
                                  {generalQuestionsTwoOneListText.map(
                                    (text) => (
                                      <ListItem
                                        classes={{ gutters: classes.gutters }}
                                      >
                                        <span
                                          className={`${classes.listItemText} ${classes.bold}`}
                                        >
                                          •
                                        </span>{" "}
                                        {text}
                                      </ListItem>
                                    )
                                  )}
                                </List>
                              </Box>
                            )}
                          </ListItem>
                        )}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              ) : null}

              {/* 3.2 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  square={true}
                  elevation={0}
                  expanded={subSectionsStatus["3.2"]}
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                    onClick={() =>
                      openSpecificSubSection(
                        setSubSectionsStatus,
                        subSectionsStatus,
                        {
                          3.2: !subSectionsStatus["3.2"],
                        }
                      )
                    }
                  >
                    <Box>
                      <Box className={classes.containerTitleInline}>
                        <WhatDataWeCollectThirdParty className="ppIconSvg" />

                        <Typography variant="body2">
                          <span
                            className={`${classes.bold} ${classes.subSectionNumber}`}
                          >
                            {sectionIdentifies.shift()}
                          </span>{" "}
                        </Typography>

                        <Typography variant="body2">
                          <span className={classes.bold}>
                            Data collected and processed via third parties
                          </span>
                        </Typography>
                      </Box>

                      <Typography
                        variant="body2"
                        className={classes.indentationLevel2}
                      >
                        While we can’t offer an exhaustive list of personal data
                        collected and processed by third parties we have
                        provided, under Section 6, a link to the Privacy Policy
                        of each company who’s services we are using, so please
                        review your privacy settings there. Here you will find
                        examples related to the categories of data collected and
                        processed via third parties.
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <List
                      className={`${classes.indentationLevel3} ${classes.listSubtitle}`}
                    >
                      {thirdPartiesDataCollectedData.includes(
                        "personal & contact details"
                      ) && (
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Box className={classes.containerTitleInlineSimple}>
                            <Typography variant="body2">
                              <span
                                className={`${classes.bold} ${classes.subSectionNumberList}`}
                              >
                                {sectionIdentifiesThreeTwo.shift()}
                              </span>{" "}
                            </Typography>

                            <Typography variant="body2">
                              <span className={classes.bold}>
                                Personal and contact details
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            className={classes.indentationLevel1List}
                          >
                            Data such as: your name, your surname, your email
                            address, phone number, etc.
                          </Typography>
                        </ListItem>
                      )}

                      {thirdPartiesDataCollectedData.includes(
                        "financial information"
                      ) && (
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Box className={classes.containerTitleInlineSimple}>
                            <Typography variant="body2">
                              <span
                                className={`${classes.bold} ${classes.subSectionNumberList}`}
                              >
                                {sectionIdentifiesThreeTwo.shift()}
                              </span>{" "}
                            </Typography>

                            <Typography variant="body2">
                              <span className={classes.bold}>
                                Financial information
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            className={classes.indentationLevel1List}
                          >
                            Data such as: payment method, card details, purchase
                            amount, bank account, etc.
                          </Typography>
                        </ListItem>
                      )}

                      {thirdPartiesDataCollectedData.includes(
                        "data introduced by you within the platform"
                      ) && (
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Box className={classes.containerTitleInlineSimple}>
                            <Typography variant="body2">
                              <span
                                className={`${classes.bold} ${classes.subSectionNumberList}`}
                              >
                                {sectionIdentifiesThreeTwo.shift()}
                              </span>{" "}
                            </Typography>

                            <Typography variant="body2">
                              <span className={classes.bold}>
                                Data introduced by you
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            className={classes.indentationLevel1List}
                          >
                            Data such as: pictures, comments, videos, messages,
                            etc.
                          </Typography>
                        </ListItem>
                      )}

                      {thirdPartiesDataCollectedData.includes(
                        "data that identifies you"
                      ) && (
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Box className={classes.containerTitleInlineSimple}>
                            <Typography variant="body2">
                              <span
                                className={`${classes.bold} ${classes.subSectionNumberList}`}
                              >
                                {sectionIdentifiesThreeTwo.shift()}
                              </span>{" "}
                            </Typography>

                            <Typography variant="body2">
                              <span className={classes.bold}>
                                Data that identifies you & your device
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            className={classes.indentationLevel1List}
                          >
                            Data such as: IP address, MAC address, cookies,
                            browser type, time zone settings, etc.
                          </Typography>
                        </ListItem>
                      )}

                      {thirdPartiesDataCollectedData.includes(
                        "data on how you use the service"
                      ) && (
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Box className={classes.containerTitleInlineSimple}>
                            <Typography variant="body2">
                              <span
                                className={`${classes.bold} ${classes.subSectionNumberList}`}
                              >
                                {sectionIdentifiesThreeTwo.shift()}
                              </span>{" "}
                            </Typography>

                            <Typography variant="body2">
                              <span className={classes.bold}>
                                Data on how you use the service
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            className={classes.indentationLevel1List}
                          >
                            Data such as: page response time, errors, time spent
                            on our website, clickstreams, etc.
                          </Typography>
                        </ListItem>
                      )}

                      {thirdPartiesDataCollectedData.includes(
                        "data related to your third-party accounts"
                      ) && (
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Box className={classes.containerTitleInlineSimple}>
                            <Typography variant="body2">
                              <span
                                className={`${classes.bold} ${classes.subSectionNumberList}`}
                              >
                                {sectionIdentifiesThreeTwo.shift()}
                              </span>{" "}
                            </Typography>

                            <Typography variant="body2">
                              <span className={classes.bold}>
                                Data related to your third party accounts
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            className={classes.indentationLevel1List}
                          >
                            Data such as: your accounts on other platforms like
                            Twitter, Linkedin, Youtube, etc.
                          </Typography>
                        </ListItem>
                      )}

                      {thirdPartiesDataCollectedData.includes(
                        "data from your device"
                      ) && (
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Box className={classes.containerTitleInlineSimple}>
                            <Typography variant="body2">
                              <span
                                className={`${classes.bold} ${classes.subSectionNumberList}`}
                              >
                                {sectionIdentifiesThreeTwo.shift()}
                              </span>{" "}
                            </Typography>

                            <Typography variant="body2">
                              <span className={classes.bold}>
                                Data from your device
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            className={classes.indentationLevel1List}
                          >
                            Data such as: your geolocation, bluetooth data,
                            calendar, photos, etc.
                          </Typography>
                        </ListItem>
                      )}

                      {thirdPartiesDataCollectedData.includes(
                        "underage data"
                      ) && (
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Box className={classes.containerTitleInlineSimple}>
                            <Typography variant="body2">
                              <span
                                className={`${classes.bold} ${classes.subSectionNumberList}`}
                              >
                                {sectionIdentifiesThreeTwo.shift()}
                              </span>{" "}
                            </Typography>

                            <Typography variant="body2">
                              <span className={classes.bold}>
                                Underage data
                              </span>
                            </Typography>
                          </Box>

                          <Typography
                            variant="body2"
                            className={classes.indentationLevel1List}
                          >
                            Data related to: users which are under the age of
                            consent, as defined by the different states: 13 - 16
                            years, depending on the jurisdiction.
                          </Typography>
                        </ListItem>
                      )}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            className={classes.gridItemWhatDataWeCollectNote}
          >
            <Box
              display="flex"
              flexDirection="column"
              p={2}
              alignItems="stretch"
              className={classes.boxItemWhatDataWeCollectNote}
            >
              <Typography
                variant="body2"
                className={`${classes.noteTitle} ${classes.noteText}`}
              >
                Basically
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                We collect various types of personal data which, according to
                the law, is any piece of info that could identify you.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                Here we disclose the exact type of personal data we collect and
                process directly and the categories of personal data collected
                and processed via third parties.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const WhyHowUseYourDataSection = ({ data, code }) => {
  const classes = useStyles({ colors: data.colors });

  const [subSectionsStatus, setSubSectionsStatus] = useState({
    clicked: false,
    4.1: false,
    4.2: false,
    4.3: false,
    4.4: false,
    4.5: false,
    4.6: false,
    4.7: false,
    4.8: false,
    4.9: false,
  });

  // eslint-disable-next-line
  let [clicked, ...sectionIdentifies] = Object.keys(subSectionsStatus);

  let sectionIdentifiesFourEight = ["a.", "b.", "c.", "d."];

  const [explanationStatus, setExplanationStatus] = useState(false);

  const legalBasisData = {
    accessConnectivity: unionLegalBasis(data.accessConnectivity),
    advertising: unionLegalBasis(data.advertising),
    analytics: unionLegalBasis(data.analytics),
    customerSupport: unionLegalBasis(data.customerSupport),
    infrastructure: unionLegalBasis(data.infrastructure),
    interactionExternalPlatforms: unionLegalBasis(
      data.interactionExternalPlatforms
    ),
    marketing: unionLegalBasis(data.marketing),
    payments: unionLegalBasis(data.payments),
  };

  const uniqueLegalBasisData = Array.from(
    new Set(Object.values(legalBasisData).flat())
  );

  // Access connectivity
  const accessConnectivityOne = getSectionQuestion(
    data.accessConnectivity,
    "1"
  );

  const accessConnectivityTwo = getSectionQuestion(
    data.accessConnectivity,
    "2"
  );

  const accessConnectivityThree = getSectionQuestion(
    data.accessConnectivity,
    "3"
  );

  const accessConnectivityFour = getSectionQuestion(
    data.accessConnectivity,
    "4"
  );

  // Advertising
  const advertisingOne = getSectionQuestion(data.advertising, "1");

  const advertisingTwo = getSectionQuestion(data.advertising, "2");

  const advertisingThree = getSectionQuestion(data.advertising, "3");

  const advertisingFour = getSectionQuestion(data.advertising, "4");

  // Analytics
  const analyticsOne = getSectionQuestion(data.analytics, "1");

  const analyticsTwo = getSectionQuestion(data.analytics, "2");

  const analyticsThree = getSectionQuestion(data.analytics, "3");

  const analyticsThreeOne = getSectionQuestion(data.analytics, "3.1");

  const analyticsThreeTwo = getSectionQuestion(data.analytics, "3.2");

  const analyticsThreeThree = getSectionQuestion(data.analytics, "3.3");

  const analyticsThreeFour = getSectionQuestion(data.analytics, "3.4");

  // Customer support
  const customerSupportOne = getSectionQuestion(data.customerSupport, "1");

  const customerSupportTwo = getSectionQuestion(data.customerSupport, "2");

  const customerSupportThree = getSectionQuestion(data.customerSupport, "3");

  const customerSupportThreeOne = getSectionQuestion(
    data.customerSupport,
    "3.1"
  );

  const customerSupportThreeTwo = getSectionQuestion(
    data.customerSupport,
    "3.2"
  );

  const customerSupportThreeThree = getSectionQuestion(
    data.customerSupport,
    "3.3"
  );

  const customerSupportThreeFour = getSectionQuestion(
    data.customerSupport,
    "3.4"
  );

  // Device permission
  // const devicePermissionOne = getSectionQuestion(data.devicePermission, "1");

  // Infrastructure
  const infrastructureOne = getSectionQuestion(data.infrastructure, "1");

  const infrastructureTwo = getSectionQuestion(data.infrastructure, "2");

  const infrastructureThree = getSectionQuestion(data.infrastructure, "3");

  const infrastructureFour = getSectionQuestion(data.infrastructure, "4");

  const infrastructureFive = getSectionQuestion(data.infrastructure, "5");

  const infrastructureSix = getSectionQuestion(data.infrastructure, "6");

  const infrastructureSeven = getSectionQuestion(data.infrastructure, "7");

  // Interaction external platforms
  const interactionExternalPlatformsOne = getSectionQuestion(
    data.interactionExternalPlatforms,
    "1"
  );

  const interactionExternalPlatformsTwo = getSectionQuestion(
    data.interactionExternalPlatforms,
    "2"
  );

  const interactionExternalPlatformsThree = getSectionQuestion(
    data.interactionExternalPlatforms,
    "3"
  );

  const interactionExternalPlatformsFour = getSectionQuestion(
    data.interactionExternalPlatforms,
    "4"
  );

  const interactionExternalPlatformsFive = getSectionQuestion(
    data.interactionExternalPlatforms,
    "5"
  );

  let showInteractionWithExternalPlatforms = [
    interactionExternalPlatformsOne.condition,

    interactionExternalPlatformsTwo.condition,

    interactionExternalPlatformsThree.condition,

    interactionExternalPlatformsFour.condition,

    interactionExternalPlatformsFive.condition,
  ].some((item) => item);

  // Marketing
  const marketingOne = getSectionQuestion(data.marketing, "1");

  const marketingTwo = getSectionQuestion(data.marketing, "2");

  const marketingTwoOne = getSectionQuestion(data.marketing, "2.1");

  const marketingTwoTwo = getSectionQuestion(data.marketing, "2.2");

  const marketingTwoThree = getSectionQuestion(data.marketing, "2.3");

  const marketingTwoFour = getSectionQuestion(data.marketing, "2.4");

  const marketingTwoFive = getSectionQuestion(data.marketing, "2.5");

  const marketingTwoSix = getSectionQuestion(data.marketing, "2.6");

  const marketingTwoSeven = getSectionQuestion(data.marketing, "2.7");

  // Payments
  const paymentsOne = getSectionQuestion(data.payments, "1");

  const paymentsTwo = getSectionQuestion(data.payments, "2");

  const showSectionFour = [
    reduceSectionCondition(data.accessConnectivity),
    reduceSectionCondition(data.advertising),
    reduceSectionCondition(data.analytics),
    reduceSectionCondition(data.customerSupport),
    reduceSectionCondition(data.infrastructure),
    reduceSectionCondition(data.payments),
    showInteractionWithExternalPlatforms,
  ].some((item) => item);

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      square={true}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={
          showSectionFour ? (
            <ExpandMoreIcon
              className={
                subSectionsStatus.clicked === true
                  ? classes.expandIconActiveRotation
                  : classes.expandIconDefaultRotation
              }
            />
          ) : null
        }
        classes={{
          root: classes.AccordionSummary,
          content: classes.expandContent,
        }}
        onClick={() =>
          toggleAllSubSections(setSubSectionsStatus, subSectionsStatus)
        }
        aria-controls="panel3-control"
        id="panel3-id"
      >
        <Typography variant="h2" className={classes.sectionHeaderTitle}>
          4. Why and how we use your data
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.AccordionDetails }}>
        {!showSectionFour ? (
          <Box>
            <Typography variant="body2" className={classes.indentationLevel1}>
              According to the law, we can use your data only for specific
              reasons and only if we have a legal basis to do so.
            </Typography>
          </Box>
        ) : null}

        {showSectionFour ? (
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            className={classes.gridWhyHowUseData}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={11}
              lg={8}
              className={classes.gridItemWhyHowUseDataContent}
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="stretch"
                className={classes.gridItemWhyHowUseDataContentContainer}
              >
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Typography
                    variant="body2"
                    className={classes.indentationLevel1}
                  >
                    We use your data for the following purposes:
                  </Typography>
                </Grid>
                {/* 4.1 */}
                {reduceSectionCondition(data.accessConnectivity) && (
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className={
                      classes.gridItemWhyHowUseDataContentContainerItemList
                    }
                  >
                    <Accordion
                      defaultExpanded={false}
                      square={true}
                      elevation={0}
                      expanded={subSectionsStatus["4.1"]}
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                        onClick={() =>
                          openSpecificSubSection(
                            setSubSectionsStatus,
                            subSectionsStatus,
                            { 4.1: !subSectionsStatus["4.1"] }
                          )
                        }
                      >
                        <Box className={classes.containerTitleInline}>
                          <AccessConnectivity className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>
                              Access & Connectivity
                            </span>
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{ root: classes.AccordionDetailsSubsection }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.indentationLevel2Inline}
                        >
                          We process your data for the following purposes:
                        </Typography>

                        <List
                          className={classes.indentationLevel3}
                          classes={{ root: classes.listRoot }}
                        >
                          {accessConnectivityOne.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                identifying you and authorising you to access
                                our services through log in and sign up systems;
                              </Typography>
                            </ListItem>
                          )}
                          {accessConnectivityTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                allowing you to create a profile;
                              </Typography>
                            </ListItem>
                          )}
                          {accessConnectivityThree.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                enabling you to connect to other platforms and
                                by this allowing us to access your data on such
                                platforms;
                              </Typography>
                            </ListItem>
                          )}
                          {accessConnectivityFour.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                facilitating you to invite connections directly
                                through our platform;
                              </Typography>
                            </ListItem>
                          )}{" "}
                        </List>

                        <Box
                          display="flex"
                          flexDirection="row"
                          className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                        >
                          <Typography variant="body2">Legal basis:</Typography>

                          <Box className={classes.chips}>
                            {anchorTips(
                              legalBasisData.accessConnectivity,
                              code,
                              setExplanationStatus
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}

                {/* 4.2 */}
                {reduceSectionCondition(data.advertising) && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion
                      defaultExpanded={false}
                      square={true}
                      elevation={0}
                      expanded={subSectionsStatus["4.2"]}
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                        onClick={() =>
                          openSpecificSubSection(
                            setSubSectionsStatus,
                            subSectionsStatus,
                            { 4.2: !subSectionsStatus["4.2"] }
                          )
                        }
                      >
                        <Box className={classes.containerTitleInline}>
                          <Advertising className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>Advertising</span>
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{ root: classes.AccordionDetailsSubsection }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.indentationLevel2Inline}
                        >
                          We process your data for advertising communication
                          purposes. With this we refer to the ads which are
                          displayed on our website under different forms such
                          as: advertising links, banners, text and video
                          billboards etc. These ads could be tailored to your
                          interests and behaviour.
                        </Typography>

                        <List
                          className={classes.indentationLevel3}
                          classes={{ root: classes.listRoot }}
                        >
                          {advertisingOne.condition &&
                            !advertisingTwo.condition &&
                            !advertisingThree.condition &&
                            !advertisingFour.condition && (
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  We do not use third parties for this process
                                  and therefore all the relevant data used for
                                  this purpose is processed only by us.
                                </Typography>
                              </ListItem>
                            )}

                          {advertisingTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                For this purpose we use the help of third
                                parties which also have access to some of your
                                personal data, such as cookies and other
                                identifiers.
                              </Typography>
                            </ListItem>
                          )}

                          {accessConnectivityThree.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                Also, on our website, we display ads for
                                products or services belonging to third parties.
                                Your interaction with these ads, such as a click
                                on an advertisement banner, is monitored and
                                tracked by such third parties and also shared
                                with us.
                              </Typography>
                            </ListItem>
                          )}

                          {advertisingFour.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                Additionally, we use remarketing and behavioural
                                targeting technologies, which allow us to
                                optimise and show you marketing based on your
                                previous use of our website. Together with our
                                partners we may track your use across different
                                websites and applications in order to show you
                                ads that are better tailored to your interests.
                              </Typography>
                            </ListItem>
                          )}
                        </List>

                        <Box
                          display="flex"
                          flexDirection="row"
                          className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                        >
                          <Typography variant="body2">Legal basis:</Typography>

                          <Box className={classes.chips}>
                            {anchorTips(
                              legalBasisData.advertising,
                              code,
                              setExplanationStatus
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}

                {/* 4.3 */}
                {reduceSectionCondition(data.analytics) && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion
                      defaultExpanded={false}
                      square={true}
                      elevation={0}
                      expanded={subSectionsStatus["4.3"]}
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                        onClick={() =>
                          openSpecificSubSection(
                            setSubSectionsStatus,
                            subSectionsStatus,
                            { 4.3: !subSectionsStatus["4.3"] }
                          )
                        }
                      >
                        <Box className={classes.containerTitleInline}>
                          <Analytics className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>Analytics</span>
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{ root: classes.AccordionDetailsSubsection }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.indentationLevel2Inline}
                        >
                          We process your data for{" "}
                          <span className={classes.bold}>
                            the purpose of analysing your online behaviour and
                            for discovering patterns
                          </span>
                          .
                        </Typography>

                        <br />

                        {analyticsOne.condition &&
                          analyticsTwo.condition &&
                          !analyticsThree.condition && (
                            <Typography
                              variant="body2"
                              className={classes.indentationLevel2Inline}
                            >
                              We do not use third parties for this process and
                              therefore all the relevant data used for this
                              purpose is processed only by us.
                            </Typography>
                          )}
                        {analyticsThree.condition && (
                          <Typography
                            variant="body2"
                            className={classes.indentationLevel2Inline}
                          >
                            For this purpose we use third parties which help us
                            monitor and analyse the web traffic. Such third
                            parties help us:
                          </Typography>
                        )}

                        <List
                          className={classes.indentationLevel3}
                          classes={{ root: classes.listRoot }}
                        >
                          {analyticsThreeOne.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                track and analyse your response in relation to
                                different changes of our website, such as
                                changes in the structure or in the content
                                available on our website
                              </Typography>
                            </ListItem>
                          )}

                          {analyticsThreeTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                track and analyse your use of the website by
                                showing us where you move the mouse and where
                                you click most frequently
                              </Typography>
                            </ListItem>
                          )}

                          {analyticsThreeThree.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                identify and resolve IT infrastructure problems
                                related to the operation, performance and
                                maintenance of our infrastructure so that we can
                                improve our processes
                              </Typography>
                            </ListItem>
                          )}

                          {analyticsThreeFour.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                monitor, track and analyse your use of the
                                website and prepare reports on your activity
                              </Typography>
                            </ListItem>
                          )}
                        </List>

                        <Box
                          display="flex"
                          flexDirection="row"
                          className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                        >
                          <Typography variant="body2">Legal basis:</Typography>

                          <Box className={classes.chips}>
                            {anchorTips(
                              legalBasisData.analytics,
                              code,
                              setExplanationStatus
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}

                {/* 4.4 */}
                {reduceSectionCondition(data.customerSupport) && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion
                      defaultExpanded={false}
                      square={true}
                      elevation={0}
                      expanded={subSectionsStatus["4.4"]}
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                        onClick={() =>
                          openSpecificSubSection(
                            setSubSectionsStatus,
                            subSectionsStatus,
                            { 4.4: !subSectionsStatus["4.4"] }
                          )
                        }
                      >
                        <Box className={classes.containerTitleInline}>
                          <CustomerSupport className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>
                              Customer Support
                            </span>
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{ root: classes.AccordionDetailsSubsection }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.indentationLevel2Inline}
                        >
                          We process your data for{" "}
                          <span className={classes.bold}>
                            the purpose of offering you support and replying to
                            your requests
                          </span>
                          .
                        </Typography>

                        {customerSupportOne.condition &&
                          !customerSupportTwo.condition &&
                          !customerSupportThree.condition && (
                            <Typography
                              variant="body2"
                              className={classes.indentationLevel2Inline}
                            >
                              We do not use third parties for this process and
                              therefore all the relevant data used for this
                              purpose is processed only by us.
                            </Typography>
                          )}

                        {customerSupportTwo.condition && (
                          <>
                            <Typography
                              variant="body2"
                              className={classes.indentationLevel2Inline}
                            >
                              We use third parties which provide Customer
                              Relationship Management software. This helps us
                              aggregate information and make our interactions
                              more efficient, by giving us easy access to data
                              such as: contact details, purchase history,
                              previous conversations, purchasing behaviour and
                              others. It also allows us to build user profiles
                              and track your activities through analytics
                              features.
                            </Typography>

                            <br />
                          </>
                        )}

                        {customerSupportThree.condition && (
                          <Typography
                            variant="body2"
                            className={classes.indentationLevel2Inline}
                          >
                            Also, for this purpose, for specific tasks, we use
                            software provided by third parties, which collects
                            some data such as browsing data independently of
                            your interaction with it. Such third parties help
                            us:
                          </Typography>
                        )}

                        <List
                          className={classes.indentationLevel3}
                          classes={{ root: classes.listRoot }}
                        >
                          {customerSupportThreeOne.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                communicating in real time with you and
                                gathering data about such communications
                              </Typography>
                            </ListItem>
                          )}

                          {customerSupportThreeTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                manage our database of email contacts, send
                                emails and collect data about your interaction
                                with such emails
                              </Typography>
                            </ListItem>
                          )}

                          {customerSupportThreeThree.condition ||
                            (customerSupportThreeFour.condition && (
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  manage your contact and support requests
                                  received via email, contact form or other
                                  means and collect data about the interaction
                                  and the results
                                </Typography>
                              </ListItem>
                            ))}
                        </List>

                        <Box
                          display="flex"
                          flexDirection="row"
                          className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                        >
                          <Typography variant="body2">Legal basis:</Typography>

                          <Box className={classes.chips}>
                            {anchorTips(
                              legalBasisData.customerSupport,
                              code,
                              setExplanationStatus
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>{" "}
                  </Grid>
                )}

                {/* 4.5 */}
                {/* {devicePermissionOne.condition && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Accordion
                    defaultExpanded={false}
                    square={true}
                    elevation={0}
                    expanded={subSectionsStatus["4.5"]}
                  >
                    <AccordionSummary
                      classes={{
                        root: classes.AccordionSummarySubsection,
                      }}
                      aria-controls="panel1-control"
                      id="panel1-id"
                      onClick={() =>
                        openSpecificSubSection(
                          setSubSectionsStatus,
                          subSectionsStatus,
                          { "4.5": !subSectionsStatus["4.5"] }
                        )
                      }
                    >
                      <Box className={classes.containerTitleInline}>
                        <DevicePermission className="ppIconSvg" />

                        <Typography variant="body2">
                          <span
                            className={`${classes.bold} ${classes.subSectionNumber}`}
                          >
                            {sectionIdentifies.shift()}
                          </span>{" "}
                        </Typography>

                        <Typography variant="body2">
                          <span className={classes.bold}>
                            Device Permission
                          </span>
                        </Typography>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      classes={{ root: classes.AccordionDetailsSubsection }}
                    >
                      <Typography
                        variant="body2"
                        className={classes.indentationLevel2Inline}
                      >
                        We ask permission to access the following features and
                        related data from your device, for the purpose of
                        providing our service:
                      </Typography>

                      <List
                        className={classes.indentationLevel3}
                        classes={{ root: classes.listRoot }}
                      >
                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Typography variant="body2">
                            <span
                              className={`${classes.listItemText} ${classes.bold}`}
                            >
                              •
                            </span>{" "}
                            Text
                          </Typography>
                        </ListItem>

                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Typography variant="body2">
                            <span
                              className={`${classes.listItemText} ${classes.bold}`}
                            >
                              •
                            </span>{" "}
                            Text
                          </Typography>
                        </ListItem>

                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Typography variant="body2">
                            <span
                              className={`${classes.listItemText} ${classes.bold}`}
                            >
                              •
                            </span>{" "}
                            Text
                          </Typography>
                        </ListItem>

                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Typography variant="body2">
                            <span
                              className={`${classes.listItemText} ${classes.bold}`}
                            >
                              •
                            </span>{" "}
                            Text
                          </Typography>
                        </ListItem>

                        <ListItem classes={{ gutters: classes.gutters }}>
                          <Typography variant="body2">
                            <span
                              className={`${classes.listItemText} ${classes.bold}`}
                            >
                              •
                            </span>{" "}
                            Text
                          </Typography>
                        </ListItem>
                      </List>

                      <Box
                        display="flex"
                        flexDirection="row"
                        className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                      >
                        <Typography variant="body2">Legal basis:</Typography>

                        <Box className={classes.chips}>
                          {anchorTips(
                            legalBasisData.devicePermission,
                            code,
                            setExplanationStatus
                          )}
                        </Box>
                      </Box>
                    </AccordionDetails>
                  </Accordion>{" "}
                </Grid>
              )} */}

                {/* 4.6 */}
                {reduceSectionCondition(data.infrastructure) && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion
                      defaultExpanded={false}
                      square={true}
                      elevation={0}
                      expanded={subSectionsStatus["4.6"]}
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                        onClick={() =>
                          openSpecificSubSection(
                            setSubSectionsStatus,
                            subSectionsStatus,
                            { 4.6: !subSectionsStatus["4.6"] }
                          )
                        }
                      >
                        <Box className={classes.containerTitleInline}>
                          <Infrastructure className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>Infrastructure</span>
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{ root: classes.AccordionDetailsSubsection }}
                      >
                        <Typography
                          variant="body2"
                          className={classes.indentationLevel2Inline}
                        >
                          We process your data, together with third parties for{" "}
                          <span className={classes.bold}>
                            the purpose of managing and keeping the website
                            running and offering you different features
                          </span>
                          . Please take into account that such third parties may
                          process some limited personal data, for example web
                          traffic, even if you do not use the provided features.
                          More precisely, we use:
                        </Typography>

                        <List
                          className={classes.indentationLevel3}
                          classes={{ root: classes.listRoot }}
                        >
                          {infrastructureOne.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                external tools to help us build and manage our
                                website and/or key elements of it; Please
                                consider that these third party platforms
                                provide a wide range of tools such as user
                                registration, analytics, e-commerce, database
                                management and the handling of payments&taxes
                                witch require the processing of your personal
                                data;
                              </Typography>
                            </ListItem>
                          )}

                          {infrastructureTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                external fonts libraries which allow us to use
                                different types of writing;
                              </Typography>
                            </ListItem>
                          )}

                          {infrastructureThree.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                external hosting providers, which besides
                                hosting and running key elements of our website
                                can also provide other functionalities such as
                                commenting systems, payment processing, user
                                registration and others;
                              </Typography>
                            </ListItem>
                          )}

                          {infrastructureFour.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                external servers for saving and managing the
                                backups of our data which include our content
                                but also the data that you have provided to us;
                              </Typography>
                            </ListItem>
                          )}

                          {infrastructureFive.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                external security systems which allow us, for
                                example, to filter the communications between
                                our website and your browser, to recognise and
                                filter the content that is considered as spam,
                                to optimise and keep our traffic secure by
                                distributing the content among different servers
                                and others;
                              </Typography>
                            </ListItem>
                          )}

                          {infrastructureSix.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                external commenting systems, embedded in our
                                website, which allow you to post your comments
                                on our website. Please take into consideration
                                that you are responsible for the content of your
                                comments;
                              </Typography>
                            </ListItem>
                          )}

                          {infrastructureSeven.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                other external systems that help us run our
                                online infrastructure by managing the
                                administration, deployment and hosting of
                                features like: web conferencing, feed
                                management, online telephony, messaging,
                                webinars and others.
                              </Typography>
                            </ListItem>
                          )}
                        </List>

                        <Box
                          display="flex"
                          flexDirection="row"
                          className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                        >
                          <Typography variant="body2">Legal basis:</Typography>

                          <Box className={classes.chips}>
                            {anchorTips(
                              legalBasisData.infrastructure,
                              code,
                              setExplanationStatus
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>{" "}
                  </Grid>
                )}

                {/* 4.7 */}
                {showInteractionWithExternalPlatforms && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion
                      defaultExpanded={false}
                      square={true}
                      elevation={0}
                      expanded={subSectionsStatus["4.7"]}
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                        onClick={() =>
                          openSpecificSubSection(
                            setSubSectionsStatus,
                            subSectionsStatus,
                            { 4.7: !subSectionsStatus["4.7"] }
                          )
                        }
                      >
                        <Box className={classes.containerTitleInline}>
                          <InteractionExternal className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>
                              Interaction with external platforms
                            </span>
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{ root: classes.AccordionDetailsSubsection }}
                      >
                        {reduceSectionCondition(
                          data.interactionExternalPlatforms
                        ) && (
                          <>
                            <Typography
                              variant="body2"
                              className={classes.indentationLevel2Inline}
                            >
                              We process your data, together with third parties,
                              for{" "}
                              <span className={classes.bold}>
                                the purpose of allowing you visualise and
                                interact with content which is hosted on
                                external platforms, directly from our website
                              </span>
                              . Please take in consideration that:
                            </Typography>

                            <List
                              className={classes.indentationLevel3}
                              classes={{ root: classes.listRootLetter }}
                            >
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    a.
                                  </span>{" "}
                                  these platforms might still collect some
                                  limited personal data, such as traffic data,
                                  even if you do not interact with the provided
                                  features
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    b.
                                  </span>{" "}
                                  if you have a profile on these third party
                                  platforms, the data processed on our website
                                  can be connected with your data from your
                                  profile, limited to your privacy settings on
                                  each of these platforms.
                                </Typography>
                              </ListItem>
                            </List>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel2Inline}
                            >
                              For these reasons, in order to eliminate the
                              possibility to connect the data processed on our
                              website to the data from your profile on these
                              platforms, you should log out from your profile on
                              these platforms.
                            </Typography>

                            <Typography
                              variant="body2"
                              className={classes.indentationLevel2Inline}
                            >
                              More precisely, we process your data for allowing
                              you to interact with external platforms, directly
                              from our website, by:
                            </Typography>
                          </>
                        )}

                        <List
                          className={classes.indentationLevel3}
                          classes={{ root: classes.listRoot }}
                        >
                          {interactionExternalPlatformsOne.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography>
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                liking, sharing, commenting and performing other
                                interactions with{" "}
                                <span className={classes.bold}>
                                  social networks
                                </span>
                                . Please take into consideration that you are
                                responsible for the content of your comments.
                              </Typography>
                            </ListItem>
                          )}

                          {interactionExternalPlatformsTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography>
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                viewing and interacting with{" "}
                                <span className={classes.bold}>
                                  maps services
                                </span>
                                ;
                              </Typography>
                            </ListItem>
                          )}

                          {interactionExternalPlatformsThree.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography>
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                viewing and interacting with{" "}
                                <span className={classes.bold}>
                                  video platforms
                                </span>
                              </Typography>
                            </ListItem>
                          )}

                          {interactionExternalPlatformsFour.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography>
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                viewing and interacting with{" "}
                                <span className={classes.bold}>
                                  music services
                                </span>{" "}
                              </Typography>
                            </ListItem>
                          )}

                          {interactionExternalPlatformsFive.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography>
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                viewing and interacting with{" "}
                                <span className={classes.bold}>
                                  other external services
                                </span>
                                such as: calendar, search features, games,
                                images and others.
                              </Typography>
                            </ListItem>
                          )}
                        </List>

                        <Box
                          display="flex"
                          flexDirection="row"
                          className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                        >
                          <Typography variant="body2">Legal basis:</Typography>

                          <Box className={classes.chips}>
                            {anchorTips(
                              legalBasisData.interactionExternalPlatforms,
                              code,
                              setExplanationStatus
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                )}

                {/* 4.8 */}
                {reduceSectionCondition(data.marketing) && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion
                      defaultExpanded={false}
                      square={true}
                      elevation={0}
                      expanded={subSectionsStatus["4.8"]}
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                        onClick={() =>
                          openSpecificSubSection(
                            setSubSectionsStatus,
                            subSectionsStatus,
                            { 4.8: !subSectionsStatus["4.8"] }
                          )
                        }
                      >
                        <Box className={classes.containerTitleInline}>
                          <Marketing className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>Marketing</span>
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{ root: classes.AccordionDetailsSubsection }}
                      >
                        {reduceSectionCondition(data.marketing) && (
                          <Typography
                            variant="body2"
                            className={classes.indentationLevel2Inline}
                          >
                            We process your data in order to be able{" "}
                            <span className={classes.bold}>
                              to reach you, directly, for the purpose of
                              promoting our products & services
                            </span>
                            . Please take into consideration, that:
                          </Typography>
                        )}

                        <List
                          className={classes.indentationLevel3}
                          classes={{ root: classes.listRootLetter }}
                        >
                          {reduceSectionCondition(data.marketing) && (
                            <>
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    {sectionIdentifiesFourEight.shift()}
                                  </span>{" "}
                                  for this purpose, we might build and use user
                                  profiles and so, the content could be tailored
                                  to your interests and behaviour;
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    {sectionIdentifiesFourEight.shift()}
                                  </span>{" "}
                                  some communications could be automatically
                                  triggered by a specific action you perform
                                  such as a click on our website;
                                </Typography>
                              </ListItem>
                            </>
                          )}
                          {marketingOne.condition && !marketingTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  {sectionIdentifiesFourEight.shift()}
                                </span>{" "}
                                we do not use third parties for this process and
                                therefore all the relevant data used for this
                                purpose is processed only by us;
                              </Typography>
                            </ListItem>
                          )}

                          {marketingTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  {sectionIdentifiesFourEight.shift()}
                                </span>{" "}
                                for this purpose we also use the help of third
                                parties which have access to some of your
                                personal data, such as cookies and other
                                identifiers.
                              </Typography>
                            </ListItem>
                          )}
                        </List>

                        {marketingTwo.condition && (
                          <Typography
                            variant="body2"
                            className={classes.indentationLevel2Inline}
                          >
                            Please take into account that these third parties
                            might still collect some limited personal data, such
                            as traffic data, even if you do not interact with
                            the provided features. If you interact with them,
                            such third parties might also process data related
                            to the date and time of your interaction and the way
                            you interacted with the feature (e.g. clicking a
                            link in an email). Also, they provide analytics and
                            the possibility to retarget you. More precisely,
                            such third parties help us collect data about you
                            and:
                          </Typography>
                        )}

                        <List
                          className={classes.indentationLevel3}
                          classes={{ root: classes.listRoot }}
                        >
                          {marketingTwoOne.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                build and manage our contact database, by
                                allowing us to aggregate data such as email,
                                address, phone number and other and facilitating
                                our direct communication with you
                              </Typography>
                            </ListItem>
                          )}

                          {marketingTwoTwo.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                send you marketing email
                              </Typography>
                            </ListItem>
                          )}

                          {marketingTwoThree.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                send you marketing messages
                              </Typography>
                            </ListItem>
                          )}

                          {marketingTwoFour.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                send you notifications & pop-ups
                              </Typography>
                            </ListItem>
                          )}

                          {marketingTwoFive.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                send you surveys
                              </Typography>
                            </ListItem>
                          )}

                          {marketingTwoSix.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                send you newsletters
                              </Typography>
                            </ListItem>
                          )}

                          {marketingTwoSeven.condition && (
                            <ListItem classes={{ gutters: classes.gutters }}>
                              <Typography variant="body2">
                                <span
                                  className={`${classes.listItemText} ${classes.bold}`}
                                >
                                  •
                                </span>{" "}
                                allow us to analyse, measure and track the
                                marketing tags and scripts we have on our
                                website
                              </Typography>
                            </ListItem>
                          )}
                        </List>

                        <Box
                          display="flex"
                          flexDirection="row"
                          className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                        >
                          <Typography variant="body2">Legal basis:</Typography>

                          <Box className={classes.chips}>
                            {anchorTips(
                              legalBasisData.marketing,
                              code,
                              setExplanationStatus
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>{" "}
                  </Grid>
                )}

                {/* 4.9 */}
                {reduceSectionCondition(data.payments) && (
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Accordion
                      defaultExpanded={false}
                      square={true}
                      elevation={0}
                      expanded={subSectionsStatus["4.9"]}
                    >
                      <AccordionSummary
                        classes={{
                          root: classes.AccordionSummarySubsection,
                        }}
                        aria-controls="panel1-control"
                        id="panel1-id"
                        onClick={() =>
                          openSpecificSubSection(
                            setSubSectionsStatus,
                            subSectionsStatus,
                            { 4.9: !subSectionsStatus["4.9"] }
                          )
                        }
                      >
                        <Box className={classes.containerTitleInline}>
                          <Payments className="ppIconSvg" />

                          <Typography variant="body2">
                            <span
                              className={`${classes.bold} ${classes.subSectionNumber}`}
                            >
                              {sectionIdentifies.shift()}
                            </span>{" "}
                          </Typography>

                          <Typography variant="body2">
                            <span className={classes.bold}>Payments</span>
                          </Typography>
                        </Box>
                      </AccordionSummary>

                      <AccordionDetails
                        classes={{ root: classes.AccordionDetailsSubsection }}
                      >
                        {(paymentsOne.condition || paymentsTwo.condition) && (
                          <Typography
                            variant="body2"
                            className={classes.indentationLevel2Inline}
                          >
                            We process your data in order to be able{" "}
                            <span className={classes.bold}>
                              process your payments
                            </span>
                            .
                          </Typography>
                        )}

                        <br />

                        {paymentsOne.condition && !paymentsTwo.condition && (
                          <Typography
                            variant="body2"
                            className={classes.indentationLevel2Inline}
                          >
                            We do not use third parties for this process and
                            therefore all the relevant data used for this
                            purpose is processed only by us.
                          </Typography>
                        )}

                        {paymentsTwo.condition && (
                          <Typography
                            variant="body2"
                            className={classes.indentationLevel2Inline}
                          >
                            For this purpose we use the help of third parties
                            which are external payment service providers. All
                            the data that you provide for the purpose of making
                            a payment is processed only by such third parties
                            and we receive only a notification in relation to
                            the status of the payment (confirmed or not).
                          </Typography>
                        )}

                        <Box
                          display="flex"
                          flexDirection="row"
                          className={`${classes.indentationLevel2Inline} ${classes.boxChip}`}
                        >
                          <Typography variant="body2">Legal basis:</Typography>

                          <Box className={classes.chips}>
                            {anchorTips(
                              legalBasisData.payments,
                              code,
                              setExplanationStatus
                            )}
                          </Box>
                        </Box>
                      </AccordionDetails>
                    </Accordion>{" "}
                  </Grid>
                )}

                {/* Explanation */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.gridItemExplanation}
                >
                  <Accordion
                    defaultExpanded={false}
                    expanded={explanationStatus}
                    square={true}
                    elevation={0}
                    className={classes.expandPainelExplanationActive}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      classes={{
                        root: classes.AccordionSummary,
                      }}
                      onClick={() => setExplanationStatus(!explanationStatus)}
                      aria-controls="panel3-1-control"
                      id="panel3-1-id"
                    >
                      <span className={classes.anchor} id="legalBasis" />

                      <Typography
                        variant="body2"
                        className={`${classes.explanationTitle} ${classes.indentationLevel1}`}
                      >
                        Legal basis explained
                      </Typography>
                    </AccordionSummary>

                    <AccordionDetails
                      classes={{ root: classes.AccordionDetailsSubsection }}
                    >
                      <Grid
                        container
                        direction="column"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        className={classes.gridExplanation}
                      >
                        {uniqueLegalBasisData.includes("consent") && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.indentationLevel1}
                          >
                            <span className={classes.anchor} id="consent" />

                            <Chip
                              label="Consent"
                              className={classes.atomicChip}
                            />

                            <Typography variant="body2">
                              You have given your clear consent for us to
                              process your data for a specific purpose. You can
                              always withdraw your consent, freely and in any
                              moment. If you do so, and we do not have another
                              legal basis for processing your data, we will
                              immediately stop such processing.
                            </Typography>
                          </Grid>
                        )}

                        {uniqueLegalBasisData.includes("contract") && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.indentationLevel1}
                          >
                            <span className={classes.anchor} id="contract" />

                            <Chip
                              label="Contract"
                              className={classes.atomicChip}
                            />

                            <Typography variant="body2">
                              The processing is necessary for a contract you
                              have with us, or because you have asked us to take
                              specific steps before entering into a contract.
                            </Typography>
                          </Grid>
                        )}

                        {uniqueLegalBasisData.includes("legal obligation") && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.indentationLevel1}
                          >
                            <span
                              className={classes.anchor}
                              id="legal-obligation"
                            />

                            <Chip
                              label="Legal obligation"
                              className={classes.atomicChip}
                            />

                            <Typography variant="body2">
                              The processing is necessary for us to comply with
                              the law.
                            </Typography>
                          </Grid>
                        )}

                        {uniqueLegalBasisData.includes("vital interests") && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.indentationLevel1}
                          >
                            <span
                              className={classes.anchor}
                              id="vital-interests"
                            />

                            <Chip
                              label="Vital interests"
                              className={classes.atomicChip}
                            />

                            <Typography variant="body2">
                              The processing is necessary to protect someone’s
                              life.
                            </Typography>
                          </Grid>
                        )}

                        {uniqueLegalBasisData.includes("public task") && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.indentationLevel1}
                          >
                            <span className={classes.anchor} id="public-task" />

                            <Chip
                              label="Public task"
                              className={classes.atomicChip}
                            />

                            <Typography variant="body2">
                              The processing is necessary so that we can perform
                              a task in the public interest or for official
                              functions, and such task or function has a clear
                              basis in law.
                            </Typography>
                          </Grid>
                        )}

                        {uniqueLegalBasisData.includes(
                          "legitimate interest"
                        ) && (
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            className={classes.indentationLevel1}
                          >
                            <span
                              className={classes.anchor}
                              id="legitimate-interest"
                            />

                            <Chip
                              label="Legitimate interest"
                              className={classes.atomicChip}
                            />

                            <Typography variant="body2">
                              The processing is necessary for our legitimate
                              interests or the legitimate interests of a third
                              party, unless your rights and interest out-weight
                              our legitimate interests. Such legitimate interest
                              could refer to:
                            </Typography>

                            <List className={classes.indentationLevel1List}>
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  gaining insights from your behaviour on our
                                  platform
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  delivering, developing and improving our
                                  service
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  enabling us to enhance, customise or modify
                                  our services, content and communications
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  determining whether marketing campaigns are
                                  efective
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  enhancing data security
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  marketing our products & services to existing
                                  customers
                                </Typography>
                              </ListItem>

                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  displaying advertising on our platform,
                                  tailored to your interests
                                </Typography>
                              </ListItem>
                            </List>
                          </Grid>
                        )}
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              sm={4}
              md={4}
              lg={4}
              className={classes.gridItemWhyHowUseDataNote}
            >
              <Box
                display="flex"
                flexDirection="column"
                p={2}
                alignItems="stretch"
                className={classes.boxWhyHowUseDataNote}
              >
                <Typography
                  variant="body2"
                  className={`${classes.noteTitle} ${classes.noteText}`}
                >
                  Basically
                </Typography>

                <Typography variant="body2" className={classes.noteText}>
                  According to the law, we can use your data only for specific
                  reasons and only if we have a legal basis to do so.
                </Typography>

                <Typography variant="body2" className={classes.noteText}>
                  Here we let you know what are the specific reasons for which
                  we use your personal data and what is the legal basis.{" "}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};

const WhereProcessStoreYourDataSection = ({ data }) => {
  const classes = useStyles({ colors: data.colors });

  const [subSectionsStatus, setSubSectionsStatus] = useState({
    clicked: false,
    5.1: false,
    5.2: false,
  });

  const projectCountry = getAttributeProject(data.project, "country");

  const dataStorageTransfersOneOne = getSectionQuestion(
    data.dataStorageTransfers,
    "1.1"
  );

  const dataStorageTransfersOneTwo = getSectionQuestion(
    data.dataStorageTransfers,
    "1.2"
  );

  const dataStorageTransfersOneOneListTextSafeguards =
    getSectionQuestionListText(data.dataStorageTransfers, "1.1", "safeguards");

  return (
    <Accordion
      defaultExpanded={false}
      expanded={true}
      square={true}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={
          (subSectionsStatus["5.1"] || !dataStorageTransfersOneOne.condition) &&
          dataStorageTransfersOneOne.condition ? (
            <ExpandMoreIcon
              className={
                !dataStorageTransfersOneOne.condition
                  ? ""
                  : subSectionsStatus.clicked === true
                  ? classes.expandIconActiveRotation
                  : classes.expandIconDefaultRotation
              }
            />
          ) : null
        }
        classes={{
          root: classes.AccordionSummary,
          content: classes.expandContent,
        }}
        onClick={() =>
          toggleAllSubSections(setSubSectionsStatus, subSectionsStatus)
        }
        aria-controls="panel4-control"
        id="panel4-id"
      >
        <Typography variant="h2" className={classes.sectionHeaderTitle}>
          5. Where we process and store your data
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.AccordionDetails }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.gridWhereProcessData}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={8}
            className={classes.gridItemWhereProcessDataContent}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={classes.gridItemWhereProcessDataContentContainer}
            >
              {/* 5.1 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  square={true}
                  elevation={0}
                  expanded={
                    subSectionsStatus["5.1"] ||
                    !dataStorageTransfersOneOne.condition
                  }
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 5.1: !subSectionsStatus["5.1"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box className={classes.containerTitle}>
                      <PersonalData className="ppIconSvg" />

                      <Typography variant="body2">
                        <span
                          className={`${classes.bold} ${classes.subSectionNumber}`}
                        >
                          5.1
                        </span>{" "}
                      </Typography>

                      <Typography variant="body2">
                        Your personal data is processed and stored in{" "}
                        {projectCountry}.
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2}
                    >
                      Also, the processing takes place in any of the data
                      processing facilities operated by the third parties
                      identified in section 6,{" "}
                      <span className={classes.bold}>
                        “Third parties we share data with”
                      </span>
                      . Under the same section you can find a link to their
                      Privacy Policy.
                    </Typography>

                    <br />
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2}
                    >
                      By using our service and submitting your personal data you
                      agree to to the processing, storing and transfer of your
                      personal data according to this policy. If in this way we
                      transfer or store data outside the EEA, we will take the
                      necessary steps in order to make sure that your rights
                      continue to be protected.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 5.2 */}
              {dataStorageTransfersOneOne.condition && (
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Accordion
                    defaultExpanded={false}
                    square={true}
                    elevation={0}
                    expanded={subSectionsStatus["5.2"]}
                    onClick={() =>
                      openSpecificSubSection(
                        setSubSectionsStatus,
                        subSectionsStatus,
                        { 5.2: !subSectionsStatus["5.2"] }
                      )
                    }
                  >
                    <AccordionSummary
                      classes={{
                        root: classes.AccordionSummarySubsection,
                      }}
                      aria-controls="panel1-control"
                      id="panel1-id"
                    >
                      <Box className={classes.containerTitle}>
                        <LevelProtection className="ppIconSvg" />

                        <Typography variant="body2">
                          <span
                            className={`${classes.bold} ${classes.subSectionNumber}`}
                          >
                            5.2
                          </span>{" "}
                        </Typography>

                        <Typography variant="body2">
                          Taking into account that {projectCountry} does not
                          offer an adequate level of protection according to
                          European standards, we are implementing appropriate
                          safeguards.
                        </Typography>
                      </Box>
                    </AccordionSummary>

                    <AccordionDetails
                      classes={{
                        root: classes.AccordionDetailsSubsection,
                      }}
                    >
                      {!!dataStorageTransfersOneOneListTextSafeguards.length && (
                        <>
                          <Typography
                            variant="body2"
                            className={classes.indentationLevel2}
                          >
                            The processing takes place on the basis of:
                          </Typography>

                          <List
                            className={classes.indentationLevel3}
                            classes={{ root: classes.listRoot }}
                          >
                            {dataStorageTransfersOneOneListTextSafeguards.some(
                              (safeguard) =>
                                safeguard
                                  ?.toLowerCase()
                                  ?.includes("SCC".toLowerCase())
                            ) && (
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  SCC - Standard data protection Contractual
                                  Clauses approved by the European Commission
                                </Typography>
                              </ListItem>
                            )}

                            {dataStorageTransfersOneOneListTextSafeguards.some(
                              (safeguard) =>
                                safeguard
                                  ?.toLowerCase()
                                  ?.includes("BCR".toLowerCase())
                            ) && (
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  BCR - Binding Corporate Rules submitted and
                                  approved by a Supervising Data Protection
                                  Authority
                                </Typography>
                              </ListItem>
                            )}

                            {dataStorageTransfersOneOneListTextSafeguards.some(
                              (safeguard) =>
                                safeguard
                                  ?.toLowerCase()
                                  ?.includes(
                                    "Your explicit and informed consent".toLowerCase()
                                  )
                            ) && (
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  your explicit and informed consent
                                </Typography>
                              </ListItem>
                            )}

                            {dataStorageTransfersOneOneListTextSafeguards.some(
                              (safeguard) =>
                                safeguard
                                  ?.toLowerCase()
                                  ?.includes("A Code of Conduct ".toLowerCase())
                            ) && (
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  a Code of Conduct approved by a Supervising
                                  Data Protection Authority
                                </Typography>
                              </ListItem>
                            )}

                            {dataStorageTransfersOneTwo.condition && (
                              <ListItem classes={{ gutters: classes.gutters }}>
                                <Typography variant="body2">
                                  <span
                                    className={`${classes.listItemText} ${classes.bold}`}
                                  >
                                    •
                                  </span>{" "}
                                  we are Privacy Shield Certified on the basis
                                  of the framework designed by the U.S.
                                  Department of Commerce and approved by the the
                                  European Commission and Swiss Administration.
                                  You can find our certification here:{" "}
                                  <Link
                                    href="https://www.privacyshield.gov/list"
                                    target="_blank"
                                    rel="noreferrer"
                                    className={classes.colorLink}
                                    style={{ wordBreak: "break-all" }}
                                  >
                                    https://www.privacyshield.gov/list
                                  </Link>
                                </Typography>
                              </ListItem>
                            )}
                          </List>
                        </>
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            className={classes.gridItemWhereProcessDataNote}
          >
            <Box
              display="flex"
              flexDirection="column"
              p={2}
              alignItems="stretch"
              className={classes.boxWhereProcessDataNote}
            >
              <Typography
                variant="body2"
                className={`${classes.noteTitle} ${classes.noteText}`}
              >
                Basically
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                This section informs you about the place in which we process
                your data.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                This could be storage or handling of your personal data.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                We follow the law for processing your data safely and we require
                the same from the third parties we work with.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const ThirdPartiesSection = ({ data }) => {
  const classes = useStyles({ colors: data.colors });

  const audaxlyThirdParty = {
    providerName: "Audaxly, srl.",
    providerService: "Audaxly",
    questionGenerator: "Q7 - Other infrastructure",
    dataCollected: "Data that identifies you, Data on how you use the service",
    specificData: null,
    providerPrivacyLink: `${process.env.REACT_APP_HOST_NAME}/privacy-policy?code=g8eds9kkrvpb01`,
    optOut: null,
    placeProcessing: "Romania",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  };

  const thirdPartiesTableData = [
    {
      icon: AccessConnectivity,
      title: "Access and Connectivity",
      detail: unionThirdParties(data.accessConnectivity),
    },
    {
      icon: Advertising,
      title: "Advertising",
      detail: unionThirdParties(data.advertising),
    },
    {
      icon: Analytics,
      title: "Analytics",
      detail: unionThirdParties(data.analytics),
    },
    {
      icon: CustomerSupport,
      title: "Customer Support",
      detail: unionThirdParties(data.customerSupport),
    },
    {
      icon: Infrastructure,
      title: "Infrastructure",
      detail: [...unionThirdParties(data.infrastructure)],
    },
    {
      icon: InteractionExternal,
      title: "Interaction with external platforms",
      detail: unionThirdParties(data.interactionExternalPlatforms),
    },
    {
      icon: Marketing,
      title: "Marketing",
      detail: unionThirdParties(data.marketing),
    },
    {
      icon: Payments,
      title: "Payments",
      detail: unionThirdParties(data.payments),
    },
  ];

  if (data?.project[0]?.url !== process.env.REACT_APP_HOST_NAME) {
    const infrastructureIndex = thirdPartiesTableData.findIndex(
      (thirdParty) => thirdParty.title === "Infrastructure"
    );

    thirdPartiesTableData[infrastructureIndex].detail.push(audaxlyThirdParty);
  }

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      square={true}
      elevation={0}
    >
      <AccordionSummary
        classes={{
          root: classes.AccordionSummaryClosed,
          content: classes.expandContent,
        }}
        aria-controls="panel5-control"
        id="panel5-id"
      >
        <Typography variant="h2" className={classes.sectionHeaderTitle}>
          6. Third parties we share data with
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.AccordionDetails }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.gridThirdParties}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={8}
            className={classes.gridItemThirdPartiesContent}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={classes.gridItemThirdPartiesContentContainer}
            >
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography
                  variant="body2"
                  className={classes.indentationLevel1}
                >
                  For running our website we use the help of the third parties
                  enlisted in the table below, where you will also find: the
                  purposes for which we share data with them, their details, a
                  link to their privacy policy, the categories of data they
                  collect or we share with each of them, as well as the place in
                  which the data is processed.
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                <TableContainer
                  component={Box}
                  elevation={0}
                  className={classes.tableContainer}
                >
                  <Table className={classes.table} aria-label="third parties">
                    <TableHead className={classes.tableHead}>
                      <TableRow className={classes.rowContentHead}>
                        <TableCell className={classes.tableHeadCell}>
                          Purpose
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Service Provider & Privacy Policy
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Data collected or shared
                        </TableCell>

                        <TableCell className={classes.tableHeadCell}>
                          Place of processing
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {thirdPartiesTableData
                        .filter((item) => item.detail.length)
                        .map((item, index) => {
                          if (item.detail.length === 1) {
                            return (
                              <TableRow
                                className={classes.rowContentBody}
                                key={`${index} table row 1`}
                              >
                                <TableCell
                                  className={classes.tableBodyCellIcon}
                                >
                                  {<item.icon className="ppIconSvg" />}

                                  <Typography
                                    variant="body2"
                                    className={`${classes.tableTextColor} ${classes.bold}`}
                                  >
                                    {item.title}
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  className={classes.tableBodyCellProvider}
                                >
                                  <Typography
                                    variant="body2"
                                    className={`${classes.tableTextColor} ${classes.bold}`}
                                  >
                                    {item.detail[0].providerName}
                                  </Typography>

                                  <Typography variant="body2">
                                    <Link
                                      href={item.detail[0].providerPrivacyLink}
                                      target="_blank"
                                      rel="noreferrer"
                                      className={classes.colorLink}
                                    >
                                      Privacy Policy
                                    </Link>
                                  </Typography>
                                </TableCell>

                                <TableCell
                                  className={classes.tableBodyCellList}
                                >
                                  <List classes={{ root: classes.listRoot }}>
                                    {item.detail[0].dataCollected
                                      .split(",")
                                      .map((dataCollectedItem, index) => (
                                        <ListItem
                                          classes={{
                                            gutters: classes.gutters,
                                          }}
                                          key={`${dataCollectedItem} ${index} table list 1 item`}
                                        >
                                          <Typography
                                            variant="body2"
                                            className={classes.tableTextColor}
                                          >
                                            <span
                                              className={`${classes.listItemText} ${classes.bold}`}
                                            >
                                              •
                                            </span>{" "}
                                            {dataCollectedItem}
                                          </Typography>
                                        </ListItem>
                                      ))}
                                  </List>
                                </TableCell>

                                <TableCell
                                  className={classes.tableBodyCellPlace}
                                >
                                  <Typography
                                    variant="body2"
                                    className={classes.tableTextColor}
                                  >
                                    {item.detail[0].placeProcessing}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          } else {
                            return (
                              <React.Fragment key={`${index} fragment table`}>
                                <TableRow
                                  className={classes.rowContentBody}
                                  key={`${index} table row 2`}
                                >
                                  <TableCell
                                    rowSpan={item.detail.length}
                                    className={classes.tableBodyCellIcon}
                                  >
                                    {<item.icon className="ppIconSvg" />}

                                    <Typography
                                      variant="body2"
                                      className={`${classes.tableTextColor} ${classes.bold}`}
                                    >
                                      {item.title}
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className={classes.tableBodyCellProvider}
                                  >
                                    <Typography
                                      variant="body2"
                                      className={`${classes.tableTextColor} ${classes.bold}`}
                                    >
                                      {item.detail[0].providerName}
                                    </Typography>

                                    <Typography variant="body2">
                                      <Link
                                        href={
                                          item.detail[0].providerPrivacyLink
                                        }
                                        target="_blank"
                                        rel="noreferrer"
                                        className={classes.colorLink}
                                      >
                                        Privacy Policy
                                      </Link>
                                    </Typography>
                                  </TableCell>

                                  <TableCell
                                    className={classes.tableBodyCellList}
                                  >
                                    <List classes={{ root: classes.listRoot }}>
                                      {item.detail[0].dataCollected
                                        .split(",")
                                        .map((dataCollectedItem, index) => (
                                          <ListItem
                                            classes={{
                                              gutters: classes.gutters,
                                            }}
                                            key={`${index} ${dataCollectedItem} table list 2 item`}
                                          >
                                            <Typography
                                              variant="body2"
                                              className={classes.tableTextColor}
                                            >
                                              <span
                                                className={`${classes.listItemText} ${classes.bold}`}
                                              >
                                                •
                                              </span>{" "}
                                              {dataCollectedItem}
                                            </Typography>
                                          </ListItem>
                                        ))}
                                    </List>
                                  </TableCell>

                                  <TableCell
                                    className={classes.tableBodyCellPlace}
                                  >
                                    <Typography
                                      variant="body2"
                                      className={classes.tableTextColor}
                                    >
                                      {item.detail[0].placeProcessing}
                                    </Typography>
                                  </TableCell>
                                </TableRow>

                                {item.detail
                                  .slice(1, item.detail.length)
                                  .map((detail, index) => (
                                    <TableRow
                                      className={classes.rowContentBody}
                                      key={`${index} table row 3`}
                                    >
                                      <TableCell
                                        className={
                                          classes.tableBodyCellProvider
                                        }
                                      >
                                        <Typography
                                          variant="body2"
                                          className={`${classes.tableTextColor} ${classes.bold}`}
                                        >
                                          {detail.providerName}
                                        </Typography>

                                        <Typography variant="body2">
                                          <Link
                                            href={detail.providerPrivacyLink}
                                            target="_blank"
                                            rel="noreferrer"
                                            className={classes.colorLink}
                                          >
                                            Privacy Policy
                                          </Link>
                                        </Typography>
                                      </TableCell>

                                      <TableCell
                                        className={classes.tableBodyCellList}
                                      >
                                        <List
                                          classes={{ root: classes.listRoot }}
                                        >
                                          {detail.dataCollected
                                            .split(",")
                                            .map((dataCollectedItem, index) => (
                                              <ListItem
                                                classes={{
                                                  gutters: classes.gutters,
                                                }}
                                                key={`${index} ${dataCollectedItem} table list 3 item`}
                                              >
                                                <Typography
                                                  variant="body2"
                                                  className={
                                                    classes.tableTextColor
                                                  }
                                                >
                                                  <span
                                                    className={`${classes.listItemText} ${classes.bold}`}
                                                  >
                                                    •
                                                  </span>{" "}
                                                  {dataCollectedItem}
                                                </Typography>
                                              </ListItem>
                                            ))}
                                        </List>
                                      </TableCell>

                                      <TableCell
                                        className={classes.tableBodyCellPlace}
                                      >
                                        <Typography
                                          variant="body2"
                                          className={classes.tableTextColor}
                                        >
                                          {detail.placeProcessing}
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                              </React.Fragment>
                            );
                          }
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            className={classes.gridItemThirdPartiesNote}
          >
            <Box
              display="flex"
              flexDirection="column"
              p={2}
              alignItems="stretch"
              className={classes.boxThirdPartiesNote}
            >
              <Typography
                variant="body2"
                className={`${classes.noteTitle} ${classes.noteText}`}
              >
                Basically
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                While the majority of our data processing activities are hadled
                by ourselves, for certain purposes we use the help of third
                parties.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                We choose them carefully.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                Here we let you know who they are and why we share data with
                them and we also provide a link to their Privacy Policy.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const HowLongKeepYourDataSection = ({ data }) => {
  const classes = useStyles({ colors: data.colors });

  const [subSectionsStatus, setSubSectionsStatus] = useState({
    clicked: false,
    7.1: false,
    7.2: false,
  });

  const projectUrl = getAttributeProject(data.project, "url");

  const projectEmail = getAttributeProject(data.project, "email");

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      square={true}
      elevation={0}
    >
      <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            className={
              subSectionsStatus.clicked === true
                ? classes.expandIconActiveRotation
                : classes.expandIconDefaultRotation
            }
          />
        }
        classes={{
          root: classes.AccordionSummary,
          content: classes.expandContent,
        }}
        onClick={() =>
          toggleAllSubSections(setSubSectionsStatus, subSectionsStatus)
        }
        aria-controls="panel6-control"
        id="panel6-id"
      >
        <Typography variant="h2" className={classes.sectionHeaderTitle}>
          7. For how long we keep your data and how we keep it safe
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.AccordionDetails }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.gridHowLongKeepData}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={8}
            className={classes.gridItemHowLongKeepDataContent}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="stretch"
              className={classes.gridItemHowLongKeepDataContentContainer}
            >
              {/* 7.1 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  square={true}
                  elevation={0}
                  expanded={subSectionsStatus["7.1"]}
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 7.1: !subSectionsStatus["7.1"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box>
                      <Box className={classes.containerTitleInline}>
                        <HowLongKeepData className="ppIconSvg" />

                        <Typography variant="body2">
                          <span
                            className={`${classes.bold} ${classes.subSectionNumber}`}
                          >
                            7.1
                          </span>{" "}
                        </Typography>

                        <Typography variant="body2">
                          <span className={classes.bold}>
                            For how long we keep your data
                          </span>
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        className={classes.indentationLevel2}
                      >
                        We limit the storage period of your personal data to the
                        time necessary to fulfill the purposes for which we
                        collected it.
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2Inline}
                    >
                      This period may be longer if:
                    </Typography>

                    <List
                      className={classes.indentationLevel3}
                      classes={{ root: classes.listRootLetter }}
                    >
                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            a.
                          </span>{" "}
                          we are required to do so for the performance of a
                          legal obligation
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            b.
                          </span>{" "}
                          there is an order of a public authority
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            c.
                          </span>{" "}
                          we are required to do so for the purpose of satisfying
                          any legal, accounting, or reporting obligations or to
                          resolve disputes
                        </Typography>
                      </ListItem>
                    </List>

                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2Inline}
                    >
                      Also
                    </Typography>

                    <List
                      className={classes.indentationLevel3}
                      classes={{ root: classes.listRoot }}
                    >
                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          When the retention period expires, we will delete your
                          personal data and the rights related to access,
                          erasure, rectification and transfer will not be
                          enforceable.
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          For the cases in which you gave us consent to process
                          your personal data, we will retain it until you
                          withdraw such consent.
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          If the data is collected and processes for the purpose
                          of a contract, we will retain it ultil the contract is
                          fully performed.
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          If the data is collected and processed based on our
                          legitimate interest it will be retained until such
                          interest is fulfilled.
                        </Typography>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>

              {/* 7.2 */}
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Accordion
                  defaultExpanded={false}
                  square={true}
                  elevation={0}
                  expanded={subSectionsStatus["7.2"]}
                  onClick={() =>
                    openSpecificSubSection(
                      setSubSectionsStatus,
                      subSectionsStatus,
                      { 7.2: !subSectionsStatus["7.2"] }
                    )
                  }
                >
                  <AccordionSummary
                    classes={{
                      root: classes.AccordionSummarySubsection,
                    }}
                    aria-controls="panel1-control"
                    id="panel1-id"
                  >
                    <Box>
                      <Box className={classes.containerTitleInline}>
                        <HowLongKeepDataSafe className="ppIconSvg" />

                        <Typography variant="body2">
                          <span
                            className={`${classes.bold} ${classes.subSectionNumber}`}
                          >
                            7.2
                          </span>{" "}
                        </Typography>

                        <Typography variant="body2">
                          <span className={classes.bold}>
                            How we keep your data safe
                          </span>
                        </Typography>
                      </Box>
                      <Typography
                        variant="body2"
                        className={classes.indentationLevel2}
                      >
                        At{" "}
                        <Link
                          href={projectUrl}
                          target="_blank"
                          rel="noreferrer"
                          className={classes.colorLink}
                        >
                          {projectUrl}
                        </Link>{" "}
                        we are making efforts to ensure the security,
                        confidentiality, and integrity of your personal data.
                      </Typography>
                    </Box>
                  </AccordionSummary>

                  <AccordionDetails
                    classes={{ root: classes.AccordionDetailsSubsection }}
                  >
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2Inline}
                    >
                      We use commercially reasonable technological, physical,
                      and administrative security safeguards and we have
                      implemented the necessary procedures to prevent
                      unauthorized access, disclosure, modification, or
                      destruction of your personal data.
                    </Typography>

                    <br />
                    <Typography
                      variant="body2"
                      className={classes.indentationLevel2Inline}
                    >
                      Please take into account:
                    </Typography>

                    <List
                      className={classes.indentationLevel3}
                      classes={{ root: classes.listRoot }}
                    >
                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          no electronic transmission of data is ever fully
                          secure or error free, so please take care in deciding
                          what information you disclose, as any such
                          transmission is at your own risk
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          if you believe that your privacy has been breached
                          please contact us immediatly at{" "}
                          <Link
                            href={"mailto:" + projectEmail}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.colorLink}
                          >
                            {projectEmail}
                          </Link>
                        </Typography>
                      </ListItem>

                      <ListItem classes={{ gutters: classes.gutters }}>
                        <Typography variant="body2">
                          <span
                            className={`${classes.listItemText} ${classes.bold}`}
                          >
                            •
                          </span>{" "}
                          we are not to be held responsible for the
                          functionality and security measures of any third party
                        </Typography>
                      </ListItem>
                    </List>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            className={classes.gridItemHowLongKeepDataNote}
          >
            <Box
              display="flex"
              flexDirection="column"
              p={2}
              alignItems="stretch"
              className={classes.boxHowLongKeepDataNote}
            >
              <Typography
                variant="body2"
                className={`${classes.noteTitle} ${classes.noteText}`}
              >
                Basically
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                In order to keep your data safe and to comply with the law we
                use security safeguards and only keep your personal data for as
                long as it is necessary to fulfill the purpose for which it was
                collected.
              </Typography>

              <Typography variant="body2" className={classes.noteText}>
                Here we let you know the criteria we apply.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

const FinalWordsSection = ({ data }) => {
  const classes = useStyles({ colors: data.colors });

  const projectEmail = getAttributeProject(data.project, "email");

  return (
    <Accordion
      defaultExpanded={true}
      expanded={true}
      square={true}
      elevation={0}
    >
      <AccordionSummary
        classes={{
          root: classes.AccordionSummaryClosed,
          content: classes.expandContent,
        }}
        aria-controls="panel1-control"
        id="panel1-id"
      >
        <Typography variant="h2" className={classes.sectionHeaderTitle}>
          8. Final words
        </Typography>
      </AccordionSummary>

      <AccordionDetails classes={{ root: classes.AccordionDetails }}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          className={classes.gridFinalWords}
        >
          <Grid item xs={12} sm={12} md={11} lg={8}>
            <Typography variant="body2" className={classes.indentationLevel1}>
              Thank you for your interest in privacy and for getting to the end
              of our policy!
            </Typography>

            <br />
          </Grid>

          <Grid item xs={12} sm={12} md={11} lg={8}>
            <Typography variant="body2" className={classes.indentationLevel1}>
              Please consider that we might change this policy with time but we
              are compromising ourselvs to always respect your privacy! However,
              please do periodically review this page for the latest information
              as your continued use of our services after the effective date of
              this policy,{" "}
              <span className={classes.bold}>
                {moment(data.updatedAt, "x").format("MMMM Do YYYY")}
              </span>
              , constitutes an acceptance of our terms.
            </Typography>

            <br />
          </Grid>

          <Grid item xs={12} sm={12} md={11} lg={8}>
            <Typography variant="body2" className={classes.indentationLevel1}>
              Also, if you have any questions about this policy or generally
              about the way we process your personal data contact us at:{" "}
              <Link
                href={"mailto:" + projectEmail}
                target="_top"
                className={classes.colorLink}
              >
                {projectEmail}
              </Link>
              . We are happy to answer any inquiry you may have!
            </Typography>

            <Box className={classes.boxContainerPoweredBy}>
              <Typography variant="body2" className={classes.textPoweredBy}>
                Powered by
              </Typography>

              <Link
                href="https://audaxly.com/"
                target="_black"
                className={classes.linkPoweredBy}
              >
                <img
                  src={logoDark}
                  alt="Audaxly"
                  className={classes.imagePoweredBy}
                />
              </Link>
            </Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

// PP query pages
const ErrorPPQuery = (error) => {
  const classes = useStyles();

  return (
    <Box className={classes.containerError}>
      <Box className={classes.containerErrorLogo}>
        <img src={errorOops} alt="Error" className={classes.errorBanner} />
      </Box>

      <Box className={classes.containerErrorText}>
        <Box className={classes.subContainerErrorText}>
          <Typography variant="h1" className={classes.errorTextTitle}>
            Looks like you ran into a problem...
          </Typography>

          <Typography variant="body3" className={classes.errorText}>
            If you’re a visitor and have any concerns about your privacy on this
            website, please contact us directly.
          </Typography>

          <Typography variant="body3" className={classes.errorText}>
            If you’re the website administrator, please make sure all the
            embedding steps have been followed carefully.
          </Typography>

          <Typography variant="body3" className={classes.errorText}>
            Thank you,
          </Typography>

          <Typography
            variant="body3"
            className={`${classes.errorText} ${classes.errorTextThankYou}`}
          >
            The Audaxly Team
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

// PP cookie disabled
const ErrorPPCookie = () => {
  const classes = useStyles();

  return (
    <Box className={classes.containerError}>
      <Box className={classes.containerErrorLogo}>
        <img src={errorOops} alt="Error" className={classes.errorBanner} />
      </Box>

      <Box className={classes.containerErrorText}>
        <Box className={classes.subContainerErrorText}>
          <Typography variant="h1" className={classes.errorTextTitle}>
            Looks like you ran into a problem...
          </Typography>

          <Typography variant="body3" className={classes.errorText}>
            To see the Privacy Policy you need to enable the cookies. For that
            click the information or the padlock on the top of your browser.
          </Typography>

          <Typography variant="body3" className={classes.errorText}>
            Thank you,
          </Typography>

          <Typography
            variant="body3"
            className={`${classes.errorText} ${classes.errorTextThankYou}`}
          >
            The Audaxly Team
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const LoadingPPQuery = (loading) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <LoadingPage styleClass={classes.loadingPage} />
      </Grid>
    </Grid>
  );
};

// PP query
const defaultReturnAttributes = `
	identify
	title
	condition
	conditionText
	url
	legalBasis
	devicePermissions
	dataStorageAndTransfers
	safeguards
	thirdParties {
	  thirdPartyName
	}
	personalData {
	  tag
	  dataType
	  sensitive
	}
`;

const PRIVACY_POLICY_PUBLIC = gql`
	query PrivacyPolicyPublic($publicCode: ID!) {
		privacyPolicyPublic(publicCode: $publicCode) {
			colors {
				main
				link
				icon
				scroll
			}
			overrides {
				disablePadding
				disableError
			}
			updatedAt
			project {
				legalName
				country
				state
				address
				postalCode
				email
				url
				plan {
					startAt
				}
				firstName
				lastName
			}
			accessConnectivity {
				${defaultReturnAttributes}
			}
			advertising {
				${defaultReturnAttributes}
			}
			analytics {
				${defaultReturnAttributes}
			}
			customerSupport {
				${defaultReturnAttributes}
			}
			devicePermission {
				${defaultReturnAttributes}
			}
			infrastructure {
				${defaultReturnAttributes}
			}
			interactionExternalPlatforms {
				${defaultReturnAttributes}
			}
			marketing {
				${defaultReturnAttributes}
			}
			payments {
				${defaultReturnAttributes}
			}
			dataStorageTransfers {
				${defaultReturnAttributes}
			}
			generalQuestions {
				${defaultReturnAttributes}
			}
		}
	}
`;

// Sections
const Sections = ({ queryString }) => {
  const [scrollHeight, setScrollHeight] = useState(
    document?.body?.scrollHeight
  );

  const { loading, error, data } = useQuery(PRIVACY_POLICY_PUBLIC, {
    variables: { publicCode: queryString?.code },
  });

  // Default attributes
  let dataExtensible = {
    colors: {
      main: "#000000",
      link: "#424242",
      icon: "#000000",
      scroll: "transparent",
    },
    overrides: {
      disablePadding: false,
      disableError: false,
    },
  };

  const handleDocumentResize = () => {
    setScrollHeight(document?.body?.scrollHeight);
  };

  useEffect(() => {
    window.addEventListener("resize", handleDocumentResize, {
      passive: true,
    });

    return () => {
      window.removeEventListener("resize", handleDocumentResize);
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading) {
      setScrollHeight(document?.body?.scrollHeight);

      if (window?.parent && !error) {
        const url = new URL(data?.privacyPolicyPublic?.project?.at(0)?.url);

        window?.parent?.postMessage(
          {
            source: "audaxly",
            document: "privacy policy",
            height: `${scrollHeight}px`,
          },
          `${url.protocol}//${url.host}/*`
        );
      }
    }

    // eslint-disable-next-line
  }, [loading, scrollHeight]);

  if (typeof data === "object" && data !== null)
    dataExtensible = { ...dataExtensible, ...data.privacyPolicyPublic };

  const classes = useStyles({
    colors: dataExtensible.colors,
    overrides: dataExtensible.overrides,
  });

  if (loading) return <LoadingPPQuery loading={loading} />;

  if (error)
    return dataExtensible?.overrides?.disableError ? (
      <></>
    ) : (
      <ErrorPPQuery error={error} />
    );

  if (!navigator?.cookieEnabled) return <ErrorPPCookie />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.gridGlobal}
    >
      {/* Titke */}
      <Helmet>
        <title>
          {getAttributeProject(dataExtensible.project, "legalName") && getAttributeProject(dataExtensible.project, "legalName")}
          {getAttributeProject(dataExtensible.project, "firstName") && getAttributeProject(dataExtensible.project, "firstName")}{" "}
          {getAttributeProject(dataExtensible.project, "lastName") && getAttributeProject(dataExtensible.project, "lastName")} - Privacy Policy</title>
      </Helmet>

      {/* 0. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={11}
        lg={7}
        className={classes.gridItemIntro}
      >
        <IntroSection data={dataExtensible} />
      </Grid>

      {/* 1. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemDrawer}
      >
        <WhoWeAreSection data={dataExtensible} />
      </Grid>

      {/* 2. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemDrawer}
      >
        <WhatAreYouRightsSection data={dataExtensible} />
      </Grid>

      {/* 3. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemDrawer}
      >
        <WhatDataWeCollectSection data={dataExtensible} />
      </Grid>

      {/* 4. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemDrawer}
      >
        <WhyHowUseYourDataSection
          data={dataExtensible}
          code={queryString.code}
        />
      </Grid>

      {/* 5. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemDrawer}
      >
        <WhereProcessStoreYourDataSection data={dataExtensible} />
      </Grid>

      {/* 6. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemDrawer}
      >
        <ThirdPartiesSection data={dataExtensible} />
      </Grid>

      {/* 7. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemDrawer}
      >
        <HowLongKeepYourDataSection data={dataExtensible} />
      </Grid>

      {/* 8. */}
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemDrawer}
      >
        <FinalWordsSection data={dataExtensible} />
      </Grid>
    </Grid>
  );
};

export default Sections;
