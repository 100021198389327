import React, { createContext, useContext, useEffect, useReducer } from "react";

import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../utils";

const defaultStateUser = {};

const reducer = (previousState, newState) => {
  if (newState === null) {
    removeLocalStorageItem({
      key: process.env.REACT_APP_USER_LOCAL_STORAGE_KEY,
    });

    return defaultStateUser;
  }

  return { ...previousState, ...newState };
};

const UserContext = createContext(defaultStateUser);

const UserProvider = ({ children }) => {
  const [user, setUser] = useReducer(
    reducer,
    getLocalStorageItem({
      key: process.env.REACT_APP_USER_LOCAL_STORAGE_KEY,
      defaultState: defaultStateUser,
    })
  );

  useEffect(() => {
    setLocalStorageItem({
      key: process.env.REACT_APP_USER_LOCAL_STORAGE_KEY,
      data: user,
    });
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const { user, setUser } = useContext(UserContext);

  if (!user) throw new Error("Hook useUser must be used over UserProvider");

  return { user, setUser };
};

export { UserContext, UserProvider, defaultStateUser, useUser };
