import React, { useEffect } from "react";

import { Grid, Typography, makeStyles } from "@material-ui/core";

import { gql, useMutation } from "@apollo/client";

import { toast } from "react-toastify";

import { useApolloClient } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import {
  useAuthentication,
  useDashboard,
  useProject,
  useProjectCategories,
  useProjectPrivacyPolicy,
  useUser,
  useSpam,
} from "../../contexts";

import { logout } from "../../utils";

import jumping from "../../assets/images/dashboard/verifyEmail/jumping.svg";

const useStyles = makeStyles((theme) => ({
  grid: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    justifyContent: "center",
    width: "50%",
    height: "calc(100vh - 64px)",
    margin: "0 auto",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    [theme.breakpoints.down("lg")]: {
      "&": {
        width: "60%",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "80%",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "100%",
      },
    },
  },
  gridItemIllustration: {
    justifyContent: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(2),
  },
  img: {
    width: "70%",
    minWidth: "265px",
    maxWidth: "450px",
    marginBottom: theme.spacing(8),
  },
  gridItemContent: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    "&> *": {
      margin: "10px 0",
    },
    "& > *:first-child": {
      margin: "0 0 10px 0",
    },
  },
  title: {
    margin: "0 0 10px 0",
    textAlign: "center",
    color: theme.palette.blackOne,
    lineHeight: "42px",
    letterSpacing: "0.02em",
    "& > *:first-child": {
      marginBottom: "10px",
    },
  },
  email: {
    color: theme.palette.secondary.light,
  },
  text: {
    color: theme.palette.grayOne,
    textAlign: "center",
    fontSize: "1.2rem",
    lineHeight: "25px",
    letterSpacing: "0.02em",
  },
  link: {
    cursor: "pointer",
    color: theme.palette.secondary.light,
    textDecoration: "none",
    "&:hover": {
      transition: "all .2s linear 0s",
      color: theme.palette.secondary.soft,
    },
  },
}));

const VERIFY_REGISTRATION = gql`
  mutation verifyRegistration($email: String!) {
    verifyRegistration(email: $email)
  }
`;

const VerifyEmail = () => {
  const classes = useStyles();

  const { setAuthentication } = useAuthentication();

  const { setDashboard } = useDashboard();

  const { setProject } = useProject();

  const { setProjectCategories } = useProjectCategories();

  const { setProjectPrivacyPolicy } = useProjectPrivacyPolicy();

  const { user, setUser } = useUser();

  const { spam, setSpam } = useSpam();

  const client = useApolloClient();

  const navigate = useNavigate();

  // prettier-ignore
  const [
    verifyRegistration,
    // eslint-disable-next-line
    { dataVerifyRegistration, loadingVerifyRegistration, errorVerifyRegistration, calledVerifyRegistration },
  ] = useMutation(VERIFY_REGISTRATION);

  const sendEmail = async () => {
    setSpam({ emailsSent: spam?.emailsSent + 1 });

    if (spam?.emailsSent >= 2) {
      toast.info(
        "We've sent you multiple verification links, check your inbox",
        { toastId: "multipleEmailSent" }
      );
    } else {
      try {
        // eslint-disable-next-line
        const emailSent = (
          await verifyRegistration({
            variables: {
              email: user.email,
            },
          })
        ).data.verifyRegistration;

        toast.success("Email sent");
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    (async () => {
      // The automatic email that is sent when the page is open
      // its not counted on spam
      sendEmail();
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <Grid container className={classes.grid}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemIllustration}
      >
        <img src={jumping} alt="Verify email" className={classes.img} />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemContent}
      >
        <Typography variant="h1" className={classes.title}>
          Just one more step...
        </Typography>

        <Typography variant="body3" className={classes.text}>
          To login you need to verify your email. We’ve sent a verification
          email to{" "}
          <span className={classes.email}>
            {user?.email ? user?.email : "Invalid email"}
          </span>
          .
        </Typography>

        <Typography variant="body3" className={classes.text}>
          Can’t find the verification email? You can ask for a new{" "}
          <u
            onClick={() => {
              sendEmail();
            }}
            className={classes.link}
          >
            verification
          </u>{" "}
          or{" "}
          <u
            onClick={() => {
              logout({
                client,
                navigate,
                setters: [
                  setAuthentication,
                  setDashboard,
                  setProject,
                  setProjectCategories,
                  setProjectPrivacyPolicy,
                  setUser,
                  setSpam,
                ],
              });
            }}
            className={classes.link}
          >
            login
          </u>{" "}
          , if you've confirmed your account.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default VerifyEmail;
