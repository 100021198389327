import React from "react";

import { makeStyles, Link, Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonStartFree: {
    margin: "20px 0 10px 0",
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.dark,
    fontWeight: "600",
    "&:hover": {
      backgroundColor: theme.palette.secondary.dark,
    },
  },
}));

const StartFree = ({ styles = {}, url = null }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      component={Link}
      underline="none"
      color="inherit"
      className={classes.buttonStartFree}
      style={styles}
      href={url ? url : "sign-up"}
    >
      Start free
    </Button>
  );
};

export default StartFree;
