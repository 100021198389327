import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { useDashboard } from "../../contexts";

import { OptionsDashboard, OptionsProfile, OptionsSupport } from "./index";

const useStyles = makeStyles((theme) => ({
  gridCards: {
    height: "100%",
  },
}));

const SideMenu = () => {
  const classes = useStyles();

  const { dashboard } = useDashboard();

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.gridCards}
    >
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {/* Cards here */}
        {dashboard.menu === "dashboard" && <OptionsDashboard />}

        {dashboard.menu === "profile" && <OptionsProfile />}

        {dashboard.menu === "support" && <OptionsSupport />}
      </Grid>
    </Grid>
  );
};

export default SideMenu;
