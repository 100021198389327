import SecureLS from "secure-ls";

let ls = new SecureLS({
  encodingType: "aes",
  isCompression: true,
  encryptionSecret: process.env.REACT_APP_STORE_SECRET,
});

const getLocalStorageItem = ({ key, defaultState }) => {
  const item = parseInt(process.env.REACT_APP_ENCRYPT_LOCAL_STORAGE)
    ? ls.get(key)
    : JSON.parse(localStorage.getItem(key));

  return item ? item : defaultState;
};

const setLocalStorageItem = ({ key, data }) => {
  parseInt(process.env.REACT_APP_ENCRYPT_LOCAL_STORAGE)
    ? ls.set(key, data)
    : localStorage.setItem(key, JSON.stringify(data));
};

const removeLocalStorageItem = ({ key }) => {
  parseInt(process.env.REACT_APP_ENCRYPT_LOCAL_STORAGE)
    ? ls.remove(key)
    : localStorage.removeItem(key);
};

const removeAllLocalStorage = () => {
  parseInt(process.env.REACT_APP_ENCRYPT_LOCAL_STORAGE)
    ? ls.removeAll()
    : localStorage.clear();
};

export {
  getLocalStorageItem,
  setLocalStorageItem,
  removeLocalStorageItem,
  removeAllLocalStorage,
};
