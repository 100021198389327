import React, { useState } from "react";

import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
} from "@material-ui/core";

import { Person as PersonIcon, Close as CloseIcon } from "@material-ui/icons";

import { Link as RouterLink, useNavigate } from "react-router-dom";

import { useApolloClient } from "@apollo/client";

import {
  useAuthentication,
  useDashboard,
  useProject,
  useProjectCategories,
  useProjectPrivacyPolicy,
  useUser,
  useSpam,
} from "../../contexts";

import { logout } from "../../utils";

import HamburgerMenu from "./hamburgerMenu";

import logoDark from "../../assets/images/home/logoDark.svg";

import { ReactComponent as Logout } from "../../assets/images/dashboard/logout.svg";

import { ReactComponent as Settings } from "../../assets/images/dashboard/settings.svg";

import { ReactComponent as Support } from "../../assets/images/dashboard/support.svg";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiListItemIcon-root": {
      minWidth: "40px",
    },
    ".headerMenuIcon": {},
    ".headerMenuIcon path": {
      fill: theme.palette.primary.dark,
    },
  },
  root: {
    flexGrow: 1,
  },
  hamburgerMenu: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      "&": {
        display: "block",
      },
    },
  },
  menuUser: {
    "& > *": {
      boxShadow: theme.shadows[9],
      // Box
      left: "543px",
      "& > *:first-child": {
        // Ul
        minWidth: "180px",
        "& > *": {
          //Li
          paddingTop: "10px",
          paddingBottom: "10px",
        },
      },
    },
  },
  boxContainerUser: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  userText: {
    marginRight: "8px",
    color: theme.palette.blackTwo,
    cursor: "pointer",
  },
  userButton: {
    margin: "0",
    padding: "0",
    height: "40px",
    width: "40px",
    color: theme.palette.blackOne,
  },
  userButtonIcon: {
    fontSize: "1.4em",
  },
  closeButton: {
    height: "40px",
    width: "40px",
    color: theme.palette.blackOne,
  },
  closeButtonIcon: {
    fontSize: "1.4em",
  },
  logoLink: {
    // flexGrow: 1,
    [theme.breakpoints.down("sm")]: {
      "&": {
        flexGrow: "0",
      },
    },
  },
  logo: {
    width: "140px",
    outline: "none",
    [theme.breakpoints.down("sm")]: {
      width: "110px",
    },
  },
  appBar: {
    zIndex: "999",
    display: "flex",
    justifyContent: "center",
    minHeight: "80px",
    backgroundColor: theme.palette.common.white,
    boxShadow: `0px 0px 75px -35px ${theme.palette.grayFour}`,
    borderBottom: "1px solid rgba(231, 238, 236, .75)",
  },
  boxContainer: {
    minHeight: "80px",
    width: "100%",
    height: "100%",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: (props) =>
      props.presentation ? "center" : "space-between",
  },
}));

const Header = ({ presentation = false }) => {
  const classes = useStyles({ presentation });

  const navigate = useNavigate();

  const client = useApolloClient();

  const { setAuthentication } = useAuthentication();

  const { setProjectPrivacyPolicy } = useProjectPrivacyPolicy();

  const { dashboard, setDashboard } = useDashboard();

  const { setProject } = useProject();

  const { setProjectCategories } = useProjectCategories();

  const { setUser, user } = useUser();

  const { setSpam } = useSpam();

  const [anchorEl, setAnchorEl] = useState(null);

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className={classes.appBar} position="static">
      <Toolbar>
        <Box className={classes.boxContainer}>
          {dashboard?.hideSidebar ? <HamburgerMenu /> : null}

          {presentation || dashboard?.hideSidebar ? null : (
            <Box className={classes.hamburgerMenu}>
              <HamburgerMenu />
            </Box>
          )}

          <RouterLink
            to="/dashboard/home"
            className={classes.logoLink}
            onClick={
              presentation
                ? () => {}
                : () => {
                    setDashboard({
                      menu: "dashboard",
                      optionsProfileMenuItemSelected: 0,
                      hideSidebar: false,
                    });

                    setProject(null);

                    navigate("/dashboard/home");
                  }
            }
          >
            <img alt="Dashboard" src={logoDark} className={classes.logo} />
          </RouterLink>

          {presentation ? null : (
            <Box>
              {dashboard.menu === "profile" || dashboard.menu === "support" ? (
                <IconButton
                  aria-label="close screen"
                  aria-haspopup="false"
                  onClick={() => {
                    setDashboard({
                      menu: "dashboard",
                      optionsProfileMenuItemSelected: 0,
                      optionsSupportMenuItemSelected: 0,
                      hideSidebar: false,
                    });

                    navigate("/dashboard/home");
                  }}
                  className={classes.closeButton}
                  color="inherit"
                >
                  <CloseIcon className={classes.closeButtonIcon} />
                </IconButton>
              ) : (
                <Box className={classes.boxContainerUser}>
                  {!matches ? (
                    <Typography
                      className={classes.userText}
                      onClick={handleMenu}
                    >
                      {user?.name?.split(" ")?.slice(0, 1)}
                    </Typography>
                  ) : null}

                  <IconButton
                    aria-label="settings options"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleMenu}
                    className={classes.userButton}
                    color="inherit"
                  >
                    <PersonIcon className={classes.userButtonIcon} />
                  </IconButton>
                </Box>
              )}

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
                className={classes.menuUser}
              >
                <MenuItem
                  onClick={() => {
                    handleClose();

                    setDashboard({
                      menu: "profile",
                      optionsProfileMenuItemSelected: 0,
                      hideSidebar: false,
                    });

                    navigate("/dashboard/account");
                  }}
                >
                  <ListItemIcon>
                    <Settings className="headerMenuIcon" />
                  </ListItemIcon>

                  <Typography variant="inherit">Settings</Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();

                    setDashboard({
                      menu: "support",
                      optionsSupportMenuItemSelected: 0,
                      hideSidebar: false,
                    });

                    navigate("/dashboard/contact");
                  }}
                >
                  <ListItemIcon>
                    <Support className="headerMenuIcon" />
                  </ListItemIcon>

                  <Typography variant="inherit">Support</Typography>
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    handleClose();

                    logout({
                      client,
                      navigate,
                      setters: [
                        setAuthentication,
                        setDashboard,
                        setProject,
                        setProjectCategories,
                        setProjectPrivacyPolicy,
                        setUser,
                        setSpam,
                      ],
                    });
                  }}
                >
                  <ListItemIcon>
                    <Logout className="headerMenuIcon" />
                  </ListItemIcon>

                  <Typography variant="inherit">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
