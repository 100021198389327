import React from "react";

import { Grid } from "@material-ui/core";

import { Helmet } from "react-helmet";

import { Sections } from "../components/termsAndConditions";

const TermsAndConditions = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Helmet>
        <title>Terms & Conditions</title>
      </Helmet>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Sections />
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
