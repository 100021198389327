import React, { useState } from "react";

import {
  Box,
  IconButton,
  makeStyles,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  drawer: {
    "& > *": {
      boxShadow: "none",
    },
  },
  fullList: {
    width: "auto",
  },
  list: {},
  subList: {},
  text: {
    "& > *": {
      color: theme.palette.blackOne,
      fontWeight: "700",
      letterSpacing: ".5px",
    },
  },
  textDefault: {
    "& > *": {
      color: theme.palette.secondary.main,
      fontWeight: "700",
      letterSpacing: ".5px",
    },
  },
  nested: {
    "& > *": {
      color: theme.palette.secondary.main,
    },
    "& > div:nth-child(1)": {
      flex: "unset",
    },
  },
  iconButtonMenu: {
    margin: "0",
    padding: "0",
  },
  menuIcon: {
    fontSize: "1.6em",
    color: theme.palette.blackOne,
  },
}));

const HamburgerMenu = () => {
  const classes = useStyles();

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const [stateDrawer, setStateDrawer] = useState({
    bottom: false,
  });

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setStateDrawer({ ...stateDrawer, [side]: open });
  };

  const activeLink = (path, defaultClasses = classes.text) =>
    window.location.pathname === path ? classes.textDefault : defaultClasses;

  const ListItemLink = (props, { component }) => {
    return (
      <ListItem
        button
        component={component ? component : RouterLink}
        {...props}
      />
    );
  };

  const fullList = (side) => {
    return (
      <Box
        className={classes.fullList}
        role="presentation"
        onKeyDown={toggleDrawer(side, false)}
      >
        <List className={classes.list}>
          <ListItemLink to="/login">
            <ListItemText primary="Login" className={classes.text} />
          </ListItemLink>

          <ListItemLink to="/sign-up">
            <ListItemText primary="Sign up" className={classes.text} />
          </ListItemLink>

          <ListItemLink to="/how-it-works">
            <ListItemText
              primary="How it works"
              className={activeLink("/how-it-works")}
            />
          </ListItemLink>

          <ListItemLink to="/pricing">
            <ListItemText
              primary="Pricing"
              className={activeLink("/pricing")}
            />
          </ListItemLink>

          <ListItemLink to="/about-us">
            <ListItemText
              primary="About us"
              className={activeLink("/about-us")}
            />
          </ListItemLink>
        </List>
      </Box>
    );
  };

  return (
    <>
      <IconButton
        onClick={toggleDrawer("bottom", true)}
        className={classes.iconButtonMenu}
        disableFocusRipple
        disableRipple
      >
        {stateDrawer.bottom ? (
          <CloseIcon className={classes.menuIcon} />
        ) : (
          <MenuIcon className={classes.menuIcon} />
        )}
      </IconButton>

      <Drawer
        anchor="bottom"
        open={stateDrawer.bottom && matches}
        onClose={toggleDrawer("bottom", false)}
        className={classes.drawer}
      >
        {fullList("bottom")}
      </Drawer>
    </>
  );
};

export default HamburgerMenu;
