import React from "react";

import { Box, IconButton, Typography, makeStyles } from "@material-ui/core";

import { CheckCircleOutline as CheckCircleOutlineIcon } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  successRequestBox: {
    maxWidth: "50%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100%",
    textAlign: "center",
  },
  successButton: {
    color: theme.palette.secondary.main,
  },
  successButtonIcon: {
    width: "45px",
    height: "45px",
  },
  successMessage: {
    fontSize: "1.3rem",
    color: theme.palette.grayOne,
  },
}));

const SuccessLoad = ({ message }) => {
  const classes = useStyles();

  return (
    <Box className={classes.successRequestBox}>
      <IconButton
        aria-label="success"
        className={classes.successButton}
        disableRipple
        disableFocusRipple
      >
        <CheckCircleOutlineIcon className={classes.successButtonIcon} />
      </IconButton>

      <Typography variant="h2" className={classes.successMessage}>
        {message}
      </Typography>
    </Box>
  );
};

export default SuccessLoad;
