import React from "react";

import { makeStyles, Box, Typography } from "@material-ui/core";

import compliant from "../../assets/images/home/compliant.png";

import multiplePlatforms from "../../assets/images/home/multiplePlatforms.png";

import customisable from "../../assets/images/home/customisable.png";

import design from "../../assets/images/home/design.png";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(8),
    boxSizing: "border-box",
    padding: theme.spacing(2),
    minHeight: "700px",
  },
  boxContent: {
    height: "100%",
    width: "100%",
    textAlign: "center",
  },
  boxIntro: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: theme.spacing(5),
  },
  title: {
    fontSize: "3rem",
    fontWeight: "700",
    color: theme.palette.blackOne,
    marginBottom: theme.spacing(1),
  },
  description: {
    fontSize: "1.5rem",
    color: theme.palette.grayOne,
  },
  boxAdvantages: {
    minHeight: "350px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    marginTop: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  advantageItem: {
    display: "flex",
    height: "365px",
    flexDirection: "column",
    alignItems: "center",
    margin: theme.spacing(0, 8),
    "& > *:first-child": {
      marginRight: "0",
    },
    "& > *:last-child": {
      marginRight: "0",
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        margin: theme.spacing(2, 0),
      },
      "& > *:last-child": {
        marginBottom: "0",
      },
    },
  },
  boxIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%",
    height: "130px",
    width: "130px",
    background: `linear-gradient(90deg, ${theme.palette.grayThree} 0%, ${theme.palette.grayFour} 100%)`,
  },
  advantageTitle: {
    fontWeight: "700",
    letterSpacing: ".5px",
    marginTop: theme.spacing(3),
    fontSize: "1.5rem",
    color: theme.palette.blackOne,
  },
  advantageDescription: {
    color: theme.palette.grayOne,
    fontSize: "1.3rem",
    textAlign: "center",
    marginTop: theme.spacing(2),
    maxWidth: "225px",
  },
  icon: {
    color: theme.palette.secondary.main,
    width: "65px",
  },
}));

const PrivacyPolicy = () => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxContent}>
        <Box className={classes.boxIntro}>
          <Typography className={classes.title}>
            Create a policy in under 2 minutes
          </Typography>

          <Typography className={classes.description}>
            We'll create a policy to satisfy minimum legal requirements and
            <br />
            you can fill in the data in your own time
          </Typography>
        </Box>

        <Box className={classes.boxAdvantages}>
          <Box className={classes.advantageItem}>
            <Box className={classes.boxIconContainer}>
              <img src={compliant} alt="Compliant" className={classes.icon} />
            </Box>

            <Typography className={classes.advantageTitle}>
              Compliant
            </Typography>

            <Typography className={classes.advantageDescription}>
              Your Privacy Policy, will be compliant with all applicable legal
              requirements. We collaborated with best privacy practitioners.
            </Typography>
          </Box>

          <Box className={classes.advantageItem}>
            <Box className={classes.boxIconContainer}>
              <img src={design} alt="Design" className={classes.icon} />
            </Box>

            <Typography className={classes.advantageTitle}>Design</Typography>

            <Typography className={classes.advantageDescription}>
              The policy generated by Audaxly is user centric: beautifully
              designed and easy to understand. No legalese. Yes legal design.
            </Typography>
          </Box>

          <Box className={classes.advantageItem}>
            <Box className={classes.boxIconContainer}>
              <img
                src={customisable}
                alt="Customisable"
                className={classes.icon}
              />
            </Box>

            <Typography className={classes.advantageTitle}>
              Customisable
            </Typography>

            <Typography className={classes.advantageDescription}>
              Carefully selected questions, customisable data entries and
              hundreds of tools to choose from. Caring for your business needs.
            </Typography>
          </Box>

          <Box className={classes.advantageItem}>
            <Box className={classes.boxIconContainer}>
              <img
                src={multiplePlatforms}
                alt="Multiple Platforms"
                className={classes.icon}
              />
            </Box>

            <Typography className={classes.advantageTitle}>
              Multiple Platforms
            </Typography>

            <Typography className={classes.advantageDescription}>
              Easy to install on your website, mobile or web app. You can add it
              to Shopify, Wix, Squarespace, Wordpress, Webflow and others.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PrivacyPolicy;
