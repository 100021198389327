import React, { createContext, useReducer, useEffect, useContext } from "react";

import moment from "moment";

import { isEqual } from "lodash";

import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../utils";

const defaultStateAuthentication = { token: "", issuedAt: 0, expiresAt: 0 };

const reducer = (previousState, newState) => {
  if (newState === null) {
    removeLocalStorageItem({
      key: process.env.REACT_APP_AUTHENTICATION_LOCAL_STORAGE_KEY,
    });

    return defaultStateAuthentication;
  }

  return { ...previousState, ...newState };
};

const AuthenticationContext = createContext(defaultStateAuthentication);

const AuthenticationProvider = ({ children }) => {
  const [authentication, setAuthentication] = useReducer(
    reducer,
    getLocalStorageItem({
      key: process.env.REACT_APP_AUTHENTICATION_LOCAL_STORAGE_KEY,
      defaultState: defaultStateAuthentication,
    })
  );

  useEffect(() => {
    setLocalStorageItem({
      key: process.env.REACT_APP_AUTHENTICATION_LOCAL_STORAGE_KEY,
      data: authentication,
    });
  }, [authentication]);

  return (
    <AuthenticationContext.Provider
      value={{ authentication, setAuthentication }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

const checkAuthenticationStatus = (authentication = undefined) => {
  let _authentication = Object.assign({}, defaultStateAuthentication);

  if (authentication) {
    _authentication = authentication;
  } else {
    _authentication = getLocalStorageItem({
      key: process.env.REACT_APP_AUTHENTICATION_LOCAL_STORAGE_KEY,
      defaultState: defaultStateAuthentication,
    });
  }

  if (isEqual(_authentication, defaultStateAuthentication)) return false;

  const now = moment().format();

  const expiresAt = moment(_authentication.expiresAt, "x").format();

  if (now >= expiresAt) return false;

  return true;
};

const useAuthentication = () => {
  const { authentication, setAuthentication } = useContext(
    AuthenticationContext
  );

  if (!authentication)
    throw new Error(
      "Hook useAuthentication must be used over AuthenticationProvider"
    );

  return { authentication, setAuthentication };
};

export {
  AuthenticationContext,
  AuthenticationProvider,
  defaultStateAuthentication,
  checkAuthenticationStatus,
  useAuthentication,
};
