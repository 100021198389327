import React, { useState, useRef, useEffect } from "react";

import {
  Grid,
  Button,
  IconButton,
  Typography,
  makeStyles,
  TextField,
  Box,
  Card,
  CardContent,
  CardActions,
} from "@material-ui/core";

import { DeleteOutline } from "@material-ui/icons";

import { useQuery, useMutation, gql } from "@apollo/client";

import { useForm } from "react-hook-form";

import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";

import { ErrorMessage } from "@hookform/error-message";

import * as Yup from "yup";

import { useApolloClient } from "@apollo/client";

import { useNavigate } from "react-router-dom";

import { objectsDifference, logout } from "../../utils";

import {
  LoadingComponent,
  LoadingButton,
  ErrorLoad,
  DeleteDialog,
} from "../general";

import {
  useAuthentication,
  useDashboard,
  useProject,
  useProjectCategories,
  useProjectPrivacyPolicy,
  useUser,
  useSpam,
} from "../../contexts";

const useStyles = makeStyles((theme) => ({
  gridAccount: {
    boxSizing: "border-box",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(5),
    "& > *": {
      marginTop: theme.spacing(2),
    },
    "& > *:first-child": {
      marginTop: "0",
    },
    "& > *:last-child": {},
  },
  gridItemTitle: {},
  title: {
    textAlign: "start",
    color: theme.palette.blackOne,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "42px",
  },
  gridItemForm: {
    "& input": {
      background: theme.palette.common.white,
    },
  },
  grid: {},
  gridItem: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& > *:first-child": {
      marginTop: "0",
    },
    "& > *:last-child": {
      marginBottom: "0",
    },
  },
  gridItemSave: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    marginTop: "0",
    marginBottom: "0",
  },
  error: {
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
  },
  buttonSubmit: {
    fontWeight: "normal",
  },
  gridItemExtra: {
    width: "100%",
  },
  cardDelete: {
    display: "flex",
    flexDirection: "row",
    minHeight: "90px",
    maxHeight: "fit-content",
    marginTop: theme.spacing(2),
    boxShadow: theme.shadows[4],
    "& > *": {
      display: "flex",
      alignItems: "center",
    },
  },
  boxContainerTextDelete: {
    width: "90%",
    height: "auto",
  },
  cardContentDeleteTitle: {
    color: theme.palette.blackOne,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
  },
  cardContentDeleteDescription: {
    color: theme.palette.primary.soft,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "24px",
  },
  boxContainerIconDelete: {
    width: "20%",
    height: "auto",
    justifyContent: "center",
  },
  cardActionsDelete: {},
  cardActionsDeleteButton: {},
  cardActionsDeleteButtonIcon: {
    color: theme.palette.blackOne,
    width: "24px",
    height: "24px",
  },
}));

const AccountSchema = Yup.object().shape({
  name: Yup.string().required("You must fill the name"),
  email: Yup.string()
    .required("You must fill the email")
    .trim()
    .email("Invalid email"),
  password: Yup.string().test(
    "password",
    "Minimum 8 characters and must contain one uppercase character and one number",
    (value) => {
      if (value === "") return true;

      if (value.length >= 8 && value.match(/[A-Z]/) && value.match(/[0-9]/))
        return true;

      return false;
    }
  ),
  confirmPassword: Yup.string().oneOf(
    [Yup.ref("password")],
    "The confirmation password must be equal to the password"
  ),
});

const USER = gql`
  query User {
    user {
      name
      email
    }
  }
`;

const UPDATE_USER = gql`
  mutation UpdateUser($input: UserUpdateInput!) {
    updateUser(input: $input) {
      name
      email
      password
      marketingAccept
      customerId
      paymentMethods {
        id
      }
      verifyRegistration {
        status
      }
      updatedAt
    }
  }
`;

const DELETE_USER = gql`
  mutation DeleteUser {
    deleteUser
  }
`;

const AccountForm = () => {
  const classes = useStyles();

  const { loading, error, data } = useQuery(USER);

  const [
    updateUser,
    // eslint-disable-next-line
    { dataUpdateUser, loadingUpdateUser, errorUpdateUser, calledUpdateUser },
  ] = useMutation(UPDATE_USER, { refetchQueries: [{ query: USER }] });

  const [
    deleteUser,
    // eslint-disable-next-line
    { dataDeleteUser, loadingDeleteUser, errorDeleteUser, calledDeleteUser },
  ] = useMutation(DELETE_USER);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(AccountSchema),
  });

  const { setAuthentication } = useAuthentication();

  const { setDashboard } = useDashboard();

  const { setProject } = useProject();

  const { setProjectCategories } = useProjectCategories();

  const { setProjectPrivacyPolicy } = useProjectPrivacyPolicy();

  const { setUser } = useUser();

  const { setSpam } = useSpam();

  const client = useApolloClient();

  const navigate = useNavigate();

  const [confirmPasswordStatus, setConfirmPasswordStatus] = useState(false);

  const [openDeleteAccount, setOpenDeleteAccount] = useState(false);

  const accountButtonContent = useRef("Save");

  useEffect(() => {
    setDashboard({
      menu: "profile",
      optionsProfileMenuItemSelected: 0,
      optionsSupportMenuItemSelected: 0,
      hideSidebar: false,
    });

    // eslint-disable-next-line
  }, []);

  const handleOpenDeleteAccount = () => {
    setOpenDeleteAccount(true);
  };

  const deleteAccount = async () => {
    try {
      // eslint-disable-next-line
      const userDeleted = (await deleteUser()).data.deleteUser;

      toast.success(`Account deleted`);

      setOpenDeleteAccount(false);

      logout({
        client,
        navigate,
        setters: [
          setAuthentication,
          setDashboard,
          setProject,
          setProjectCategories,
          setProjectPrivacyPolicy,
          setUser,
          setSpam,
        ],
      });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onSubmit = async ({ confirmPassword, ..._dataForm }, event) => {
    const dataForm = objectsDifference(_dataForm, data);

    if (Object.keys(dataForm).length === 0) {
      toast.warn(`No changes found`);

      return;
    }

    accountButtonContent.current = <LoadingButton />;

    if ("email" in dataForm)
      toast.info(
        `To complete the email change, access the link sent to ${dataForm?.email}`
      );

    try {
      // eslint-disable-next-line
      const userUpdated = (
        await updateUser({
          variables: {
            input: dataForm,
          },
        })
      ).data.updateUser;

      setUser(userUpdated);

      toast.success(`Account updated`);
    } catch (error) {
      toast.error(error.message);
    }

    accountButtonContent.current = "Save";
  };

  if (loading) return <LoadingComponent />;

  if (error) return <ErrorLoad message={error.message} />;

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      className={classes.gridAccount}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemTitle}
      >
        <Typography variant="h1" className={classes.title}>
          My Account
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={9} lg={4} className={classes.gridItemForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.grid}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItem}
            >
              <TextField
                name="name"
                type="text"
                label="Name"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data.user.name}
                error={Boolean(errors.name)}
                inputRef={register}
                fullWidth
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="name" />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItem}
            >
              <TextField
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                defaultValue={data.user.email}
                error={Boolean(errors.email)}
                inputRef={register}
                fullWidth
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="email" />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItem}
            >
              <TextField
                name="password"
                type="password"
                label="Password"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors.password)}
                inputRef={register}
                fullWidth
                inputProps={{
                  autoComplete: "password",
                  form: {
                    autoComplete: "off",
                  },
                }}
                placeholder="Type a new password"
                onClick={() => {
                  setConfirmPasswordStatus(true);
                }}
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="password" />
              </Typography>
            </Grid>

            {confirmPasswordStatus && (
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.gridItem}
              >
                <TextField
                  name="confirmPassword"
                  type="password"
                  label="Confirm password"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    autoComplete: "password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                  error={Boolean(errors.confirmPassword)}
                  placeholder="Confirm the password"
                  inputRef={register}
                  fullWidth
                />

                <Typography className={classes.error}>
                  <ErrorMessage errors={errors} name="confirmPassword" />
                </Typography>
              </Grid>
            )}

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={`${classes.gridItem} ${classes.gridItemSave}`}
            >
              <Button
                variant="hovered"
                color="primary"
                type="submit"
                className={classes.buttonSubmit}
                disabled={error}
              >
                {accountButtonContent.current}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={9}
        lg={4}
        className={classes.gridItemExtra}
      >
        <Card className={classes.cardDelete}>
          <Box className={classes.boxContainerTextDelete}>
            <CardContent>
              <Typography className={classes.cardContentDeleteTitle}>
                Delete account
              </Typography>

              <Typography className={classes.cardContentDeleteDescription}>
                If you delete your account all the data, aside from the support
                ticket, associated with it will be lost.
              </Typography>
            </CardContent>
          </Box>

          <Box className={classes.boxContainerIconDelete}>
            <CardActions className={classes.cardActionsDelete}>
              <IconButton
                aria-label="delete"
                className={classes.cardActionsDeleteButton}
                onClick={handleOpenDeleteAccount}
              >
                <DeleteOutline
                  className={classes.cardActionsDeleteButtonIcon}
                />
              </IconButton>
            </CardActions>
          </Box>
        </Card>

        <DeleteDialog
          resource="account"
          openStatus={openDeleteAccount}
          closeAction={() => {
            setOpenDeleteAccount(false);
          }}
          deleteAction={deleteAccount}
        />
      </Grid>
    </Grid>
  );
};

export default AccountForm;
