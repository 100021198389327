const interactionWithExternalPlatform = [
  {
    providerName:
      "Microsoft Ireland Operations Ltd (EEA users) | Microsoft Corp. (USA & other countries users)",
    providerService: "Bing Maps",
    questionGenerator: "Q2 - Maps ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://privacy.microsoft.com/en-us/privacystatement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Maps widget",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Maps widget",
    questionGenerator: "Q2 - Maps ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy?hl=en",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Maps widget",
    "Unnamed: 12": null,
  },
  {
    providerName: "HERE Global B.V.",
    providerService: "HERE Maps widget",
    questionGenerator: "Q2 - Maps ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://legal.here.com/en-gb/privacy",
    optOut: null,
    placeProcessing: "Netherlands",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Maps widget",
    "Unnamed: 12": null,
  },
  {
    providerName: "Mapbox, Inc.",
    providerService: "Mapbox widget",
    questionGenerator: "Q2 - Maps ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mapbox.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Maps widget",
    "Unnamed: 12": null,
  },
  {
    providerName: "OpenStreetMap Foundation",
    providerService: "OpenStreetMap widget",
    questionGenerator: "Q2 - Maps ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://wiki.osmfoundation.org/wiki/Privacy_Policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Maps widget",
    "Unnamed: 12": null,
  },
  {
    providerName: "TomTom International BV",
    providerService: "TomTom Maps",
    questionGenerator: "Q2 - Maps ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.tomtom.com/en_us/privacy/",
    optOut: null,
    placeProcessing: "Netherlands",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Maps widget",
    "Unnamed: 12": null,
  },
  {
    providerName: "SoundCloud Germany Ltd.",
    providerService: "SoundCloud widget",
    questionGenerator: "Q4 - Music ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://soundcloud.com/pages/privacy",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Music streaming wiget",
    "Unnamed: 12": null,
  },
  {
    providerName: "Spotify AB",
    providerService: "Spotify widget",
    questionGenerator: "Q4 - Music ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.spotify.com/us/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "Sweden",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Music streaming wiget",
    "Unnamed: 12": null,
  },
  {
    providerName: "CodePen Inc.",
    providerService: "CodePen widget",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://blog.codepen.io/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Displays code editor into a website",
    "Unnamed: 12": null,
  },
  {
    providerName: "GamePix S.r.l.",
    providerService: "GamePix widget",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.gamepix.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Displays games into a website",
    "Unnamed: 12": null,
  },
  {
    providerName: "Getty Images, Inc.",
    providerService: "Getty Images widget",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.gettyimages.com/company/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Image stock widget",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Calendar widget",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy?hl=en",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Embeds Google Maps to a website",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Site Search",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy?hl=en",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Internal search widget",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "Gravatar",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://automattic.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Displays avatars generated with Gravatar's tool ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Headway App, Inc.",
    providerService: "Headway widget",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://headwayapp.co/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      'Embeds an updates log ("changelog") into a website',
    "Unnamed: 12": null,
  },
  {
    providerName: "Issuu, Inc.",
    providerService: "Issuu widget",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://issuu.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Displays images, magazines and other types of publications created on Issuu",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Elasticsearch B.V. (EEA users) | Elasticsearch, Inc. (USA&other countries users)",
    providerService: "Swiftype",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.elastic.co/pt/legal/privacy-statement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Internal search widget",
    "Unnamed: 12": null,
  },
  {
    providerName: "Zmags Inc. ",
    providerService: "Zmags widget",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://zmags.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Image content created on Zmags",
    "Unnamed: 12": null,
  },
  {
    providerName: "Calendly LLC",
    providerService: "Calendly widget ",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://calendly.com/pages/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "State in the PP that are compliant but not present in the Privacy Shield List ",
    "Unnamed: 12": null,
  },
  {
    providerName: "SimplyBook.me Ltd",
    providerService: "SimplyBook.me Form & Widget",
    questionGenerator: "Q5 - Other external platforms ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://simplybook.me/en/policy",
    optOut: null,
    placeProcessing: "Cyprus",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Oracle Corp.",
    providerService: "AddThis Share, Image sharing and Follow buttons",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts [of your clients]",
    specificData: null,
    providerPrivacyLink:
      "https://www.oracle.com/legal/privacy/addthis-privacy-policy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "AddThis Tools provide website owners with the capability to enable users of their websites to share webpages, blogs, news, photos, videos, and other content with social networks and other destinations via a browser plug-in or website plug-in. With AddThis Tools, publishers can (i) enable users to share, follow, view, recommend and interact with the publisher\u2019s content, and (ii) collect information from those users as a result of their visit. The AddThis Tools are available for download through AddThis.com or through selected third party sites.",
    "Unnamed: 12":
      "- Share Buttons [allows client to implement a share bar where the different social platforms will appear]\n+ - Image sharing [allows client to implement otion to share specific images directly from website]\n+ - Follow buttons [ allows client to follow on 65+ social networks! ] ",
  },
  {
    providerName: "AddToAny LLC",
    providerService: "AddToAny",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts [of your clients]",
    specificData: null,
    providerPrivacyLink: "https://www.addtoany.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": "- Share buttons + Image Sharing + ANALYTICS FOR ALL ",
  },
  {
    providerName:
      "AngelList UK Ltd (EEA users) | AngelList Holdings, LLC.(USA&other countries users)",
    providerService: "AngelList follow button and social widget ",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts [of your clients]",
    specificData: null,
    providerPrivacyLink: "https://angel.co/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "EU representative appointed ",
    "Unnamed: 12": "page on how to embed widget not found",
  },
  {
    providerName: "Buffer, Inc",
    providerService: "Buffer button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts [of your clients]",
    specificData: null,
    providerPrivacyLink: "https://buffer.com/legal#privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "SERVICES FOR BUSINESS: Could also be Customer Support \nScheduling\nCustom posting schedules\nTailored posts\nCalendar\nBrowser extension\niOS and Android apps\n60+ third-party extensions\nTwo-factor authentication\nKnowledgebase\nCustomer support",
    "Unnamed: 12":
      "- Allows integration of all social networks + sharing + replying + analytics ",
  },
  {
    providerName:
      "Coinbase Custody Ireland International Ltd & Coinbase UK, Ltd (EEA users) | Coinbase Inc. (USA users)",
    providerService: "Coinbase button and widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.coinbase.com/legal/privacy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "We may store and process all or part of your personal and transactional information, including certain payment information, such as your encrypted bank account and/or routing numbers, in the United States and elsewhere in the world where our facilities or our service providers are located. USA PRIVACY SHIELD \n\nThe Coinbase Index Widget enables publishers, website developers and bloggers to display the performance of Coinbase Index (CBI) in real time. This widget makes it easier to embed and share cryptocurrency data with a dynamic ticker and sparkline that moves with the market: no more out of date screenshots.\nThe widget makes a request to load the last 24 hours of Coinbase Index values from the Coinbase API. It also connects to the Coinbase API websocket to receive a stream of new CBI values to update the ticker in real time. Finally, when clicked, the widget will link users to the Coinbase Index webpage for more information.",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Facebook Ireland Ltd (EEA users) | Facebook Inc. (USA&other countries users)",
    providerService: "Facebook Comments",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.facebook.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "comment plugin for bloggers /publishers",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Facebook Ireland Ltd (EEA users) | Facebook Inc. (USA&other countries users)",
    providerService: "Facebook Like button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.facebook.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "Social Plugins\nComments\nEmbedded Comments\nEmbedded Posts\nEmbedded Videos\nGroup Plugin\nLike Button\nPage Plugin\nQuote Plugin\nSave Button\nShare Button\noEmbed Endpoints\nChild-Directed Sites",
  },
  {
    providerName: "GetWebCraft Ltd.",
    providerService: "GetSiteControl social widget",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://getsitecontrol.com/privacy/",
    optOut: null,
    placeProcessing: "Cyprus",
    EuEea: "yes",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Collect more detailed information about your website visitors\nDisplay personalized messages to website visitors\nSend data collected from widgets to third party applications\nTracking pages in Single Page Apps\nDisplay widgets based on your site logic (e.g. button clicks)\nTarget visitors by custom API parameters",
    "Unnamed: 12": "Marketing pop-ups and widgets ",
  },
  {
    providerName:
      "Facebook Ireland Ltd (EEA users) | Facebook Inc. (USA&other countries users)",
    providerService: "Instagram widget",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://help.instagram.com/519522125107875",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Embeds Instagram photo/profile into a website",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "LinkedIn Ireland Unlimited Company (EEA users) | LinkedIn Corporation (USA & other countries users)",
    providerService: "LinkedIn button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.linkedin.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "- Share\n- Follow Company [allow your users to easily follow your company page on LinkedIn]\n- LinkedIn AutoFill [to easily populate profile form fields from your LinkedIn profile]",
  },
  {
    providerName: "Mixtech Inc.",
    providerService: "Mix button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://mix.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Myspace LLC",
    providerService: "Myspace button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://myspace.com/pages/eeaprivacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": "- Share content on Myspace account",
  },
  {
    providerName:
      "Pinterest Europe Ltd. (EEA users) | Pinterest Inc. (USA & other countries users)",
    providerService: "Pinterest \u201cPin it\u201d button and social widget",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://policy.pinterest.com/en/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "- Visitors can save content they like on your site to Pinterest",
  },
  {
    providerName: "R1Demand, LLC",
    providerService: "Po.st",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.rhythmone.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12":
      "- Share Buttons [allows client to implement a share bar where the different social platforms will appear]",
  },
  {
    providerName:
      "Reddit Ireland Limited (EEA users) | Reddit, Inc. (USA&other countries users)",
    providerService: "Reddit button and widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.redditinc.com/policies/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      '"Reddit is an American social news aggregation, web content rating, and discussion website." (Wikipedia)',
    "Unnamed: 12":
      "- Share buttons that allow users to share content on a Reddit forum",
  },
  {
    providerName: "ShareThis, Inc.",
    providerService: "ShareThis",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://sharethis.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "- Share Buttons [allows client to implement a share bar where the different social platforms will appear]",
  },
  {
    providerName: "TripAdvisor LLC ",
    providerService: "TripAdvisor widget",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies, Cookies",
    specificData: null,
    providerPrivacyLink: "https://tripadvisor.mediaroom.com/us-privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service":
      "Embeds comments, images and scores from TripAdvisor's platform",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "Tumblr sharing button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.tumblr.com/privacy/en_eu",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "button to share content on tumblr's social network profile directly from the website",
  },
  {
    providerName:
      "Twitter International Company (EEA users) | Twitter, Inc. (USA & other countries users) ",
    providerService: "Twitter Tweet button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://vine.co/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      'Social network widget:\n- Post ("tweet")\n- Display tweet\n- Display timeline',
  },
  {
    providerName: "V Kontakte LLC",
    providerService: "VK Like button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://vk.com/privacy",
    optOut: null,
    placeProcessing: "Russia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Russian social network. Widget for enabling page visitors to share/comment directly on their social network profile.",
    "Unnamed: 12":
      'Social network widget:\n- "Like" \n- Post content\n- Display tweet\n- Display videos',
  },
  {
    providerName: "Weibo Corp.",
    providerService: "Weibo button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://m.weibo.cn/page/647?entry=client",
    optOut: null,
    placeProcessing: "China",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Chinese social network (seems like a mix of Twitter and YouTube). Widget for enabling page visitors to share/comment directly on their social network profile.",
    "Unnamed: 12": "share button for the social network",
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google button and social widgets",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Merge with YT Display content from external platforms\n\nIn final policy, mention difference between being logged in and not",
    "Unnamed: 12":
      "- Play YouTube Videos (Use an embedded player to play videos directly in your app and customize the playback experience.) [ also in DISPLAING CONTENT FROM EXTERNAL PLATFORMS]\n- Add YouTube Data (Let users search YouTube content, upload videos, create and manage playlists, and more)\n-Analytics & Reporting (Understand your users and how they interact with your channel and your videos.)\n- Subscribe Buttons (Enable users to subscribe to your YouTube channel with one click.)\n- Live streaming (Schedule live YouTube broadcasts and manage your broadcast video streams.)",
  },
  {
    providerName: "Crunchbase, Inc.",
    providerService: "Crunchbase button and social widget",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://about.crunchbase.com/terms-of-service/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "A Medium Corp.",
    providerService: "Medium button and social widget",
    questionGenerator: "Q1 - Social Networks",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://policy.medium.com/medium-privacy-policy-f03bf92035c9",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "no",
    "Comments about the service": "EU representative appointed ",
    "Unnamed: 12": null,
  },
  {
    providerName: "LongTail Ad Solutions, Inc.",
    providerService: "JWPlayer widget",
    questionGenerator: "Q3 - Video",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.jwplayer.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Video streaming player widget",
    "Unnamed: 12": null,
  },
  {
    providerName: "Perform Group Ltd. ",
    providerService: "Omnisport video widget",
    questionGenerator: "Q3 - Video",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.omnisport.tv/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Video streaming widget for sports content",
    "Unnamed: 12": null,
  },
  {
    providerName: "Vimeo, Inc.",
    providerService: "Vimeo video",
    questionGenerator: "Q3 - Video",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://vimeo.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Video streaming",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Twitter International Company (EEA users) | Twitter, Inc. (USA & other countries users) ",
    providerService: "Vine widget",
    questionGenerator: "Q3 - Video",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://vine.co/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Video streaming",
    "Unnamed: 12": null,
  },
  {
    providerName: "Wistia, Inc.",
    providerService: "Wistia widget",
    questionGenerator: "Q3 - Video",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://wistia.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Video streaming",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "YouTube video widget",
    questionGenerator: "Q3 - Video",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy?hl=en",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Video streaming widget",
    "Unnamed: 12": null,
  },
];

export default interactionWithExternalPlatform;
