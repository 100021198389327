const infrastructure = [
  {
    providerName: "netcup GmbH",
    providerService: "netcup",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.netcup.eu/kontakt/datenschutzerklaerung.php",
    optOut: "http://optout.networkadvertising.org",
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Amazon Web Services EMEA SARL (EEA users) | Amazon Web Services, Inc. (USA & other countries users)",
    providerService: "Amazon Glacier",
    questionGenerator: "Q4 - Backup ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://aws.amazon.com/privacy/?nc1=f_pr",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Dropbox, Inc. (USA users) | Dropbox International Unlimited Company (users outside USA)",
    providerService: "Backup on Dropbox",
    questionGenerator: "Q4 - Backup ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink:
      "Dropbox, Inc. / Dropbox International Unlimited Company (users outside USA)",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Backup on Google Drive",
    questionGenerator: "Q4 - Backup ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "The Inactiv.com Media Solutions Private Ltd.",
    providerService: "BlogVault Backup",
    questionGenerator: "Q4 - Backup ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://blogvault.net/privacy/",
    optOut: null,
    placeProcessing: "India",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "Vaultpress",
    questionGenerator: "Q4 - Backup ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://automattic.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "MongoDB, Inc. ",
    providerService: "Mongo DB",
    questionGenerator: "Q4 - Backup ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mongodb.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Blogger",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Drupal.org Association",
    providerService: "Drupal",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.drupal.org/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "ePages GmbH (EEA users) | ePages Software UK Ltd.(UK users) | ePages Inc. (USA&other countries users)",
    providerService: "ePages",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://epages.com/en/data-privacy-protection/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Ghost Foundation Ltd",
    providerService: "Ghost",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://ghost.org/dpa/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Jimdo GmbH",
    providerService: "Jimdo",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.jimdo.com/info/privacy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Open Source Matters Inc. ",
    providerService: "Joomla!",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.joomla.org/privacy-policy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Magento, Inc.",
    providerService: "Magento",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://magento.com/sites/default/files/REVISED-MAGENTO-PRIVACY-POLICY.pdf",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Six Apart Ltd.",
    providerService: "MovableType",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.movabletype.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "PrestaShop SA",
    providerService: "PrestaShop",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.prestashop.com/en/privacy-policy",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Shopify International Ltd. (EEA & Middle East & Suth America & Africa users) | Shopify Inc. (other countries users)",
    providerService: "Shopify",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.shopify.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Square Ireland Ltd. (EEA & other countries users) | Squarespace Inc. (USA users) ",
    providerService: "Squarespace",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.squarespace.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Strikingly, Inc.",
    providerService: "Strikingly",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://support.strikingly.com/hc/en-us/articles/214364818",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Neutral Corp.",
    providerService: "Svbtle",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://svbtle.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "Tumblr",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.tumblr.com/privacy/en_eu",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Webflow, Inc.",
    providerService: "Webflow",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://webflow.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Square, Inc. \n \n",
    providerService: "Weebly",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.weebly.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Wix Online Platforms Ireland Ltd. (EEA users) | Wix.com Inc. (USA&other countries users) ",
    providerService: "Wix",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://wix.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Thinkific Labs Inc.",
    providerService: "Thinkific ",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.thinkific.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "WordPress.com",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://automattic.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Wordpress.Org",
    providerService: "Wordpress.org",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://wordpress.org/about/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "E-business4us Inc dba Builderall LLC",
    providerService: "Builderall Website Builder ",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://my.builderall.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Bubble Group, Inc.",
    providerService: "Bubble",
    questionGenerator: "Q1 - CMS Buiding & managing website",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink: "https://bubble.io/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Commento, Inc.",
    providerService: "Commento",
    questionGenerator: "Q6 - Commenting system",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://commento.io/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Disqus, Inc.",
    providerService: "Disqus",
    questionGenerator: "Q6 - Commenting system",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Sensitive data",
    specificData: null,
    providerPrivacyLink:
      "https://help.disqus.com/en/articles/1717103-disqus-privacy-policy",
    optOut: "https://disqus.com/data-sharing-settings/",
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "comment plugin for bloggers /publishers",
    "Unnamed: 12": "Inactive on Privacy Shield list",
  },
  {
    providerName:
      "GitHub BV (EEA users) | GitHub, Inc. (USA & other countries users)",
    providerService: "GitHub button and social widgets",
    questionGenerator: "Q6 - Commenting system",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Sensitive data",
    specificData: null,
    providerPrivacyLink:
      "https://help.github.com/en/github/site-policy/github-privacy-statement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Buttons to follow, star, download, sponsor, fork, watch and file an issue on GitHub",
    "Unnamed: 12": "SOCIAL NETWORK",
  },
  {
    providerName: "GraphComment dba Semiologic",
    providerService: "GraphComment",
    questionGenerator: "Q6 - Commenting system",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://graphcomment.com/en/conditions.html",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "IntenseDebate",
    questionGenerator: "Q6 - Commenting system",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://automattic.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "comment system for WordPress, Blogger, Tumblr and many other blogging/CMS platforms.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Muut, Inc.",
    providerService: "Muut",
    questionGenerator: "Q6 - Commenting system",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Sensitive data",
    specificData: null,
    providerPrivacyLink:
      "https://muut.com/terms/privacy-policy-simplified.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Forum, comments and direct user-webmaster messaging",
    "Unnamed: 12": null,
  },
  {
    providerName: "Spot.IM Ltd.",
    providerService: "Spot.IM",
    questionGenerator: "Q6 - Commenting system",
    dataCollected: "https://graphcomment.com/en/conditions.html",
    specificData: null,
    providerPrivacyLink: "https://www.spot.im/Privacy/",
    optOut: null,
    placeProcessing: "Israel",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Adobe Systems Software Ireland Ltd. (EEA&other countries users) | Adobe Inc. (USA users)",
    providerService: "Adobe Edge Web Fonts",
    questionGenerator: "Q2 - Fonts library",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.adobe.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Fonts library",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Adobe Systems Software Ireland Ltd. (EEA&other countries users) | Adobe Inc. (USA users)",
    providerService: "Adobe Fonts",
    questionGenerator: "Q2 - Fonts library",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.adobe.com/privacy/policies/adobe-fonts.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Fonts library",
    "Unnamed: 12": null,
  },
  {
    providerName: "Fonticons, Inc.",
    providerService: "Font Awesome",
    questionGenerator: "Q2 - Fonts library",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://fontawesome.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Fonts library",
    "Unnamed: 12": null,
  },
  {
    providerName: "Monotype Imaging Inc.",
    providerService: "Fonts.com Web Fonts",
    questionGenerator: "Q2 - Fonts library",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.monotype.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Fonts library",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Fonts",
    questionGenerator: "Q2 - Fonts library",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy?hl=en",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "Fonts library",
    "Unnamed: 12": null,
  },
  {
    providerName: "Monotype Imaging Inc.",
    providerService: "MyFonts",
    questionGenerator: "Q2 - Fonts library",
    dataCollected:
      "Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.monotype.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Fonts library",
    "Unnamed: 12": null,
  },
  {
    providerName: "1&1 Ionos Ltd.",
    providerService: "1&1 Ionos",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.ionos.co.uk/terms-gtc/privacy-policy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Akamai Technologies, Inc.",
    providerService: "Akamai Content Delivery Network",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.akamai.com/us/en/multimedia/documents/akamai/akamai-privacy-statement-july-2018.pdf",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Algolia UK Ltd & Algolia France SAS (EEA users) | Algolia Inc. (USA&other countries users)",
    providerService: "Algolia Infrastructure",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.algolia.com/policies/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Amazon.com, Inc.",
    providerService: "Amazon Appstore",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink:
      "https://www.amazon.com/gp/help/customer/display.html/?ie=UTF8&nodeId=468496&ref=a20m_us_fnav_prvcy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Amazon Web Services EMEA SARL (EEA users) | Amazon Web Services, Inc. (USA & other countries users)",
    providerService: "Amazon Web Services (AWS)",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://aws.amazon.com/privacy/?nc1=f_pr",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Australia / Brazil / Canada / China / France / Germany / India / Ireland / Japan / Korea / Singapore / Uk / USA ",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Apple Distribution International Ltd. (EEA users) | Apple Inc. (USA & other countries users)",
    providerService: "Apple App Store",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.apple.com/uk/legal/privacy/en-ww/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Arsys Internet S.L.U.",
    providerService: "Arsys",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.arsys.net/legal?dhtml=personal-data-protection",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Aruba S.p.a.",
    providerService: "ArubaCloud",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://hosting.aruba.it/documents/tc-files/en/7_privacypolicyhostingcart.pdf",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Bluehost Inc.",
    providerService: "Bluehost",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.endurance.com/privacy/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "10dencehispahard S.L.",
    providerService: "CDmon",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.cdmon.com/en/privacy-policy",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Cloud66 Inc.",
    providerService: "Cloud 66",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.cloud66.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Cloudflare, Inc.",
    providerService: "Cloudflare",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.cloudflare.com/privacypolicy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Cloudinary Ltd.",
    providerService: "Cloudinary",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://cloudinary.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Datacenter Luxembourg S.A.",
    providerService: "Datacenter Luxembourg",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.datacenter.eu/app/uploads/2018/05/dclux-privacy-policy-v10-20180525.pdf",
    optOut: null,
    placeProcessing: "Luxembourg",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "DigitalOcean, LLC.",
    providerService: "DigitalOcean",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.digitalocean.com/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Canada / Germany / India / Netherlands / Singapore / UK / USA ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Soluciones Corporativas IP, SL",
    providerService: "Don Dominio",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.dondominio.com/legal/55/politica-de-privacidad/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Elasticsearch B.V. (EEA users) | Elasticsearch, Inc. (USA&other countries users)",
    providerService: "Elasticsearch",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.elastic.co/legal/privacy-statement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Engine Yard, Inc.",
    providerService: "Engine Yard",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.engineyard.com/policies/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Factor\u00edaDigitalCom Soluciones Internet, SLU.",
    providerService: "Factor\u00eda Digital",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.factoriadigital.com/informacion/politica-de-privacidad",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "FastComet Inc.",
    providerService: "FastComet",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.fastcomet.com/terms/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Filestack, Inc.",
    providerService: "Filestack",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.filestack.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Firebase Cloud / Hosting / Database",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "GitHub BV (EEA users) | GitHub, Inc. (USA & other countries users)",
    providerService: "GitHub Pages",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://help.github.com/en/github/site-policy/github-privacy-statement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "GoDaddy.com, LLC",
    providerService: "GoDaddy",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://uk.godaddy.com/legal/agreements/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google App Engine",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Australia / Belgium / Brazil / Canada / Germany / India / Ireland / Japan / UK / USA",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Cloud Storage",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Australia / Belgium / Brazil / Canada / Germany / India / Ireland / Japan / Netherlands / Singapore / Taiwan / UK / USA ",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Play Store",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Salesforce.com, Inc.",
    providerService: "Heroku",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Asutralia Saleseforce.com. Inc / Eu / Germany / Ireland / Japan / USA",
    "Unnamed: 12": null,
  },
  {
    providerName: "GoDaddy.com, LLC",
    providerService: "Host Europe",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://uk.godaddy.com/legal/agreements/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Acens Technologies S.L.U.",
    providerService: "Hostalia",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.hostalia.com/politica-privacidad/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Hostinet SLU",
    providerService: "Hostinet",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.hostinet.com/informacion-legal/politica-de-privacidad/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Hostinger International Ltd.",
    providerService: "Hostinger",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.hostinger.es/privacidad",
    optOut: null,
    placeProcessing: "Cyprus",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Imagekit Pvt Ltd",
    providerService: "Imagekit",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://imagekit.io/privacy-policy-new",
    optOut: null,
    placeProcessing: "India",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Our Sub-Processors\nAmazon Web Services, Inc.\nImageKit India Pvt Ltd\nGoogle LLC\nKlenty India Pvt Ltd\nRazorpay Software Private Limited\nFreshworks Inc\nDigitalOcean, LLC",
    "Unnamed: 12": null,
  },
  {
    providerName: "Linode, LLC",
    providerService: "Linode",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.linode.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Microsoft Ireland Operations Ltd (EEA users) | Microsoft Corp. (USA & other countries users)",
    providerService: "Microsoft Azure",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://privacy.microsoft.com/en-us/privacystatement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Australia Microsoft Ireland Operations Ltd (EEA users) | Microsoft Corp. (USA & other countries users) / Brazil / Canada / China / France / Germany / India / Ireland / Japan / Korea / Netherlands / South Africa / Switzerland / United Arab Emirates / UK / USA ",
    "Unnamed: 12": null,
  },
  {
    providerName: "ObjectLabs Corp.",
    providerService: "mLab",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://mlab.com/company/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "MongoDB, Inc. ",
    providerService: "Mongo DB",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mongodb.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Netlify, Inc.",
    providerService: "Netlify",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData:
      "address\ncompany name\nData communicated while using the service\nemail address\npassword\nphone number\nUsage Data\nusername\nvarious types of Data as speci\ufb01ed in the privacy policy of the service",
    providerPrivacyLink: "https://www.netlify.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Nominalia Internet S.L.",
    providerService: "Nominalia",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.nominalia.com/quienes-somos/condiciones-legales/ccs-politica-privacidad/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "One.com Group AB ",
    providerService: "One.com",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.one.com/en/info/privacy-policy",
    optOut: null,
    placeProcessing: "Sweden",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Red Hat Ireland Ltd. (EEA users) | Red Hat, Inc. (USA&other countries users) \n",
    providerService: "Openshift",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.redhat.com/en/about/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Ovh US LLC. dba OvhCloud",
    providerService: "OVH.es",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://us.ovhcloud.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Exit Games GmbH (EEA users) | Exit Games Inc. (USA&other countries users) / ",
    providerService: "Photon Cloud",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://dashboard.photonengine.com/en-US/account/privacyandcookiepolicy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Rackone Srl",
    providerService: "RackOne Hosting",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.rackone.it/en/privacy-policy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Raiola Networks, S.L.",
    providerService: "Raiola Networks",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://raiolanetworks.es/politica-de-privacidad/",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Xtudio Networks, S.L.U.",
    providerService: "Sered",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://sered.net/politica-de-privacidad",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "SiteGround Spain S.L.",
    providerService: "SiteGround Hosting",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.siteground.com/privacy.htm",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Netherlands - SiteGround Hosting Ltd.\nNetherlands - SG Hosting Inc.\nSingapore - SiteGround Hosting Ltd.\nSingapore - SG Hosting Inc.\nSpain - SiteGround Spain S.L.\nUnited Kingdom - SiteGround Hosting Ltd.\nUnited Kingdom - SG Hosting Inc.\nUnited States - SiteGround Hosting Ltd.\nUnited States - SG Hosting Inc.",
    "Unnamed: 12": null,
  },
  {
    providerName: "Transloadit Ltd",
    providerService: "Transloadit",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://transloadit.com/legal/privacy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Uploadcare Inc.",
    providerService: "Uploadcare",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://uploadcare.com/about/privacy_policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Webempresa Europa S.L.",
    providerService: "Webempresa",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.webempresa.com/aviso-legal.html",
    optOut: null,
    placeProcessing: "Spain",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Valve Germany GmbH (EEA users) | Valve Corp. (USA&other countries users) ",
    providerService: "Stem Powered",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://store.steampowered.com/privacy_agreement/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Liquid Web LLC",
    providerService: "Liquid Web Hosting ",
    questionGenerator: "Q3 - Hosting provider ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.liquidweb.com/about-us/policies/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Microsoft Ireland Operations Ltd (EEA users) | Microsoft Corp. (USA & other countries users) ",
    providerService: "App Center",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: "N/A ",
    providerPrivacyLink: "https://privacy.microsoft.com/en-gb/privacystatement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "In order to understand data collection by beta testing platforms : https://docs.microsoft.com/en-us/appcenter/gdpr/install-id\n\n",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Beta by Crashlytics",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData:
      "Cookies\ncountry\nData communicated while using the service\ndate of birth\ndevice information\ndevice logs\nemail address\n\ufb01rst name\ngender\nlast name\nphone number\nUsage Data\nusername\nvarious types of Data as speci\ufb01ed in the privacy policy of the service\napp information",
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Feedburner",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Netcats SAS",
    providerService: "FeedPress",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://feed.press/privacy",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Firebase Dynamic Links",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected: "Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "How they work: https://firebase.google.com/docs/dynamic-links/",
    "Unnamed: 12":
      "Firebase Dynamic Links are links that work the way you want, on multiple platforms, and whether or not your app is already installed.",
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Play Beta Testing",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: "N/A ",
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Hangouts",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Livestorm SAS",
    providerService: "Livestorm",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData:
      "address\ncompany name\nCookies\nData communicated while using the service \nemail address\n\ufb01rst name\nlast name\nphone number\nprofession\nUsage Data",
    providerPrivacyLink: "https://livestorm.co/privacy-policy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "NeverBounce LLC",
    providerService: "Neverbounce ",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://neverbounce.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "https://neverbounce.com/eu-privacy-policy",
    "Unnamed: 12": null,
  },
  {
    providerName: "Short.cm Inc",
    providerService: "Short.cm ",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected: null,
    specificData: null,
    providerPrivacyLink: "https://short.cm/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Microsoft Ireland Operations Ltd (EEA users) | Microsoft Corp. (USA & other countries users)",
    providerService: "Skype",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://privacy.microsoft.com/es-es/privacystatement/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Apple Distribution International Ltd. (EEA users) | Apple Inc. (USA & other countries users)",
    providerService: "TestFlight",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: "N/A ",
    providerPrivacyLink: "https://www.apple.com/legal/privacy/en-ww/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Video Communication Services AS",
    providerService: "Whereby",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://whereby.com/information/tos/privacy-policy/",
    optOut: null,
    placeProcessing: "Norway",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Zoom Video Communications, Inc.",
    providerService: "Zoom",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://zoom.us/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "BunnyWay d.o.o.",
    providerService: "Bunny CDN",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data from your device, Cookies",
    specificData: null,
    providerPrivacyLink: "https://bunnycdn.com/privacy",
    optOut: null,
    placeProcessing: "Slovenia",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Zapier, Inc.",
    providerService: "Zapier ",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://zapier.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Team ON PTY Ltd.",
    providerService: "WebinarNinja",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://webinarninja.com/privacy/",
    optOut: null,
    placeProcessing: "Australia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Dialogflow",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Aut O\u2019Mattic A8C Ireland Ltd. (EEA users) | Automattic Inc. (USA & other countries users) ",
    providerService: "Akismet",
    questionGenerator: "Q5 - Security ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://automattic.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google reCAPTCHA",
    questionGenerator: "Q5 - Security ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://policies.google.com/privacy?hl=en",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Imperial Purchaser, LLC",
    providerService: "Imperva Application Security",
    questionGenerator: "Q5 - Security ",
    dataCollected: "Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.imperva.com/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Go Daddy Operating Company, LLC Media Temple dba Sucuri",
    providerService: "Sucuri CloudProxy",
    questionGenerator: "Q5 - Security ",
    dataCollected: "Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://sucuri.net/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "WebFurther, LLC",
    providerService: "WebPurify",
    questionGenerator: "Q5 - Security ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.webpurify.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Fastly, Inc.",
    providerService: "Fastly CDN",
    questionGenerator: "Q7 - Other infrastructure ",
    dataCollected:
      " Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.fastly.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
];

export default infrastructure;
