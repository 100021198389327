import React from "react";

import { Box, Typography, Link, makeStyles } from "@material-ui/core";

import clsx from "clsx";

import logoDark from "../../assets/images/home/logoDark.svg";

import favicon from "../../assets/images/authentication/favicon.svg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    width: "100%",
    height: "135px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  boxContainerNoTitle: {
    width: "125px",
    alignItems: "center",
    justifyContent: "center",
  },
  imgLogo: {
    display: "none",
    width: "175px",
    [theme.breakpoints.down("md")]: {
      "&": {
        display: "block",
      },
    },
  },
  noTitleImg: {
    width: "50px",
    display: "block",
  },
  title: {
    [theme.breakpoints.down("md")]: {
      "&": {
        fontSize: "2rem",
      },
    },
  },
}));

const Header = ({ title }) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx({
        [classes.boxContainer]: true,
        [classes.boxContainerNoTitle]: title === undefined,
      })}
    >
      <Link href="/">
        <img
          src={title ? logoDark : favicon}
          alt="Audaxly"
          className={clsx({
            [classes.imgLogo]: true,
            [classes.noTitleImg]: title === undefined,
          })}
        />
      </Link>

      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
    </Box>
  );
};

export default Header;
