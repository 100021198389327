import React, { createContext, useContext, useEffect, useReducer } from "react";

import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../utils";

const defaultStateProjectCategories = [];

const reducer = (previousState, newState) => {
  if (newState === null || newState === undefined) {
    removeLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_CATEGORIES_LOCAL_STORAGE_KEY,
    });

    return defaultStateProjectCategories;
  }

  return [...previousState, ...newState];
};

const ProjectCategoriesContext = createContext(defaultStateProjectCategories);

const ProjectCategoriesProvider = ({ children }) => {
  const [projectCategories, setProjectCategories] = useReducer(
    reducer,
    getLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_CATEGORIES_LOCAL_STORAGE_KEY,
      defaultState: defaultStateProjectCategories,
    })
  );

  useEffect(() => {
    setLocalStorageItem({
      key: process.env.REACT_APP_PROJECT_CATEGORIES_LOCAL_STORAGE_KEY,
      data: projectCategories,
    });
  }, [projectCategories]);

  return (
    <ProjectCategoriesContext.Provider
      value={{ projectCategories, setProjectCategories }}
    >
      {children}
    </ProjectCategoriesContext.Provider>
  );
};

const useProjectCategories = () => {
  const { projectCategories, setProjectCategories } = useContext(
    ProjectCategoriesContext
  );

  if (!projectCategories)
    throw new Error(
      "Hook useProjectCategories must be used over ProjectCategoriesProvider"
    );

  return { projectCategories, setProjectCategories };
};

export {
  ProjectCategoriesContext,
  ProjectCategoriesProvider,
  defaultStateProjectCategories,
  useProjectCategories,
};
