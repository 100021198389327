import React from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";

import { GetStarted } from "../general";

import css from "../../assets/images/howItWorks/css.svg";

import girl from "../../assets/images/howItWorks/girl.svg";

import hook from "../../assets/images/howItWorks/hook.svg";

import screen from "../../assets/images/howItWorks/screen.svg";

const useStyle = makeStyles((theme) => ({
  boxContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginBottom: theme.spacing(4),
  },
  boxContainerSection: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      "&": {
        flexDirection: "column-reverse",
      },
    },
  },
  boxContainerSectionLeft: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    boxSizing: "border-box",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      "&": {
        flexDirection: "column-reverse",
      },
    },
  },
  boxContainerSectionBackground: {
    background: theme.palette.gradient
  },
  boxContainerSectionContent: {
    maxWidth: "550px",
    marginRight: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
    },
  },
  boxContainerSectionContentLeft: {
    maxWidth: "550px",
    marginLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      marginLeft: "0",
    },
  },
  boxContainerSectionTitle: {
    marginBottom: theme.spacing(3),
  },
  title: {
    fontSize: "3.5rem",
    color: theme.palette.blackOne,
  },
  boxContainerSectionText: {
    "& > *:last-child": {
      marginBottom: "0",
    },
  },
  text: {
    fontSize: "1.5rem",
    color: theme.palette.grayOne,
    marginBottom: theme.spacing(2),
  },
  highlight: {
    fontWeight: "700",
  },
  image: {
    height: "500px",
    minHeight: "auto",
    width: "450px",
    minWidth: "auto",
    backgroundAttachment: "scroll",
    backgroundPosition: "right center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "95%",
    [theme.breakpoints.down("md")]: {
      "&": {
        height: "350px",
        width: "100%",
        backgroundPosition: "center",
        backgroundSize: "contain",
        marginBottom: theme.spacing(5),
      },
    },
  },
  imageAskYourTeam: {
    backgroundImage: `url(${css})`,
  },
  imageFillInTheData: {
    backgroundPosition: "left",
    backgroundImage: `url(${hook})`,
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center",
    },
  },
  imageCustomisePublish: {
    backgroundImage: `url(${screen})`,
  },
  imageCarePP: {
    backgroundImage: `url(${girl})`,
    backgroundPosition: "left",
    marginRight: theme.spacing(10),
    [theme.breakpoints.down("md")]: {
      backgroundPosition: "center",
      marginRight: "0",
      marginBottom: theme.spacing(10),
    },
  },
  contentCreditCard: {
    fontSize: "1.1rem",
    color: theme.palette.grayFive,
    marginTop: theme.spacing(2),
    marginLeft: ".75px",
  },
}));

const Content = () => {
  const classes = useStyle();

  return (
    <Box className={classes.boxContainer}>
      {/* Create your first project */}
      <Box className={classes.boxContainerSection}>
        <Box className={classes.boxContainerSectionContent}>
          <Box className={classes.boxContainerSectionTitle}>
            <Typography variant="h2" className={classes.title}>
              1. Create your first project
            </Typography>
          </Box>

          <Box className={classes.boxContainerSectionText}>
            <Typography className={classes.text}>
              The project details{" "}
              <span className={classes.highlight}>
                inform the user who to contact for privacy issues
              </span>
              .
            </Typography>

            <br />

            <Typography className={classes.text}>
              It should state{" "}
              <span className={classes.highlight}>
                who the legal owner of the project is
              </span>
              , be it an individual (personal blog, newsletter) or a company
              (company website, apps, platforms).
            </Typography>

            <br />

            <Typography className={classes.text}>
              It is advisable you create an email address dedicated to privacy
              issues to make sure user requests are attended in a timely manner.
              The general term established by law is 30 days.
            </Typography>
          </Box>
        </Box>

        <Box className={`${classes.imageAskYourTeam} ${classes.image}`}></Box>
      </Box>

      {/* Choose your brand colors and publish */}
      <Box
        className={`${classes.boxContainerSectionLeft} ${classes.boxContainerSectionBackground}`}
      >
        <Box className={classes.boxContainerSectionContentLeft}>
          <Box className={classes.boxContainerSectionTitle}>
            <Typography variant="h2" className={classes.title}>
              2. Choose your brand colors and publish
            </Typography>
          </Box>

          <Box className={classes.boxContainerSectionText}>
            <Typography className={classes.text}>
              We'll generate a policy that satisfies minimum legal requirements.
            </Typography>

            <br />

            <Typography className={classes.text}>
              <span className={classes.highlight}>
                You'll be able to choose your brand colors and publish it in
                under 5 minutes
              </span>
              .
            </Typography>

            <br />

            <Typography className={classes.text}>
              It will inform the users about general responsabilities, who to
              contact, what their rights are and what steps you are taking to be
              compliant.
            </Typography>
          </Box>
        </Box>

        <Box className={`${classes.imageFillInTheData} ${classes.image}`}></Box>
      </Box>

      {/* Build compliance in your own time */}
      <Box className={classes.boxContainerSection}>
        <Box className={classes.boxContainerSectionContent}>
          <Box className={classes.boxContainerSectionTitle}>
            <Typography variant="h2" className={classes.title}>
              3. Build compliance in your own time
            </Typography>
          </Box>

          <Box className={classes.boxContainerSectionText}>
            <Typography className={classes.text}>
              After having the minimal Privacy Policy you will be able to fill
              in the relevant data. To make the process smoother,{" "}
              <span className={classes.highlight}>make an inventory</span>. Ask
              your team:
            </Typography>

            <br />

            <Typography className={classes.text}>
              <span className={classes.highlight}>
                What apps & services do you use & What user data do you collect?
              </span>
            </Typography>

            <br />

            <Typography className={classes.text}>
              Think about all the systems implemented on your website like log
              in, payment, widgets.
            </Typography>
          </Box>
        </Box>

        <Box
          className={`${classes.imageCustomisePublish} ${classes.image}`}
        ></Box>
      </Box>

      {/* Your company will be safe and your customers will appreciate it  */}
      <Box
        className={`${classes.boxContainerSectionLeft} ${classes.boxContainerSectionBackground}`}
      >
        <Box className={classes.boxContainerSectionContentLeft}>
          <Box className={classes.boxContainerSectionTitle}>
            <Typography variant="h2" className={classes.title}>
              4. Your company will be safe and your customers will appreciate
              it.
            </Typography>
          </Box>

          <Box className={classes.boxContainerSectionText}>
            <Typography className={classes.text}>
              Because you care about privacy
            </Typography>

            <br />

            <br />
          </Box>

          <GetStarted text="Get started - Free" styles={{ width: "150px" }} />

          <Typography className={classes.contentCreditCard}>
            No credit card required!
          </Typography>
        </Box>

        <Box className={`${classes.imageCarePP} ${classes.image}`}></Box>
      </Box>
    </Box>
  );
};

export default Content;
