const personal = [
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Age",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Car insurance records",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Children\u2019s names",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "City",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Country",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Current employer",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Date of birth",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Driver\u2019s license / state ID",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Education history",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Email address - personal",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Email address - work",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Employment history",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Eye color",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Gender",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Hair color",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Handwriting",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Height",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "HR data",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Maiden name",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Marital status",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Name",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Name of friends",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Names of parents",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Names other siblings",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Nationality",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Number of people in household",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Occupation",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Other names used",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Passport information",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Password",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Performance evaluations",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Pets & animals",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Phone number - home",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Phone number - mobile phone",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Phone number - work",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Physical address - home",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Physical address - work",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Place of birth",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Postal code",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Profession",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Professional license records",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Recreational license records",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Reference interviews",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Schools attended",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Security question & answer",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Signature",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Social security / social insurance number",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Spouse name",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Surname",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Tax returns",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Username",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "VAT number",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Vehicle registration records",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Veteran status",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Website",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Weight",
  },
  {
    section: "Personal & Contact details [of your users]",
    tag: "3. What data do we collect a. Personal & Contact details",
    dataType: "Writing sample (electronic)",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Bank account number",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Card details",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Credit report",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Current income",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Date of purchase",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Home value",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Homeowner status",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Income history",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Investment records",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Life insurance records",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Loan records",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Other financial statements",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType:
      "Payment method information. E.g credit or debit card number or bank account information",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "PIN number",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Property records",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Purchase amount",
  },
  {
    section: "Financial information [of your users]",
    tag: "3. What data do we collect b. Financial information",
    dataType: "Sort code",
  },
  {
    section: "Data introduced by you [your users] within the platform",
    tag:
      "3. What data do we collect c. Data introduced by you within the platform",
    dataType: "Information that users provide in the comments",
  },
  {
    section: "Data introduced by you [your users] within the platform",
    tag:
      "3. What data do we collect c. Data introduced by you within the platform",
    dataType: "Other text fields users fill in",
  },
  {
    section: "Data introduced by you [your users] within the platform",
    tag:
      "3. What data do we collect c. Data introduced by you within the platform",
    dataType: "Other's contact details",
  },
  {
    section: "Data introduced by you [your users] within the platform",
    tag:
      "3. What data do we collect c. Data introduced by you within the platform",
    dataType: "Other's email address",
  },
  {
    section: "Data introduced by you [your users] within the platform",
    tag:
      "3. What data do we collect c. Data introduced by you within the platform",
    dataType: "Pictures",
  },
  {
    section: "Data introduced by you [your users] within the platform",
    tag:
      "3. What data do we collect c. Data introduced by you within the platform",
    dataType: "Videos",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Browser plug in types",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Browser type and version",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType:
      "Connection type - wired or Wi-Fi network to which the device is connected and mobile carrier if available",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "General location",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType:
      "Identification codes that identify user's device such as MAC IP IMSI",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Information about operating system",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "ISP - Internet Service Provider",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Language preference",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Latitude/longitude of a mobile device",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Mobile advertising IDs like IDFAs and Google Advertising IDs",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Mobile Carrier ASN",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Mobile Device Identifiers and SDKs",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Other device ID's like Cross-Device ID by Adform",
  },
  {
    section:
      "Data that identifies you & your device [your users & their devices]",
    tag: "3. What data do we collect d. Data that identifies you & your device",
    dataType: "Time zone setting",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Chat history",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Cookies",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Date and time of online activity",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Errors",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Frequency of visits of the website",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType:
      "Interaction with an advertisement (e.g. whether a user clicks on an advertisement)",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Number of times user comes back to the website",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Page response time",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Purchase history",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType:
      "Records of the type of websites and pages viewed - browsing history",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Search history",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType:
      "Site/page user comes from before and visited after viewing an advertisement",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "Time spent on the website",
  },
  {
    section: "Data on how you [your users] use the service",
    tag: "3. What data do we collect e. Data on how you use the service",
    dataType: "URL clickstreams",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Activity on the third party website",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Chat history on the third party website",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Cloud storage files",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Daily life activities",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Donations to organizations",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Event attendance",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Likes & ratings",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Media preferences",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Search history on the third party website",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Shopping & purchase history (elsewhere online)",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Shopping & purchase history (offline)",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Social media accounts",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Social media posts & history",
  },
  {
    section: "Data related to your third-party accounts [of your users]",
    tag:
      "3. What data do we collect f. Data related to your third party accounts",
    dataType: "Topics of interest",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Device's speech recognition",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Biometric data access permission",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Bluetoooth",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Calendar",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Call permission",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Camera",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Camera permission without saving or recording",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Contacts",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Health data read permission",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Health data update permission",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Home kit",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Media library permission (music)",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Microphone",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Microphone permission without recording",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "NFC reader permission",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Phone",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Photo library",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Photo library - write only permission",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Precise Location - continuously",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Precise Location - discontinuous",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Reminders",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Sensors",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Device AI Assistant",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "SMS",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Social media accounts",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "Storage",
  },
  {
    section: "Data from your [your users] device",
    tag: "3. What data do we collect g. Data from your device",
    dataType: "TV provider",
  },
];

export default personal;
