import React, { useEffect } from "react";

import { AppBar, Toolbar, makeStyles, Box, Link } from "@material-ui/core";

import { Link as RouterLink } from "react-router-dom";

import logoDark from "../../assets/images/home/logoDark.svg";

import { GetStarted, HamburgerMenu } from "./index";

import Sticky from "react-sticky-el";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundColor: theme.palette.common.white,
    boxShadow: "none",
  },
  appBarBorder: {
    boxShadow: `0px 0px 75px -35px ${theme.palette.graySix}`,
    borderBottom: "1px solid rgba(231, 238, 236, .5)",
  },
  boxContainer: {
    width: "100%",
    minHeight: "80px",
    display: "flex",
    flexWrap: "nowrap",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-around",
  },
  boxItem: {
    height: "auto",
    display: "flex",
    flexWrap: "nowrap",
    alignContent: "center",
    alignItems: "center",
    "& > *": {
      margin: "10px",
    },
  },
  logo: {
    width: "135px",
    outline: "none",
    cursor: "pointer",
  },
  boxLinks: {
    display: "flex",
    justifyContent: "space-evenly",
    "& > *": {
      padding: "0 40px 0 0",
    },
    // "& > *:last-child": {
    //     padding: "0"
    // },
    [theme.breakpoints.down("md")]: {
      "&": {
        display: "none",
      },
    },
  },
  popper: {
    "& > *:first-child": {
      boxShadow: theme.shadows[11],
    },
  },
  menuPaper: {
    boxShadow: theme.shadows[11],
  },
  solutions: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "& > *:last-child": {
      marginLeft: "4px",
    },
  },
  menuItemDisabled: {
    color: theme.palette.purpleTwo,
  },
  text: {
    color: theme.palette.blackOne,
    fontWeight: "700",
    letterSpacing: ".5px",
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
  textDefault: {
    color: theme.palette.secondary.main,
    fontWeight: "700",
    letterSpacing: ".5px",
  },
  authenticationBox: {
    marginLeft: "0px",
    [theme.breakpoints.down("md")]: {
      "&": {
        display: "none",
      },
    },
  },
  login: {
    color: theme.palette.blackOne,
  },
  expandMoreRotation: {
    transition: "all .2s linear 0s",
    transform: "rotate(180deg)",
  },
  hamburgerMenu: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      "&": {
        display: "flex",
      },
    },
  },
}));

const Header = () => {
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const activeLink = (path, defaultClasses = classes.text) =>
    window.location.pathname === path ? classes.textDefault : defaultClasses;

  return (
    <AppBar className={classes.appBar} position="sticky">
      <Toolbar>
        <Box className={classes.boxContainer}>
          <Box className={classes.boxItem}>
            <RouterLink to="/">
              <img alt="Home" src={logoDark} className={classes.logo} />
            </RouterLink>
          </Box>

          <Box className={classes.boxItem}>
            <Box className={classes.boxLinks}>
              <Link
                component={RouterLink}
                to="/how-it-works"
                underline="none"
                className={activeLink("/how-it-works")}
              >
                How it works
              </Link>

              <Link
                component={RouterLink}
                to="/pricing"
                underline="none"
                className={activeLink("/pricing")}
              >
                Pricing
              </Link>

              <Link
                component={RouterLink}
                to="/about-us"
                underline="none"
                className={activeLink("/about-us")}
              >
                About us
              </Link>
            </Box>
          </Box>

          <Box className={classes.boxItem}>
            <Box className={`${classes.authenticationBox} ${classes.boxItem}`}>
              <Link
                component={RouterLink}
                to="/login"
                underline="none"
                className={`${classes.text} ${classes.login}`}
              >
                Login
              </Link>

              <GetStarted text="Sign up" />
            </Box>

            <Box className={classes.hamburgerMenu}>
              <HamburgerMenu />
            </Box>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const HeaderSticky = () => {
  const classes = useStyles();

  return (
    <Sticky
      topOffset={0}
      stickyClassName={classes.appBarBorder}
      stickyStyle={{ zIndex: 1000 }}
    >
      <Header />
    </Sticky>
  );
};

export { Header, HeaderSticky };
