import React, { createContext, useContext, useEffect, useReducer } from "react";

import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../utils";

const defaultStateSpam = {
  emailsSent: 0,
};

const reducer = (previousState, newState) => {
  if (newState === null) {
    removeLocalStorageItem({
      key: process.env.REACT_APP_SPAM_LOCAL_STORAGE_KEY,
    });

    return defaultStateSpam;
  }

  return { ...previousState, ...newState };
};

const SpamContext = createContext(defaultStateSpam);

const SpamProvider = ({ children }) => {
  const [spam, setSpam] = useReducer(
    reducer,
    getLocalStorageItem({
      key: process.env.REACT_APP_SPAM_LOCAL_STORAGE_KEY,
      defaultState: defaultStateSpam,
    })
  );

  useEffect(() => {
    setLocalStorageItem({
      key: process.env.REACT_APP_SPAM_LOCAL_STORAGE_KEY,
      data: spam,
    });
  }, [spam]);

  return (
    <SpamContext.Provider value={{ spam, setSpam }}>
      {children}
    </SpamContext.Provider>
  );
};

const useSpam = () => {
  const { spam, setSpam } = useContext(SpamContext);

  if (!spam) throw new Error("Hook useSpam must be used over SpamProvider");

  return { spam, setSpam };
};

export { SpamContext, SpamProvider, defaultStateSpam, useSpam };
