import React from "react";

import { Box, makeStyles, Typography } from "@material-ui/core";

import { GetStarted } from "../general";

import background from "../../assets/images/pricing/background.svg";

import character from "../../assets/images/pricing/character.svg";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "98vh",
    background: theme.palette.gradient,
    backgroundImage: `url(${background})`,
    backgroundAttachment: "scroll",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    [theme.breakpoints.down("md")]: {
      "&": {
        flexDirection: "column",
        marginBottom: theme.spacing(2),
        backgroundSize: "cover",
      },
    },
  },
  boxIllustration: {
    backgroundImage: `url(${character})`,
    backgroundAttachment: "scroll",
    backgroundPosition: "top center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "100%",
    height: "440px",
    width: "560px",
    maxWidth: "100%",
    marginRight: theme.spacing(15),
    [theme.breakpoints.down("md")]: {
      "&": {
        backgroundSize: "85%",
        marginRight: "0",
        marginBottom: theme.spacing(2),
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        marginTop: theme.spacing(2),
        height: "270px",
        backgroundSize: "80%",
      },
    },
  },
  boxContentContainer: {
    boxSizing: "border-box",
    padding: theme.spacing(2),
    maxWidth: "475px",
  },
  boxContentTitle: {
    marginBottom: theme.spacing(6),
  },
  title: {},
  boxContentText: {
    "& > *:last-child": {
      //   marginBottom: "0",
    },
  },
  text: {
    fontSize: "1.5rem",
    color: theme.palette.grayOne,
    marginBottom: theme.spacing(5),
  },
  textStyle: {
    "&::after": {
      content: '""',
      display: "block",
      width: "70px",
      height: "30px",
      marginTop: "-65px",
      marginLeft: "303px",
      borderBottom: `16px solid ${theme.palette.yellowOne}`,
    },
  },
  boxContentGetStarted: {},
  highlight: {},
}));

const Beta = () => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxIllustration}></Box>

      <Box className={classes.boxContentContainer}>
        <Box className={classes.boxContentTitle}>
          <Typography variant="h1" className={classes.title}>
            It's majestically <span className={classes.textStyle}>free</span>
          </Typography>
        </Box>

        <Box className={classes.boxContentText}>
          <Typography className={classes.text}>
            As we’re building other tools to help you with compliance the{" "}
            <span className={classes.highlight}>Privacy Policy generator</span>{" "}
            is free and will remain{" "}
            <span className={classes.highlight}>free</span>.
          </Typography>

          <Typography className={classes.text}>
            We’re only asking for feedback.
            <br />
            And only if you feel like it.
          </Typography>
        </Box>

        <Box className={classes.boxContentGetStarted}>
          <GetStarted text="Get started - Free" styles={{ width: "150px" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Beta;
