import React, { useState, useEffect, useLayoutEffect } from "react";

import {
  Grid,
  Box,
  Typography,
  makeStyles,
  Stepper,
  Step,
  Button,
  StepButton,
  Chip,
  Avatar,
  TextField,
  Container,
  Link,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import {
  ArrowBack as ArrowBackIcon,
  Code as CodeIcon,
  SaveAlt as SaveAltIcon,
  // Update as UpdateIcon,
} from "@material-ui/icons";

import moment from "moment";

import { toast } from "react-toastify";

// import moment from "moment";

import InputColor from "react-input-color";

import { useNavigate } from "react-router-dom";

import { gql, useApolloClient } from "@apollo/client";

import { useForm } from "react-hook-form";

import * as Yup from "yup";

import { yupResolver } from "@hookform/resolvers/yup";

import { ErrorMessage } from "@hookform/error-message";

import * as omitDeep from "omit-deep-lodash";

import { groupBy, map, startCase, toLower } from "lodash";

import { thirdParties, personal, features, countries } from "../../data";

import {
  interactionWithExternalPlatform as interactionWithExternalPlatformThirdParties,
  marketing as marketingThirdParties,
  analytics as analyticsThirdParties,
  customerSupport as customerSupportThirdParties,
  advertising as advertisingThirdParties,
  payments as paymentsThirdParties,
  infrastructure as infrastructureThirdParties,
  accessConnectivity as accessConnectivityThirdParties,
} from "../../data/thirdParties";

import {
  useDashboard,
  useProject,
  useProjectCategories,
  useProjectPrivacyPolicy,
} from "../../contexts";

import {
  ErrorLoad,
  SuccessLoad,
  LoadingComponent,
  RadioInput,
  SelectInput,
  DeleteDialog,
  InformationNote,
} from "../general";

import { URL_PATTERN, REMOVE_PROTOCOLS_PATTERN } from "../../utils";

import shopify from "../../assets/images/dashboard/projectPrivacyPolicy/shopify.png";

import squarespace from "../../assets/images/dashboard/projectPrivacyPolicy/squarespace.png";

import webflow from "../../assets/images/dashboard/projectPrivacyPolicy/webflow.png";

import weebly from "../../assets/images/dashboard/projectPrivacyPolicy/weebly.png";

import wix from "../../assets/images/dashboard/projectPrivacyPolicy/wix.png";

import wordpress from "../../assets/images/dashboard/projectPrivacyPolicy/wordpress.png";

import softr from "../../assets/images/dashboard/projectPrivacyPolicy/softr.png";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiStepIcon-root": {
      fill: "transparent",
      stroke: theme.palette.primary.main,
      strokeWidth: ".5px",
    },
    ".MuiStepIcon-active": {
      stroke: theme.palette.secondary.main,
    },
    ".MuiStepLabel-label": {
      color: theme.palette.primary.main,
    },
    ".MuiStepLabel-active": {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  container: {
    display: "flex",
    justifyContent: "space-evenly",
    height: "85vh",
  },
  grid: {
    display: "flex",
    justifyContent: "space-evenly",
    height: "85vh",
  },
  gridItemLeft: {
    height: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    "& > *": {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        height: "55%",
        flexDirection: "column-reverse",
      },
    },
  },
  gridItemRight: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    overflow: "scroll",
    boxSizing: "border-box",
    borderRadius: "5px",
    marginLeft: theme.spacing(1),
    background: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    "& > *": {
      padding: theme.spacing(1),
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        overflow: "unset",
        height: "fit-content",
        marginLeft: "0",
        margin: theme.spacing(1, 0, 2, 0),
      },
    },
  },
  ppMenu: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    boxSizing: "border-box",
    background: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    overflow: "auto",
  },
  ppMenuStepper: {
    marginTop: theme.spacing(1),
    overflowY: "scroll",
    height: "100%",
  },
  ppMenuStepperStep: {
    outline: "none",
  },
  ppMenuStepperStepButton: {},
  ppMenuStepperStepButtonRoot: {
    width: "auto",
  },
  ppContent: {
    flex: "1",
    height: "100%",
    padding: theme.spacing(2),
    boxShadow: theme.shadows[4],
    background: theme.palette.common.white,
    "& > *:first-child": {
      marginBottom: theme.spacing(1),
    },
  },
  title: {
    position: "sticky",
    wordBreak: "break-all",
  },
  embedButtonBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    minHeight: "84px",
    marginTop: theme.spacing(1),
    background: theme.palette.common.white,
    boxShadow: theme.shadows[4],
    padding: theme.spacing(2),
    boxSizing: "border-box",
    cursor: "pointer",
    "& > *": {
      height: "100%",
    },
    [theme.breakpoints.down("lg")]: {
      "&": {
        minHeight: "94px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        marginTop: "0",
        marginBottom: theme.spacing(1),
      },
    },
  },
  embedButtonBoxDescription: {
    display: "flex",
    flex: "2",
    flexDirection: "column",
    justifyContent: "center",
    alignContent: "center",
  },
  embedButtonDescriptionText: {
    fontSize: "1.2rem",
    marginTop: theme.spacing(1),
  },
  embedButtonBoxIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    padding: "0 4px",
  },
  embedButtonIcon: {
    color: theme.palette.secondary.main,
    fontSize: "3rem",
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
  form: {
    height: "97%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "self-start",
    "& > *:first-child": {
      marginTop: theme.spacing(2),
    },
    "& > *": {
      marginTop: theme.spacing(1),
    },
    "& > *:last-child": {
      marginTop: theme.spacing(2),
    },
  },
  formInputs: {
    width: "100%",
    "& > *:first-child": {
      marginTop: "0px",
    },
  },
  section: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
  },
  labelQuestion: {},
  inputColor: {
    margin: theme.spacing(1, 0),
  },
  textField: {
    marginTop: "10px",
  },
  select: {
    outline: "none",
    minWidth: "100%",
    marginTop: theme.spacing(1),
  },
  sectionActions: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  sectionInternalActions: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  sectionActionsBack: {},
  updateButton: {
    fontSize: "1.2rem",
    lineHeight: "0",
    background: "transparent",
  },
  updateButtonIcon: {
    fontSize: "22px",
    color: theme.palette.secondary.main,
  },
  backButton: {
    color: theme.palette.dashboardBlue,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    paddingRight: theme.spacing(2),
    "&:hover": {
      background: "transparent",
      filter: "opacity(.8)",
    },
  },
  backButtonIcon: {},
  nextButton: {},
  error: {
    alignSelf: "self-end",
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      "&": {
        paddingBottom: "2px",
      },
    },
  },
  link: {
    fontSize: "1.3rem",
    color: theme.palette.secondary.main,
    cursor: "pointer",
  },
  embedContainer: {
    padding: "0",
  },
  embedGridContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  embedGridItem: {
    width: "100%",
    minHeight: "225px",
  },
  embedGridItemCompliance: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    minHeight: "285px",
    marginBottom: theme.spacing(4),
    boxSizing: "border-box",
    padding: theme.spacing(3, 4),
    borderRadius: 8,
    background: theme.palette.common.white,
    boxShadow: `0px 0px 15px -5px ${theme.palette.grayFour}`,
    border: "1px solid rgba(231, 238, 236, .75)",
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "475px",
      },
    },
  },
  embedUpdateTitleCompliance: {
    fontSize: "3rem",
    fontWeight: "700",
    lineHeight: "45px",
    color: theme.palette.primary.main,
  },
  embedUpdateSubTitleCompliance: {
    fontSize: "1.4rem",
    fontWeight: "500",
    lineHeight: "35px",
    color: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      "&": {
        lineHeight: "35px",
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(4),
      },
    },
  },
  embedActionUpdateCompliance: {
    width: "fit-content",
  },
  embedGridItemIntro: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    justifyContent: "center",
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "235px",
      },
    },
  },
  embedIntroSubTitle: {
    fontSize: "1.4rem",
    color: theme.palette.grayOne,
  },
  embedUpdateTitle: {
    fontSize: "3rem",
    fontWeight: "700",
    lineHeight: "45px",
  },
  embedUpdateSubTitle: {
    fontSize: "2.5rem",
    fontWeight: "700",
    lineHeight: "45px",
    marginBottom: theme.spacing(1),
  },
  embedBoxSubtitle: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  embedBoxIntroAction: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  embedActionUpdate: {},
  embedActionPreview: {
    marginRight: theme.spacing(3),
  },
  embedGridItemCustomization: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    minHeight: "215px",
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderTop: `2px solid ${theme.palette.grayFour}`,
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "235px",
      },
    },
  },
  embedCustomizationTitle: {
    fontWeight: "700",
    color: theme.palette.primary.main,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(2),
  },
  embedCustomizationSubTitle: {
    fontSize: "1.4rem",
    color: theme.palette.grayOne,
    marginBottom: theme.spacing(2),
  },
  embedCustomizationBoxColorInput: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  embedCustomizationColorInput: {
    border: "none !important",
    padding: "0 !important",
    width: "45px !important",
    height: "45px !important",
    marginRight: theme.spacing(2),
    background: "transparent !important",
    "& > *:first-child": {
      borderRadius: "50%",
    },
  },
  embedCustomizationColorInputText: {
    background: theme.palette.common.white,
    maxWidth: "95px",
  },
  embedGridItemPP: {
    minHeight: "285px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    // borderBottom: `2px solid ${theme.palette.grayFour}`,
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "345px",
      },
    },
  },
  embedGridItemPublish: {
    minHeight: "155px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderTop: `2px solid ${theme.palette.grayFour}`,
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "195px",
      },
    },
  },
  embedPPTitle: {
    fontWeight: "700",
    color: theme.palette.primary.main,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(2),
  },
  embedPPTitleNested: {
    fontWeight: "700",
    color: theme.palette.primary.main,
    fontSize: "1.5rem",
    marginBottom: theme.spacing(2),
  },
  embedPPSubTitle: {
    fontSize: "1.4rem",
    color: theme.palette.grayOne,
    marginBottom: theme.spacing(2),
  },
  embedPPBoxLink: {
    width: "100%",
    margin: theme.spacing(2, 0, 4, 0),
    background: theme.palette.common.white,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grayThree}`,
    borderRadius: "5px",
    boxSizing: "border-box",
  },
  embedPPLink: {
    color: theme.palette.info.main,
    fontSize: "1.3rem",
  },
  embedPPCopyLinkButton: {},
  embedGridItemPrivacyPolicyEmbed: {
    minHeight: "345px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "440px",
      },
    },
  },
  embedPPEmbedTitle: {
    fontWeight: "700",
    color: theme.palette.primary.main,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(2),
  },
  embedPPEmbedSubTitle: {
    fontSize: "1.4rem",
    color: theme.palette.grayOne,
    marginBottom: theme.spacing(2),
  },
  embedPPEmbedBoxCode: {
    width: "100%",
    margin: theme.spacing(2, 0, 4, 0),
    background: theme.palette.blueTwo,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.grayThree}`,
    borderRadius: "5px",
    boxSizing: "border-box",
  },
  embedPPEmbedCode: {
    color: theme.palette.common.white,
    fontSize: "1.3rem",
  },
  embedPPCopyBoxDisablePadding: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  disablePaddingButton: {
    fontSize: "1.2rem",
    lineHeight: "0",
    background: "transparent",
    marginLeft: theme.spacing(3),
  },
  embedGridItemContact: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    borderTop: `2px solid ${theme.palette.grayFour}`,
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "260px",
      },
    },
  },
  embedPPContactTitle: {
    fontWeight: "700",
    color: theme.palette.primary.main,
    fontSize: "1.6rem",
    marginBottom: theme.spacing(2),
  },
  embedPPEmbedSubTitleContact: {
    fontSize: "1.4rem",
    color: theme.palette.grayOne,
    marginBottom: theme.spacing(3),
  },
  embedPPEmbedCopyCodeButton: {},
  embedGridItemDeletePrivacyPolicy: {
    height: "fit-content",
    minHeight: "auto",
    marginBottom: theme.spacing(5),
  },
  linkDeletePrivacyPolicy: {
    fontSize: "1.4rem",
  },
  embedGridItemHowToEmbed: {
    minHeight: "205px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "340px",
      },
    },
  },
  containerHowToEmbedChips: {},
  embedPPEmbedSubTitleHowToEmbed: {
    fontSize: "1.4rem",
    color: theme.palette.grayOne,
    marginBottom: theme.spacing(4),
  },
  chipHowToEmbed: {
    fontSize: "1.1rem",
    margin: theme.spacing(0, 2, 2, 0),
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.grayThree}`,
    "&:hover": {
      background: theme.palette.graySix,
    },
  },
  informationNoteText: {
    color: theme.palette.common.white,
  },
  highlight: {
    fontWeight: 600,
    color: theme.palette.grayOne,
  },
  gist: {
    fontWeight: 600,
    color: theme.palette.secondary.main,
  },
}));

const PrivacyPolicyTitle = ({ title = "Title" }) => {
  const classes = useStyles();

  return (
    <Typography variant="h3" className={classes.title}>
      {title}
    </Typography>
  );
};

const Separator = () => <></>;

const PrivacyPolicyMenu = ({
  sectionsTitle,
  activeStep,
  setActiveStep,
  clickable,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.ppMenu}>
      <PrivacyPolicyTitle title="Sections" />

      <Stepper
        nonLinear
        orientation="vertical"
        activeStep={activeStep}
        className={classes.ppMenuStepper}
        connector={<Separator />}
      >
        {sectionsTitle.map((label, index) => (
          <Step
            key={label}
            className={classes.ppMenuStepperStep}
            active={index === activeStep}
            disabled={!clickable}
          >
            <StepButton
              onClick={() => setActiveStep(index)}
              classes={{ root: classes.ppMenuStepperStepButtonRoot }}
              className={classes.ppMenuStepperStepButton}
              disableRipple
            >
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

const EmbedButton = ({ setEmbed }) => {
  const classes = useStyles();

  return (
    <Box className={classes.embedButtonBox} onClick={() => setEmbed(true)}>
      <Box className={classes.embedButtonBoxDescription}>
        <PrivacyPolicyTitle title="Embed page" />

        <Typography className={classes.embedButtonDescriptionText}>
          Embed your Privacy Policy
        </Typography>
      </Box>

      <Box className={classes.embedButtonBoxIcon}>
        <CodeIcon className={classes.embedButtonIcon} />
      </Box>
    </Box>
  );
};

const DELETE_PRIVACY_POLICY = gql`
  mutation DeletePrivacyPolicy($_id: ID!) {
    deletePrivacyPolicy(_id: $_id)
  }
`;

const UPDATE_PRIVACY_POLICY_COLORS = gql`
  mutation UpdatePrivacyPolicy($input: PrivacyPolicyUpdateInput!, $_id: ID!) {
    updatePrivacyPolicy(input: $input, _id: $_id) {
      colors {
        main
        link
        link
        scroll
      }
    }
  }
`;

const UPDATE_PRIVACY_POLICY_OVERRIDES = gql`
  mutation UpdatePrivacyPolicy($input: PrivacyPolicyUpdateInput!, $_id: ID!) {
    updatePrivacyPolicy(input: $input, _id: $_id) {
      overrides {
        disablePadding
        disableError
      }
    }
  }
`;

const openPP = (publicCode) =>
  window.open(
    `https://audaxly.com/privacy-policy?code=${publicCode}`,
    "_blank"
  );

const EmbedContent = ({
  embed,
  setEmbed,
  rawEmbedText,
  embedText,
  privacyPolicyData,
  setProjectPrivacyPolicy,
}) => {
  const classes = useStyles();

  // eslint-disable-next-line
  const { updatedAt, publicCode, _id, views } = privacyPolicyData;

  const [deletePrivacyPolicyStatus, setDeletePrivacyPolicyStatus] =
    useState(false);

  const { setDashboard } = useDashboard();

  const theme = useTheme();

  const [mainColor, setMainColor] = useState(
    privacyPolicyData?.colors?.main
      ? privacyPolicyData?.colors?.main
      : theme.palette.common.black
  );

  const [overrides, setOverrides] = useState({
    // Check in case it's an old Privacy Policy which don't have
    // the overrides field
    disablePadding: privacyPolicyData?.overrides
      ? privacyPolicyData?.overrides?.disablePadding
      : false,
    // disableError
  });

  const client = useApolloClient();

  const navigate = useNavigate();

  const embedCode = `
		<iframe title="Privacy Policy" src="https://audaxly.com/privacy-policy?code=${publicCode}" scrolling="no" style="border: none; width: 100%; height: 4167px;"></iframe>
	`;

  const link = `https://audaxly.com/privacy-policy?code=${publicCode}`;

  const copyEmbedCode = () => {
    navigator.clipboard.writeText(embedCode);

    toast.success("Embed code coppied");
  };

  const copyLink = () => {
    navigator.clipboard.writeText(link);

    toast.success("URL coppied");
  };

  const deletePrivacyPolicy = async () => {
    try {
      // eslint-disable-next-line
      let data = (
        await client.mutate({
          variables: { _id },
          mutation: DELETE_PRIVACY_POLICY,
        })
      ).data.deletePrivacyPolicy;

      toast.success("Privacy policy deleted");

      navigate("/dashboard/categories", { replace: true });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updatePrivacyPolicyColors = async () => {
    try {
      // eslint-disable-next-line
      let data = (
        await client.mutate({
          variables: {
            input: {
              colors: {
                main: mainColor,
                link: mainColor,
                icon: mainColor,
                scroll: "transparent",
              },
            },
            _id,
          },
          mutation: UPDATE_PRIVACY_POLICY_COLORS,
          fetchPolicy: "no-cache",
        })
      ).data.updatePrivacyPolicy;

      toast.success("Color updated");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updatePrivacyPolicyOverrides = async () => {
    try {
      // eslint-disable-next-line
      let data = (
        await client.mutate({
          variables: {
            input: {
              overrides: {
                disablePadding: !overrides?.disablePadding,
              },
            },
            _id,
          },
          mutation: UPDATE_PRIVACY_POLICY_OVERRIDES,
          fetchPolicy: "no-cache",
        })
      ).data.updatePrivacyPolicy;

      setOverrides({
        disablePadding: !overrides?.disablePadding,
      });

      toast.success("Padding updated");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const showBanner = !Object.entries(privacyPolicyData).every(
    ([key, value]) => {
      if (Array.isArray(value)) {
        if (!value.length) return false;
      }

      return true;
    }
  );

  return (
    <Container maxWidth="md" className={classes.embedContainer}>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="center"
        className={classes.embedGridContainer}
      >
        {showBanner ? (
          <Grid
            item
            className={`${classes.embedGridItem} ${classes.embedGridItemCompliance}`}
          >
            <Typography
              variant="h1"
              className={classes.embedUpdateTitleCompliance}
            >
              👀 Update your policy
            </Typography>

            <Typography
              variant="h1"
              className={classes.embedUpdateSubTitleCompliance}
            >
              You successfully created your project and we generated the basic
              policy for you! You can choose to publish it right now! Remember:
              to be compliant you have to fill in all the data so please take
              some time to answer the questionnaire.
            </Typography>

            <Button
              variant="hovered"
              onClick={() => {
                setEmbed((previousEmbed) => !previousEmbed);

                setProjectPrivacyPolicy({ status: "update" });
              }}
              className={classes.embedActionUpdateCompliance}
            >
              Get compliant
            </Button>
          </Grid>
        ) : null}

        {/* Intro */}

        <Grid
          item
          className={`${classes.embedGridItem} ${classes.embedGridItemIntro}`}
        >
          <Typography variant="h1" className={classes.embedUpdateTitle}>
            Privacy Policy
          </Typography>

          <Link
                underline="none"
                href={rawEmbedText}
                target="_blank"
          >
            <Typography variant="h1" className={classes.embedUpdateSubTitle}>
              {embedText}
            </Typography>
          </Link>

          <Typography variant="h4" className={classes.embedIntroSubTitle}>
            Last updated on {moment(updatedAt, "x").format("MMMM Do YYYY")}
          </Typography>
         
          <Typography variant="h4" className={classes.embedIntroSubTitle}>
            Since created had {views} views
          </Typography>
          
          <Box className={classes.embedBoxIntroAction}>
            <Button
              variant="hovered"
              onClick={() => openPP(publicCode)}
              className={classes.embedActionPreview}
            >
              Preview
            </Button>

            <Button
              variant="hovered-reversed-gray"
              onClick={() => {
                setEmbed((previousEmbed) => !previousEmbed);

                setProjectPrivacyPolicy({ status: "update" });
              }}
              className={classes.embedActionUpdate}
            >
              Update
            </Button>
          </Box>
        </Grid>

        {/* Customization */}
        <Grid
          item
          className={`${classes.embedGridItem} ${classes.embedGridItemCustomization}`}
        >
          <Typography variant="h3" className={classes.embedCustomizationTitle}>
            Customize your policy
          </Typography>

          <Typography
            variant="h4"
            className={classes.embedCustomizationSubTitle}
          >
            Choose your brand color to make your policy match your color
            palette.
          </Typography>

          <Box className={classes.embedCustomizationBoxColorInput}>
            <InputColor
              initialValue={mainColor}
              onChange={(color) => {
                setMainColor(color.hex);
              }}
              placement="right"
              className={classes.embedCustomizationColorInput}
            />

            <Button
              variant="hovered-reversed-no-border"
              className={classes.updateButton}
              disableRipple
              disableFocusRipple
              disableElevation
              onClick={updatePrivacyPolicyColors}
            >
              Update
            </Button>
          </Box>
        </Grid>

        {/* Publish */}
        <Grid
          item
          className={`${classes.embedGridItem} ${classes.embedGridItemPublish}`}
        >
          <Typography variant="h3" className={classes.embedPPTitle}>
            Publish your policy
          </Typography>

          <Typography variant="h4" className={classes.embedPPSubTitle}>
            As legally required, your policy should be visible and easy to
            access by your users. To embed your policy, choose the option that
            works for you.
          </Typography>
        </Grid>

        {/* Privacy Policy */}
        <Grid
          item
          className={`${classes.embedGridItem} ${classes.embedGridItemPP}`}
        >
          <Typography variant="h3" className={classes.embedPPTitleNested}>
            URL
          </Typography>

          <Typography variant="h4" className={classes.embedPPSubTitle}>
            This is the URL of your Privacy Policy.
          </Typography>

          <Box className={classes.embedPPBoxLink}>
            <Link
              underline="none"
              href={link}
              target="_blank"
              className={classes.embedPPLink}
            >
              {link}
            </Link>
          </Box>

          <Button
            variant="hovered"
            onClick={copyLink}
            className={classes.embedPPCopyLinkButton}
          >
            Copy URL
          </Button>
        </Grid>

        {/* Privacy Policy Embed */}
        <Grid
          item
          className={`${classes.embedGridItem} ${classes.embedGridItemPrivacyPolicyEmbed}`}
        >
          <Typography variant="h3" className={classes.embedPPTitleNested}>
            Embed code
          </Typography>

          <Typography variant="h4" className={classes.embedPPEmbedSubTitle}>
            This is the code that helps you integrate the Privacy Policy
            directly in your website or app. Alternatively we provide a{" "}
	    <Link
              underline="none"
              href="https://codepen.io/audaxly/pen/ZEmzBVq"
              target="_target"
              className={classes.gist}
            >
		responsive embed code
            </Link>{" "}
            that you can adapt to your Privacy Policy.
          </Typography>

          <Box className={classes.embedPPEmbedBoxCode}>
            <Typography variant="body3" className={classes.embedPPEmbedCode}>
              {embedCode}
            </Typography>
          </Box>

          <Box className={classes.embedPPCopyBoxDisablePadding}>
            <Button
              variant="hovered"
              onClick={copyEmbedCode}
              className={classes.embedPPEmbedCopyCodeButton}
            >
              Copy embed code
            </Button>

            <Button
              variant="hovered-reversed-no-border"
              className={classes.disablePaddingButton}
              onClick={updatePrivacyPolicyOverrides}
            >
              {overrides?.disablePadding ? "Add" : "Remove"} padding
              <InformationNote
                text={`${
                  overrides?.disablePadding ? "Add" : "Remove the"
                }  padding on the right and left side of the Privacy Policy page`}
              />
            </Button>
          </Box>
        </Grid>

        {/* How to embed */}
        <Grid
          item
          className={`${classes.embedGridItem} ${classes.embedGridItemHowToEmbed}`}
        >
          <Typography variant="h3" className={classes.embedPPTitleNested}>
            Builder
          </Typography>

          <Typography
            variant="h4"
            className={classes.embedPPEmbedSubTitleHowToEmbed}
          >
            Choose the builder you have used to create your website or app to
            learn how to publish your policy using the embed code.
          </Typography>

          <Box className={classes.containerHowToEmbedChips}>
            <Chip
              avatar={<Avatar alt="Wordpress" src={wordpress} />}
              label="Wordpress"
              component="a"
              target="_blank"
              href="https://wordpress.org/support/article/embeds/"
              clickable
              className={classes.chipHowToEmbed}
            />

            <Chip
              avatar={<Avatar alt="Shopify" src={shopify} />}
              label="Shopify"
              component="a"
              target="_blank"
              href="https://help.widgetic.com/integrations/Shopify/how-to-embed"
              clickable
              className={classes.chipHowToEmbed}
            />

            <Chip
              avatar={<Avatar alt="Squarespace" src={squarespace} />}
              label="Squarespace"
              component="a"
              target="_blank"
              href="https://support.squarespace.com/hc/en-us/articles/206543617-Embed-Blocks"
              clickable
              className={classes.chipHowToEmbed}
            />

            <Chip
              avatar={<Avatar alt="Webflow" src={webflow} />}
              label="Webflow"
              component="a"
              target="_blank"
              href="https://university.webflow.com/lesson/custom-code-embed"
              clickable
              className={classes.chipHowToEmbed}
            />

            <Chip
              avatar={<Avatar alt="Wix" src={wix} />}
              label="Wix"
              component="a"
              target="_blank"
              href="https://support.wix.com/en/article/wix-editor-using-iframes-to-display-visible-content-on-your-site"
              clickable
              className={classes.chipHowToEmbed}
            />

            <Chip
              avatar={<Avatar alt="Weebly" src={weebly} />}
              label="Weebly"
              component="a"
              target="_blank"
              href="https://www.weebly.com/app/help/us/en/topics/create-widgets-embed-code-and-add-external-content"
              clickable
              className={classes.chipHowToEmbed}
            />

            <Chip
              avatar={<Avatar alt="Softr" src={softr} />}
              label="Softr"
              component="a"
              target="_blank"
              href="https://docs.softr.io/integrations/typeform#adding-the-embed-code"
              clickable
              className={classes.chipHowToEmbed}
            />
          </Box>
        </Grid>

        {/* Contact */}
        <Grid
          item
          className={`${classes.embedGridItem} ${classes.embedGridItemContact}`}
        >
          <Typography variant="h3" className={classes.embedPPContactTitle}>
            Support
          </Typography>

          <Typography
            variant="h4"
            className={classes.embedPPEmbedSubTitleContact}
          >
            If you have difficulties or find any issues please contact us.
          </Typography>

          <Button
            variant="hovered"
            onClick={() => {
              setDashboard({
                menu: "support",
                optionsSupportMenuItemSelected: 0,
                hideSidebar: false,
              });

              navigate("/dashboard/contact");
            }}
          >
            Contact
          </Button>
        </Grid>

        {/* Delete Privacy Policy */}
        <Grid
          item
          className={`${classes.embedGridItem} ${classes.embedGridItemDeletePrivacyPolicy}`}
        >
          <Typography
            variant="body3"
            className={`${classes.link} ${classes.linkDeletePrivacyPolicy}`}
            onClick={() => setDeletePrivacyPolicyStatus(true)}
          >
            Delete Privacy Policy
          </Typography>

          <DeleteDialog
            resource="Privacy Policy"
            openStatus={deletePrivacyPolicyStatus}
            closeAction={() => {
              setDeletePrivacyPolicyStatus(false);
            }}
            deleteAction={deletePrivacyPolicy}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

// PP sections
const LAST_OPTIONS = [
  {
    value: "None of the above",
    label: "None of the above",
  },
];

const LEGAL_BASIS = [
  "Consent",
  "Contract",
  "Legitimate interest",
  "Legal obligation",
  "Public interest",
  "Vital interest",
].map((item) => ({ value: item, label: item }));

// the third parties is the aggregation of all the ones below, it is used for the
//Yup verification and in the visualization of the Privacy Policy, if it changes,
// make sure to change on the individual third parties and in the aggregation
//
// This was done to simplify the visualization and all the verification of inputs
// and rules that the Privacy Policy has
const THIRD_PARTIES = Object.entries(thirdParties).map(([key, value]) => ({
  label: value?.providerService,
  value: value?.providerName,
}));

const INTERACTION_WITH_EXTERNAL_PLATFORM_THIRD_PARTIES = Object.entries(
  interactionWithExternalPlatformThirdParties
).map(([key, value]) => ({
  label: value?.providerService,
  value: value?.providerName,
  questionGenerator: value?.questionGenerator,
}));

const MARKETING_THIRD_PARTIES = Object.entries(marketingThirdParties).map(
  ([key, value]) => ({
    label: value?.providerService,
    value: value?.providerName,
    questionGenerator: value?.questionGenerator,
  })
);

const ANALYTICS_THIRD_PARTIES = Object.entries(analyticsThirdParties).map(
  ([key, value]) => ({
    label: value?.providerService,
    value: value?.providerName,
    questionGenerator: value?.questionGenerator,
  })
);

const CUSTOMER_SUPPORT_THIRD_PARTIES = Object.entries(
  customerSupportThirdParties
).map(([key, value]) => ({
  label: value?.providerService,
  value: value?.providerName,
  questionGenerator: value?.questionGenerator,
}));

const ADVERTISING_THIRD_PARTIES = Object.entries(advertisingThirdParties).map(
  ([key, value]) => ({
    label: value?.providerService,
    value: value?.providerName,
    questionGenerator: value?.questionGenerator,
  })
);

const PAYMENTS_THIRD_PARTIES = Object.entries(paymentsThirdParties).map(
  ([key, value]) => ({
    label: value?.providerService,
    value: value?.providerName,
    questionGenerator: value?.questionGenerator,
  })
);

const INFRASTRUCTURE_THIRD_PARTIES = Object.entries(
  infrastructureThirdParties
).map(([key, value]) => ({
  label: value?.providerService,
  value: value?.providerName,
  questionGenerator: value?.questionGenerator,
}));

const ACCESS_CONNECTIVITY_THIRD_PARTIES = Object.entries(
  accessConnectivityThirdParties
).map(([key, value]) => ({
  label: value?.providerService,
  value: value?.providerName,
  questionGenerator: value?.questionGenerator,
}));

const PERSONAL = Object.entries(personal).map(([key, value]) => ({
  label: value?.dataType,
  value: value?.dataType,
}));

const FEATURES = Object.entries(features).map(([key, value]) => ({
  label: value?.["Feature"],
  value: value?.["Feature"],
}));

const COUNTRIES = Object.entries(countries).map(([key, value]) => ({
  label: value?.["Countries in the world"],
  value: value?.["Countries in the world"],
}));

const SAFEGUARDS = [
  "SCC - Standard data protection Contractual Clauses approved by the European Commission",
  "BCR - Binding Corporate Rules submitted and approved by a Supervising Data Protection Authority",
  "Your explicit and informed consent",
  "A Code of Conduct approved by a Supervising Data Protection Authority",
].map((item) => ({ value: item, label: item }));

const GENERAL_QUESTIONS_OPTIONS_TWO = [
  "Racial or ethnic origin",
  "Political opinions",
  "Religious or philosophical beliefs",
  "Trade union membership",
  "Genetic data",
  "Biometric data for the purpose of uniquely identifying a natural person",
  "Health",
  "A natural person’s sex life",
  "A natural person’s sexual orientation",
];

const GENERAL_QUESTIONS_OPTIONS_TWO_ONE = [
  "You have the user’s explicit consent",
  "You process data which was made public by the user",
  "It is necessary for legal claims ",
  "It is necessary for reasons of substantial public interest and in conformity with an EU or Member State law",
  "It is necessary for healthcare and it is done under the supervision of an accredited health professional.",
  "It is necessary for reasons of public interest in the area of public health and in conformity with an EU or Member State law",
  "It is necessary for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes",
  "You are a foundation, association or non for profit organisation and the processed data is of your members, ex members or persons with whom you have regular contact",
];

const GENERAL_QUESTIONS_OPTIONS_FOUR_ONE = [
  "You have your user’s specific consent",
  "The decisions are necessary for entering into a contract or for performing a contract with your user",
  "The decision is authorised by a specific EU or Member’s State law",
];

const filterQuestionThirdParties = (options, identify) =>
  options.filter((option) =>
    option?.questionGenerator?.toLowerCase().includes(identify.toLowerCase())
  );

const yupBoolean = Yup.mixed().test(
  "condition",
  "Yes or no must be checked",
  (value, context) => {
    if (value === "null" || value === null) return false;

    return true;
  }
);

const SectionActions = ({
  back = false,
  backText = "",
  next = true,
  nextText = "",
  update = false,
  updateAction,
  handleBack,
  stateKey,
  handleBackClojure,
  methods,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={`${classes.sectionActions} ${
        back ? classes.sectionActionsBack : ""
      }`}
    >
      {!!update && (
        <Button
          variant="hovered-reversed-no-border"
          className={classes.updateButton}
          disableRipple
          disableFocusRipple
          disableElevation
          onClick={updateAction}
          endIcon={<SaveAltIcon className={classes.updateButtonIcon} />}
        >
          Update
        </Button>
      )}

      <Box className={classes.sectionInternalActions}>
        {!!back && (
          <Button
            className={classes.backButton}
            disableRipple
            disableFocusRipple
            disableElevation
            onClick={() =>
              handleBackClojure
                ? handleBackClojure(stateKey, methods)
                : handleBack()
            }
            startIcon={<ArrowBackIcon className={classes.backButtonIcon} />}
          >
            {backText ? backText : "Back"}
          </Button>
        )}

        {!!next && (
          <Button
            variant="hovered"
            color="primary"
            type="submit"
            className={classes.nextButton}
            disableRipple
            disableFocusRipple
            disableElevation
          >
            {nextText ? nextText : "Next"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

const renderLegalBasis = (questions) => {
  return Object.entries(questions).some(([key, value]) => {
    return key.includes("condition") && (value === true || value === "true");
  });
};

const formatOptions = (options) => {
  return options.map((option) => ({
    value: option,
    label: option,
  }));
};

const selectValidateOptions = (options, value, context) => {
  if (!value || !value?.length) return false;

  const inputValues = value.map((inputValue) => inputValue.value);

  const optionsValues = options.map((optionValue) => optionValue.value);

  return !inputValues.some((inputValue) => !optionsValues.includes(inputValue));
};

const selectLegalBasisValidation = (value, context) => {
  if (!renderLegalBasis(context.parent)) return true;

  return selectValidateOptions(LEGAL_BASIS, value, context);
};

const selectThirdPartiesValidation = (value, context, options) => {
  return selectValidateOptions(
    options ? options : THIRD_PARTIES,
    value,
    context
  );
};

const selectPersonalValidation = (value, context) => {
  return selectValidateOptions(PERSONAL, value, context);
};

const selectDevicePermissionsValidation = (value, context) => {
  return selectValidateOptions(FEATURES, value, context);
};

const selectDataStorageTransfersValidation = (value, context) => {
  return selectValidateOptions(COUNTRIES, value, context);
};

const selectSafeGuardsValidation = (value, context) => {
  return selectValidateOptions(SAFEGUARDS.concat(LAST_OPTIONS), value, context);
};

const selectDataValidation = (value, context, options) => {
  return selectValidateOptions(options, value, context);
};

const verifyCountries = (countriesSelected) => {
  if (!countriesSelected) return [];

  const countryValues = Object.values(countries);

  const countriesMapped = countriesSelected?.map((countryOption) => {
    return countryValues.find(
      (country) => countryOption?.value === country?.["Countries in the world"]
    );
  });

  const countriesFiltered = countriesMapped.filter(
    (country) =>
      country?.["Non adequate"] === "non adequate" || country?.["EU"] === ""
  );

  return countriesFiltered;
};

const parseFormQuestions = (questions) => {
  if (!questions) return [];

  const questionsParsed = [];

  Object.entries(questions)?.forEach(([key, value]) => {
    const question = {};

    const [identify, attribute] = key.split(":");

    question.identify = identify.replaceAll("-", ".");

    switch (attribute) {
      case "legalBasis":
        question[attribute] = value.map((legalBase) => legalBase.value);

        break;

      case "safeguards":
        question[attribute] = value.map((safeguard) => safeguard.value);

        break;

      case "url":
        question[attribute] = String(value);

        break;

      case "dataStorageAndTransfers":
        question[attribute] = value.map(
          (dataStorageAndTransfer) => dataStorageAndTransfer.value
        );

        break;

      case "conditionText":
        question[attribute] = value.map((conditionText) => conditionText.value);

        break;

      case "personalData":
        question[attribute] = value.map((personalData) => {
          const personalDataMatch = personal.find(
            (_personalData) => personalData.value === _personalData.dataType
          );

          return {
            tag: personalDataMatch?.tag,
            dataType: personalDataMatch?.dataType,
          };
        });

        break;

      case "devicePermissions":
        question[attribute] = value.map(
          (devicePermission) => devicePermission.value
        );

        break;

      case "thirdParties":
        question[attribute] = value.map((thirdParty) => {
          const thirdPartyMatch = thirdParties.find(
            (_thirdParty) => thirdParty.value === _thirdParty.providerName
          );

          return {
            thirdPartyName: thirdPartyMatch?.providerName,
            serviceName: thirdPartyMatch?.providerService,
            // serviceType: "",
            firstLayer: thirdPartyMatch?.dataCollected,
            privacyPolicy: thirdPartyMatch?.providerPrivacyLink,
            optOut: thirdPartyMatch?.optOut,
            country: thirdPartyMatch?.placeProcessing,
            euEea: thirdPartyMatch?.EuEea,
            adequate: thirdPartyMatch?.Adequate,
            adequacyReason: thirdPartyMatch?.adequacyReason,
          };
        });

        break;

      default:
        question[attribute] = value;
    }

    questionsParsed.push(question);
  });

  return map(groupBy(questionsParsed, "identify"), (question) => {
    return question.reduce(
      (accumulator, current) => ({ ...accumulator, ...current }),
      {}
    );
  });
};

const parseRequestQuestions = (questions) => {
  if (!questions) return {};

  const questionsParsed = {};

  questions?.forEach((question) => {
    const identifyValueParsed = question?.identify?.replaceAll(".", "-");

    // eslint-disable-next-line
    const attributes = Object.entries(question);

    attributes.forEach((questionAttribute) => {
      const [attributeKey, attributeValue] = questionAttribute;

      switch (attributeKey) {
        case "condition":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue;

          break;

        case "safeguards":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue.map((safeguard) => ({
              value: safeguard,
              label: safeguard,
            }));

          break;

        case "url":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue;

          break;

        case "dataStorageAndTransfers":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue.map((dataStorageAndTransfer) => ({
              value: dataStorageAndTransfer,
              label: dataStorageAndTransfer,
            }));

          break;

        case "conditionText":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue.map((conditionText) => ({
              value: conditionText,
              label: conditionText,
            }));

          break;

        case "legalBasis":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue.map((legalBase) => ({
              value: legalBase,
              label: legalBase,
            }));

          break;

        case "personalData":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue.map((personalData) => ({
              label: personalData?.dataType,
              value: personalData?.dataType,
            }));

          break;

        case "devicePermissions":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue.map((devicePermission) => ({
              value: devicePermission,
              label: devicePermission,
            }));

          break;

        case "thirdParties":
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue.map((thirdParty) => ({
              label: thirdParty?.serviceName,
              value: thirdParty?.thirdPartyName,
            }));

          break;

        default:
          questionsParsed[`${identifyValueParsed}:${attributeKey}`] =
            attributeValue;
      }
    });
  });

  return questionsParsed;
};

const LegalBaseInformationNote = () => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.informationNoteText}>
        Consent - only if the user has given you explicit consent
      </Typography>

      <br />

      <Typography className={classes.informationNoteText}>
        Contract - you have a contract with the user or you are processing the
        data at the user’s request in order to take the necessary steps to enter
        into a contract
      </Typography>

      <br />

      <Typography className={classes.informationNoteText}>
        Legal Obligation - you process user data because there is a legal
        requirement to do so
      </Typography>

      <br />

      <Typography className={classes.informationNoteText}>
        Legitimate interest - you have a legitimate interest that prevails over
        the rights of the user to process their data
      </Typography>

      <br />

      <Typography className={classes.informationNoteText}>
        Public interest - there is a legally defined substantial public interest
        such as public health, taxation or safety of products
      </Typography>

      <br />

      <Typography className={classes.informationNoteText}>
        Vital Interest - the processing is necessary to protect a vital interest
        of the user
      </Typography>
    </Box>
  );
};

const AccessConnectivitySchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1-1:condition": yupBoolean,
  "1-1:personalData": Yup.mixed().test(
    "personal",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1-1:condition"]) return true;

      return selectPersonalValidation(value, context);
    }
  ),
  "1-2:condition": yupBoolean,
  "1-2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1-2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2:condition": yupBoolean,
  "2:personalData": Yup.mixed().test(
    "personal",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2:condition"]) return true;

      return selectPersonalValidation(value, context);
    }
  ),
  "3:condition": yupBoolean,
  "3:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "4:condition": yupBoolean,
  "4:personalData": Yup.mixed().test(
    "personal",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["4:condition"]) return true;

      return selectPersonalValidation(value, context);
    }
  ),
  "5:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const AccessConnectivity = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.accessConnectivity
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(AccessConnectivitySchema),
  });

  const watchFields = methods.watch();

  const stateKey = "accessConnectivity";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Does your webpage have a log in / sign in system?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
        />

        {watchFields?.["1:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="1.1."
            label="Do you have your own log in system?"
            name="1-1:condition"
            methods={methods}
            defaultValue={watchFields?.["1-1:condition"] ?? null}
            informationNote="Developed by you, not provided by a third party"
          />
        ) : null}

        {watchFields?.["1-1:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="1.1.1."
            label="Please select the data users provide in this process."
            name="1-1:personalData"
            methods={methods}
            options={PERSONAL}
            defaultValue={watchFields?.["1-1:personalData"] ?? []}
          />
        ) : null}

        {watchFields?.["1:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="1.2."
            label="Do you allow users to log in / sign in with other accounts?"
            name="1-2:condition"
            methods={methods}
            defaultValue={watchFields?.["1-2:condition"] ?? null}
            informationNote="Eg. log in with Google, sign up with Facebook"
          />
        ) : null}

        {watchFields?.["1-2:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="1.2.1."
            label="Please select the third parties you use in this process."
            name="1-2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ACCESS_CONNECTIVITY_THIRD_PARTIES,
              "Q1.2"
            )}
            defaultValue={watchFields?.["1-2:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="2."
          label="Do you allow users to create a profile?"
          name="2:condition"
          methods={methods}
          defaultValue={watchFields?.["2:condition"] ?? null}
        />

        {watchFields?.["2:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="2.1."
            label="Please select the data users provide in this process."
            name="2:personalData"
            methods={methods}
            options={PERSONAL}
            defaultValue={watchFields?.["2:personalData"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="3."
          label="Do you request access to other platforms from your users?"
          name="3:condition"
          methods={methods}
          defaultValue={watchFields?.["3:condition"] ?? null}
          informationNote="Eg. upload file from Google Drive, access user’s Facebook friend list"
        />

        {watchFields?.["3:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="3.1."
            label="Please select the third parties you use in this process."
            name="3:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ACCESS_CONNECTIVITY_THIRD_PARTIES,
              "Q3"
            )}
            defaultValue={watchFields?.["3:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="4."
          label="Do you allow users to invite connections directly through your platform?"
          name="4:condition"
          methods={methods}
          defaultValue={watchFields?.["4:condition"] ?? null}
          informationNote="Eg. field where users can manually introduce friend’s email address"
        />

        {watchFields?.["4:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="4.1."
            label="Please select the data users provide in this process."
            name="4:personalData"
            methods={methods}
            options={PERSONAL}
            defaultValue={watchFields?.["4:personalData"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="5."
            label="For these operations, related to user's access and connectivity, you process the data because:"
            name="5:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["5:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={false}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
      />
    </form>
  );
};

const AdvertisingSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1:personalData": Yup.mixed().test(
    "personal",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1:condition"]) return true;

      return selectPersonalValidation(value, context);
    }
  ),
  "2:condition": yupBoolean,
  "2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3:condition": yupBoolean,
  "3:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "4:condition": yupBoolean,
  "4:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["4:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "5:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const Advertising = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.advertising
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(AdvertisingSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "advertising";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="On your website, do you display your own ads?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
          informationNote="This refers only to advertising links, banners, text and video billboards; ads that are displayed by you on your website, without sharing data with third parties."
        />

        {watchFields?.["1:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="1.1."
            label="Please select the data users provide in this process."
            name="1:personalData"
            methods={methods}
            options={PERSONAL}
            defaultValue={watchFields?.["1:personalData"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="2."
          label="Do you use any third party tools for direct advertising such as banners and other ads displayed on your website?"
          name="2:condition"
          methods={methods}
          defaultValue={watchFields?.["2:condition"] ?? null}
          informationNote="Eg. Facebook Lookalike Audience, Google AdSense, Criteo"
        />

        {watchFields?.["2:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="2.1."
            label="Please select the third parties you use in this process."
            name="2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ADVERTISING_THIRD_PARTIES,
              "Q2"
            )}
            defaultValue={watchFields?.["2:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="3."
          label="Have you joined any commercial affiliation programme?"
          name="3:condition"
          methods={methods}
          defaultValue={watchFields?.["3:condition"] ?? null}
        />

        {watchFields?.["3:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="3.1."
            label="Please select the third parties you use in this process."
            name="3:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ADVERTISING_THIRD_PARTIES,
              "Q3"
            )}
            defaultValue={watchFields?.["3:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="4."
          label="Do you use any third party tools for remarketing?"
          name="4:condition"
          methods={methods}
          defaultValue={watchFields?.["4:condition"] ?? null}
          informationNote="Based on user’s past behaviour, eg. Twitter Tailored Audience"
        />

        {watchFields?.["4:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="4.1."
            label="Please select the third parties you use in this process."
            name="4:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ADVERTISING_THIRD_PARTIES,
              "Q4"
            )}
            defaultValue={watchFields?.["4:thirdParties"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="5."
            label="For these operations, related to advertising, you process the data because:"
            name="5:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["5:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const AnalyticsSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "2:condition": yupBoolean,
  "2:personalData": Yup.mixed().test(
    "personal",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2:condition"]) return true;

      return selectPersonalValidation(value, context);
    }
  ),
  "3:condition": yupBoolean,
  "3-1:condition": yupBoolean,
  "3-1:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3-1:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3-2:condition": yupBoolean,
  "3-2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3-2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3-3:condition": yupBoolean,
  "3-3:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3-3:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3-4:condition": yupBoolean,
  "3-4:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3-4:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "4:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const Analytics = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.analytics
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(AnalyticsSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "analytics";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Do you perform any analytics on your users online behaviour?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
        />

        {watchFields?.["1:condition"] ? (
          <RadioInput
            labelNumber="2."
            label="Do you perform your own analytics, without sharing data with third parties?"
            name="2:condition"
            methods={methods}
            defaultValue={watchFields?.["2:condition"] ?? null}
            informationNote="Eg. self hosted web analytics tool"
          />
        ) : null}

        {watchFields?.["2:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="2.1."
            label="Please select the data users provide in this process."
            name="2:personalData"
            methods={methods}
            options={PERSONAL}
            defaultValue={watchFields?.["2:personalData"] ?? []}
          />
        ) : null}

        {watchFields?.["1:condition"] ? (
          <RadioInput
            labelNumber="3."
            label="Do you perform analytics by sharing data with third parties?"
            name="3:condition"
            methods={methods}
            defaultValue={watchFields?.["3:condition"] ?? null}
          />
        ) : null}

        {watchFields?.["3:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="3.1."
            label="Are you using AB testing tools?"
            name="3-1:condition"
            methods={methods}
            defaultValue={watchFields?.["3-1:condition"] ?? null}
            informationNote="Eg. Apptimize, Google Optimise, Unbounce"
          />
        ) : null}

        {watchFields?.["3-1:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="3.1.1."
            label="Please select the third parties you use in this process."
            name="3-1:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ANALYTICS_THIRD_PARTIES,
              "Q 3.1"
            )}
            defaultValue={watchFields?.["3-1:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["3:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="3.2."
            label="Are you using heat mapping and session recording tools?"
            name="3-2:condition"
            methods={methods}
            defaultValue={watchFields?.["3-2:condition"] ?? null}
            informationNote="Eg. Hotjar Heat Maps & Recordings, Screensquid, UXCam"
          />
        ) : null}

        {watchFields?.["3-2:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="3.2.1."
            label="Please select the third parties you use in this process."
            name="3-2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ANALYTICS_THIRD_PARTIES,
              "Q3.2"
            )}
            defaultValue={watchFields?.["3-2:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["3:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="3.3."
            label="Are you using infrastructure monitoring tools?"
            name="3-3:condition"
            methods={methods}
            defaultValue={watchFields?.["3-3:condition"] ?? null}
            informationNote="Eg. Apteligent, Crashlytics, Logentries"
          />
        ) : null}

        {watchFields?.["3-3:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="3.3.1."
            label="Please select the third parties you use in this process."
            name="3-3:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ANALYTICS_THIRD_PARTIES,
              "Q3.3"
            )}
            defaultValue={watchFields?.["3-3:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["3:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="3.4."
            label="Are you using any other analytics tools?"
            name="3-4:condition"
            methods={methods}
            defaultValue={watchFields?.["3-4:condition"] ?? null}
            informationNote="Eg. Alexa Metrics, Amplitude, ComScore, HubSpot Analytics, LinkedIn conversion tracking, MixPanel, SumoMe Content Analytics"
          />
        ) : null}

        {watchFields?.["3-4:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="3.4.1."
            label="Please select the third parties you use in this process."
            name="3-4:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              ANALYTICS_THIRD_PARTIES,
              "Q3.4"
            )}
            defaultValue={watchFields?.["3-4:thirdParties"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="4."
            label="For these operations, related to analytics, you process the data because:"
            name="4:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["4:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const CustomerSupportSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1:personalData": Yup.mixed().test(
    "personal",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1:condition"]) return true;

      return selectPersonalValidation(value, context);
    }
  ),
  "2:condition": yupBoolean,
  "2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3:condition": yupBoolean,
  "3-1:condition": yupBoolean,
  "3-1:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3-1:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3-2:condition": yupBoolean,
  "3-2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3-2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3-3:condition": yupBoolean,
  "3-3:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3-3:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3-4:condition": yupBoolean,
  "3-4:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3-4:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "4:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const CustomerSupport = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.customerSupport
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(CustomerSupportSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "customerSupport";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Do you use your own tools in order to provide customer support?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
          informationNote="Own contact form, own email inbox, phone or ticketing tool"
        />

        {watchFields?.["1:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="1.1."
            label="Please select the data users provide in this process."
            name="1:personalData"
            methods={methods}
            options={PERSONAL}
            defaultValue={watchFields?.["1:personalData"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="2."
          label="Do you use any CRM (customer relationship management) tool?"
          name="2:condition"
          methods={methods}
          defaultValue={watchFields?.["2:condition"] ?? null}
          informationNote="Eg. Customerly CRM, Freshsales, HubSpot CRM, Intercom, Salesforce Service Cloud"
        />

        {watchFields?.["2:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="2.1."
            label="Please select the third parties you use in this process."
            name="2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              CUSTOMER_SUPPORT_THIRD_PARTIES,
              "Q2"
            )}
            defaultValue={watchFields?.["2:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="3."
          label="Do you use any third party tools in order to provide customer support?"
          name="3:condition"
          methods={methods}
          defaultValue={watchFields?.["3:condition"] ?? null}
        />

        {watchFields?.["3:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="3.1."
            label="Live communication tools?"
            name="3-1:condition"
            methods={methods}
            defaultValue={watchFields?.["3-1:condition"] ?? null}
            informationNote="Eg. ClickDesk Widget, Customerly Chat Widget, Facebook Messenger Customer Chat, Zendesk Chat"
          />
        ) : null}

        {watchFields?.["3-1:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="3.1.1."
            label="Please select the third parties you use in this process."
            name="3-1:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              CUSTOMER_SUPPORT_THIRD_PARTIES,
              "Q3.1"
            )}
            defaultValue={watchFields?.["3-1:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["3:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="3.2."
            label="Emailing tools?"
            name="3-2:condition"
            methods={methods}
            defaultValue={watchFields?.["3-2:condition"] ?? null}
          />
        ) : null}

        {watchFields?.["3-2:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="3.2.1."
            label="Please select the third parties you use in this process."
            name="3-2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              CUSTOMER_SUPPORT_THIRD_PARTIES,
              "Q3.2"
            )}
            defaultValue={watchFields?.["3-2:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["3:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="3.3."
            label="Contact form tools?"
            name="3-3:condition"
            methods={methods}
            defaultValue={watchFields?.["3-3:condition"] ?? null}
          />
        ) : null}

        {watchFields?.["3-3:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="3.3.1."
            label="Please select the third parties you use in this process."
            name="3-3:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              CUSTOMER_SUPPORT_THIRD_PARTIES,
              "Q3.3"
            )}
            defaultValue={watchFields?.["3-3:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["3:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="3.4."
            label="Help Center tools?"
            name="3-4:condition"
            methods={methods}
            defaultValue={watchFields?.["3-4:condition"] ?? null}
            informationNote="Eg. Desk.com, Freshdesk"
          />
        ) : null}

        {watchFields?.["3-4:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="3.4.1."
            label="Please select the third parties you use in this process."
            name="3-4:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              CUSTOMER_SUPPORT_THIRD_PARTIES,
              "Q3.4"
            )}
            defaultValue={watchFields?.["3-4:thirdParties"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="4."
            label="For these operations, related to customer support, you process the data because:"
            name="4:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["4:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const DevicePermissionSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1:devicePermissions": Yup.mixed().test(
    "device permissions",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1:condition"]) return true;

      return selectDevicePermissionsValidation(value, context);
    }
  ),
  "2:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const DevicePermission = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.devicePermission
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(DevicePermissionSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "devicePermission";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Do you ask your users to give you permission to access their device?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
        />

        {watchFields?.["1:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="1.1."
            label="Please select the features you ask access to"
            name="1:devicePermissions"
            methods={methods}
            options={FEATURES}
            defaultValue={watchFields?.["1:devicePermissions"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="2."
            label="For these operations, related to device permission, you process the data because:"
            name="2:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["2:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const InfrastructureSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2:condition": yupBoolean,
  "2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3:condition": yupBoolean,
  "3:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "4:condition": yupBoolean,
  "4:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["4:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "5:condition": yupBoolean,
  "5:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["5:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "6:condition": yupBoolean,
  "6:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["6:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "7:condition": yupBoolean,
  "7:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["7:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "8:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const Infrastructure = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.infrastructure
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(InfrastructureSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "infrastructure";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Have you used or do you use any tools for building and managing your website?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
          informationNote="Eg. Wordpress, Wix, Shopify"
        />

        {watchFields?.["1:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="1.1."
            label="Please select the third parties you use in this process."
            name="1:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INFRASTRUCTURE_THIRD_PARTIES,
              "Q1"
            )}
            defaultValue={watchFields?.["1:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="2."
          label="Do you use any external fonts library?"
          name="2:condition"
          methods={methods}
          defaultValue={watchFields?.["2:condition"] ?? null}
          informationNote="Eg. Font Awesome, Google Fonts"
        />

        {watchFields?.["2:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="2.1."
            label="Please select the third parties you use in this process."
            name="2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INFRASTRUCTURE_THIRD_PARTIES,
              "Q2"
            )}
            defaultValue={watchFields?.["2:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="3."
          label="Do you use an external hosting provider for your website?"
          name="3:condition"
          methods={methods}
          defaultValue={watchFields?.["3:condition"] ?? null}
          informationNote="Eg. Algolia Infrastructure, Amazon Web Services (AWS), GoDaddy, Microsoft Azure"
        />

        {watchFields?.["3:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="3.1."
            label="Please select the third parties you use in this process."
            name="3:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INFRASTRUCTURE_THIRD_PARTIES,
              "Q3"
            )}
            defaultValue={watchFields?.["3:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="4."
          label="Do you use an external backup service provider?"
          name="4:condition"
          methods={methods}
          defaultValue={watchFields?.["4:condition"] ?? null}
          informationNote="Eg. Amazon, Dropbox, Google Drive"
        />

        {watchFields?.["4:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="4.1."
            label="Please select the third parties you use in this process."
            name="4:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INFRASTRUCTURE_THIRD_PARTIES,
              "Q4"
            )}
            defaultValue={watchFields?.["4:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="5."
          label="Do you use external security systems?"
          name="5:condition"
          methods={methods}
          defaultValue={watchFields?.["5:condition"] ?? null}
          informationNote="Eg. WebPurify, Google reCAPTCHA"
        />

        {watchFields?.["5:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="5.1."
            label="Please select the third parties you use in this process."
            name="5:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INFRASTRUCTURE_THIRD_PARTIES,
              "Q5"
            )}
            defaultValue={watchFields?.["5:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="6."
          label="Do you use an external commenting system?"
          name="6:condition"
          methods={methods}
          defaultValue={watchFields?.["6:condition"] ?? null}
          informationNote="Eg. Commento, GraphComment, IntenseDebate"
        />

        {watchFields?.["6:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="6.1."
            label="Please select the third parties you use in this process."
            name="6:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INFRASTRUCTURE_THIRD_PARTIES,
              "Q6"
            )}
            defaultValue={watchFields?.["6:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="7."
          label="Are you using any other infrastructure systems?"
          name="7:condition"
          methods={methods}
          defaultValue={watchFields?.["7:condition"] ?? null}
          informationNote="Eg. Beta by Crashlytics, Livestorm, Short.cm  "
        />

        {watchFields?.["7:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="7.1."
            label="Please select the third parties you use in this process."
            name="7:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INFRASTRUCTURE_THIRD_PARTIES,
              "Q7"
            )}
            defaultValue={watchFields?.["7:thirdParties"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="8."
            label="For these operations, related to infrastructure, you process the data because:"
            name="8:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["8:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const InteractionExternalPlatformsSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2:condition": yupBoolean,
  "2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3:condition": yupBoolean,
  "3:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["3:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "4:condition": yupBoolean,
  "4:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["4:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "5:condition": yupBoolean,
  "5:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["5:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "6:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const InteractionExternalPlatforms = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.interactionExternalPlatforms
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(InteractionExternalPlatformsSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "interactionExternalPlatforms";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Do you allow users to interact with social networks directly from your website?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
          informationNote="Eg. Facebook like button, Twitter button"
        />

        {watchFields?.["1:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="1.1."
            label="Please select the third parties you use in this process."
            name="1:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INTERACTION_WITH_EXTERNAL_PLATFORM_THIRD_PARTIES,
              "Q1"
            )}
            defaultValue={watchFields?.["1:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="2."
          label="Did you add an external map service to your website?"
          name="2:condition"
          methods={methods}
          defaultValue={watchFields?.["2:condition"] ?? null}
          informationNote="Eg. Google Maps, Open Street Maps"
        />

        {watchFields?.["2:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="2.1."
            label="Please select the third parties you use in this process."
            name="2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INTERACTION_WITH_EXTERNAL_PLATFORM_THIRD_PARTIES,
              "Q2"
            )}
            defaultValue={watchFields?.["2:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="3."
          label="Do you have a third party video widget embedded on your website?"
          name="3:condition"
          methods={methods}
          defaultValue={watchFields?.["3:condition"] ?? null}
          informationNote="Eg. Youtube, Vimeo"
        />

        {watchFields?.["3:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="3.1."
            label="Please select the third parties you use in this process."
            name="3:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INTERACTION_WITH_EXTERNAL_PLATFORM_THIRD_PARTIES,
              "Q3"
            )}
            defaultValue={watchFields?.["3:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="4."
          label="Do you have a third party music widget embedded on your website?"
          name="4:condition"
          methods={methods}
          defaultValue={watchFields?.["4:condition"] ?? null}
          informationNote="Eg. Spotify, Sound Cloud"
        />

        {watchFields?.["4:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="4.1."
            label="Please select the third parties you use in this process."
            name="4:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INTERACTION_WITH_EXTERNAL_PLATFORM_THIRD_PARTIES,
              "Q4"
            )}
            defaultValue={watchFields?.["4:thirdParties"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="5."
          label="Do you allow other type of interaction with external platforms?"
          name="5:condition"
          methods={methods}
          defaultValue={watchFields?.["5:condition"] ?? null}
          informationNote="Eg. Coinbase, GamePix, CodePen"
        />

        {watchFields?.["5:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="5.1."
            label="Please select the third parties you use in this process."
            name="5:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              INTERACTION_WITH_EXTERNAL_PLATFORM_THIRD_PARTIES,
              "Q5"
            )}
            defaultValue={watchFields?.["5:thirdParties"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="6."
            label="For these operations, related to interaction with external platforms, you process the data because:"
            name="6:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["6:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const MarketingSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1:personalData": Yup.mixed().test(
    "personal",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1:condition"]) return true;

      return selectPersonalValidation(value, context);
    }
  ),

  "2:condition": yupBoolean,
  "2-1:condition": yupBoolean,
  "2-1:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2-1:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2-2:condition": yupBoolean,
  "2-2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2-2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2-3:condition": yupBoolean,
  "2-3:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2-3:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2-4:condition": yupBoolean,
  "2-4:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2-4:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2-5:condition": yupBoolean,
  "2-5:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2-5:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2-6:condition": yupBoolean,
  "2-6:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2-6:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "2-7:condition": yupBoolean,
  "2-7:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2-7:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const Marketing = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.marketing
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(MarketingSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "marketing";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Do you use your own tools to reach users for marketing purposes?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
          informationNote="Eg. direct email, phone, SMS, own survey tools"
        />

        {watchFields?.["1:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="1.1."
            label="Please select the data users provide in this process."
            name="1:personalData"
            methods={methods}
            options={PERSONAL}
            defaultValue={watchFields?.["1:personalData"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="2."
          label="Do you use any third party tools for marketing purposes?"
          name="2:condition"
          methods={methods}
          defaultValue={watchFields?.["2:condition"] ?? null}
        />

        {watchFields?.["2:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="2.1."
            label="Integrated marketing tools?"
            name="2-1:condition"
            methods={methods}
            defaultValue={watchFields?.["2-1:condition"] ?? null}
            informationNote="All in one marketing tools such as eg. GetResponse, Freshworks 360, Mailchimp Landing Page."
          />
        ) : null}

        {watchFields?.["2-1:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="2.1.1."
            label="Please select the third parties you use in this process."
            name="2-1:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              MARKETING_THIRD_PARTIES,
              "Q2.1"
            )}
            defaultValue={watchFields?.["2-1:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["2:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="2.2."
            label="Email marketing tools?"
            name="2-2:condition"
            methods={methods}
            defaultValue={watchFields?.["2-2:condition"] ?? null}
            informationNote="Eg. Acumba email, CoverKit, Customerly Email Marketing, Mailchimp widget "
          />
        ) : null}

        {watchFields?.["2-2:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="2.2.1."
            label="Please select the third parties you use in this process."
            name="2-2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              MARKETING_THIRD_PARTIES,
              "Q2.2"
            )}
            defaultValue={watchFields?.["2-2:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["2:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="2.3."
            label="Messaging (SMS) tools?"
            name="2-3:condition"
            methods={methods}
            defaultValue={watchFields?.["2-3:condition"] ?? null}
            informationNote="Eg. BulkSMS, sMsmode, ActiveCampaign Text Message and SMS"
          />
        ) : null}

        {watchFields?.["2-3:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="2.3.1."
            label="Please select the third parties you use in this process."
            name="2-3:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              MARKETING_THIRD_PARTIES,
              "Q2.3"
            )}
            defaultValue={watchFields?.["2-3:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["2:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="2.4."
            label="Notifications & Pop-ups tools?"
            name="2-4:condition"
            methods={methods}
            defaultValue={watchFields?.["2-4:condition"] ?? null}
            informationNote="Eg. Apptentive Widget, OneSignal, PopUp Domination"
          />
        ) : null}

        {watchFields?.["2-4:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="2.4.1."
            label="Please select the third parties you use in this process."
            name="2-4:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              MARKETING_THIRD_PARTIES,
              "Q2.4"
            )}
            defaultValue={watchFields?.["2-4:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["2:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="2.5."
            label="Marketing surveys tools?"
            name="2-5:condition"
            methods={methods}
            defaultValue={watchFields?.["2-5:condition"] ?? null}
            informationNote="Eg. Customerly Survey Widget, GetSiteControl Survey Widget, Hotjar surveys, SurveyGizmo"
          />
        ) : null}

        {watchFields?.["2-5:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="2.5.1."
            label="Please select the third parties you use in this process."
            name="2-5:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              MARKETING_THIRD_PARTIES,
              "Q2.5"
            )}
            defaultValue={watchFields?.["2-5:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["2:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="2.6."
            label="Newsletters tools?"
            name="2-6:condition"
            methods={methods}
            defaultValue={watchFields?.["2-6:condition"] ?? null}
            informationNote="Eg. Sendgrid, Tiny Letter "
          />
        ) : null}

        {watchFields?.["2-6:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="2.6.1."
            label="Please select the third parties you use in this process."
            name="2-6:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              MARKETING_THIRD_PARTIES,
              "Q2.6"
            )}
            defaultValue={watchFields?.["2-6:thirdParties"] ?? []}
          />
        ) : null}

        {watchFields?.["2:condition"] ? (
          <RadioInput
            subQuestion
            labelNumber="2.7."
            label="Any other marketing tools?"
            name="2-7:condition"
            methods={methods}
            defaultValue={watchFields?.["2-7:condition"] ?? null}
            informationNote="Eg. TagCommander, Webtrekk Tag Integration"
          />
        ) : null}

        {watchFields?.["2-7:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="2.7.1."
            label="Please select the third parties you use in this process."
            name="2-7:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(
              MARKETING_THIRD_PARTIES,
              "Q2.7"
            )}
            defaultValue={watchFields?.["2-7:thirdParties"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="3."
            label="For these operations, related to marketing, you process the data because:"
            name="3:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["3:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const PaymentsSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1:personalData": Yup.mixed().test(
    "personal",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1:condition"]) return true;

      return selectPersonalValidation(value, context);
    }
  ),
  "2:condition": yupBoolean,
  "2:thirdParties": Yup.mixed().test(
    "third parties",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["2:condition"]) return true;

      return selectThirdPartiesValidation(value, context);
    }
  ),
  "3:legalBasis": Yup.mixed().test(
    "legal basis",
    "No option was selected or an invalid option was selected",
    selectLegalBasisValidation
  ),
});

const Payments = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.payments
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(PaymentsSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "payments";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Do you have your own payment system?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
        />

        {watchFields?.["1:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="1.1."
            label="Please select the data users provide in this process."
            name="1:personalData"
            methods={methods}
            options={PERSONAL}
            defaultValue={watchFields?.["1:personalData"] ?? []}
          />
        ) : null}

        <RadioInput
          labelNumber="2."
          label="Do you use any third party payment system?"
          name="2:condition"
          methods={methods}
          defaultValue={watchFields?.["2:condition"] ?? null}
          informationNote="Eg. Stripe, Apple Pay, Coinbase, Mobyo"
        />

        {watchFields?.["2:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="2.1."
            label="Please select the third parties you use in this process."
            name="2:thirdParties"
            methods={methods}
            options={filterQuestionThirdParties(PAYMENTS_THIRD_PARTIES, "Q2")}
            defaultValue={watchFields?.["2:thirdParties"] ?? []}
          />
        ) : null}

        {renderLegalBasis(watchFields) ? (
          <SelectInput
            labelNumber="3."
            label="For these operations, related to payments, you process the data because:"
            name="3:legalBasis"
            methods={methods}
            options={LEGAL_BASIS}
            defaultValue={watchFields?.["3:legalBasis"] ?? []}
            informationNote={<LegalBaseInformationNote />}
          />
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const DataStorageTransfersSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "1:dataStorageAndTransfers": Yup.mixed().test(
    "data storage and transfers",
    "No option was selected or an invalid option was selected",
    (value, context) => {
      if (!context.parent?.["1:condition"]) return true;

      if (!value) return false;

      return selectDataStorageTransfersValidation(value, context);
    }
  ),
  "1-1:condition": yupBoolean,
  "1-1:safeguards": Yup.mixed().test(
    "safeguards",
    "No option was selected, an invalid option was selected or 'none of the above' was selected along with different options",
    (value, context) => {
      if (!context.parent?.["1-1:condition"]) return true;

      if (!value) return false;

      if (
        value.map((option) => option.value).includes("None of the above") &&
        value.length > 1
      )
        return false;

      return selectSafeGuardsValidation(value, context);
    }
  ),
  // "1-2:condition": yupBoolean,
  "2:condition": yupBoolean,
  "2:url": Yup.string().matches(URL_PATTERN, "Invalid URL"),
});

const DataStorageTransfers = ({
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.dataStorageTransfers
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(DataStorageTransfersSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "dataStorageTransfers";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    const urlTerms = ["http://", "https://"];

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions({
        ...data,
        ...(data["2:url"]
          ? {
              "2:url": urlTerms.some((term) => data["2:url"]?.includes(term))
                ? data["2:url"]
                : `http://${data["2:url"]}`,
            }
          : {}),
      }),
    }));

    handleNext();
  };

  const countryVerification = verifyCountries(
    watchFields?.["1:dataStorageAndTransfers"]
  );

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label={`We understand that your company is located in ${startCase(
            toLower(project.country)
          )}. Leaving aside the third parties with whom you are collaborating, for eg. for hosting purposes, do you transfer and/or store data in another country?`}
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
          informationNote="Eg. Servers and/or physical files. If not sure, your Internet Service Provider or your hosting provider will be able to help you with this information."
        />

        {watchFields?.["1:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="1.1."
            label={`Please select the countries. Please also include ${startCase(
              toLower(project.country)
            )} if applicable.`}
            name="1:dataStorageAndTransfers"
            methods={methods}
            options={COUNTRIES}
            defaultValue={watchFields?.["1:dataStorageAndTransfers"] ?? []}
          />
        ) : null}

        {countryVerification.length ? (
          <RadioInput
            subQuestion
            labelNumber="1.2."
            label={`${countryVerification
              .filter((country) => country?.["Non adequate"] === "non adequate")
              .map((country) => country["Countries in the world"])
              .join(
                ", "
              )} does not offer an adequate level of protection according to European standards. Are you implementing appropriate safeguards?`}
            name="1-1:condition"
            methods={methods}
            defaultValue={watchFields?.["1-1:condition"] ?? null}
          />
        ) : null}

        {watchFields?.["1-1:condition"] ? (
          <SelectInput
            subSubQuestion
            labelNumber="1.2.1"
            label="Please select the safeguards that you are implementing"
            name="1-1:safeguards"
            methods={methods}
            options={SAFEGUARDS}
            lastOptions={LAST_OPTIONS}
            defaultValue={watchFields?.["1-1:safeguards"] ?? []}
          />
        ) : null}

        {/* <RadioInput
			subQuestion
          labelNumber="1.3."
          label="Is your company or the companies located in the USA, where the data is stored, Privacy Shield certified?"
          name="1-2:condition"
          methods={methods}
          defaultValue={watchFields?.["1-2:condition"] ?? null}
        /> */}

        <RadioInput
          labelNumber="2."
          label={"Do you have an Opt Out feature for your users?"}
          name="2:condition"
          methods={methods}
          defaultValue={watchFields?.["2:condition"] ?? null}
          informationNote="The Opt Out feature is is an automatic means that you can, voluntarily, provide on your website so that your users can directly request to be excluded from your data processing activities related to advertising & marketing."
        />

        {watchFields?.["2:condition"] ? (
          <section className={classes.section}>
            <label>
              <span className={classes.labelQuestion}>2.1.</span> Please provide
              the link.
            </label>

            <TextField
              name="2:url"
              type="text"
              label=""
              variant="outlined"
              InputLabelProps={{
                shrink: true,
              }}
              error={Boolean(methods.errors?.["2:url"])}
              inputRef={methods.register}
              fullWidth
              className={classes.textField}
            />

            <Typography className={classes.error}>
              <ErrorMessage errors={methods.errors} name="2:url" />
            </Typography>
          </section>
        ) : null}
      </Box>

      <SectionActions
        back={true}
        next={true}
        update={projectStatus === "update"}
        updateAction={async () => {
          const formValidation = await methods.trigger();

          if (formValidation) {
            setPrivacyPolicyData((previousPPData) => ({
              ...previousPPData,
              [stateKey]: parseFormQuestions(methods.getValues()),
            }));

            setPPSubmission(true);
          }
        }}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const GeneralQuestionsSchema = Yup.object().shape({
  "1:condition": yupBoolean,
  "2:conditionText": Yup.mixed().test(
    "condition text",
    "No option was selected, an invalid option was selected or 'none of the above' was selected along with different options",
    (value, context) => {
      if (!value) return false;

      if (
        value.map((option) => option.value).includes("None of the above") &&
        value.length > 1
      )
        return false;

      return selectDataValidation(
        value,
        context,
        formatOptions(GENERAL_QUESTIONS_OPTIONS_TWO).concat(LAST_OPTIONS)
      );
    }
  ),
  "2-1:conditionText": Yup.mixed().test(
    "condition text",
    "No option was selected, an invalid option was selected or 'none of the above' was selected along with different options",
    (value, context) => {
      if (
        context.parent?.["2:conditionText"] &&
        context.parent?.["2:conditionText"]
          .map((option) => option.value)
          .includes("None of the above")
      )
        return true;

      if (!value) return false;

      if (
        value.map((option) => option.value).includes("None of the above") &&
        value.length > 1
      )
        return false;

      return selectDataValidation(
        value,
        context,
        formatOptions(GENERAL_QUESTIONS_OPTIONS_TWO_ONE).concat(LAST_OPTIONS)
      );
    }
  ),
  "3:condition": yupBoolean,
  "4:condition": yupBoolean,
  "4-1:conditionText": Yup.mixed().test(
    "condition text",
    "No option was selected, an invalid option was selected or 'none of the above' was selected along with different options",
    (value, context) => {
      if (!value) return true;

      if (
        value.map((option) => option.value).includes("None of the above") &&
        value.length > 1
      )
        return false;

      return selectDataValidation(
        value,
        context,
        formatOptions(GENERAL_QUESTIONS_OPTIONS_FOUR_ONE).concat(LAST_OPTIONS)
      );
    }
  ),
  "5:condition": yupBoolean,
});

const GeneralQuestions = ({
  setPPSubmissionRedirect,
  project,
  privacyPolicyData,
  setPrivacyPolicyData,
  projectStatus,
  setPPSubmission,
  setPPLoading,
  setPPSuccess,
  setPPError,
  handleNext,
  handleBack,
  handleBackClojure,
  resetActiveStep,
  ppOnSubmitCreate,
  ppOnSubmitUpdate,
}) => {
  const classes = useStyles();

  const privacyPolicyDataInternal = parseRequestQuestions(
    privacyPolicyData?.generalQuestions
  );

  const methods = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: privacyPolicyDataInternal,
    resolver: yupResolver(GeneralQuestionsSchema),
  });

  const watchFields = methods.watch();

  const stateKey = "generalQuestions";

  useEffect(() => {
    methods.reset(watchFields);

    // eslint-disable-next-line
  }, []);

  const onSubmit = (data, event) => {
    event.preventDefault();

    setPrivacyPolicyData((previousPPData) => ({
      ...previousPPData,
      [stateKey]: parseFormQuestions(data),
    }));

    setPPSubmissionRedirect(true);

    setPPSubmission(true);

    handleNext();
  };

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)} className={classes.form}>
      <Box className={classes.formInputs}>
        <RadioInput
          labelNumber="1."
          label="Do you process personal data of users under 16?"
          name="1:condition"
          methods={methods}
          defaultValue={watchFields?.["1:condition"] ?? null}
          informationNote="The GDPR establishes that, generally, the consent of your user is valid only if he/she has at least 16 years. However, age limit has been established differently across the EU Member States. "
        />

        <SelectInput
          labelNumber="2."
          label="Do you process any type of data which can reveal or which concerns?"
          name="2:conditionText"
          methods={methods}
          options={formatOptions(GENERAL_QUESTIONS_OPTIONS_TWO)}
          lastOptions={LAST_OPTIONS}
          defaultValue={watchFields?.["2:conditionText"] ?? []}
          conditional="None of the above"
        />

        {watchFields?.["2:conditionText"] &&
        watchFields?.["2:conditionText"].length &&
        !watchFields?.["2:conditionText"]?.includes("None of the above") &&
        !watchFields?.["2:conditionText"]
          .map((option) => option.value)
          .includes("None of the above") ? (
          <SelectInput
            subQuestion
            labelNumber="2.1."
            label={`When processing ${watchFields?.["2:conditionText"]
              .map((option) => option?.label?.toLowerCase())
              .join(", ")} you do it because...?`}
            name="2-1:conditionText"
            methods={methods}
            options={formatOptions(GENERAL_QUESTIONS_OPTIONS_TWO_ONE)}
            lastOptions={LAST_OPTIONS}
            defaultValue={watchFields?.["2-1:conditionText"] ?? []}
            conditional="None of the above"
          />
        ) : null}

        <RadioInput
          labelNumber="3."
          label="Do you process data related to criminal convictions and offences or related security measures?"
          name="3:condition"
          methods={methods}
          defaultValue={watchFields?.["3:condition"] ?? null}
        />

        <RadioInput
          labelNumber="4."
          label="Do you have any system in place which, only by automatic processing of data, with no significant human intervention, takes decisions that produce legal or similarly significant effects on your user?"
          name="4:condition"
          methods={methods}
          defaultValue={watchFields?.["4:condition"] ?? null}
          informationNote="These decisions taken by automated means without any human involvement can be based on factual data as well as on digitally created profiles or data interfered about your users. The decision could be in relation to, for example: insurance, loans, access to education, e-recruting practices, access to the health system etc."
        />

        {watchFields?.["4:condition"] ? (
          <SelectInput
            subQuestion
            labelNumber="4.1."
            label="When taking such decisions, you do it because...?"
            name="4-1:conditionText"
            methods={methods}
            options={formatOptions(GENERAL_QUESTIONS_OPTIONS_FOUR_ONE)}
            lastOptions={LAST_OPTIONS}
            defaultValue={watchFields?.["4-1:conditionText"] ?? []}
            conditional="None of the above"
          />
        ) : null}

        <RadioInput
          labelNumber="5."
          label="Do you process personal data about your users which you have obtained from other sources? (not directly from your user)"
          name="5:condition"
          methods={methods}
          defaultValue={watchFields?.["5:condition"] ?? null}
        />
      </Box>

      <SectionActions
        back={true}
        next={true}
        nextText={projectStatus ? startCase(toLower(projectStatus)) : "Next"}
        stateKey={stateKey}
        handleBackClojure={handleBackClojure}
        methods={methods}
      />
    </form>
  );
};

const getIds = (project, projectCategories) => {
  const category = Object.values(projectCategories).filter((category) => {
    return category?.title?.toLowerCase() === "web";
  })[0];

  const categoryItem = category.categoryItems.filter(
    (categoryItem) => categoryItem.title.toLowerCase() === "privacy policy"
  )[0];

  return {
    _idProject: project._id,
    _idProjectCategory: category._id,
    _idProjectCategoryItem: categoryItem._id,
  };
};

const QUESTION_SECTION = `
	_id
    identify
    title
    condition
    conditionText
    importanceNote {
      title
      description
      accept
    }
    personalData {
      tag
      dataType
      sensitive
    }
    thirdParties {
      _id
      thirdPartyName
      serviceName
      serviceType
      firstLayer
      privacyPolicy
      optOut
      country
      euEea
      adequate
      adequacyReason
    }
    informationNote
    nestedQuestions
    url
    legalBasis
    devicePermissions
    dataStorageAndTransfers
	safeguards
`;

const SEARCH_PRIVACY_POLICY = gql`
  	query SearchPrivacyPolicy($input: PrivacyPolicySearchInput!) {
		searchPrivacyPolicy(input: $input) {
			_id
			_idProject
			_idProjectCategory
			_idProjectCategoryItem
			creator
			views
			publicCode
			colors {
				main
				link
				icon
				scroll
			}
			overrides {
				disablePadding
				disableError
			}
			updatedAt
			accessConnectivity {
				${QUESTION_SECTION}
			}
			advertising {
				${QUESTION_SECTION}
			}
			analytics {
				${QUESTION_SECTION}
			}
			customerSupport {
				${QUESTION_SECTION}
			}
			devicePermission {
				${QUESTION_SECTION}
			}
			infrastructure {
				${QUESTION_SECTION}
			}
			interactionExternalPlatforms {
				${QUESTION_SECTION}
			}
			marketing {
				${QUESTION_SECTION}
			}
			payments {
				${QUESTION_SECTION}
			}
			dataStorageTransfers {
				${QUESTION_SECTION}
			}
			generalQuestions {
				${QUESTION_SECTION}
			}
		}
	}
`;

const CREATE_PRIVACY_POLICY = gql`
  mutation CreatePrivacyPolicy($input: PrivacyPolicyCreateInput!) {
    createPrivacyPolicy(input: $input) {
		_id
		_idProject
		_idProjectCategory
		_idProjectCategoryItem
		creator
		views
		publicCode
		colors {
			main
			link
			icon
			scroll
		}
		overrides {
			disablePadding
			disableError
		}
		updatedAt
		accessConnectivity {
			${QUESTION_SECTION}
		}
		advertising {
			${QUESTION_SECTION}
		}
		analytics {
			${QUESTION_SECTION}
		}
		customerSupport {
			${QUESTION_SECTION}
		}
		devicePermission {
			${QUESTION_SECTION}
		}
		infrastructure {
			${QUESTION_SECTION}
		}
		interactionExternalPlatforms {
			${QUESTION_SECTION}
		}
		marketing {
			${QUESTION_SECTION}
		}
		payments {
			${QUESTION_SECTION}
		}
		dataStorageTransfers {
			${QUESTION_SECTION}
		}
		generalQuestions {
			${QUESTION_SECTION}
		}
    }
  }
`;

const UPDATE_PRIVACY_POLICY = gql`
	mutation UpdatePrivacyPolicyReplace($input: PrivacyPolicyUpdateInput!, $_id: ID!) {
		updatePrivacyPolicyReplace(input: $input, _id: $_id) {
			_id
			_idProject
			_idProjectCategory
			_idProjectCategoryItem
			creator
			views
			publicCode
			colors {
				main
				link
				icon
				scroll
			}
			overrides {
				disablePadding
				disableError
			}
			updatedAt
			accessConnectivity {
				${QUESTION_SECTION}
			}
			advertising {
				${QUESTION_SECTION}
			}
			analytics {
				${QUESTION_SECTION}
			}
			customerSupport {
				${QUESTION_SECTION}
			}
			devicePermission {
				${QUESTION_SECTION}
			}
			infrastructure {
				${QUESTION_SECTION}
			}
			interactionExternalPlatforms {
				${QUESTION_SECTION}
			}
			marketing {
				${QUESTION_SECTION}
			}
			payments {
				${QUESTION_SECTION}
			}
			dataStorageTransfers {
				${QUESTION_SECTION}
			}
			generalQuestions {
				${QUESTION_SECTION}
			}
		}
	}
`;

const sectionsData = {
  accessConnectivity: {
    title: "Access & Connectivity",
    component: AccessConnectivity,
  },
  advertising: {
    title: "Advertising",
    component: Advertising,
  },
  analytics: {
    title: "Analytics",
    component: Analytics,
  },
  customerSupport: {
    title: "Customer Support",
    component: CustomerSupport,
  },
  devicePermission: {
    title: "Device Permission",
    component: DevicePermission,
  },
  infrastructure: {
    title: "Infrastructure",
    component: Infrastructure,
  },
  interactionExternalPlatforms: {
    title: "Interaction with external platforms",
    component: InteractionExternalPlatforms,
  },
  marketing: {
    title: "Marketing",
    component: Marketing,
  },
  payments: {
    title: "Payments",
    component: Payments,
  },
  dataStorageTransfers: {
    title: "Data storage and transfers",
    component: DataStorageTransfers,
  },
  generalQuestions: {
    title: "General Questions",
    component: GeneralQuestions,
  },
};

const Sections = () => {
  const client = useApolloClient();

  const theme = useTheme();

  const classes = useStyles();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const { setDashboard } = useDashboard();

  const { project } = useProject();

  const { projectCategories } = useProjectCategories();

  const { projectPrivacyPolicy, setProjectPrivacyPolicy } =
    useProjectPrivacyPolicy();

  const [activeStep, setActiveStep] = useState(0);

  const [privacyPolicyData, setPrivacyPolicyData] = useState({});

  const [ppDefaultNetworking, setPPDefaultNetworking] = useState(true);

  const [ppSubmission, setPPSubmission] = useState(false);

  const [ppSubmissionRedirect, setPPSubmissionRedirect] = useState(false);

  const [ppLoading, setPPLoading] = useState(false);

  const [ppSuccess, setPPSuccess] = useState(false);

  const [ppError, setPPError] = useState(false);

  const [embed, setEmbed] = useState(false);

  const rawEmbedText = project.project.url

  const embedText = project.project.url
    ?.replace(REMOVE_PROTOCOLS_PATTERN, "")
    .split("/")[0];

  const sectionsTitles = Object.entries(sectionsData).map(
    ([sectionTitle, sectionContent]) => sectionContent.title
  );

  const sectionContent = Object.entries(sectionsData).map(
    ([sectionTitle, sectionContent]) => sectionContent
  )[activeStep];

  useEffect(() => {
    if (matches) {
      setDashboard({ hideSidebar: true });
    } else {
      setDashboard({ hideSidebar: false });
    }

    return () => {
      setDashboard({ hideSidebar: false });
    };

    // eslint-disable-next-line
  }, [matches]);

  useEffect(() => {
    if (projectPrivacyPolicy?.status === "update") setEmbed(true);

    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    (async () => {
      try {
        const pp = (
          await client.query({
            query: SEARCH_PRIVACY_POLICY,
            variables: {
              input: {
                _idProject: project?.project?._id,
              },
            },
            fetchPolicy: "no-cache",
            notifyOnNetworkStatusChange: true,
          })
        )?.data?.searchPrivacyPolicy;

        setPrivacyPolicyData(pp ? pp[0] : {});
      } catch (error) {
        // console.log(error)
      }

      setPPDefaultNetworking(false);
    })();

    // eslint-disable-next-line
  }, []);

  const handleNext = () => {
    if (activeStep < Object.keys(sectionsData).length - 1)
      setActiveStep((previousStep) => previousStep + 1);
  };

  const handleBack = () => {
    if (activeStep >= 1) setActiveStep((previousStep) => previousStep - 1);
  };

  const handleBackClojure = (key, methods) => {
    setPrivacyPolicyData((previousStepData) => ({
      ...previousStepData,
      [key]: parseFormQuestions(methods.watch()),
    }));

    handleBack();
  };

  const resetActiveStep = () => {
    setActiveStep(0);
  };

  const ppOnSubmitCreate = async (ppData) => {
    try {
      const ppCreated = (
        await client.mutate({
          mutation: CREATE_PRIVACY_POLICY,
          variables: {
            input: {
              ...getIds(project.project, projectCategories),
              ...ppData,
            },
          },
        })
      )?.data?.createPrivacyPolicy;

      setPrivacyPolicyData(ppCreated);

      setEmbed(true);

      return ppCreated;
    } catch (error) {
      // console.log(error)

      throw error;
    }
  };

  const ppOnSubmitUpdate = async (ppData) => {
    try {
      const {
        _id,
        _idProject,
        _idProjectCategory,
        _idProjectCategoryItem,
        creator,
        publicCode,
        updatedAt,
        ...ppDataFiltered
      } = ppData;

      const ppUpdated = (
        await client.mutate({
          mutation: UPDATE_PRIVACY_POLICY,
          variables: {
            _id: privacyPolicyData?._id,
            input: omitDeep(ppDataFiltered, "__typename"),
          },
        })
      )?.data?.updatePrivacyPolicyReplace;

      return ppUpdated;
    } catch (error) {
      // console.log(error)

      throw error;
    }
  };

  useEffect(() => {
    if (ppSubmission) {
      (async () => {
        setPPLoading(true);

        try {
          // eslint-disable-next-line
          const PP =
            projectPrivacyPolicy?.status === "create"
              ? await ppOnSubmitCreate(privacyPolicyData)
              : await ppOnSubmitUpdate(privacyPolicyData);

          setPPError(false);

          setPPSuccess(true);
        } catch (error) {
          setPPError(true);

          setPPSuccess(false);
        }

        setPPSubmission(false);

        setPPLoading(false);
      })();
    }

    // eslint-disable-next-line
  }, [setPPSubmission, ppSubmission]);

  useEffect(() => {
    return () => {
      setPrivacyPolicyData({});
    };

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ppSuccess)
      setTimeout(() => {
        setPPSuccess(false);

        if (ppSubmissionRedirect) {
          setEmbed(true);

          setPPSubmissionRedirect(false);
        }
      }, 1500);

    if (ppError)
      setTimeout(() => {
        setPPError(false);
      }, 1500);
    // eslint-disable-next-line
  }, [ppSuccess, ppError]);

  if (ppDefaultNetworking)
    return (
      <Box className={classes.container}>
        <LoadingComponent />
      </Box>
    );

  if (embed)
    return (
      <Box className={classes.container}>
        <EmbedContent
          embed={embed}
          setEmbed={setEmbed}
          rawEmbedText={rawEmbedText}
          embedText={embedText}
          privacyPolicyData={privacyPolicyData}
          setProjectPrivacyPolicy={setProjectPrivacyPolicy}
        />
      </Box>
    );

  if (ppLoading && !ppError && !ppSuccess)
    return (
      <Box className={classes.container}>
        <LoadingComponent
          message={`${
            projectPrivacyPolicy?.status === "update" ? "Updating" : "Creating"
          } Privacy Policy`}
        />
      </Box>
    );

  if (ppSuccess)
    return (
      <Box className={classes.container}>
        <SuccessLoad
          message={`Privacy Policy ${
            projectPrivacyPolicy?.status === "update" ? "updated" : "created"
          }...`}
        />
      </Box>
    );

  if (ppError)
    return (
      <Box className={classes.container}>
        <ErrorLoad
          message={`Error while ${
            projectPrivacyPolicy?.status === "update" ? "updating" : "creating"
          } Privacy Policy...`}
        />
      </Box>
    );

  return (
    <Grid container className={classes.grid}>
      <Grid item xs={12} sm={4} md={4} lg={4} className={classes.gridItemLeft}>
        <PrivacyPolicyMenu
          sectionsTitle={sectionsTitles}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          clickable={projectPrivacyPolicy?.status === "update"}
        />

        {projectPrivacyPolicy?.status === "update" ? (
          <EmbedButton setEmbed={setEmbed} />
        ) : null}
      </Grid>

      <Grid item xs={12} sm={7} md={7} lg={7} className={classes.gridItemRight}>
        <Box className={classes.ppContent}>
          <PrivacyPolicyTitle title={sectionsTitles[activeStep]} />

          {
            <sectionContent.component
              setPPSubmissionRedirect={setPPSubmissionRedirect}
              project={project.project}
              privacyPolicyData={privacyPolicyData}
              setPrivacyPolicyData={setPrivacyPolicyData}
              projectStatus={projectPrivacyPolicy?.status}
              setPPSubmission={setPPSubmission}
              setPPLoading={setPPLoading}
              setPPSuccess={setPPSuccess}
              setPPError={setPPError}
              handleNext={handleNext}
              handleBack={handleBack}
              handleBackClojure={handleBackClojure}
              resetActiveStep={resetActiveStep}
              ppOnSubmitCreate={ppOnSubmitCreate}
              ppOnSubmitUpdate={ppOnSubmitUpdate}
            />
          }
        </Box>
      </Grid>
    </Grid>
  );
};

export default Sections;
