import React from "react";

import { HeaderSticky, Footer } from "../components/general";

import { Beta } from "../components/pricing";

import { Grid } from "@material-ui/core";

import { Helmet } from "react-helmet";

const Pricing = () => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Helmet>
        <title>Pricing</title>
      </Helmet>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <HeaderSticky />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Beta />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Footer disclaimer={false} />
      </Grid>
    </Grid>
  );
};

export default Pricing;
