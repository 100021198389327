import React from "react";

import { HeaderSticky, Footer } from "../components/general";

import { Intro, Paperwork, PrivacyPolicy, Safeguard } from "../components/home";

import { Grid } from "@material-ui/core";

import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <Grid container>
      <Helmet>
        <title>{process.env.REACT_APP_TITLE}</title>
      </Helmet>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <HeaderSticky />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Intro />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Paperwork />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <PrivacyPolicy />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Safeguard />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default Home;
