import React, { createContext, useContext, useEffect, useReducer } from "react";

import {
  setLocalStorageItem,
  getLocalStorageItem,
  removeLocalStorageItem,
} from "../utils";

const defaultStateDashboard = {
  hideSidebar: false, // Hides the entire sidebar. Options: true or false
  menu: "dashboard", // Options: dashboard and profile
  optionsProfileMenuItemSelected: 0, // 0 Account, 1 notifications and 2 payments & billings, see options profile
  optionsSupportMenuItemSelected: 0, // 0 Contact, see options profile
  newProjectModal: false, // Shows new project modal. Options: true or false
  newProjectTutorial: false,
};

const reducer = (previousState, newState) => {
  if (newState === null) {
    removeLocalStorageItem({
      key: process.env.REACT_APP_DASHBOARD_LOCAL_STORAGE_KEY,
    });

    return defaultStateDashboard;
  }

  return { ...previousState, ...newState };
};

const DashboardContext = createContext(defaultStateDashboard);

const DashboardProvider = ({ children }) => {
  const [dashboard, setDashboard] = useReducer(
    reducer,
    getLocalStorageItem({
      key: process.env.REACT_APP_DASHBOARD_LOCAL_STORAGE_KEY,
      defaultState: defaultStateDashboard,
    })
  );

  useEffect(() => {
    setLocalStorageItem({
      key: process.env.REACT_APP_DASHBOARD_LOCAL_STORAGE_KEY,
      data: dashboard,
    });
  }, [dashboard]);

  return (
    <DashboardContext.Provider value={{ dashboard, setDashboard }}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboard = () => {
  const { dashboard, setDashboard } = useContext(DashboardContext);

  if (!dashboard)
    throw new Error("Hook useDashboard must be used over DashboardProvider");

  return { dashboard, setDashboard };
};

export {
  DashboardContext,
  DashboardProvider,
  defaultStateDashboard,
  useDashboard,
};
