const features = [
  {
    Feature: "Apple HealthKit",
    "Type of service": "Q1 - Device permissions",
    "Data First layer":
      "Contact details [of your clients], Data that identifies you [your clients], Data from your [your clients] device, Sensitive data",
    "Specific data for this service": "Sensitive data",
    "": "Health",
    "Privacy policy": "https://www.apple.com/uk/legal/privacy/en-ww/",
    "Opt Out": "",
    Country: "United States",
    "EU/EEA": "no",
    Adequate: "yes",
    "Adequacy reason": "SCC",
    "Comments about the service": "nan",
  },
  {
    Feature: "Apple's speech recognition - server permission",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "Sensitive data",
    "": "Biometric",
    "Privacy policy": "https://www.apple.com/uk/legal/privacy/en-ww/",
    "Opt Out": "",
    Country: "United States",
    "EU/EEA": "no",
    Adequate: "yes",
    "Adequacy reason": "SCC",
    "Comments about the service": "nan",
  },
  {
    Feature: "Biometric data access permission",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "Sensitive data",
    "": "Biometric",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Bluetoooth",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Calendar",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Call permission",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Camera",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Camera permission without saving or recording",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Contacts",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Google Assistant",
    "Type of service": "Q1 - Device permissions",
    "Data First layer":
      "Contact details [of your clients], Data that identifies you [your clients], Data from your [your clients] device, Sensitive data",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "https://policies.google.com/privacy?hl=en",
    "Opt Out": "",
    Country: "United States",
    "EU/EEA": "no",
    Adequate: "yes",
    "Adequacy reason": "Privacy Shield",
    "Comments about the service": "nan",
  },
  {
    Feature: "Google home",
    "Type of service": "Q1 - Device permissions",
    "Data First layer":
      "Contact details [of your clients], Data that identifies you [your clients], Data from your [your clients] device, Sensitive data",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "https://policies.google.com/privacy?hl=en",
    "Opt Out": "",
    Country: "United States",
    "EU/EEA": "no",
    Adequate: "yes",
    "Adequacy reason": "Privacy Shield",
    "Comments about the service": "nan",
  },
  {
    Feature: "Health data read permission",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "Sensitive data",
    "": "Health",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Health data update permission",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "Sensitive data",
    "": "Health",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Home kit",
    "Type of service": "Q1 - Device permissions",
    "Data First layer":
      "Contact details [of your clients], Data that identifies you [your clients], Data from your [your clients] device, Sensitive data",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Media library permission (music)",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Microphone",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "Sensitive data",
    "": "Biometric",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Microphone permission without recording",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "Sensitive data",
    "": "Biometric",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "NFC reader permission",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Phone",
    "Type of service": "Q1 - Device permissions",
    "Data First layer":
      "Contact details [of your clients], Data that identifies you [your clients], Data from your [your clients] device, Sensitive data",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Photo library",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Photo library - write only permission",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Precise Location - continuously",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Precise Location - discontinuous",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Reminders",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Sensors",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Siri",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "https://www.apple.com/uk/legal/privacy/en-ww/",
    "Opt Out": "",
    Country: "United States",
    "EU/EEA": "no",
    Adequate: "yes",
    "Adequacy reason": "SCC",
    "Comments about the service": "nan",
  },
  {
    Feature: "SMS",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Social media accounts",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "Storage",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
  {
    Feature: "TV provider",
    "Type of service": "Q1 - Device permissions",
    "Data First layer": "",
    "Specific data for this service": "",
    "": "",
    "Privacy policy": "",
    "Opt Out": "",
    Country: "",
    "EU/EEA": "",
    Adequate: "",
    "Adequacy reason": "",
    "Comments about the service": "nan",
  },
];

export default features;
