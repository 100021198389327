import React from "react";

import { Box, Link, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    minHeight: "100vh",
    display: "flex",
    alignItems: "stretch",
    justifyContent: "center",
    background: theme.palette.gradient,
    boxSizing: "border-box",
    padding: theme.spacing(2),
  },
  boxSections: {
    maxWidth: "800px",
    "& > *:first-child": {
      marginTop: theme.spacing(6),
    },
    "& > *": {
      marginBottom: theme.spacing(12),
    },
  },
  boxSection: {},
  sectionTitle: {
    marginBottom: theme.spacing(3),
  },
  sectionText: {
    fontSize: "1.5rem",
    color: theme.palette.grayOne,
  },
  boxSectionTextMulti: {
    "& > *": {
      marginBottom: theme.spacing(4),
    },
    "& > *:last-child": {
      marginBottom: "0",
    },
  },
  link: {
    color: theme.palette.secondary.light,
    "&:hover": {
      color: theme.palette.secondary.soft,
    },
  },
}));

const Sections = () => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box className={classes.boxSections}>
        <Box className={classes.boxSection}>
          <Typography variant="h1" className={classes.sectionTitle}>
            Who we are
          </Typography>

          <Typography className={classes.sectionText}>
            We are a team of two with legal and software engineering background.
            Our mission is to make legal documents and processes user-centric,
            affordable and cool as we always wanted it to be.
          </Typography>
        </Box>

        <Box className={classes.boxSection}>
          <Typography variant="h1" className={classes.sectionTitle}>
            Why Audaxly
          </Typography>

          <Box className={classes.boxSectionTextMulti}>
            <Typography className={classes.sectionText}>
              We rely on many years of consulting practice and take into account
              all the legal requirements established by GDPR, CCPA and ePrivacy
              regulation.
            </Typography>

            <Typography className={classes.sectionText}>
              We ask exhaustive questions, use plain language and give you hints
              and examples so that you can easily navigate through every aspect
              of your business.
            </Typography>

            <Typography className={classes.sectionText}>
              Also, our philosophy is that legal documents don’t have to make
              you cringe and plain language is a must. Therefore we have
              designed our policy with the help of{" "}
              <Link
                underline="none"
                href="https://stefaniapassera.com/"
                target="_blank"
                className={classes.link}
              >
                Stefania Passera
              </Link>{" "}
              which believes that “legal documents can be user-friendly, clear,
              and effective. By design.”
            </Typography>
          </Box>
        </Box>

        <Box className={classes.boxSection}>
          <span id="#careers" />
          <Typography variant="h1" className={classes.sectionTitle}>
            Careers
          </Typography>

          <Typography className={classes.sectionText}>
            We are constantly looking for talented people who relate to our
            values. If this sentence resonates with you: ‘I wish legal stuff
            would be more accessible, reasonably priced, easier to understand
            and more transparent”, sign up and contact us through the support
            in the menu options.
          </Typography>
        </Box>

        <Box className={classes.boxSection}>
          <span id="#contact" />

          <Typography variant="h1" className={classes.sectionTitle}>
            Contact us
          </Typography>

          <Typography className={classes.sectionText}>
            We are open to innovative collaborations and we are happy to discuss
            the future of legal tech. Especially about that part that is
            directed to democratise access to services. Fell free to contact us
            through the support in the menu options.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Sections;
