import React from "react";

import {
  Box,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxLoadingPage: {
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  loadingPage: {
    color: theme.palette.secondary.main,
    marginBottom: theme.spacing(2),
  },
}));

const LoadingPage = ({ size = 60, style = {}, styleClass = "" }) => {
  const classes = useStyles();

  return (
    <Box className={classes.boxLoadingPage}>
      <CircularProgress
        size={size}
        className={`${classes.loadingPage} ${styleClass}`}
        style={style}
      />
    </Box>
  );
};
export default LoadingPage;
