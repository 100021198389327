import React, { useEffect } from "react";

import { Box, makeStyles } from "@material-ui/core";

import { Helmet } from "react-helmet";

import { Outlet } from "react-router-dom";

import { Header, SideMenu, VerifyEmail } from "../components/dashboard";

import { useApolloClient, gql } from "@apollo/client";

import { toast } from "react-toastify";

import { useUser, useDashboard } from "../contexts";

import { LoadingPage } from "../components/general";

const useStyles = makeStyles((theme) => ({
  "@global": {
    "::-webkit-scrollbar": {
      width: "0",
      background: "transparent",
    },
    "::-webkit-scrollbar-thumb": {
      background: "transparent",
    },
    "::-webkit-scrollbar-track": {},
    "::-webkit-scrollbar-thumb:hover": {},
    ".MuiList-padding": {
      paddingTop: "0",
      paddingBottom: "0",
    },
  },
  containerGeneral: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "stretch",
  },
  containerSideMenuContent: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    width: "100%",
  },
  containerSideMenu: {
    zIndex: "99",
    height: "100%",
    width: "65vw",
    minWidth: "300px",
    maxWidth: "330px",
    boxSizing: "border-box",
    boxShadow: `0px 0px 50px -35px ${theme.palette.grayFour}`,
    borderRight: "1px solid rgba(231, 238, 236, .75)",
    [theme.breakpoints.down("sm")]: {
      "&": {
        // Hide side menu when in small screen
        display: "none",
      },
    },
  },
  containerContent: {
    width: "100%",
    height: "100%",
    background: theme.palette.gradient,
  },
}));

const USER = gql`
  query User {
    user {
      name
      email
      password
      marketingAccept
      customerId
      paymentMethods {
        id
      }
      verifyRegistration {
        status
      }
      updatedAt
    }
  }
`;

const Dashboard = () => {
  const { user, setUser } = useUser();

  const { dashboard } = useDashboard();

  const classes = useStyles({ dashboard });

  const client = useApolloClient();

  useEffect(() => {
    (async () => {
      if (!Object.keys(user).length) {
        try {
          const _user = (
            await client.query({
              query: USER,
            })
          ).data.user;

          setUser(_user);
        } catch (error) {
          // And error will be shown if the API is off and the user logged, but
          // the page is updated and then the loading page component will be on
          // screen forever
          //
          // A specific message can be specified on this case
          toast.error(error.message);
        }
      }
    })();

    // eslint-disable-next-line
  }, []);

  return (
    <Box className={classes.containerGeneral}>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>

      {user?.verifyRegistration?.status || user?.email ? (
        <Header presentation={!user?.verifyRegistration?.status} />
      ) : null}

      {user?.verifyRegistration?.status ? (
        <Box className={classes.containerSideMenuContent}>
          {dashboard?.hideSidebar ? null : (
            <Box className={classes.containerSideMenu}>
              <SideMenu />
            </Box>
          )}

          <Box className={classes.containerContent}>
            <Outlet />
          </Box>
        </Box>
      ) : user?.email ? (
        <VerifyEmail />
      ) : (
        <LoadingPage />
      )}
    </Box>
  );
};

export default Dashboard;
