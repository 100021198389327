import React, { useRef, useEffect, useState } from "react";

import {
  Grid,
  Button,
  Typography,
  TextField,
  makeStyles,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { ErrorMessage } from "@hookform/error-message";

import { gql, useMutation } from "@apollo/client";

import { toast } from "react-toastify";

import { yupResolver } from "@hookform/resolvers/yup";

import * as Yup from "yup";

import { LoadingButton } from "../general";

import { useDashboard, useUser } from "../../contexts";

const useStyles = makeStyles((theme) => ({
  gridContact: {
    display: "flex",
    boxSizing: "border-box",
    flexWrap: "nowrap",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: theme.spacing(5),
    "& > *": { width: "100%" },
    "& > *:first-child": {},
    "& > *:last-child": {},
  },
  gridItemTitle: {},
  title: {
    textAlign: "start",
    color: theme.palette.blackOne,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "36px",
    lineHeight: "42px",
  },
  subTitle: {
    color: theme.palette.primary.soft,
    margin: theme.spacing(1, 0, 1, 0),
  },
  gridItemForm: {},
  grid: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  gridItem: {
    width: "100%",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    "& > *:first-child": {
      marginTop: "0",
    },
    "& > *:last-child": {
      marginBottom: "0",
    },
  },
  gridItemCreate: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    marginTop: "0",
    marginBottom: "0",
  },
  buttonSubmit: {
    fontWeight: "normal",
  },
  buttonSubmitDisabled: {
    color: `${theme.palette.common.white} !important`,
  },
  error: {
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
  },
  messageInput: {
    background: theme.palette.common.white,
  },
}));

const ContactSchema = Yup.object().shape({
  message: Yup.string().required("You must fill the message").min(50).max(400),
});

const CONTACT = gql`
  mutation Contact($input: ContactInput!) {
    contact(input: $input)
  }
`;

const ContactForm = () => {
  const classes = useStyles();

  const { user } = useUser();

  const [submitted, setSubmitted] = useState(false);

  const [
    contact,
    // eslint-disable-next-line
    { dataContact, loadingContact, errorContact, calledContact },
  ] = useMutation(CONTACT);

  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(ContactSchema),
  });

  const { setDashboard } = useDashboard();

  const contactButtonContent = useRef("Create");

  useEffect(() => {
    setDashboard({ menu: "support", optionsSupportMenuItemSelected: 0 });

    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data, event) => {
    contactButtonContent.current = <LoadingButton />;

    try {
      // eslint-disable-next-line
      const _contact = (
        await contact({
          variables: {
            input: {
              ...data,
              purpose: "support",
              name: user?.name,
              email: user?.email,
            },
          },
        })
      ).data.contact;

      toast.success(`Message created`);

      toast.info(`We're gonna reach you as soon as possible`);
    } catch (error) {
      toast.error(error.message);
    }

    contactButtonContent.current = "Created";

    setSubmitted(true);
  };

  return (
    <Grid container className={classes.gridContact}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemTitle}
      >
        <Typography variant="h1" className={classes.title}>
          Contact
        </Typography>

        <Typography className={classes.subTitle}>
          We're always improving and we appreciate your feedback, feel free to
          let us know how we can help you.
        </Typography>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemForm}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.grid}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={9}
              lg={4}
              className={classes.gridItem}
            >
              <TextField
                name="message"
                type="text"
                label="Message"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  autoComplete: "message",
                  form: {
                    autoComplete: "off",
                  },
                }}
                multiline
                rows={5}
                rowsMax={7}
                fullWidth
                error={Boolean(errors.message)}
                inputRef={register}
                className={classes.messageInput}
              />
              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="message" />
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={`${classes.gridItem} ${classes.gridItemCreate}`}
            >
              <Button
                variant="hovered"
                color="primary"
                type="submit"
                disabled={submitted}
                classes={{ disabled: classes.buttonSubmitDisabled }}
                className={classes.buttonSubmit}
              >
                {contactButtonContent.current}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
