const customerSupport = [
  {
    providerName: "GetWebCraft Ltd.",
    providerService: "GetSiteControl contact form",
    questionGenerator: "Q3.3. - Contact form",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://getsitecontrol.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Sumo Group Inc.",
    providerService: "SumoMe Contact form",
    questionGenerator: "Q3.3. - Contact form",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink:
      "https://help.sumo.com/hc/en-us/articles/218958727-Privacy-Policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Wunderbuilt Systems Inc.",
    providerService: "Usebasin Contact Form ",
    questionGenerator: "Q3.3. - Contact form",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data",
    specificData: null,
    providerPrivacyLink: "https://usebasin.com/privacy",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "ActiveCampaign, LLC.",
    providerService: "ActiveCampaign",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.activecampaign.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Different services that can be purchased separately : Email Marketing / Marketing Automation / Sales & CRM / Messaging/ Machine Learning",
    "Unnamed: 12": null,
  },
  {
    providerName: "Braze, Inc.",
    providerService: "Braze",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.braze.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Different services that can be purchased separately: send messages, integrate emial, develop client engagement, create customer journey, geolocation ...",
    "Unnamed: 12": null,
  },
  {
    providerName: "Customerly Ltd. ",
    providerService: "Customerly",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.customerly.io/en/privacy",
    optOut: null,
    placeProcessing: "Ireland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Live Chat Software\nKnowledgebase platform\nSales Marketing Funnel\nIn-app Surveys & NPS",
    "Unnamed: 12": null,
  },
  {
    providerName: "Salesforce Inc. ",
    providerService: "Desk.com",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Route inquiries from help center, email, phone, chat, and social channels through one easy-to-use console.\nBasically everything + CRM (can also be purchased by service)\n- Datorama, Inc.\nDemandware LLC\nHeroku, Inc.\nKrux Digital LLC\nMuleSoft, LLC\nQuip LLC\nSalesforce.com, inc.",
    "Unnamed: 12": null,
  },
  {
    providerName: "EngageBay Inc. ",
    providerService: "EngageBay",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.engagebay.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Freshworks Inc.",
    providerService: "Freshsales",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.freshworks.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "LINK TO WHERE DATA IS HOSTED \nFor customers hosted in the EEA with custom mailbox, data remains in the EEA by default. However, if customers choose to use services like 3rd party integrations and apps, custom apps then data is expected to leave the EEA (European Economic Area).",
    "Unnamed: 12": null,
  },
  {
    providerName: "FullContact Inc. ",
    providerService: "FullContact",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink:
      "https://www.fullcontact.com/privacy-center/#privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Other coverd Entities:  FullContact Inc./ FullContact International, LLC (a Delaware Company) / Gentoo Labs, Inc. / nGame Inc. (a Delaware Company)\n- services: Identity resolution, Profile enrichment, Audience Insights & Development, Fraud prevention and Customer care ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Help Scout Inc.",
    providerService: "Help Scout",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.helpscout.com/company/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "CRM system",
    "Unnamed: 12": "CRM",
  },
  {
    providerName: "Helpshift, Inc",
    providerService: "Helpshift",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.helpshift.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "The basic includes :Email Ticketing\nLive Chat\nWeb & Mobile In-App Help Center\nAgent Desktop & Case Management\nKnowledge Management\nTime-Based Automation\nNew Issue Automation",
    "Unnamed: 12": "CRM",
  },
  {
    providerName: "HubSpot, Inc.",
    providerService: "HubSpot",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://legal.hubspot.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "3 services : HubSpot CRM / HubSpot Marketing Hub / HubSpot Sales Hub",
    "Unnamed: 12": null,
  },
  {
    providerName: "Intercom, Inc.",
    providerService: "Intercom",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.intercom.com/terms-and-policies#privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "3 services : Lead generation(Qualify leads and book meetings with live chat and bots) / Customer engagement (Onboard customers and keep them engaged with email, push, and in-product messages) / Customer support (Help your customers with real-time support, a built-in help center, and bots for quick self-service)",
    "Unnamed: 12": null,
  },
  {
    providerName: "Infusion Software, Inc. dba Keap",
    providerService: "Keap",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://keap.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "Keap is a registered trademark of Infusion Software, Inc. IOS is a registered trademark of Cisco Systems, Inc. Android is a trademark of Google LLC. Facebook is a registered trademark of Facebook, Inc. Twitter is a registered trademark of Twitter, Inc. VeraSafe is a trademark of Advanced Partnerships LLC. - think that comp does not exist?!",
    "Unnamed: 12": null,
  },
  {
    providerName: "Pipedrive Inc",
    providerService: "Pipedrive",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.pipedrive.com/en/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Personal Data we collect through the Services in centralized databases and with service providers located in the U.S. The Service is hosted in the United States or Germany. EU customers\u2019 databases will be hosted in Pipedrive\u2019s EU datacenter located in Frankfurt, Germany.  Regardless of the database being hosted in the EU, if you choose to use the Service from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you may be transferring your Client Data and Personal Data outside of those regions to the United States for storage and processing by our service providers listed in the Pipedrive Terms of Service. ",
    "Unnamed: 12": "CRM",
  },
  {
    providerName: "Salesforce.com, Inc.",
    providerService: "Salesforce Service Cloud",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Reach your customers on the channels they're most comfortable using, including mobile messaging, web chat, social media, and more. ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Salesforce.com, Inc.",
    providerService: "Salesforce Service Cloud",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Reach your customers on the channels they're most comfortable using, including mobile messaging, web chat, social media, and more. ",
    "Unnamed: 12": "CRM",
  },
  {
    providerName: "Totango Inc",
    providerService: "Totango",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.totango.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": "CRM",
  },
  {
    providerName:
      "Zendesk Ireland International Ltd. (EAA&other countries users) | Zendesk, Inc. (USA users) ",
    providerService: "Zendesk",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink:
      "https://www.zendesk.com/company/customers-partners/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": "CRM",
  },
  {
    providerName:
      "Zoho Corporation BV (EEA users) | Zoho Corporation Pvt. Ltd. (AU users) | Zoho Corporation (USA&other countries users)",
    providerService: "ZOHO",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.zoho.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": "CRM",
  },
  {
    providerName: "Bitrix, Inc.",
    providerService: "Birtix 24",
    questionGenerator: "Q2 - CRM ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Data from your device",
    specificData: null,
    providerPrivacyLink: "https://www.bitrix24.eu/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zoho Corporation BV (EEA users) | Zoho Corporation Pvt. Ltd. (AU users) | Zoho Corporation (USA&other countries users)",
    providerService: "ZOHO Email",
    questionGenerator: "Q3.2. - Email",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you",
    specificData: null,
    providerPrivacyLink: "https://www.zoho.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "GetWebCraft Ltd.",
    providerService: "GetSiteControl Email Marketing",
    questionGenerator: "Q3.2. - Email",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://getsitecontrol.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Move to MKT",
    "Unnamed: 12": null,
  },
  {
    providerName: "Sumo Group Inc.",
    providerService: "SumoMe Mailing List or Newsletter",
    questionGenerator: "Q3.2. - Email",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData:
      "company name\nemail address\n\ufb01rst name\nlast name\nphone number\nwebsite\nZIP/Postal code",
    providerPrivacyLink:
      "https://help.sumo.com/hc/en-us/articles/218958727-Privacy-Policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Salesforce.com, Inc.",
    providerService: "Desk.com",
    questionGenerator: "Q3.4. - Help center ",
    dataCollected:
      "Personal & Contact details, Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.salesforce.com/company/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Route inquiries from help center, email, phone, chat, and social channels through one easy-to-use console.\n",
    "Unnamed: 12": "Async&Sync",
  },
  {
    providerName: "EngageBay Inc. ",
    providerService: "EngageBay Live Chat & Helpdesk",
    questionGenerator: "Q3.4. - Help center ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.engagebay.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Freshworks Inc.",
    providerService: "Freshdesk",
    questionGenerator: "Q3.4. - Help center ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.freshworks.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "LINK TO WHERE DATA IS HOSTED \nFor customers hosted in the EEA with custom mailbox, data remains in the EEA by default. However, if customers choose to use services like 3rd party integrations and apps, custom apps then data is expected to leave the EEA (European Economic Area).",
    "Unnamed: 12": null,
  },
  {
    providerName: "Quality Unit, s. r. o.",
    providerService: "LiveAgent",
    questionGenerator: "Q3.4. - Help center ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.liveagent.com/privacy-policy-live-agent/",
    optOut: null,
    placeProcessing: "Slovakia",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "TeamSupport LLC",
    providerService: "TeamSupport",
    questionGenerator: "Q3.4. - Help center ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink: "https://www.teamsupport.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zendesk Ireland International Ltd. (EAA&other countries users) | Zendesk, Inc. (USA users) ",
    providerService: "Zendesk",
    questionGenerator: "Q3.4. - Help center ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service",
    specificData: null,
    providerPrivacyLink:
      "https://www.zendesk.com/company/customers-partners/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Help center / chat / tickets\nalso Email Integration & CRM",
    "Unnamed: 12": null,
  },
  {
    providerName: "Agora, Inc.",
    providerService: "Agora.io",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Sensitive data , Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.agora.io/en/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "ClickDesk Corp.",
    providerService: "ClickDesk Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.clickdesk.com/legalese/customers/customer-privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "Live chat software ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Comm100 Network Corp. ",
    providerService: "Comm100 Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.comm100.com/privacy/",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": "Live chat plugin for wordpress",
    "Unnamed: 12": null,
  },
  {
    providerName: "Crisp IM SARL",
    providerService: "Crisp Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://crisp.chat/en/privacy/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": "Live chat and maketing campaigns",
    "Unnamed: 12": null,
  },
  {
    providerName: "Customerly Ltd.",
    providerService: "Customerly Chat Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData:
      "Cookies \nData comunicated while using the service \nemail address\nfirst name\nlast name \nusage data ",
    providerPrivacyLink: "https://www.customerly.io/en/privacy",
    optOut: null,
    placeProcessing: "Ireland",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Live Chat Software\nKnowledgebase platform\nSales Marketing Funnel\nIn-app Surveys & NPS",
    "Unnamed: 12": null,
  },
  {
    providerName: "Drift.com, Inc.",
    providerService: "Drift Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.drift.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Drift Chat\nDrift Email\nDrift Video\nDrift Automation",
    "Unnamed: 12": null,
  },
  {
    providerName: "EngageBay Inc. ",
    providerService: "EngageBay Live Chat & Helpdesk",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.engagebay.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Facebook Ireland Ltd (EEA users) | Facebook Inc. (USA&other countries users)",
    providerService: "Facebook Messenger Customer Chat",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.facebook.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "https://developers.facebook.com/products/messenger/tutorials/messenger-customer-chat-plugin-tutorial/",
    "Unnamed: 12": null,
  },
  {
    providerName: "Freshworks Inc.",
    providerService: "Freshchat Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.freshworks.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "LINK TO WHERE DATA IS HOSTED \nFor customers hosted in the EEA with custom mailbox, data remains in the EEA by default. However, if customers choose to use services like 3rd party integrations and apps, custom apps then data is expected to leave the EEA (European Economic Area).",
    "Unnamed: 12": null,
  },
  {
    providerName: "GetWebCraft Ltd. ",
    providerService: "GetSiteControl Chat Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://getsitecontrol.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": "ALSO SURVEYS ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Freshworks Inc.",
    providerService: "Integration of Freshsales with Freshchat and/or Facebook",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Sensitive data , Cookies",
    specificData:
      "address\ncompany name\nemail address\n\ufb01rst name\ngeography / region \nlast name\nphone number\nprofession\nsocial media accounts ",
    providerPrivacyLink: "https://www.freshworks.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "LINK TO WHERE DATA IS HOSTED \nFor customers hosted in the EEA with custom mailbox, data remains in the EEA by default. However, if customers choose to use services like 3rd party integrations and apps, custom apps then data is expected to leave the EEA (European Economic Area).",
    "Unnamed: 12": null,
  },
  {
    providerName: "LiveChat, Inc.",
    providerService: "LiveChat Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.livechatinc.com/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "www.livechatinc.com or www.chatbot.com, www.helpdesk.com and www.knowledgebase.ai we",
    "Unnamed: 12": null,
  },
  {
    providerName: "CuteSoft Components Inc. ",
    providerService: "My LiveChat Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://mylivechat.com/privacy.aspx",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": "part of https://cutesoft.net/ ",
    "Unnamed: 12": null,
  },
  {
    providerName: "Habla, Inc.",
    providerService: "Olark Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.olark.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Pure Chat, Inc.",
    providerService: "Pure Chat Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://purechat.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service":
      "- they have a dedicated GDPR page https://purechat.com/gdpr\n- data protection agreement https://www.purechat.com/dpa",
    "Unnamed: 12": null,
  },
  {
    providerName: "Smartsupp.com, s.r.o",
    providerService: "Smartsupp",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.smartsupp.com/help/privacy/",
    optOut: null,
    placeProcessing: "Czech Republic",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Only exception is transactional emails as offline emails or chat transcripts. We use Mandrill (service of Mailchimp) for sending transactional emails and Mandrill stores the data in USA under EU-US Privacy shield.\nLink to DPA https://www.smartsupp.com/data/Smartsupp_DPA_EN_web_17_05.pdf",
    "Unnamed: 12": null,
  },
  {
    providerName: "SnapEngage, LLC.",
    providerService: "Snapengage Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://snapengage.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Tawk.to Inc",
    providerService: "Tawk.to Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.tawk.to/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Tidio LLC",
    providerService: "Tidio Live Chat widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.tidio.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service":
      "Tidio LLC(with its registered office in 180 Steuart St,CA 94119, San Francisco) + Tidio Ltd with its registered office in [220C Blythe Road, W14 0HH, London] and Tidio Poland Sp. z o.o. with its registered office in [Podhala\u0144ska 15B, 80-322, Gda\u0144sk].\nAlso EMAIL Integration",
    "Unnamed: 12": null,
  },
  {
    providerName: "Vivocha S.p.A.",
    providerService: "Vivocha Widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.vivocha.com/privacy-policy/",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service":
      "Chat / Click2Call / Co-browsing / Video Chat / CallBack",
    "Unnamed: 12": null,
  },
  {
    providerName: "Tencent, Inc.",
    providerService: "WeChat widget",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.wechat.com/en/privacy_policy.html",
    optOut: null,
    placeProcessing: "China",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service":
      "we share your Personal Information within our group of companies, including Tencent International Service Europe BV (located in the Netherlands), Tencent International Service Pte. Ltd (located in Singapore), WeChat International Pte Ltd (located in Singapore) and Oriental Power Holdings Limited (located in Hong Kong) and WeChat International (Canada) Limited (located in Canada) that run the Hong Kong and Canadian Servers",
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zendesk Ireland International Ltd. (EAA&other countries users) | Zendesk, Inc. (USA users) ",
    providerService: "Zendesk Chat",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData:
      "address\ncompany name\nCookies\ncountry\nData communicated while using the service\nemail address\n\ufb01rst name\nlast name\nphone number\nUsage Data",
    providerPrivacyLink:
      "https://www.zendesk.com/company/customers-partners/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": "also Email Integration & CRM",
    "Unnamed: 12": null,
  },
  {
    providerName: "LivePerson Automotive, LLC",
    providerService: "LivePerson Messaging & Bot",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.liveperson.com/policies/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield + SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zoho Corporation BV (EEA users) | Zoho Corporation Pvt. Ltd. (AU users) | Zoho Corporation (USA&other countries users)",
    providerService: "Zoho SalesIQ",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Sensitive data, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.zoho.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Slack Technologies Ltd. (EEA&other countries users) | Slack Technologies, Inc. (USA&CA users)",
    providerService: "Slack Customer Support",
    questionGenerator: "Q3.1. - Live communication ",
    dataCollected:
      "Personal & Contact details,  Data introduced by you within the platform, Data that identifies you, Cookies",
    specificData: null,
    providerPrivacyLink: "https://slack.com/intl/en/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
];

export default customerSupport;
