import interactionWithExternalPlatform from "./interactionWithExternalPlatform";

import marketing from "./marketing";

import analytics from "./analytics";

import customerSupport from "./customerSupport";

import advertising from "./advertising";

import payments from "./payments";

import infrastructure from "./infrastructure";

import accessConnectivity from "./accessConnectivity";

const thirdParties = [
  ...interactionWithExternalPlatform,
  ...marketing,
  ...analytics,
  ...customerSupport,
  ...advertising,
  ...payments,
  ...infrastructure,
  ...accessConnectivity,
];

export {
  interactionWithExternalPlatform,
  marketing,
  analytics,
  customerSupport,
  advertising,
  payments,
  infrastructure,
  accessConnectivity,
  thirdParties,
};
