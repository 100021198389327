// Return the difference between two objects based on
// different key-value verifications
const objectsDifference = (dataForm, dataRequest) => {
  const cache = Object.assign({}, dataForm);

  Object.keys(cache).forEach(
    (key) =>
      (cache[key] === null ||
        cache[key] === "" ||
        cache[key] === dataRequest.user[key]) &&
      delete cache[key]
  );

  return cache;
};

export default objectsDifference;
