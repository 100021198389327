import React from "react";

import { Box, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  boxContainer: {
    minHeight: "250px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "290px",
      },
    },
  },
  title: {
    color: theme.palette.blackOne,
  },
  description: {
    fontSize: "1.5rem",
    color: theme.palette.grayOne,
  },
  
}));

const Intro = () => {
  const classes = useStyles();

  return (
    <Box className={classes.boxContainer}>
      <Box>
        <Typography variant="h1" className={classes.title}>
          About us
        </Typography>

        <Typography variant="body2" className={classes.description}>
          Legal magicians that are turning privacy friendly and complaint
        </Typography>
      </Box>
    </Box>
  );
};

export default Intro;
