import React, { useEffect } from "react";

import { Grid } from "@material-ui/core";

import { Intro, LoginForm } from "../components/authentication";

import { Helmet } from "react-helmet";

import { useLocation } from "react-router-dom";

import { toast } from "react-toastify";

const Login = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.state) toast.info(location.state.message);

    // eslint-disable-next-line
  }, []);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Helmet>
        <title>Login</title>
      </Helmet>

      <Grid item xs={12} sm={5} md={6} lg={6}>
        <Intro />
      </Grid>

      <Grid item xs={12} sm={12} md={6} lg={6}>
        <LoginForm state={location.state} />
      </Grid>
    </Grid>
  );
};

export default Login;
