import { removeAllLocalStorage } from "./index";

const logout = ({ client, navigate, setters }) => {
  client.clearStore();

  removeAllLocalStorage();

  if (setters) setters.forEach((setter) => setter(null));

  if (navigate) navigate("/login");
};

export default logout;
