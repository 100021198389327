const payments = [
  {
    providerName:
      "Avangate Romania SRL (EEA users) | 2Checkout.com Inc (USA&other countries users)",
    providerService: "2Checkout",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.2checkout.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Oracle Corp.",
    providerService: "AddThis Inline Tip Jar",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information ,  Data introduced by you within the platform, Data that identifies you,  Data on how you use the service, Data related to your third-party accounts , Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.oracle.com/legal/privacy/addthis-privacy-policy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12":
      "- Inline Tip Jar [ bar which allow web visitors to donate via PayPal, Amazon Smile, Patreon, Venmo, and Square Cash]",
  },
  {
    providerName: "Adyen B.V.",
    providerService: "Adyen",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.adyen.com/policies-and-disclaimer/privacy-policy",
    optOut: null,
    placeProcessing: "Netherlands",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Alipay Singapore E-Commerce Private Ltd. - Alipay EU Louxembourg Ltd. (EU. repr for EEA users)",
    providerService: "Alipay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://render.alipay.com/p/f/agreementpages/alipayglobalprivacypolicy.html",
    optOut: null,
    placeProcessing: "Singapore",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Amazon Payments Europe S.C.A. (EEA users) | Amazon Payments UK Ltd. (UK users) | Amazon Payments, Inc. (USA & other countries users) ",
    providerService: "Amazon Payments",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://pay.amazon.co.uk/help/201751600",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Apple Distribution International Ltd. (EEA users) | Apple Inc. (USA & other countries users)",
    providerService: "Apple Pay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.apple.com/uk/legal/privacy/en-ww/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "CyberSource Corp.",
    providerService: "Authorize.Net",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.authorize.net/about-us/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "BitPay B.V. NL (EEA users) | BitPay, Inc. (USA&other countries users)",
    providerService: "BitPay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service , Cookies",
    specificData: null,
    providerPrivacyLink: "https://bitpay.com/about/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "BlueSnap Payment Services UK Ltd (EEA users) | BlueSnap Inc. (USA&other countries users) | BlueSnap Enterprise Canada ULC (CA users) | BlueSnap Australia Pty Ltd. (AU users)",
    providerService: "BlueSnap",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://home.bluesnap.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "PayPal Europe S.a.r.l. et Cie, S.C.A.",
    providerService: "Braintree",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "See the Braintree privacy Policy - PayPal Inc. \nSee the Braintree privacy Policy - PayPal (Europe) S.a.r.l. et Cie S.C.A\nSee the Braintree privacy Policy - PayPal Australia Pty Limited\nSee the Braintree privacy Policy - PayPal Canada Co.\nSee the Braintree privacy Policy - PayPal Hong Kong Limited\nSee the Braintree privacy Policy - PayPal Pte. Ltd.",
    providerPrivacyLink:
      "https://www.braintreepayments.com/gb/legal/braintree-privacy-policy",
    optOut: null,
    placeProcessing: "Luxembourg",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Visa Europe UK Ltd. (EEA users) | Visa Inc. (USA&other countries users)",
    providerService: "CardinalCommerce",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.cardinalcommerce.com/about/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Chargebee Inc.",
    providerService: "ChargeBee",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.chargebee.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Chargify, LLC",
    providerService: "Chargify",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.chargify.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Coinbase Custody Ireland International Ltd & Coinbase UK, Ltd (EEA users) | Coinbase Inc. (USA users)",
    providerService: "Coinbase",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.coinbase.com/legal/user_agreement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "UAB Decentralized Lithuania Ltd. ",
    providerService: "CoinGate",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://coingate.com/privacy",
    optOut: null,
    placeProcessing: "Lithuania",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Hodltech O\u00dc dba CoinPayments",
    providerService: "CoinPayments",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.coinpayments.net/downloads/CoinPayments_Privacy_Policy.pdf",
    optOut: null,
    placeProcessing: "Estonia",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Concardis GmbH",
    providerService: "ConCardis",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.concardis.com/de-en/protecting-your-data",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Dwolla, Inc.",
    providerService: "Dwolla",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.dwolla.com/legal/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Elavon Inc.",
    providerService: "Elavon",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.elavon.com/privacy-policy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Web Active Corporation Pty Ltd",
    providerService: "eWAY",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.eway.com.au/legal/",
    optOut: null,
    placeProcessing: "Australia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Bright Market, LLC ",
    providerService: "FastSpring",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://fastspring.com/privacy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Fortumo O\u00dc",
    providerService: "Fortumo",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.eway.com.au/legal/",
    optOut: null,
    placeProcessing: "Estonia",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "2ndSite Inc.",
    providerService: "Freshbooks",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.freshbooks.com/policies/privacy",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "GoCardless Ltd.",
    providerService: "GoCardless",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://gocardless.com/privacy/payers/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Google Ireland Ltd. (EEA users) | Google LLC. (USA & other countries users)",
    providerService: "Google Pay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://payments.google.com/payments/apis-secure/get_legal_document?ldo=0&ldt=privacynotice&ldl=pt",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "HiPay SAS",
    providerService: "Hipay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://hipay.com/en/terms",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Klarna Bank AB",
    providerService: "Klarna",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://cdn.klarna.com/1.0/shared/content/legal/terms/0/en_gb/privacy",
    optOut: null,
    placeProcessing: "Sweden",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Scvngr, Inc.",
    providerService: "LevelUp",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.thelevelup.com/privacy-policy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Mangopay S.A.",
    providerService: "Mango Pay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mangopay.com/privacy/",
    optOut: null,
    placeProcessing: "Luxembourg",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Mobiyo SAS",
    providerService: "Mobyo",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mobiyo.com/legal/",
    optOut: null,
    placeProcessing: "France",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Moneris Solutions Corp.",
    providerService: "Moneris",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.moneris.com/Legal/Privacy-Policy#PrivacyPolicy",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Paysafe Financial Services UK Ltd.",
    providerService: "Neteller",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.neteller.com/en/policies/privacy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Onebip S.R.L.",
    providerService: "Onebip",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "http://corporate.onebip.com/wp-content/uploads/2018/05/privacy-notice-policy.pdf",
    optOut: null,
    placeProcessing: "Italy",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Paymill GmbH",
    providerService: "Paymill",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.paymill.com/en/privacy-policy/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "PAYONE GmbH",
    providerService: "Payone",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.payone.com/datenschutzbestimmungen/",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Payoneer Gibraltar Ltd (EEA users) | Payoneer Pty Ltd (AU users) | Payoneer Inc (USA & other countries users)",
    providerService: "Payoneer",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.payoneer.com/legal/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "PayPal Europe S.a.r.l. et Cie, S.C.A.",
    providerService: "PayPal",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.paypal.com/uk/webapps/mpp/ua/privacy-full",
    optOut: null,
    placeProcessing: "Luxembourg",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Paypro Global Canada Inc.",
    providerService: "PayPro",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://docs.payproglobal.com/documents/legal/privacyPolicy.pdf",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Paysafe Financial Services UK Ltd.",
    providerService: "Paysafecard",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.paysafecard.com/en-gb/data-protection/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "PaySimple, Inc.",
    providerService: "PaySimple",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://paysimple.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "MIH PayU BV",
    providerService: "PayU",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://corporate.payu.com/payu-privacy-policy",
    optOut: null,
    placeProcessing: "Netherlands",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "MH Pillars Ltd.",
    providerService: "Payza",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.payza.eu/privacy-policy",
    optOut: null,
    placeProcessing: "Canada",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Southern Payment Systems Pty Ltd",
    providerService: "Pin Payments",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://pinpayments.com/privacy",
    optOut: null,
    placeProcessing: "Australia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Plaid Financial Ltd. (EEA&UK users) | Plaid Inc. (other countries users)",
    providerService: "Plaid Auth",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData:
      "address\nCookies\nemail address\n\ufb01rst name\nlast name\npassword\npayment data\nUsage Data\nusername\nvarious types of Data as speci\ufb01ed in the privacy policy of the service",
    providerPrivacyLink: "https://plaid.com/legal/#privacy-statement",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Recurly, Inc.",
    providerService: "Recurly",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://recurly.com/legal/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Safecharge International Group Ltd",
    providerService: "SafeCharge",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.safecharge.com/privacy-cookies-policy/",
    optOut: null,
    placeProcessing: "Cyprus",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Sage Pay Europe Ltd",
    providerService: "Sage Pay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.sagepay.co.uk/policies/privacy-policy",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Securetrading Ltd. ",
    providerService: "Secure Trading",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.securetrading.com/wp-content/uploads/2018/09/STL-WEBSITE-PRIVACY-NOTICE.pdf",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Online Payments Group Ltd.",
    providerService: "SecurionPay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://securionpay.com/docs/privacy",
    optOut: null,
    placeProcessing: "Switzerland",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Mastercard Europe SA (EEA users) | MasterCard International Inc. (other countries users)",
    providerService: "Simplify",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.mastercard.us/en-us/about-mastercard/what-we-do/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Skrill Ltd.",
    providerService: "Skrill",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.skrill.com/en/footer/privacypolicy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Spreedly, INC.",
    providerService: "Spreedly",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.spreedly.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Square, Inc.",
    providerService: "Square",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://squareup.com/us/en/legal/general/privacy-no-account",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Stripe Payments Europe, Ltd (EEA users) |  Stripe Payments Australia Pty. Ltd. (AU users) | Stripe, Inc. (USA&other countries users)",
    providerService: "Stripe",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://stripe.com/gb/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Verifone, S.A. de C.V.",
    providerService: "Verifone",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.verifone.com/sites/default/files/Aviso%20de_Privacidad_Integral_%28Mexico%29.pdf",
    optOut: null,
    placeProcessing: "Mexico",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "WePay Inc.",
    providerService: "WePay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://go.wepay.com/privacy-policy/",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "SCC",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Wirecard AG",
    providerService: "Wirecard",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.wirecard.com/privacy-policy",
    optOut: null,
    placeProcessing: "Germany",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Fidelity National Information Services, Inc. dba FIS",
    providerService: "WorldPay",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.fisglobal.com/privacy",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Yandex.Money LLC",
    providerService: "Yandex.Money",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://money.yandex.ru/page?id=525698",
    optOut: null,
    placeProcessing: "Russia",
    EuEea: "no",
    Adequate: "no",
    adequacyReason: "-",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName:
      "Zoho Corporation BV (EEA users) | Zoho Corporation Pvt. Ltd. (AU users) | Zoho Corporation (USA&other countries users)",
    providerService: "Zoho Checkout",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.zoho.com/privacy.html",
    optOut: null,
    placeProcessing: "United States",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "Privacy Shield",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Zooz Mobile Ltd.",
    providerService: "Zooz",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.zooz.com/legal/privacy-policy",
    optOut: null,
    placeProcessing: "Israel",
    EuEea: "no",
    Adequate: "yes",
    adequacyReason: "EU adequacy list",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Mollie B.V.",
    providerService: "Mollie ",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://www.mollie.com/en/privacy",
    optOut: null,
    placeProcessing: "Netherlands",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Satispay Europe S.A.",
    providerService: "Satispay ",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink:
      "https://www.satispay.com/en-de/privacy/privacy-policy/",
    optOut: null,
    placeProcessing: "Luxembourg",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
  {
    providerName: "Paddle.com Market Ltd. ",
    providerService: "Paddle ",
    questionGenerator: "Q2 - Third party payment system ",
    dataCollected:
      "Personal & Contact details, Financial information , Data that identifies you,  Data on how you use the service, Cookies",
    specificData: null,
    providerPrivacyLink: "https://paddle.com/privacy/",
    optOut: null,
    placeProcessing: "United Kingdom",
    EuEea: "yes",
    Adequate: "yes",
    adequacyReason: "EU/EEA country",
    "Comments about the service": null,
    "Unnamed: 12": null,
  },
];

export default payments;
