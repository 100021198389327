import React from "react";

import {
  Grid,
  Box,
  Button,
  Typography,
  Link,
  List,
  ListItem,
  makeStyles,
} from "@material-ui/core";

import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";

import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  "@global": {
    '[class*="MuiTypography-body"]': {
      fontFamily: "Muli, Arial, sans-serif",
      color: theme.palette.common.black,
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "16px",
      lineHeight: "175%",
      letterSpacing: ".01em",
    },
  },
  gridGlobal: {
    margin: "0 auto",
    width: "85%",
    padding: "0 100px",
    boxSizing: "border-box",
    "& > *": {
      width: "100%",
      height: "100%",
    },
    "& > *:not(:first-child)": {
      margin: "30px 0",
    },
    // prettier-ignore
    ["@media (max-width:1700px)"]: { // eslint-disable-line no-useless-computed-key
      width: "95%",
      padding: "0 95px",
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        width: "100%",
        padding: "0 80px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0 20px",
      },
    },
  },
  gridItemIntro: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    minHeight: "425px",
    "& > *": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
    [theme.breakpoints.down("md")]: {
      "&": {
        minHeight: "500px",
      },
    },
  },
  boxIntroTitle: {
    flex: ".5",
    [theme.breakpoints.down("md")]: {
      "&": {
        flex: ".7",
      },
    },
  },
  boxIntroTitleHeader: {
    textAlign: "left",
    color: theme.palette.common.black,
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "155%",
  },
  boxIntroDescription: {},
  boxTermItem: {
    marginBottom: theme.spacing(7),
  },
  boxWoWeAreContent: {
    flex: "2",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    background: theme.palette.graySix,
    boxSizing: "border-box",
    padding: theme.spacing(5),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(2),
    marginLeft: "27px",
    marginRight: "48px",
    borderTop: `4px solid ${theme.palette.secondary.main}`,
    "& > *:last-child": {
      paddingBottom: "0",
    },
    [theme.breakpoints.down("sm")]: {
      "&": {
        minHeight: "260px",
        marginLeft: "0",
        marginRight: "0",
      },
    },
  },
  indentationLevel1: {
    boxSizing: "border-box",
    paddingLeft: "27px",
    paddingRight: "48px",
    paddingBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        paddingLeft: "0",
        paddingRight: "0",
      },
    },
  },
  indentationLevel2: {
    boxSizing: "border-box",
    paddingLeft: "48px",
    paddingRight: "48px",
    paddingBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        padding: "0px 0px 0px 20px",
      },
    },
  },
  indentationLevel3: {
    boxSizing: "border-box",
    paddingLeft: "73px",
    paddingRight: "48px",
    [theme.breakpoints.down("sm")]: {
      "&": {
        paddingLeft: "16px",
        paddingRight: "0",
      },
    },
  },
  link: {
    color: theme.palette.secondary.main,
  },
  bold: {
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "200%",
    letterSpacing: "0.01em",
    boxSizing: "border-box",
  },
  underline: {
    textDecoration: "underline",
  },
  boxSectionTitle: {
    display: "flex",
    "& > *:first-child": {
      marginRight: theme.spacing(1),
    },
  },
}));

const IntroSection = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  return (
    <>
      <Box className={classes.boxIntroTitle}>
        <Button
          variant="hovered-reversed-no-border"
          startIcon={<ArrowBackIcon />}
          onClick={() =>
            document.referrer === "" ? navigate("/") : window.history.back()
          }
          disableElevation
          disableFocusRipple
          disableRipple
        >
          Back
        </Button>

        <Typography variant="body2" className={classes.boxIntroTitleHeader}>
          Terms & Conditions
        </Typography>

        <Typography variant="body2" className={classes.indentationLevel1}>
          Last updated: <span className={classes.bold}>February 21st 2021</span>
        </Typography>
      </Box>

      <Box className={classes.boxIntroDescription}>
        <Typography variant="body2" className={classes.indentationLevel1}>
          Welcome to{" "}
          <Link
            href={"https://audaxly.com"}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            audaxly.com
          </Link>
          ’s Terms & Conditions.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel1}>
          Please take into account that if you use any of the services offered
          by our Platform you acknowledge and accept these terms and conditions.
        </Typography>
      </Box>
    </>
  );
};

const Terms = () => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">1.</Typography>

          <Typography variant="h3">Who are we (“us / we”)</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          We are the owners of the{" "}
          <Link
            href={"https://audaxly.com"}
            target="_blank"
            rel="noreferrer"
            className={classes.link}
          >
            audaxly.com
          </Link>{" "}
          website and of all related services (“Services and/or Platform”).
        </Typography>

        <Box className={classes.boxWoWeAreContent}>
          <Typography
            variant="body2"
            className={`${classes.indentationLevel1} ${classes.bold}`}
          >
            Audaxly Design S.R.L.
          </Typography>

          <Typography variant="body2" className={classes.indentationLevel1}>
            Pta. Sf. Voievozi, nr.2, <br />
          </Typography>

          <Typography variant="body2" className={classes.indentationLevel1}>
            Bucharest, Romania
          </Typography>
        </Box>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">2.</Typography>

          <Typography variant="h3">
            Information about the Services / Platform
          </Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          The Service provided by us allows you to use technological tools that
          we have developed in order to generate, host and keep document
          templates related to your online activities (your website, platform,
          mobile app, etc.)
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">3.</Typography>

          <Typography variant="h3">
            Appointment of Audaxly as processor & use of data
          </Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          You acknowledge and accept that by using the Services offered, you
          appointing Audaxly to process personal data as “processor” pursuant to
          the European data protection regulations. We will only process the
          personal data necessary for the provision of the service. Also, you
          understand that by generating the documents you allow the indexation
          of such documents by any third parties.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">4. </Typography>

          <Typography variant="h3">
            Use of the online Services offered on this Platform
          </Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          You are solely responsible for the use of any Service and the use is
          to be considered at your own risk and falls entirely under your
          responsibility.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          Please take into account that Audaxly provides a software, materials
          and assistance with the only purpose of helping you generate a
          compliant Privacy Policy for your website. In this sense, Audaxly
          shall not be considered a lawyer or a law firm and no Attorney -
          client relationship is established.{" "}
          <span className={classes.underline}>
            Audaxly does not engage in the practice of law and our software does
            not substitute professional legal consultancy
          </span>
          . We have done our best to provide software, that generates a
          compliant document, but it should be treated as an elaborated template
          and we advise you to seek legal advice, which is the only way to
          guarantee full compliance.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          Audaxly is to be considered just a provider of technology and in no
          way may be held responsible for the way you use it. Furthermore, we
          recommend that you check your privacy practice with an accredited
          legal professional.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">5. </Typography>

          <Typography variant="h3">Account information </Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          To use the Service, you will need to register and create an account.
          Audaxly will give you access to the Service and functionality that we
          may establish and maintain from time to time and in our sole
          discretion. We may maintain different types of accounts for different
          types of users. If you open an Audaxly account on behalf of a company,
          organization, or other entity, then (i) “you” includes you and that
          entity, and (ii) you represent and warrant that you are an authorized
          representative of the entity with the authority to bind the entity to
          this Agreement, and that you agree to this Agreement on the entity’s
          behalf.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          You are responsible for taking all steps to ensure that no
          unauthorized person shall have access to your account or password.
          When creating your account, you must provide accurate and complete
          information. We encourage you to use “strong” passwords (passwords
          that use a combination of upper and lowercase letters, numbers, and
          symbols) with your account. It is your sole responsibility to: (i)
          control the dissemination and use of your account and password, and
          (ii) promptly inform us of any need to deactivate an account or
          password. We are not liable for any harm related to the theft of
          passwords, or your disclosure of passwords.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          You shall immediately notify us of any unauthorized use of your
          account or password or any breach of confidentiality. You are solely
          responsible for any damage resulting from the use of your account and
          username. You may not use your account or password to breach security
          of another account.We shall not be held responsible under any
          circumstances in case of loss, disclosure, theft or unauthorized use
          by third parties, for whatever reason, of your access credentials.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          You may control your account and how you interact with the Service by
          changing your settings. By providing us with your email address, you
          consent to our using the email address to send you Service-related
          notices, including any notices required by law, in lieu of
          communication by postal mail. We may also use your email address to
          send you other messages, such as changes to features of the Service.
          You may not opt out of these messages unless you cancel your account.
          For more information, please read our{" "}
          <Link
            underline="none"
            href={process.env.REACT_APP_PRIVACY_POLICY}
            target="_blank"
            className={classes.link}
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">6. </Typography>

          <Typography variant="h3">Content provided by you</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          You are responsible for your content and that of third parties that
          you share, upload or post through this Platform. You confirm that you
          have all the necessary consents from third parties whose data and/or
          content you share with us and hereby indemnify us for any liability or
          claim arising against us in connection with illegal distribution of
          third-party content or unlawful use of the Service.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          As a general rule, we do not moderate the content provided by you or
          by third parties but will act if complaints are received or if orders
          are issued by the public authorities regarding content deemed
          offensive or illegal.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          The only rights you grant us in relation to the content you provide
          are those necessary to operate and maintain this Platform.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">7. </Typography>

          <Typography variant="h3">
            Services provided by third parties
          </Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          In those cases in which our Platform offers you the possibility to use
          services provided by third parties you must be aware that the terms
          and conditions and the privacy policies of such third parties apply
          and that you give consent to them. We shall not be deemed liable,
          under any circumstance, in relation to the proper functionality and /
          or availability of third-party services. To see the third parties that
          we use and understand how we process your personal data, please visit
          our{" "}
          <Link
            underline="none"
            href={process.env.REACT_APP_PRIVACY_POLICY}
            target="_blank"
            className={classes.link}
          >
            Privacy Policy
          </Link>
          .
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">8.</Typography>

          <Typography variant="h3">What we own</Typography>
        </Box>
        <Typography
          variant="h3"
          className={classes.indentationLevel1}
        ></Typography>

        <Typography variant="body2" className={classes.indentationLevel2}>
          Except for the content that you insert into our Platform, which fully
          belongs to you, all materials on the Service and the Service itself
          and all Intellectual Property Rights contained therein or related
          thereto, including text, graphics, user and visual interfaces,
          photographs, trademarks, logos, sounds, music, artwork, applications,
          computer code and associated documentation (collectively, the
          “Content”), including but not limited to the design, structure,
          arrangement, and “look and feel” of such Content, is owned by or
          licensed to us and our licensors and is protected by copyright,
          trademark, and other intellectual property rights and laws.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          Other than as provided herein, Audaxly does not grant to user any
          license, express or implied, to the intellectual property of Audaxly
          or its licensors. Except as expressly provided in the Terms of
          Service, no part of the Service and no Content may be copied,
          reproduced, sold, republished, transmitted, displayed, reposted, or
          otherwise distributed for public or commercial purposes.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          Subject to the terms and conditions of this Agreement, Audaxly
          provides you with a non-exclusive, revocable license to use the
          Service, as expressly permitted by the features and functionality of
          the Service and the Terms of Service. Audaxly may terminate this
          license at any time for any reason or no reason.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">9. </Typography>

          <Typography variant="h3">Deleting your account</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          You can cancel your account and stop using the Service at any time,
          through the interface of this Platform or by directly contacting us.
          Also, in case you breach these Terms and Conditions, we reserve the
          right to suspend or terminate your account at any time and without
          notice.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">10.</Typography>

          <Typography variant="h3">Your responsibilities</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          Our Platform simply provides you the technological tools to help and
          assist you with the management of certain legal aspects of your
          business activities. However, under no circumstances our Services /
          Platform should be considered as legal advice and it remains your
          exclusive responsibility to ensure that your operations are in
          accordance with the applicable rules.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          Please take into account that not only your answers / input are your
          own responsibility but the verification of the final generated
          document with the applicable legislation remains also your exclusive
          responsibility. You have to verify the correspondence of the template
          that we provide to the characteristics of their activities.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          If at any moment you understand that the templates we provide should
          not be compliant with the legislation applicable to your business /
          region / practices you are obliged to refrain from using the Services/
          Platform.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          You are authorised to use the templates only for the duration of the
          service and in their integrity. When the Service ends, you shall not,
          in any circumstance, use or reproduce the template provided by us, in
          any way.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">11.</Typography>

          <Typography variant="h3">
            Disclaimer of warranties, limitation of liability, “as is” / “as
            available”
          </Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          Audaxly provides its Services / Platform on an “as is” & “as
          available” basis without warranty of any kind, either express or
          implied, including without limitation, warranties of merchantability,
          fitness for a particular purpose, accuracy, completeness, currentness,
          freedom from interruption, viruses or other defect, and
          non-infringement. Your sole remedy against us for dissatisfaction with
          the service or any content is to stop using the service or any such
          content.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          To the maximum extent permitted by applicable law, Audaxly assumes no
          liability or responsibility for any (i) errors, mistakes, or
          inaccuracies of content; (ii) personal injury or property damage, of
          any nature whatsoever, resulting from your access to or use of our
          service; (iii) any unauthorized access to or use of our secure servers
          and/or any and all information, including personal information or
          financial information, stored therein; (iv) any errors, mistakes,
          losses, damage or unauthorized access resulting from the use of third
          party applications by you, by your end users, or by webflow on your
          behalf; (v) any interruption or cessation of transmission to or from
          the service; (vi) any bugs, viruses, trojan horses, or the like that
          may be transmitted to or through our service by any third party; (vii)
          any errors or omissions in any content or for any loss or damage
          incurred as a result of the use of any content posted, emailed,
          transmitted, or otherwise made available through the service; and/or
          (viii) user content or the defamatory, offensive, or illegal conduct
          of any third party. in no event shall webflow, its affiliates, agents,
          directors, employees, suppliers, or licensors be liable to you for any
          claims, proceedings, liabilities, obligations, damages, losses, or
          costs in an amount exceeding one euro.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">12.</Typography>

          <Typography variant="h3">Suspending the Services</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          We reserve the right to add and remove functionalities or features as
          well as suspend or even discontinue the Service, either temporarily or
          permanently. In case of final discontinuation, Audaxly will do the
          utmost to allow you to withdraw your content.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">13. </Typography>

          <Typography variant="h3">Use of the Service</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          Your use of the Service is subject to all applicable laws and
          regulations. Audaxly only offers it’s Services to users who are over
          18.
        </Typography>

        <Typography variant="body2" className={classes.indentationLevel2}>
          You may not:
        </Typography>

        <List
          className={classes.indentationLevel3}
          classes={{ root: classes.listRoot }}
        >
          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> use any “deep-link”,
              “page-scrape”, “robot”, “spider” or other automatic device,
              program, algorithm or methodology, or any manual process to
              access, acquire, copy, or monitor any portion of the Service or
              any Content or obtain or attempt to obtain any materials,
              documents or information through any means not purposely made
              available through the Service
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> attempt to gain
              unauthorized access to any portion of the Service or any Content,
              or any systems or networks connected to the Service, by hacking,
              password “mining”, or any other illegitimate means
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> probe, scan, or test the
              vulnerability of the Service or any Content, or any system or
              network connected to the Service
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> reverse look-up or trace
              any information of any other user or visitor or otherwise use the
              Service for the purpose of obtaining information of any other user
              or visitor
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> transmit spam, chain
              letters, or other unsolicited email
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> take any action that
              imposes, or may impose at our sole discretion an unreasonable or
              disproportionately large load on our infrastructure
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> upload invalid data,
              viruses, worms, or other software agents through the Service
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> impersonate another person
              or otherwise misrepresent your affiliation with a person or
              entity, conduct fraud, hide, or attempt to hide your identity
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> disclose any information
              that you do not have the consent to disclose (such as confidential
              information of others, including their personally identifiable
              information)
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> use any device, software,
              or process to interfere with, or attempt to interfere with, the
              proper working of the Service or any Content, or any systems or
              networks connected to the Service, or with any other person’s use
              of the Service
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> conduct, engage in or
              otherwise process End User information or data to analyze
              individuals or groups of natural persons for unlawful or
              discriminatory purposes
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> use the Service or any
              Content for any purpose that is unlawful or prohibited by the
              Terms of Service, or
            </Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">
              <span className={classes.bold}>•</span> store or collect any
              personally identifiable information that is deemed sensitive or
              requires special protections under applicable laws. For example,
              Social Security numbers, passwords, and credit card information
            </Typography>
          </ListItem>
        </List>

        <Typography variant="body2" className={classes.indentationLevel2}>
          Any unauthorized use of the Service or any Content may violate
          copyright, trademark, and other applicable laws and could result in
          criminal or civil penalties.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">14. </Typography>

          <Typography variant="h3">Changes to these Terms</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          We reserve the right to modify these Terms and Conditions at any time
          and we will inform you as the law requires like, for example, by
          publishing a notice within this Platform.
        </Typography>

        <br />

        <Typography variant="body2" className={classes.indentationLevel2}>
          If you continue to use this Platform after the publication of the
          changes, you expressly accept the new Terms and Conditions in their
          entirety.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">15. </Typography>

          <Typography variant="h3">Assignment of contract</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          This Agreement, and any rights and licenses granted hereunder, may not
          be transferred or assigned by you, but may be assigned by Audaxly
          without restriction. Any attempted transfer or assignment in violation
          hereof shall be null and void.
        </Typography>
      </Box>

      <Box className={classes.boxTermItem}>
        <Box
          className={`${classes.indentationLevel1} ${classes.boxSectionTitle}`}
        >
          <Typography variant="h3">16. </Typography>

          <Typography variant="h3">Governing law and jurisdiction</Typography>
        </Box>

        <Typography variant="body2" className={classes.indentationLevel2}>
          These Terms and any dispute concerning the implementation,
          interpretation and validity of this agreement are subject to the law,
          the jurisdiction of the state and to the exclusive jurisdiction of
          Romania. An exception to this rule applies in cases, where the law
          provides a sole place of jurisdiction for consumers.
        </Typography>
      </Box>
    </Box>
  );
};

const Sections = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      className={classes.gridGlobal}
    >
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemIntro}
      >
        <IntroSection />
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridItemIntro}
      >
        <Terms />
      </Grid>
    </Grid>
  );
};

export default Sections;
