import React from "react";

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  makeStyles,
} from "@material-ui/core";

import { Link } from "react-router-dom";

import { useDashboard } from "../../contexts";

import { ReactComponent as Plane } from "../../assets/images/contact/plane.svg";

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".optionIcon": {
      transform: "scale(.7)",
    },
    ".optionIcon path": { fill: theme.palette.primary.light },
    ".optionIconActive": { transform: "scale(.7)" },
    ".optionIconActive path": { fill: theme.palette.secondary.main },
  },
  container: {
    padding: "12px",
    boxSizing: "border-box",
    width: "100%",
    backgroundColor: "transparent",
  },
  rootListItem: {
    borderRadius: "5px",
    backgroundColor: "transparent !important",
    paddingTop: "10px",
    paddingBottom: "10px",
    margin: "12px 0px",
    boxShadow: "none",
    border: `1px solid transparent`,
    "&:hover": {
      border: `1px solid ${theme.palette.purpleThree}`,
      backgroundColor: `${theme.palette.purpleOne} !important`,
      "& svg path": {},
      "& span": {},
    },
    "& > *:first-child": {
      marginTop: "0",
    },
    "&> *:last-child": {
      marginBottom: "0",
    },
  },
  listItem: {},
  listItemText: {},
  guttersListItem: {},
  selectedListItem: {
    border: `1px solid ${theme.palette.purpleThree}`,
    backgroundColor: `${theme.palette.purpleOne} !important`,
    "& svg path": {},
    "& span": {},
  },
  boxTitle: {
    width: "100%",
    flex: ".5",
    maxHeight: "50px",
    minHeight: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    boxSizing: "border-box",
    padding: theme.spacing(1, 1, 0, 3),
    marginTop: "10px",
  },
  optionSectionTitle: {
    paddingRight: "15px",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    textTransform: "uppercase",
    color: theme.palette.grayTwo,
  },
}));

const OptionsSupport = () => {
  const classes = useStyles();

  const { dashboard, setDashboard } = useDashboard();

  const handleListItemClick = (event, index) => {
    setDashboard({ optionsSupportMenuItemSelected: index });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.boxTitle}>
        <Typography variant="body2" className={classes.optionSectionTitle}>
          Support
        </Typography>
      </Box>

      <List component="nav" aria-label="main mailbox folders">
        <ListItem
          component={Link}
          to="/dashboard/contact"
          button
          disableFocusRipple
          disableRipple
          selected={dashboard.optionsSupportMenuItemSelected === 0}
          onClick={(event) => handleListItemClick(event, 0)}
          classes={{
            root: classes.rootListItem,
            gutters: classes.guttersListItem,
            selected: classes.selectedListItem,
          }}
          className={classes.listItem}
        >
          <ListItemIcon>
            <Plane
              className={
                dashboard.optionsSupportMenuItemSelected === 0
                  ? "optionIconActive"
                  : "optionIcon"
              }
            />
          </ListItemIcon>

          <ListItemText className={classes.listItemText} primary="Contact" />
        </ListItem>
      </List>
    </Box>
  );
};

export default OptionsSupport;
