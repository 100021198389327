import React, { useState, useRef, useEffect } from "react";

import {
  Grid,
  Box,
  Container,
  Typography,
  Button,
  Link,
  makeStyles,
  TextField,
} from "@material-ui/core";

import { useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import { ErrorMessage } from "@hookform/error-message";

import { useNavigate } from "react-router-dom";

import { gql, useMutation } from "@apollo/client";

import { toast } from "react-toastify";

import * as Yup from "yup";

import { Header } from "./index";

import { LoadingButton } from "../general";

const stopCounter = 0;

const startCounter = 10;

const useStyles = makeStyles((theme) => ({
  boxContainer: {},
  container: {
    height: "auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(3),
  },
  containerConfirmation: {
    height: "auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(3),
  },
  containerReset: {
    height: "auto",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    boxSizing: "border-box",
    paddingBottom: theme.spacing(3),
  },
  form: {
    width: "100%",
  },
  grid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    minHeight: "350px",
    width: "100%",
  },
  gridErrorResetPassword: {
    padding: "0px",
  },
  gridPasswordUpdated: {
    padding: "0px",
  },
  gridItem: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  gridItemErrorResetPassword: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    justifyContent: "center",
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
    color: theme.palette.grayOne,
  },
  highlight: {
    color: theme.palette.secondary.light,
    "&:hover": {
      color: theme.palette.secondary.soft,
    },
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  textColor: {
    color: theme.palette.grayOne,
  },
  login: {
    marginTop: theme.spacing(2),
    color: theme.palette.grayOne,
  },
  boxContentConfirmation: {
    height: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  error: {
    position: "relative",
    right: "-13px",
    fontSize: "0.85rem",
    marginTop: "5px",
    fontWeight: "400",
    lineHeight: "1.66",
    letterSpacing: "0.03333em",
    color: theme.palette.error.main,
  },
}));

const FORGOT_PASSWORD = gql`
  mutation ForgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

const FORGOT_PASSWORD_UPDATE = gql`
  mutation ForgotPasswordUpdate($code: String!, $password: String!) {
    forgotPasswordUpdate(code: $code, password: $password)
  }
`;

const ResetPasswordSchema = Yup.object().shape({
  newPassword: Yup.string()
    .required("You must fill the new password")
    .min(8, "Minimum 8 characters")
    .matches(/[A-Z]/, "Must contain one uppercase character")
    .matches(/[0-9]/, "Must contain one number"),
  confirmPassword: Yup.string()
    .required("You must fill the same password again")
    .min(8, "Minimum 8 characters")
    .matches(/[A-Z]/, "Must contain one uppercase character")
    .matches(/[0-9]/, "Must contain one number")
    .oneOf(
      [Yup.ref("newPassword")],
      "Confirmation password must be equal to the new password"
    ),
});

const ResetPassword = ({ classes, queryString }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(ResetPasswordSchema),
  });

  const [errorResetPassword, setErrorResetPassword] = useState(false);

  const [passwordUpdated, setPasswordUpdated] = useState(false);

  const navigate = useNavigate();

  const [counter, setCounter] = useState(startCounter);

  const resetPasswordButtonContent = useRef("Reset password");

  const intervalId = useRef();

  const [
    forgotPasswordUpdate,
    // eslint-disable-next-line
    { data, loading, error, called },
  ] = useMutation(FORGOT_PASSWORD_UPDATE);

  useEffect(() => {
    intervalId.current = setInterval(() => {
      setCounter((previous) => previous - 1);
    }, 1000);

    return () => clearInterval(intervalId.current);
  }, []);

  useEffect(() => {
    if (passwordUpdated && counter === stopCounter) {
      clearInterval(intervalId.current);

      navigate(`/login`);
    }

    // eslint-disable-next-line
  }, [counter]);

  const onSubmit = async (data, event) => {
    resetPasswordButtonContent.current = <LoadingButton />;

    try {
      const passwordUpdated = (
        await forgotPasswordUpdate({
          variables: {
            code: queryString.code,
            password: data.newPassword,
          },
        })
      ).data.forgotPasswordUpdate;

      if (!passwordUpdated)
        throw new Error(
          "An error happened when updating the password, try to request another password change"
        );

      event.target.reset();

      setPasswordUpdated(passwordUpdated);

      setCounter(startCounter);

      toast.success("Password updated");
    } catch (error) {
      setErrorResetPassword(true);

      toast.error(error.message);
    }

    resetPasswordButtonContent.current = "Reset password";
  };

  return (
    <Box className={classes.boxContainer}>
      <Container maxWidth="xs" className={classes.containerReset}>
        <Header title="Password change" />

        {errorResetPassword && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={`${classes.grid} ${classes.gridErrorResetPassword}`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItemErrorResetPassword}
            >
              <Typography variant="body2" className={classes.textColor}>
                It was not possible to update your password. Try to request a
                new password change at{" "}
                <Link
                  underline="none"
                  href="/forgot-password"
                  className={classes.highlight}
                >
                  Forgot password
                </Link>
              </Typography>
            </Grid>
          </Grid>
        )}

        {passwordUpdated && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            className={`${classes.grid} ${classes.gridPasswordUpdated}`}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItemErrorResetPassword}
            >
              <Typography variant="body2" className={classes.textColor}>
                You're gonna be redirected to make login in {counter} seconds,
                or click{" "}
                <Link
                  underline="none"
                  href="/login"
                  className={classes.highlight}
                >
                  here
                </Link>{" "}
                to be redirected now.
              </Typography>
            </Grid>
          </Grid>
        )}

        {!errorResetPassword && !passwordUpdated && (
          <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              className={classes.grid}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.gridItem}
              >
                <TextField
                  name="newPassword"
                  type="password"
                  label="New password"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors.newPassword)}
                  inputRef={register}
                />

                <Typography className={classes.error}>
                  <ErrorMessage errors={errors} name="newPassword" />
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.gridItem}
              >
                <TextField
                  name="confirmPassword"
                  type="password"
                  label="Confirm password"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={Boolean(errors.confirmPassword)}
                  inputRef={register}
                />

                <Typography className={classes.error}>
                  <ErrorMessage errors={errors} name="confirmPassword" />
                </Typography>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.gridItem}
              >
                <Button variant="hovered" color="primary" type="submit">
                  {resetPasswordButtonContent.current}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}

        {errorResetPassword && (
          <Typography variant="body2" className={classes.textColor}>
            If you can't update your password clean your cache or try a different browser.
          </Typography>
        )}
      </Container>
    </Box>
  );
};

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .required("You must fill the email")
    .trim()
    .email("Invalid email"),
});

const ForgotPassword = ({
  classes,
  confirmation,
  setConfirmation,
  setEmail,
}) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(ForgotPasswordSchema),
  });

  const forgotPasswordButtonContent = useRef("Recover");

  const [
    forgotPassword,
    // eslint-disable-next-line
    { data, loading, error, called },
  ] = useMutation(FORGOT_PASSWORD);

  const onSubmit = async (data, event) => {
    forgotPasswordButtonContent.current = <LoadingButton />;

    try {
      const forgotPasswordEmailSent = (
        await forgotPassword({
          variables: { email: data.email.toLowerCase() },
        })
      ).data.forgotPassword;

      if (!forgotPasswordEmailSent)
        throw new Error(
          "An error happened when sending the email to verify your account email"
        );

      event.target.reset();

      setEmail(data.email.toLowerCase());

      setConfirmation(!confirmation);

      toast.success(
        `An email to verify your account email and instructions to reset your password was sent to "${data.email.toLowerCase()}", check your inbox`
      );
    } catch (error) {
      toast.error(error.message);
    }

    forgotPasswordButtonContent.current = "Recover";
  };

  return (
    <Box className={classes.boxContainer}>
      <Container maxWidth="xs" className={classes.container}>
        <Header title="Reset password" />

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.grid}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItem}
            >
              <TextField
                name="email"
                type="email"
                label="Email"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                error={Boolean(errors.email)}
                inputRef={register}
              />

              <Typography className={classes.error}>
                <ErrorMessage errors={errors} name="email" />
              </Typography>

              <Typography className={classes.forgotPassword}>
                Email associated with the account being recovered
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.gridItem}
            >
              <Button variant="hovered" color="primary" type="submit">
                {forgotPasswordButtonContent.current}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Container>
    </Box>
  );
};

const Confirmation = ({ classes, email }) => {
  const [
    forgotPassword,
    // eslint-disable-next-line
    { data, loading, error, called },
  ] = useMutation(FORGOT_PASSWORD);

  const sendItAgain = async (event) => {
    event.preventDefault();

    try {
      const forgotPasswordEmailSent = (
        await forgotPassword({
          variables: { email },
        })
      ).data.forgotPassword;

      if (!forgotPasswordEmailSent)
        throw new Error(
          "An error happened when sending the email to verify your account email"
        );

      toast.success(
        `An email to verify your account email and instructions to reset your password was sent to "${email}", check your inbox`
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Box className={classes.boxContainer}>
      <Container maxWidth="xs" className={classes.containerConfirmation}>
        <Header title="One more step…" />

        <Box className={classes.boxContentConfirmation}>
          <Typography variant="body2" className={classes.textColor}>
            We have sent you an email. Follow the instructions to change the
            password. Didn’t receive an email?{" "}
            <Link href="" className={classes.highlight} onClick={sendItAgain}>
              Send it again.
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

const ForgotPasswordForm = ({ queryString }) => {
  const [confirmation, setConfirmation] = useState(false);

  const [email, setEmail] = useState("");

  const classes = useStyles();

  if (Boolean(queryString.code))
    return <ResetPassword classes={classes} queryString={queryString} />;

  return confirmation ? (
    <Confirmation classes={classes} email={email} />
  ) : (
    <ForgotPassword
      classes={classes}
      confirmation={confirmation}
      setConfirmation={setConfirmation}
      setEmail={setEmail}
    />
  );
};

export default ForgotPasswordForm;
