import React from "react";

import { makeStyles, Button, Link } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  buttonGetStarted: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "700",
    fontSize: "1.1rem",
    marginRight: "0",
    border: `1px solid ${theme.palette.secondary.main}`,
    transition: "all .2s linear 0s",
    "&:hover": {
      color: theme.palette.secondary.main,
      backgroundColor: theme.palette.common.white,
    },
  },
}));

const GetStarted = ({ styles = {}, url = null, text = "Get started" }) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      component={Link}
      underline="none"
      color="inherit"
      className={classes.buttonGetStarted}
      style={styles}
      href={url ? url : "sign-up"}
    >
      {text}
    </Button>
  );
};

export default GetStarted;
