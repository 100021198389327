import React, { useEffect } from "react";

import { useDashboard } from "../contexts";

import { Welcome } from "../components/dashboard";

const DashboardHome = () => {
  const { setDashboard } = useDashboard();

  useEffect(() => {
    setDashboard((previousState) => ({ ...previousState, menu: "dashboard" }));

    // eslint-disable-next-line
  }, []);

  return <Welcome />;
};

export default DashboardHome;
