import React, { useState } from "react";

import {
  Box,
  IconButton,
  makeStyles,
  Drawer,
  useTheme,
  useMediaQuery,
} from "@material-ui/core";

import { Close as CloseIcon, Menu as MenuIcon } from "@material-ui/icons";

import { useDashboard } from "../../contexts";

import { OptionsDashboard, OptionsProfile, OptionsSupport } from "./index";

const useStyles = makeStyles((theme) => ({
  sideMenuButton: {
    margin: "0",
    width: "40px",
    height: "40px",
    color: theme.palette.primary.dark,
    "&:hover": {
      filter: "opacity(.8)",
    },
  },
  sideMenuButtonIcon: { fontSize: "1.4em" },
  sideMenuButtonIconClose: {
    fontSize: "1.4em",
    color: theme.palette.primary.dark,
    background: "transparent",
  },
  boxContainerDrawer: {
    margin: "0",
    height: "100%",
    maxHeight: "100vh",
    width: "90vw",
    maxWidth: "300px",
    boxSizing: "border-box",
  },
  bold: {
    "& > *": {
      fontWeight: "600",
    },
  },
}));

const HamburgerMenu = () => {
  const classes = useStyles();

  const { dashboard } = useDashboard();

  const [stateDrawer, setStateDrawer] = useState({
    right: false,
  });

  const theme = useTheme();

  const matches = useMediaQuery(theme.breakpoints.down("md"));

  const toggleDrawer = (side, open) => (event) => {
    if (
      event.type === "keydown" ||
      (event.type === "click" && (event.key === "Tab" || event.key === "Shift"))
    ) {
      return;
    }

    setStateDrawer({ ...stateDrawer, [side]: open });
  };

  return (
    <Box>
      <IconButton
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer("right", true)}
        edge="start"
        className={classes.sideMenuButton}
      >
        {!stateDrawer.right && (
          <MenuIcon className={classes.sideMenuButtonIcon} />
        )}

        {stateDrawer.right && (
          <CloseIcon className={classes.sideMenuButtonIconClose} />
        )}
      </IconButton>

      <Drawer
        anchor="right"
        open={stateDrawer.right && matches}
        onClose={toggleDrawer("right", false)}
        elevation={0}
      >
        <Box
          role="presentation"
          onKeyDown={toggleDrawer("right", false)}
          className={classes.boxContainerDrawer}
        >
          {/* Cards here */}

          {dashboard.menu === "dashboard" && <OptionsDashboard />}

          {dashboard.menu === "profile" && <OptionsProfile />}

          {dashboard.menu === "support" && <OptionsSupport />}
        </Box>
      </Drawer>
    </Box>
  );
};

export default HamburgerMenu;
